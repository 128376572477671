"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeTrackerEvents = exports.aeTracker = void 0;
const tracker_setup_1 = require("./tracker_setup");
//export tracker object so we can call tracking functions directly
exports.aeTracker = (0, tracker_setup_1.createTracker)(apiKey, amplitudeIdentifier);
function initializeTrackerEvents(tracker) {
    if (typeof tracker === 'undefined' || tracker === null) {
        console.log('Tracker is not defined');
        return null;
    }
    $(document).ready(function () {
        (0, tracker_setup_1.setupSignupPageViewTracker)(tracker);
        (0, tracker_setup_1.setupCancelButtonTracker)(tracker);
        (0, tracker_setup_1.setupUgradeButtonClicked)(tracker);
        (0, tracker_setup_1.setupSignupButonClicked)(tracker);
        (0, tracker_setup_1.setupSignupPlanSelected)(tracker);
        (0, tracker_setup_1.setupUpgradePlansPageViewed)(tracker);
        (0, tracker_setup_1.setupSignupSelectPlanButtonClicked)(tracker);
        (0, tracker_setup_1.setupSignupSelectPlanPageViewed)(tracker);
    });
    return tracker;
}
exports.initializeTrackerEvents = initializeTrackerEvents;
