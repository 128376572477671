/* Tooltip
---------------------------------------------------------------- */

export const tooltip = {
  timer: null,
  initialize: function () {
    // Find tooltip
    $(".tip").each(function () {
      // Get class
      let cls = $(this).hasClass("tipset");

      // Not set
      if (!cls) {
        // Object
        let obj = $(this);

        // Set tip attributes
        $(this).attr("data-title", $(this).attr("title"));
        // Remove title
        $(this).attr("title", "");
        // Append
        $(this).on("mouseover", function () {
          // Clear timeout
          if (this.timer != null) {
            clearTimeout(this.timer);
          }
          // Timed
          this.timer = setTimeout(function () {
            // Call
            tooltip.show(obj);
          }, 500);
        });
        $(this).on("mouseout", function () {
          // Clear timeout
          if (this.timer != null) {
            clearTimeout(this.timer);
          }
          // Hide
          tooltip.hide(obj);
        });

        // Add class
        $(this).addClass("tipset");
      }
    });
  },
  show: function (f) {
    // Show tooltip if link is enabled
    if (!$(f).hasClass("disabled")) {
      // Get object
      let obj = document.getElementById("tooltip");
      // Set variable
      let html = "";

      // .nav-t1 user context
      if (!obj) {
        // Set
        html += '<div class="tooltip" id="tooltip">';
        html += '	<div class="rel">';
        html += '		<div class="txt"></div>';
        html += '		<div class="arr"></div>';
        html += "	</div>";
        html += "</div>";

        // Append
        $("body").append(html);
      }

      // Get object positions
      let el = $(f).offset().left;
      let et = $(f).offset().top;
      let ew = $(f).outerWidth();
      let eh = $(f).outerHeight();

      // Arrow widht
      let aw = $("#tooltip .rel .arr").outerWidth();

      // Get text
      let txt = $(f).attr("data-title");
      // Update text
      $("#tooltip .rel .txt").html(txt);
      // Show tooltip
      $("#tooltip").show();
      // Get tooltip dimensions
      let th = $("#tooltip").outerHeight();
      let tw = $("#tooltip").outerWidth();
      $("#tooltip .rel .arr").removeAttr("style");
      // Default display (north)
      let dis = "n";
      // Display array
      let disarr = ["n", "ne", "e", "se", "s", "sw", "w", "nw"];

      // Loop to find matching class
      for (let i = 0; i < disarr.length; i++) {
        if ($(f).hasClass(disarr[i])) {
          dis = disarr[i];
          break;
        }
      }

      // Add class
      $("#tooltip").addClass(dis);
      // New positions
      let posleft = 0,
        postop = 0,
        posarr = 0;

      // North
      if (dis === "n") {
        posleft = el + ew / 2 - tw / 2;
        postop = et - th;
      }

      // North west
      if (dis === "nw") {
        posleft = el - 6;
        postop = et - th;
        posarr = ew / 2 - aw / 2 + 6;
      }

      // North east
      if (dis === "ne") {
        posleft = el + ew - tw;
        postop = et - th;
        posarr = ew / 2 - aw / 2;
      }

      // South east
      if (dis === "se") {
        posleft = el + ew - tw;
        postop = et + eh;
        posarr = ew / 2 - aw / 2;
      }

      // South west
      if (dis === "sw") {
        posleft = el;
        postop = et + eh;
        posarr = ew / 2 - aw / 2;
      }

      // South
      if (dis === "s") {
        posleft = el + ew / 2 - tw / 2;
        postop = et + eh;
      }

      // West
      if (dis === "w") {
        posleft = el - tw;
        postop = et + eh / 2 - th / 2;
      }

      // East
      if (dis === "e") {
        posleft = el + ew;
        postop = et + eh / 2 - th / 2;
      }

      // Set position
      $("#tooltip").css("left", posleft + "px");
      $("#tooltip").css("top", postop + "px");

      // Arrow
      if (dis == "ne" || dis == "se") {
        $("#tooltip .rel .arr").css("right", posarr + "px");
      } else if (dis == "sw" || dis == "nw") {
        $("#tooltip .rel .arr").css("left", posarr + "px");
      }
    }
  },
  hide: function () {
    // Hide
    $("#tooltip").hide();
    // Display array
    let disarr = ["n", "ne", "e", "se", "s", "sw", "w", "nw"];

    // Loop to remove classes
    for (let i = 0; i < disarr.length; i++) {
      $("#tooltip").removeClass(disarr[i]);
    }
  },
};

/* Attach */
$(document).ready(function () {
  tooltip.initialize();
});
