import { aeTracker, initializeTrackerEvents } from "../../typescript/app";
import { notificator } from "./notificator";
import { validate } from "./validate";
import { introt1 } from "./onboarding";
import { tooltip } from "./tooltip";
import { addresslookup } from "./address";
import { cookies } from "./cookies";
import { grecapform } from "./recaptcha";
import { fileload } from "./fileloader";
import _ from "./jquery.ui";
import _topmenu from "./topmenu";

/* global define */
/* global Stripe */
/* global google */
/* global Beacon */
/* global CodeMirror */
/* global AColorPicker */
/* global editor */

/* global codemrr2 */
/* global codemrr3 */
/* global codemrr4 */
/* global codemrr5 */
/* global codemrr6 */
/* global stripeApiKey */

/* Singleline functions
---------------------------------------------------------------- */

const checkIfString = function (data) {
  return typeof data === "string" || data instanceof String;
};

const parseResponse = function (data) {
  if (checkIfString(data)) {
    return JSON.parse(data);
  }

  return data;
};

const $d = function (d) {
  return document.getElementById(d);
};

/* Calendar
---------------------------------------------------------------- */
let calm = (function () {
  // Global variables
  let firstday = 0,
    evd = null,
    evo = null,
    quickshowobj = null,
    allowajaxcalls = false,
    loadmore = true,
    loadmoretimer = null;

  return {
    initialize: function () {
      // "crgr" should be changed
      // Calendar drop down
      $("#sidebarleft .scals a").on("click", function () {
        calm.caldropclick(this);
        return false;
      });
      $("#sidebarleft .scals .more").on("click", function () {
        calm.caldropmoreshow(this, event);
        return false;
      });
      $("#calsettings-drop .calendar-edit").on("click", function () {
        calm.caldropmoreedit(this);
        return false;
      });
      $("#calsettings-drop .calendar-share").on("click", function () {
        calm.caldropmoreshare(this);
        return false;
      });

      // Calendar nav buttons
      $(".opera .caltoday").on("click", function () {
        calm.caltoday(this);
        return false;
      });
      $(".opera .calprev").on("click", function () {
        calm.calprev(this);
        return false;
      });
      $(".opera .calnext").on("click", function () {
        calm.calnext(this);
        return false;
      });
      $(".opera .calview").on("click", function () {
        calm.calviewshow(this);
        return false;
      });
      $(".opera #calview-drop .calendar-month").on("click", function () {
        calm.calviewmonth(this);
        return false;
      });
      $(".opera #calview-drop .calendar-schedule").on("click", function () {
        calm.calviewschedule(this);
        return false;
      });
      $(".opera #calview-drop .allevents").on("click", function () {
        calm.calviewallevents(this, "upcoming");
        return false;
      });
      $(".opera .allevtsview").on("click", function () {
        calm.allevtsviewshow(this);
        return false;
      });
      $("#evtsall-opts .allevents-upcoming").on("click", function () {
        calm.allevtsviewopts(this, "upcoming");
        return false;
      });
      $("#evtsall-opts .allevents-past").on("click", function () {
        calm.allevtsviewopts(this, "past");
        return false;
      });

      // Event delete options
      $("#confirm-delete-opts #repeat-confirm-delete-opts-series li").on(
        "click",
        function () {
          calm.eventdeletetoggle(this);
          return false;
        }
      );
      $("#confirm-delete-opts .cancel").on("click", function () {
        calm.eventdeletehide(this);
        return false;
      });
      $("#confirm-delete-opts form").on("submit", function () {
        return calm.eventdeleteconfirmed(this);
      });
      $("#confirm-delete-opts").on("click", function (event) {
        event.stopPropagation();
      });

      // More events popup
      $("#moreevt-pop").on("click", function (event) {
        event.stopPropagation();
      });

      // All events filter drop (prevent)
      $("#allview-drop").on("click", function (event) {
        event.stopPropagation();
      });

      // Contrast
      calm.contrastinit();

      // Exists?
      if ($d("maincalendar")) {
        // Event details
        $("#pop-evt-det").on("click", function (event) {
          calm.eventmorehide();
          event.stopPropagation();
        });
        $("#pop-evt-det .view").on("click", function () {
          return calm.eventview(this);
        });
        $("#pop-evt-det .details").on("click", function () {
          calm.eventdetails(this);
          return false;
        });
        $("#pop-evt-det .edit").on("click", function () {
          calm.eventedit(this);
          return false;
        });
        $("#pop-evt-det .delete").on("click", function () {
          calm.eventdelete(this);
          return false;
        });
        $("#pop-evt-det .copy").on("click", function () {
          calm.eventcopy(this);
          return false;
        });
        $("#pop-evt-det .share").on("click", function () {
          calm.eventshare(this);
          return false;
        });
        $("#pop-evt-det .more").on("click", function () {
          calm.eventmoreshow(this);
          return false;
        });
        $("#pop-evt-det .close").on("click", function () {
          calm.eventhide(this);
          return false;
        });
        $("#pop-evt-new").on("click", function (event) {
          event.stopPropagation();
          colorpickerxo.colorevtqckhide();
        });
        $("#pop-evt-new .close").on("click", function () {
          calm.quickhide(this);
          return false;
        });
        $("#pop-evt-new #check-all-day").on("click", function () {
          calm.quickallday(this);
          return false;
        });
        $("#pop-evt-new .vtcolors ul li").on("click", function () {
          calm.colorpicked(this);
        });
        $("#pop-evt-new form").on("submit", function () {
          return calm.quickform(this);
        });
        $("#pop-evt-new .moreopts").on("click", function () {
          calm.quickmore(this);
          return false;
        });

        // Get first day
        let fd = $("#calendar-controle").attr("data-firstday");

        // Get time format
        let tfm = $("#calendar-controle").attr("data-timeformat");

        // Timepicker
        if (tfm == "2") {
          $(".picker .time").timepicker({
            showDuration: true,
            timeFormat: "H:i",
            step: 15,
          });
        } else {
          $(".picker .time").timepicker({
            showDuration: true,
            timeFormat: "g:ia",
            step: 15,
          });
        }

        // Datepicker
        $(".picker .date")
          .datepicker({
            showWeek: true,
            firstDay: fd,
            dateFormat: "M d, yy",
            autoclose: true,
            beforeShow: function (i) {
              return false;
            },
          })
          .on("change", function (e) {
            // Timed, get date
            setTimeout(function () {
              // Get start date
              let sda = $("#date_start").datepicker("getDate");
              let eda = $("#date_end").datepicker("getDate");
              // Set min date
              $(".picker #date_end").datepicker("option", "minDate", sda);
              // Get dates
              let sdaf =
                ("0" + (sda.getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + sda.getDate()).slice(-2) +
                "/" +
                sda.getFullYear();
              let edaf =
                ("0" + (eda.getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + eda.getDate()).slice(-2) +
                "/" +
                eda.getFullYear();

              // Update
              $(".picker #date_start").attr("data-date", sdaf);
              $(".picker #date_end").attr("data-date", edaf);
            }, 200);
          });

        // Date/time pair
        $(".picker").datepair({
          parseDate: function (input) {
            return $(input).datepicker("getDate");
          },
          updateDate: function (input, dateObj) {
            return $(input).datepicker("setDate", dateObj);
          },
        });

        // Load
        calm.loadcalendar();
        // Get pre-selected calendar view type
        let calviewtyp = cookies.read("calviewtyp");
        // Set if any
        if (calviewtyp == "month") {
          $("#calview-drop .calendar-month").click();
        } else if (calviewtyp == "schedule") {
          $("#calview-drop .calendar-schedule").click();
        } else if (calviewtyp == "allevents") {
          $("#calview-drop .allevents").click();
        }
      }
    },
    caltoday: function (f) {
      // Load today
      calm.gototoday();
    },
    calprev: function (f) {
      // Navigate
      calm.navprev();
    },
    calnext: function (f) {
      // Navigate
      calm.navnext();
    },
    calviewshow: function (f) {
      // Toogle
      if (!$(".opera .calview").hasClass("active")) {
        // Clear all
        contextmenu.clearallins();
        // Show
        $("#calview-drop").addClass("show");
        // Add class
        $(".opera .calview").addClass("active");
        // Unbind + Timed
        $(document).unbind(".calviewcontext");
        setTimeout(function () {
          $(document).on("click.calviewcontext", function () {
            calm.calviewhide();
          });
        }, 200);
      } else {
        // Hide
        calm.calviewhide();
      }
    },
    calviewhide: function (f) {
      // Show
      $("#calview-drop").removeClass("show");
      // Add class
      $(".opera .calview").removeClass("active");
      // Unbind
      $(document).unbind(".calviewcontext");
    },
    allevtsviewshow: function (f) {
      // Toogle
      if (!$(".opera .allevtsview").hasClass("active")) {
        // Clear all
        contextmenu.clearallins();
        // Show
        $("#allevtsview-drop").addClass("show");
        // Add class
        $(".opera .allevtsview").addClass("active");

        // Unbind + Timed
        $(document).unbind(".allevtsviewcontext");
        setTimeout(function () {
          $(document).on("click.allevtsviewcontext", function () {
            calm.allevtsviewhide();
          });
        }, 200);
      } else {
        // Hide
        calm.allevtsviewhide();
      }
    },
    allevtsviewhide: function (f) {
      // Show
      $("#allevtsview-drop").removeClass("show");
      // Add class
      $(".opera .allevtsview").removeClass("active");
      // Unbind
      $(document).unbind(".allevtsviewcontext");
    },
    allevtsviewopts: function (f, dir) {
      // Get calendar, month, year
      let cal = $("#calendar-controle").attr("data-calendar");
      let mon = $("#calendar-controle").attr("data-month");
      let yer = $("#calendar-controle").attr("data-year");

      // Change history
      window.history.pushState(
        {
          type: "calview",
          viewtype: "allevents",
          cal: cal,
          year: yer,
          month: mon,
        },
        null,
        "/calendars/" + cal + "/all-events/" + dir
      );

      // Update main path
      $("#calendar-controle").attr(
        "data-base-path",
        "/calendars/" + cal + "/all-events/" + dir
      );

      // Remove checked
      $("#allevtsview-drop a").removeClass("checked");

      // Set checked
      $(f).addClass("checked");

      // Update label
      $("#allview-lbl").text($(f).attr("data-lbl"));

      // Hide
      calm.allevtsviewhide();

      // Load
      calm.calviewalleventsload(f, dir, false, null);
    },
    calviewset: function (v) {
      // Reset context menu
      $(".opera #calview-drop a").removeClass("checked");
      $(".opera #allview-drop a").removeClass("checked");

      // Get view
      let dvt = $("#calendar-controle").attr("data-view-type");

      // Toggle
      if (dvt == "schedule") {
        // Menus
        $("#calview-opts").show();
        $("#evtsall-opts").hide();

        // Show / hide
        $("#calview-cal").hide();
        $("#calview-list").show();
        $("#allevents-list").hide();

        // Set active
        $("#calview-drop .calendar-schedule").addClass("checked");

        // Update label
        $("#calview-lbl").text(
          $("#calview-drop .calendar-schedule").attr("data-lbl")
        );
      } else if (dvt == "allevents") {
        // Menus
        $("#calview-opts").hide();
        $("#evtsall-opts").show();

        // Show / hide
        $("#calview-cal").hide();
        $("#calview-list").hide();
        $("#allevents-list").show();

        // Set active
        $("#calview-drop .allevents-upcoming").addClass("checked");
        //$('#allview-drop .allevents-upcoming').addClass('checked');

        // Update label
        $("#calview-lbl").text(
          $("#calview-drop .allevents-upcoming").attr("data-lbl")
        );
        //$('#allview-lbl').text($('#calview-drop .allevents-upcoming').attr('data-lbl'));

        // On init
        calm.calviewalleventsoninit();
      } else {
        // Menus
        $("#calview-opts").show();
        $("#evtsall-opts").hide();

        // Show / hide
        $("#calview-cal").show();
        $("#calview-list").hide();
        $("#allevents-list").hide();

        // Set active
        $("#calview-drop .calendar-month").addClass("checked");

        // Update label
        $("#calview-lbl").text(
          $("#calview-drop .calendar-month").attr("data-lbl")
        );
      }
    },
    calviewmonth: function (f) {
      // Set view type
      $("#calendar-controle").attr("data-view-type", "month");

      // Get calendar, month, year
      let cal = $("#calendar-controle").attr("data-calendar");
      let mon = $("#calendar-controle").attr("data-month");
      let yer = $("#calendar-controle").attr("data-year");

      // Not currently checked
      if (!$(f).hasClass("checked")) {
        // Change history
        window.history.pushState(
          {
            type: "calview",
            viewtype: "month",
            cal: cal,
            year: yer,
            month: mon,
          },
          null,
          "/calendars/" + cal + "/month/" + yer + "/" + mon
        );
      }

      // Reset
      $(".opera #calview-drop a").removeClass("checked");
      $(".opera #allview-drop a").removeClass("checked");

      // Set active
      $(f).addClass("checked");

      // Hide
      calm.calviewhide();

      // Update label
      $("#calview-lbl").text($(f).attr("data-lbl"));

      // Menus
      $("#calview-opts").show();
      $("#evtsall-opts").hide();

      // Show / hide
      $("#calview-cal").fadeIn("fast");
      $("#calview-list").hide();
      $("#allevents-list").hide();

      // Reset scroll top
      window.scrollTo(0, 0);

      // Hide
      calm.clearpopups();

      // Remember option
      cookies.create("calviewtyp", "month", 365);

      // Load calendar
      calm.loadcalendar();
    },
    calviewschedule: function (f) {
      // Set view type
      $("#calendar-controle").attr("data-view-type", "schedule");

      // Get calendar, month, year
      let cal = $("#calendar-controle").attr("data-calendar");
      let mon = $("#calendar-controle").attr("data-month");
      let yer = $("#calendar-controle").attr("data-year");

      // Not currently checked
      if (!$(f).hasClass("checked")) {
        // Change history
        window.history.pushState(
          {
            type: "calview",
            viewtype: "schedule",
            cal: cal,
            year: yer,
            month: mon,
          },
          null,
          "/calendars/" + cal + "/schedule/" + yer + "/" + mon
        );
      }

      // Reset
      $(".opera #calview-drop a").removeClass("checked");
      $(".opera #allview-drop a").removeClass("checked");

      // Set active
      $(f).addClass("checked");

      // Hide
      calm.calviewhide();

      // Update label
      $("#calview-lbl").text($(f).attr("data-lbl"));

      // Menus
      $("#calview-opts").show();
      $("#evtsall-opts").hide();

      // Show / hide
      $("#calview-cal").hide();
      $("#calview-list").fadeIn("fast");
      $("#allevents-list").hide();

      // Reset scroll top
      window.scrollTo(0, 0);

      // Hide
      calm.clearpopups();

      // Remember option
      cookies.create("calviewtyp", "schedule", 365);

      // Load calendar
      calm.loadcalendar();
    },
    calviewallevents: function (f, dir) {
      // Run if not checked
      if (!$(f).hasClass("checked")) {
        // Load events
        calm.calviewalleventsload(f, "upcoming", false, null);

        // Reset / set label and checks
        $("#allview-lbl").text(
          $("#allevtsview-drop .allevents-upcoming").attr("data-lbl")
        );
        $("#allevtsview-drop a").removeClass("checked");
        $("#allevtsview-drop .allevents-upcoming").addClass("checked");

        // Set view type
        $("#calendar-controle").attr("data-view-type", "allevents");

        // Get calendar, month, year
        let cal = $("#calendar-controle").attr("data-calendar");
        let mon = $("#calendar-controle").attr("data-month");
        let yer = $("#calendar-controle").attr("data-year");

        // Direction
        if (dir == "upcoming") {
          dir = "upcoming";
        } else {
          dir = "past";
        }

        // Not currently checked
        if (!$(f).hasClass("checked")) {
          // Change history
          window.history.pushState(
            {
              type: "calview",
              viewtype: "allevents",
              cal: cal,
              year: yer,
              month: mon,
            },
            null,
            "/calendars/" + cal + "/all-events/" + dir
          );
        }

        // Reset
        $(".opera #calview-drop a").removeClass("checked");

        // Set active
        $(f).addClass("checked");

        // Hide
        calm.calviewhide();

        // Update label
        $("#calview-lbl").text($(f).attr("data-lbl"));

        // Menus
        $("#calview-opts").hide();
        $("#evtsall-opts").show();

        // Show / hide
        $("#calview-cal").hide();
        $("#calview-list").hide();
        $("#allevents-list").fadeIn("fast");

        // Reset scroll top
        window.scrollTo(0, 0);

        // Hide
        calm.clearpopups();
      } else {
        // Hide
        calm.calviewhide();
        // Reset scroll top
        window.scrollTo(0, 0);
        // Hide
        calm.clearpopups();
      }

      // Remember option
      cookies.create("calviewtyp", "allevents", 365);
    },
    calviewalleventsload: function (f, dir, ajx, page) {
      // Notify
      notificator.show("Loading");

      // Get variables
      let tzi = $("#calendar-controle").attr("data-timezone");
      let cid = $("#calendar-controle").attr("data-id");
      let cal = $("#calendar-controle").attr("data-calendar");
      let mon = $("#calendar-controle").attr("data-month");
      let yer = $("#calendar-controle").attr("data-year");
      let dxr = "upcoming";

      // Get page (if any)
      let cpg = $(f).attr("data-page");

      // Page predefined?
      if (typeof page !== "undefined") {
        cpg = page;
      }

      if (typeof cpg !== "undefined") {
        cpg = "/" + cpg;
      } else {
        cpg = "";
      }

      // Set path
      let pth = "/calendars/" + cal + "/all-events/" + dir + cpg;

      // Update direction
      $("#aeevtlst").attr("data-dir", dir);

      // Set direction
      if (dir == "past") {
        dxr = "past";
      } else {
        dxr = "upcoming";
      }

      // Set data
      let dat = "ref=" + cid + "&tzid=" + tzi + "&dir=" + dxr + "&path=" + pth;

      // Page predefined?
      if (typeof page !== "undefined") {
        // Set page
        dat += "&page=" + page;
      }

      // Using ajax to move around pages
      if (ajx) {
        // Get page
        let cxg = $(f).attr("data-page");

        // Set page
        if (typeof cxg !== "undefined") {
          dat += "&page=" + cxg;
        }

        // Change history
        window.history.pushState(
          {
            type: "calview",
            viewtype: "allevents",
            cal: cal,
            year: yer,
            month: mon,
          },
          null,
          "/calendars/" + cal + "/all-events/" + dir + "/" + cxg
        );

        // Update main path
        $("#calendar-controle").attr(
          "data-base-path",
          "/calendars/" + cal + "/all-events/" + dir + "/" + cxg
        );
      }

      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax.calendar.events.upcoming.past.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Append
          $("#aeevtlst").html(data);

          // Remove
          notificator.break();

          // Loop, attach
          $("#aeevtlst .myevent").each(function () {
            // Get set class
            let clk = $(this).hasClass("clickattached");

            // Not set?
            if (!clk) {
              $(this).on("click", function (event) {
                event.stopPropagation();
                calm.eventshow(this, event);
              });
              // Add
              $(this).addClass("clickattached");
            }
          });

          // Today
          let cdte = new Date();
          // Set day variable
          let xcdy = calm.l2(cdte.getDate());
          let xcmo = calm.l2(cdte.getMonth() + 1);
          let xcyr = cdte.getFullYear();

          // Markup day
          $("#aeevtlst .to" + xcmo + xcdy + xcyr).addClass("currentday");

          // Attach pagination
          $("#aeevtlst .pagi-t1 a").on("click", function () {
            calm.calviewalleventsload(this, dxr, true, null);
            return false;
          });

          // Reset scroll top
          window.scrollTo(0, 0);
        },
      });
    },
    calviewalleventsoninit: function () {
      // Load (if loaded on page init)
      if ($("#aeevtlst").text() == "") {
        // Reset
        $("#calview-drop .allevents").addClass("checked");
        $("#allevtsview-drop a").removeClass("checked");

        // Set button label
        $("#calview-lbl").text($("#calview-drop .allevents").attr("data-lbl"));

        // Get details
        let dth = $("#calendar-controle").attr("data-base-path");
        let dar = dth.split("/");

        // Set page variable
        let psge = "";

        // Any page?
        if (typeof dar[5] !== "undefined" && !isNaN(dar[5])) {
          psge = dar[5];
        }

        // Upcoming/past?
        if (typeof dar[4] !== "undefined" && dar[4] == "past") {
          // Load
          calm.calviewalleventsload(this, "past", false, psge);
          // Check
          $("#allevtsview-drop .allevents-past").addClass("checked");
          // Update label
          $("#allview-lbl").text(
            $("#allevtsview-drop .allevents-past").attr("data-lbl")
          );
        } else {
          // Load
          calm.calviewalleventsload(this, "upcoming", false, psge);
          // Check
          $("#allevtsview-drop .allevents-upcoming").addClass("checked");
          // Update label
          $("#allview-lbl").text(
            $("#allevtsview-drop .allevents-upcoming").attr("data-lbl")
          );
        }
      }
    },
    caldropmoreshow: function (f, e) {
      // Reset active
      $("#sidebarleft .scals a").removeClass("active");

      // Set active
      $(f).parent("a").addClass("active");

      // Prevent click
      e.preventDefault();

      // Update unique
      $("#calsettings-drop").attr("data-unique", $(f).attr("data-unique"));

      // Get dimensions
      let pt = $(f).offset().top;
      let pl = $(f).offset().left;
      let ow = $(f).outerWidth();

      // Set top left
      $("#calsettings-drop").css("top", pt - 27 + "px");
      $("#calsettings-drop").css("left", pl + ow - 30 + "px");

      // Show
      setTimeout(function () {
        $("#calsettings-drop").addClass("show");
      }, 200);

      // Unbind
      $(document).unbind(".calsettings");
      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.calsettings", function () {
          calm.caldropmorehide();
        });
      }, 200);
    },
    caldropmorehide: function () {
      // Reset active
      $("#sidebarleft .scals a").removeClass("active");
      // Show
      $("#calsettings-drop").removeClass("show");
      // Unbind
      $(document).unbind(".calsettings");
    },
    caldropmoreedit: function () {
      // Get unique
      let cal = $("#calsettings-drop").attr("data-unique");
      // Goto
      location.href = "/calendars/" + cal + "/edit";
    },
    caldropmoreshare: function () {
      // Get unique
      let cal = $("#calsettings-drop").attr("data-unique");
      // Goto
      location.href = "/calendars/" + cal + "/view";
    },
    caldropclick: function (f) {
      // Get type
      let typ = $(f).attr("data-type");
      let uni = $(f).attr("data-unique");
      // Toggle
      if (typ == "newcalendar") {
        // Go
        location.href = "/calendars/new";
      } else {
        // If item is not checked
        let isl = $(f).parent("li").hasClass("check");

        if (isl) {
          // Load group loader
          calm.loadcalendarswitchshow("reload");
          // Go to
          setTimeout(function () {
            // Go to
            location.href = "/calendars/" + uni;
          }, 1200);
        } else {
          // Load group loader
          calm.loadcalendarswitchshow("new");
          // Go to
          setTimeout(function () {
            // Go to
            location.href = "/calendars/" + uni;
          }, 1200);
        }
      }
    },
    loadcalendarswitchshow: function (s) {
      // Get object
      let obj = $d("cal-load-switch");
      // Set variable
      let html = "";
      // Add to body
      if (!obj) {
        html += '<div class="cal-swi-bg" id="cal-load-switch-bg"></div>';
        html += '<div class="cal-swi" id="cal-load-switch">';
        html += '	<div class="ic">';
        html += '		<div class="im"></div>';
        html += "	</div>";
        html += '	<div class="tx">';

        if (s == "reload") {
          html += "		<p>Reloading calendar</p>";
        } else {
          html += "		<p>Switching calendar</p>";
        }

        html += "	</div>";
        html += "</div>";

        // Append
        $("body").append(html);

        // Show
        $("#cal-load-switch-bg").show();
        $("#cal-load-switch").show();
      }
    },
    loadcalendarswitchhide: function () {
      // Hide
      $("#load-switch-bg").hide();
      $("#load-switch").hide();
    },
    contrastinit: function () {
      // Loop
      $(".longevent .ino").each(function () {
        // Has the function not been run on object?
        if (!$(this).hasClass("cocobaca")) {
          // Get background color
          let hexcolor = $(this).css("backgroundColor");
          // Get hex color
          let hex = calm.contrasthexc(hexcolor);
          // Add class
          $(this).addClass("cocobaca");
          // Get contrast color
          let ccolr = calm.contrastinvert(hex, true).toLowerCase();
          // Add
          $(this).addClass("co" + ccolr);
        }
      });
    },
    contrastinvert: function (hex, bw) {
      // Any #
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }

      // Convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }

      // Variables
      let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

      // Black / white contrast only?
      if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "000000" : "FFFFFF";
      }

      // Invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);

      // Pad each with zeros and return
      return calm.contrastpad(r) + calm.contrastpad(g) + calm.contrastpad(b);
    },
    contrastpad: function (str, len) {
      // Length, slice, return
      len = len || 2;
      let zeros = new Array(len).join("0");

      return (zeros + str).slice(-len);
    },
    contrasthexc: function (colorval) {
      // Any color?
      let color = "";
      if (colorval == null) {
        color = "#FFFFFF";
      } else {
        try {
          // Get parts
          let parts = colorval.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
          // Delete
          delete parts[0];

          // Loop
          for (let i = 1; i <= 3; ++i) {
            parts[i] = parseInt(parts[i]).toString(16);
            if (parts[i].length == 1) parts[i] = "0" + parts[i];
          }

          // Join
          color = "#" + parts.join("");
        } catch (err) {
          color = "#FFFFFF";
        }
      }

      return color;
    },
    loadcalendar: function (dir, year, month) {
      // Get calendar id
      let cid = $("#calendar-controle").attr("data-id");
      let tzi = $("#calendar-controle").attr("data-timezone");
      let mon = $("#calendar-controle").attr("data-month");
      let yer = $("#calendar-controle").attr("data-year");
      let csrf = $("#calendar-controle").attr("data-csrf");

      // Allowed?
      if (loadmore) {
        // Load type
        if (dir == "prev" || dir == "next") {
          // Month/year does not exists
          if (
            !$("#calendar-list-items #calendar-" + mon + "-" + yer + "-list")
              .length
          ) {
            // Set data
            let dat =
              "ref=" +
              cid +
              "&tzid=" +
              tzi +
              "&mon=" +
              mon +
              "&yer=" +
              yer +
              "&direction=" +
              dir +
              "&desexpand=false&csrf=" +
              csrf;

            // Clear
            clearTimeout(loadmoretimer);

            // Timed load
            loadmoretimer = setTimeout(function () {
              // Disable
              loadmore = false;
              // Notify
              notificator.show("Loading");
              // POST
              $.ajax({
                type: "POST",
                url: "/source/web/templates/ajax.calendar.events.php",
                data: dat,
                cache: false,
                success: function (data) {
                  // Enable
                  loadmore = true;
                  // Unique lists
                  calm.uniquelists(data);
                  // Update
                  $("#calendar-list-items").append(data);
                  // Remove
                  notificator.break();
                  // Tooltip
                  tooltip.initialize();
                  // Reload calendar setup
                  calm.calendarsetup();
                },
              });
            }, 300);
          }
        } else if (dir == "existing") {
          // Update
          $("#calendar-controle").attr("data-month", month);
          $("#calendar-controle").attr("data-year", year);
          (mon = month), (yer = year);
          // Month/year does not exists
          if (
            !$("#calendar-list-items #calendar-" + mon + "-" + yer + "-list")
              .length
          ) {
            // Set data
            let dat =
              "ref=" +
              cid +
              "&tzid=" +
              tzi +
              "&mon=" +
              mon +
              "&yer=" +
              yer +
              "&direction=" +
              dir +
              "&desexpand=false&csrf=" +
              csrf;

            // Clear
            clearTimeout(loadmoretimer);

            // Timed load
            loadmoretimer = setTimeout(function () {
              // Disable
              loadmore = false;
              // Notify
              notificator.show("Loading");

              // POST
              $.ajax({
                type: "POST",
                url: "/source/web/templates/ajax.calendar.events.php",
                data: dat,
                cache: false,
                success: function (data) {
                  // Enable
                  loadmore = true;
                  // Unique lists
                  calm.uniquelists(data);
                  // Update
                  $("#calendar-list-items").append(data);
                  // Remove
                  notificator.break();
                  // Tooltip
                  tooltip.initialize();
                  // Reload calendar setup
                  calm.calendarsetup();
                },
              });
            }, 300);
          }
        } else {
          // Notify
          notificator.show("Loading");

          // Set data
          let dat =
            "ref=" +
            cid +
            "&tzid=" +
            tzi +
            "&mon=" +
            mon +
            "&yer=" +
            yer +
            "&direction=now&desexpand=false&csrf=" +
            csrf;

          // Ajax
          $.ajax({
            type: "POST",
            url: "/source/web/templates/ajax.calendar.events.php",
            data: dat,
            cache: false,
            success: function (data) {
              // Enable
              loadmore = true;
              // Unique lists
              calm.uniquelists(data);
              // Update
              $("#calendar-list-items").html(data);
              // Remove
              notificator.break();
              // Tooltip
              tooltip.initialize();
              // Reload calendar setup
              calm.calendarsetup();
            },
          });
        }

        // Create calendar
        calm.calendarsetup();
      }
    },
    uniquelists: function (data) {
      // Loop through events
      $(data)
        .filter(".calendarlist")
        .each(function () {
          // Get ID
          let fid = $(this).attr("id");

          // Remove most recent list
          $("#calendar-list-items")
            .find("#" + fid)
            .remove();
        });
    },
    calendarsetup: function () {
      // Variables
      let M,
        D,
        pdy,
        pmo,
        pyr,
        yr,
        mo,
        bgn,
        d,
        html,
        dy,
        pos,
        ld,
        ctr,
        lmonth,
        lyear;

      // Get first day
      firstday = $("#calendar-controle").attr("data-firstday");
      // First day not defined?
      if (firstday != "0" && firstday != "1") {
        firstday = 0;
      }
      // Reset main calendar
      $("#maincalendar").html("");
      // Get month + year
      lmonth = $("#calendar-controle").attr("data-month");
      lyear = $("#calendar-controle").attr("data-year");

      // Set month to javascript month
      lmonth = parseInt(lmonth) - 1;
      // Labels
      M = new Array(
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      );
      let MA = new Array(
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      );

      // Week labels
      if (firstday == "0") {
        D = new Array("Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat");
      } else {
        D = new Array("Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun");
      }

      pdy = new Date(lyear, lmonth);
      // Present month
      pmo = pdy.getMonth();
      // Present year
      pyr = pdy.getYear();

      // Year 2000 fix
      if (pyr < 2000) {
        pyr = pyr + 1900;
      }

      // Present year
      yr = pyr;
      // Present month
      mo = pmo;
      // Assign to date
      bgn = new Date(yr, mo, 1);
      // Reset output
      html = "";

      // Get day (starts with sunday or monday)
      if (firstday == "0") {
        // Set day
        dy = bgn.getDay();
      } else {
        // Set day
        dy = bgn.getDay() == 0 ? 7 : bgn.getDay();
      }

      // Evaluate
      yr = eval(yr);

      // Days in months
      d = "312831303130313130313031";

      // Stuff
      if (yr / 4 == Math.floor(yr / 4)) {
        d = d.substring(0, 2) + "29" + d.substring(4, d.length);
      }

      // And stuff
      pos = mo * 2;
      // And stuff
      ld = eval(d.substring(pos, pos + 2));
      // Collect
      html += "<table><tr>";
      // Reset
      ctr = 0;

      // Start at sunday or monday
      if (firstday == "0") {
        // Loop (first row)
        for (let i = 0; i < 7; i++) {
          if (i < dy) {
            // Collect
            if (i == 0 || i == 6) {
              html +=
                '<td class="td1 blank weekend cl' +
                i +
                '"><div class="dna">' +
                D[i] +
                "</div>&nbsp;</td>";
            } else {
              html +=
                '<td class="td1 blank cl' +
                i +
                '"><div class="dna">' +
                D[i] +
                "</div>&nbsp;</td>";
            }
          } else {
            // Add
            ctr++;

            // Collect
            if (i == 0 || i == 6) {
              html +=
                '<td class="td1 weekend ddok cl' +
                i +
                " to" +
                calm.l2(mo + 1) +
                calm.l2(ctr) +
                yr +
                '" data-month="' +
                calm.l2(mo + 1) +
                '" data-day="' +
                calm.l2(ctr) +
                '" data-year="' +
                yr +
                '"><div class="dna">' +
                D[i] +
                '</div><div class="tnu"><span>' +
                ctr +
                '</span></div><div id="evts' +
                calm.l2(mo + 1) +
                calm.l2(ctr) +
                yr +
                '" class="list"></div></td>';
            } else {
              html +=
                '<td class="td1 ddok cl' +
                i +
                " to" +
                calm.l2(mo + 1) +
                calm.l2(ctr) +
                yr +
                '" data-month="' +
                calm.l2(mo + 1) +
                '" data-day="' +
                calm.l2(ctr) +
                '" data-year="' +
                yr +
                '"><div class="dna">' +
                D[i] +
                '</div><div class="tnu"><span>' +
                ctr +
                '</span></div><div id="evts' +
                calm.l2(mo + 1) +
                calm.l2(ctr) +
                yr +
                '" class="list"></div></td>';
            }
          }
        }

        // Collect
        html += "</tr><tr>";

        // Loop
        while (ctr < ld) {
          for (let i = 0; i < 7; i++) {
            // Add
            ctr++;

            // Collect
            if (ctr > ld) {
              if (i == 0 || i == 6) {
                html +=
                  '<td class="td2 blank weekend cl' +
                  i +
                  '"><div class="dna">' +
                  D[i] +
                  "</div>&nbsp;</td>";
              } else {
                html +=
                  '<td class="td2 blank cl' +
                  i +
                  '"><div class="dna">' +
                  D[i] +
                  "</div>&nbsp;</td>";
              }
            } else {
              if (i == 0 || i == 6) {
                html +=
                  '<td class="td2 weekend ddok cl' +
                  i +
                  " to" +
                  calm.l2(mo + 1) +
                  calm.l2(ctr) +
                  yr +
                  '" data-month="' +
                  calm.l2(mo + 1) +
                  '" data-day="' +
                  calm.l2(ctr) +
                  '" data-year="' +
                  yr +
                  '"><div class="dna">' +
                  D[i] +
                  '</div><div class="tnu"><span>' +
                  ctr +
                  '</span></div><div id="evts' +
                  calm.l2(mo + 1) +
                  calm.l2(ctr) +
                  yr +
                  '" class="list"></div></td>';
              } else {
                html +=
                  '<td class="td2 ddok cl' +
                  i +
                  " to" +
                  calm.l2(mo + 1) +
                  calm.l2(ctr) +
                  yr +
                  '" data-month="' +
                  calm.l2(mo + 1) +
                  '" data-day="' +
                  calm.l2(ctr) +
                  '" data-year="' +
                  yr +
                  '"><div class="dna">' +
                  D[i] +
                  '</div><div class="tnu"><span>' +
                  ctr +
                  '</span></div><div id="evts' +
                  calm.l2(mo + 1) +
                  calm.l2(ctr) +
                  yr +
                  '" class="list"></div></td>';
              }
            }
          }

          // Collect
          html += "</tr><tr>";
        }
      } else {
        // Loop (first row)
        for (let i = 1; i <= 7; i++) {
          if (i < dy) {
            // Collect
            if (i == 6 || i == 7) {
              html +=
                '<td class="td1 blank weekend cl' +
                i +
                '"><div class="dna">' +
                D[i - 1] +
                "</div>&nbsp;</td>";
            } else {
              html +=
                '<td class="td1 blank cl' +
                i +
                '"><div class="dna">' +
                D[i - 1] +
                "</div>&nbsp;</td>";
            }
          } else {
            // Add
            ctr++;

            // Collect
            if (i == 6 || i == 7) {
              html +=
                '<td class="td1 weekend ddok cl' +
                i +
                " to" +
                calm.l2(mo + 1) +
                calm.l2(ctr) +
                yr +
                '" data-month="' +
                calm.l2(mo + 1) +
                '" data-day="' +
                calm.l2(ctr) +
                '" data-year="' +
                yr +
                '"><div class="dna">' +
                D[i - 1] +
                '</div><div class="tnu"><span>' +
                ctr +
                '</span></div><div id="evts' +
                calm.l2(mo + 1) +
                calm.l2(ctr) +
                yr +
                '" class="list"></div></td>';
            } else {
              html +=
                '<td class="td1 ddok cl' +
                i +
                " to" +
                calm.l2(mo + 1) +
                calm.l2(ctr) +
                yr +
                '" data-month="' +
                calm.l2(mo + 1) +
                '" data-day="' +
                calm.l2(ctr) +
                '" data-year="' +
                yr +
                '"><div class="dna">' +
                D[i - 1] +
                '</div><div class="tnu"><span>' +
                ctr +
                '</span></div><div id="evts' +
                calm.l2(mo + 1) +
                calm.l2(ctr) +
                yr +
                '" class="list"></div></td>';
            }
          }
        }

        // Collect
        html += "</tr><tr>";

        // Loop
        while (ctr < ld) {
          for (let i = 1; i <= 7; i++) {
            // Add
            ctr++;
            // Collect
            if (ctr > ld) {
              if (i == 6 || i == 7) {
                html +=
                  '<td class="td2 blank weekend cl' +
                  i +
                  '"><div class="dna">' +
                  D[i - 1] +
                  "</div>&nbsp;</td>";
              } else {
                html +=
                  '<td class="td2 blank cl' +
                  i +
                  '"><div class="dna">' +
                  D[i - 1] +
                  "</div>&nbsp;</td>";
              }
            } else {
              if (i == 6 || i == 7) {
                html +=
                  '<td class="td2 weekend ddok cl' +
                  i +
                  " to" +
                  calm.l2(mo + 1) +
                  calm.l2(ctr) +
                  yr +
                  '" data-month="' +
                  calm.l2(mo + 1) +
                  '" data-day="' +
                  calm.l2(ctr) +
                  '" data-year="' +
                  yr +
                  '"><div class="dna">' +
                  D[i - 1] +
                  '</div><div class="tnu"><span>' +
                  ctr +
                  '</span></div><div id="evts' +
                  calm.l2(mo + 1) +
                  calm.l2(ctr) +
                  yr +
                  '" class="list"></div></td>';
              } else {
                html +=
                  '<td class="td2 ddok cl' +
                  i +
                  " to" +
                  calm.l2(mo + 1) +
                  calm.l2(ctr) +
                  yr +
                  '" data-month="' +
                  calm.l2(mo + 1) +
                  '" data-day="' +
                  calm.l2(ctr) +
                  '" data-year="' +
                  yr +
                  '"><div class="dna">' +
                  D[i - 1] +
                  '</div><div class="tnu"><span>' +
                  ctr +
                  '</span></div><div id="evts' +
                  calm.l2(mo + 1) +
                  calm.l2(ctr) +
                  yr +
                  '" class="list"></div></td>';
              }
            }
          }

          // Collect
          html += "</tr><tr>";
        }
      }

      // Collect
      html += "</tr></table>";
      // Append
      $("#maincalendar").html(html);
      // Remove last row
      $("#maincalendar").find("tr:last").remove();
      // Reset
      html = "";

      // Generate containers for calendar list
      for (let i = 1; i <= ld; i++) {
        // Collect
        html +=
          '<div id="evtlst' + calm.l2(mo + 1) + calm.l2(i) + yr + '"></div>';
      }

      // Append divs
      $("#calendarlist").html(html);
      // Update month year label
      $("#month-lbl").text(M[mo] + " " + yr);
      // Attach
      $("#maincalendar .ddok").on("click", function () {
        calm.evtclick(this, event);
        return false;
      });

      // Today
      let cdte = new Date();

      // Set day variable
      let xcdy = calm.l2(cdte.getDate());
      let xcmo = calm.l2(cdte.getMonth() + 1);
      let xcyr = cdte.getFullYear();

      // Markup day
      $(".to" + xcmo + xcdy + xcyr).addClass("currentday");
      $("#evtlst" + xcmo + xcdy + xcyr).addClass("currentday");

      // Adjust cells
      calm.adjustcells();

      // Get events and apply to calendar view
      calm.eventsincalendar();
    },
    eventsincalendar: function () {
      // Loop through events
      $(".calendarlist .list ul li").each(function () {
        // Get attributes
        let tit = $(this).attr("data-title");
        let rel = $(this).attr("data-id");
        let uni = $(this).attr("data-unique");
        let tst = $(this).attr("data-start-time");
        let set = $(this).attr("data-start-end-time");
        let day = $(this).attr("data-day");
        let mon = $(this).attr("data-month");
        let yer = $(this).attr("data-year");
        let drp = $(this).attr("data-repeat");
        let xcy = $(this).attr("data-xcopy");
        let rru = $(this).attr("data-rrule");
        let col = $(this).attr("data-color");
        let mdsd = $(this).attr("data-start-day");
        let mdsm = $(this).attr("data-start-month");
        let mdsy = $(this).attr("data-start-year");
        let mded = $(this).attr("data-end-day");
        let mdem = $(this).attr("data-end-month");
        let mdey = $(this).attr("data-end-year");
        let alda = $(this).attr("data-all-day");
        let ymd = mon + day + yer;

        if (alda == "true") {
          tst = "All day";
          set = "All day";
        }

        // Multiple days event?
        if (mdsm !== mdem || mdsd !== mded || mdsy !== mdey) {
          // Set start and end dates
          let start = new Date(mdsm + "/" + mdsd + "/" + mdsy);
          let end = new Date(mdem + "/" + mded + "/" + mdey);

          // Loop
          while (start <= end) {
            // Get date variables
            let mo = start.getMonth() + 1;
            let da = start.getDate();
            let yr = start.getFullYear();

            // Fix
            if (mo < 10) {
              mo = "0" + mo;
            }
            if (da < 10) {
              da = "0" + da;
            }

            // Override
            ymd = String(mo) + String(da) + String(yr);

            // Append to calendar
            if (drp == "true") {
              $("#evts" + ymd).append(
                '<div class="myevent" data-unique="' +
                  uni +
                  '" data-id="' +
                  rel +
                  '" data-color="' +
                  col +
                  '" data-title="' +
                  tit +
                  '" data-repeat="' +
                  drp +
                  '" data-xcopy="' +
                  xcy +
                  '" data-start-month="' +
                  mdsm +
                  '" data-start-day="' +
                  mdsd +
                  '" data-start-year="' +
                  mdsy +
                  '" data-end-month="' +
                  mdem +
                  '" data-end-day="' +
                  mded +
                  '" data-end-year="' +
                  mdey +
                  '" data-all-day="' +
                  alda +
                  '"><div class="inw"><div class="ti">' +
                  tst +
                  '</div><div class="tia">' +
                  set +
                  '</div><div class="lbl">' +
                  tit +
                  '</div><div class="dot"></div></div><span class="rpt">S</span></div>'
              );
            } else if (xcy == "true") {
              $("#evts" + ymd).append(
                '<div class="myevent" data-unique="' +
                  uni +
                  '" data-id="' +
                  rel +
                  '" data-color="' +
                  col +
                  '" data-title="' +
                  tit +
                  '" data-repeat="' +
                  drp +
                  '" data-xcopy="' +
                  xcy +
                  '" data-start-month="' +
                  mdsm +
                  '" data-start-day="' +
                  mdsd +
                  '" data-start-year="' +
                  mdsy +
                  '" data-end-month="' +
                  mdem +
                  '" data-end-day="' +
                  mded +
                  '" data-end-year="' +
                  mdey +
                  '" data-all-day="' +
                  alda +
                  '"><div class="inw"><div class="ti">' +
                  tst +
                  '</div><div class="tia">' +
                  set +
                  '</div><div class="lbl">' +
                  tit +
                  '</div><div class="dot"></div></div><span class="xtt">EX</span></div>'
              );
            } else if (rru != "") {
              $("#evts" + ymd).append(
                '<div class="myevent" data-unique="' +
                  uni +
                  '" data-id="' +
                  rel +
                  '" data-color="' +
                  col +
                  '" data-title="' +
                  tit +
                  '" data-repeat="' +
                  drp +
                  '" data-xcopy="' +
                  xcy +
                  '" data-start-month="' +
                  mdsm +
                  '" data-start-day="' +
                  mdsd +
                  '" data-start-year="' +
                  mdsy +
                  '" data-end-month="' +
                  mdem +
                  '" data-end-day="' +
                  mded +
                  '" data-end-year="' +
                  mdey +
                  '" data-all-day="' +
                  alda +
                  '"><div class="inw"><div class="ti">' +
                  tst +
                  '</div><div class="tia">' +
                  set +
                  '</div><div class="lbl">' +
                  tit +
                  '</div><div class="dot"></div></div><span class="rru">R</span></div>'
              );
            } else {
              $("#evts" + ymd).append(
                '<div class="myevent" data-unique="' +
                  uni +
                  '" data-id="' +
                  rel +
                  '" data-color="' +
                  col +
                  '" data-title="' +
                  tit +
                  '" data-repeat="' +
                  drp +
                  '" data-xcopy="' +
                  xcy +
                  '" data-start-month="' +
                  mdsm +
                  '" data-start-day="' +
                  mdsd +
                  '" data-start-year="' +
                  mdsy +
                  '" data-end-month="' +
                  mdem +
                  '" data-end-day="' +
                  mded +
                  '" data-end-year="' +
                  mdey +
                  '" data-all-day="' +
                  alda +
                  '"><div class="inw"><div class="ti">' +
                  tst +
                  '</div><div class="tia">' +
                  set +
                  '</div><div class="lbl">' +
                  tit +
                  '</div><div class="dot"></div></div></div>'
              );
            }

            // Add one day
            let newDate = start.setDate(start.getDate() + 1);

            start = new Date(newDate);
          }
        } else {
          // Append to calendar
          if (drp == "true") {
            $("#evts" + ymd).append(
              '<div class="myevent" data-unique="' +
                uni +
                '" data-id="' +
                rel +
                '" data-color="' +
                col +
                '" data-title="' +
                tit +
                '" data-repeat="' +
                drp +
                '" data-xcopy="' +
                xcy +
                '" data-start-month="' +
                mdsm +
                '" data-start-day="' +
                mdsd +
                '" data-start-year="' +
                mdsy +
                '" data-end-month="' +
                mdem +
                '" data-end-day="' +
                mded +
                '" data-end-year="' +
                mdey +
                '" data-all-day="' +
                alda +
                '"><div class="inw"><div class="ti">' +
                tst +
                '</div><div class="tia">' +
                set +
                '</div><div class="lbl">' +
                tit +
                '</div><div class="dot"></div></div><span class="rpt">S</span></div>'
            );
          } else if (xcy == "true") {
            $("#evts" + ymd).append(
              '<div class="myevent" data-unique="' +
                uni +
                '" data-id="' +
                rel +
                '" data-color="' +
                col +
                '" data-title="' +
                tit +
                '" data-repeat="' +
                drp +
                '" data-xcopy="' +
                xcy +
                '" data-start-month="' +
                mdsm +
                '" data-start-day="' +
                mdsd +
                '" data-start-year="' +
                mdsy +
                '" data-end-month="' +
                mdem +
                '" data-end-day="' +
                mded +
                '" data-end-year="' +
                mdey +
                '" data-all-day="' +
                alda +
                '"><div class="inw"><div class="ti">' +
                tst +
                '</div><div class="tia">' +
                set +
                '</div><div class="lbl">' +
                tit +
                '</div><div class="dot"></div></div><span class="xtt">EX</span></div>'
            );
          } else if (rru != "") {
            $("#evts" + ymd).append(
              '<div class="myevent" data-unique="' +
                uni +
                '" data-id="' +
                rel +
                '" data-color="' +
                col +
                '" data-title="' +
                tit +
                '" data-repeat="' +
                drp +
                '" data-xcopy="' +
                xcy +
                '" data-start-month="' +
                mdsm +
                '" data-start-day="' +
                mdsd +
                '" data-start-year="' +
                mdsy +
                '" data-end-month="' +
                mdem +
                '" data-end-day="' +
                mded +
                '" data-end-year="' +
                mdey +
                '" data-all-day="' +
                alda +
                '"><div class="inw"><div class="ti">' +
                tst +
                '</div><div class="tia">' +
                set +
                '</div><div class="lbl">' +
                tit +
                '</div><div class="dot"></div></div><span class="rru">R</span></div>'
            );
          } else {
            $("#evts" + ymd).append(
              '<div class="myevent" data-unique="' +
                uni +
                '" data-id="' +
                rel +
                '" data-color="' +
                col +
                '" data-title="' +
                tit +
                '" data-repeat="' +
                drp +
                '" data-xcopy="' +
                xcy +
                '" data-start-month="' +
                mdsm +
                '" data-start-day="' +
                mdsd +
                '" data-start-year="' +
                mdsy +
                '" data-end-month="' +
                mdem +
                '" data-end-day="' +
                mded +
                '" data-end-year="' +
                mdey +
                '" data-all-day="' +
                alda +
                '"><div class="inw"><div class="ti">' +
                tst +
                '</div><div class="tia">' +
                set +
                '</div><div class="lbl">' +
                tit +
                '</div><div class="dot"></div></div></div>'
            );
          }
        }
      });

      // Get events
      $("#calview-cal .myevent").each(function () {
        // Get start and end day
        let smon = $(this).attr("data-start-month");
        let sday = $(this).attr("data-start-day");
        let syear = $(this).attr("data-start-year");
        let emon = $(this).attr("data-end-month");
        let eday = $(this).attr("data-end-day");
        let eyear = $(this).attr("data-end-year");
        let dun = $(this).attr("data-unique");
        let did = $(this).attr("data-id");
        let dti = $(this).attr("data-title");
        let dst = $(this).attr("data-start");
        let ded = $(this).attr("data-end");
        let dre = $(this).attr("data-repeat");
        let dxc = $(this).attr("data-xcopy");
        let dcl = $(this).attr("data-color");
        let dal = $(this).attr("data-all-day");
        let elm = $(this);
        // Element
        let cox = $(elm).html();

        // Multiple days event?
        if (
          smon !== emon ||
          sday !== eday ||
          syear !== eyear ||
          dal == "true"
        ) {
          // Set start and end dates
          let start = new Date(smon + "/" + sday + "/" + syear);
          let end = new Date(emon + "/" + eday + "/" + eyear);
          // Set skip variable
          let skip = false;
          // Skip event if all day but same day
          if (smon + sday + syear == emon + eday + eyear) {
            skip = true;
          }

          if (!skip) {
            // Loop
            while (start <= end) {
              // Get date variables
              let mo = start.getMonth() + 1;
              let da = start.getDate();
              let yr = start.getFullYear();
              // Fix
              if (mo < 10) {
                mo = "0" + mo;
              }
              if (da < 10) {
                da = "0" + da;
              }
              // String
              mo = String(mo);
              da = String(da);
              yr = String(yr);

              // Append
              if ($("#evts" + mo + da + yr + " .longevent").length > 0) {
                $("#evts" + mo + da + yr + " .longevent").after(
                  '<div class="longeventtmp muldaysevents" data-unique="' +
                    dun +
                    '" data-id="' +
                    did +
                    '" data-color="' +
                    dcl +
                    '" data-title="' +
                    dti +
                    '" data-start-day="' +
                    sday +
                    '" data-repeat="' +
                    dre +
                    '" data-xcopy="' +
                    dxc +
                    '"><div class="ino">' +
                    cox +
                    "</div></div>"
                );
              } else {
                $("#evts" + mo + da + yr).prepend(
                  '<div class="longevent muldaysevents" data-unique="' +
                    dun +
                    '" data-id="' +
                    did +
                    '" data-color="' +
                    dcl +
                    '" data-title="' +
                    dti +
                    '" data-start-day="' +
                    sday +
                    '" data-repeat="' +
                    dre +
                    '" data-xcopy="' +
                    dxc +
                    '"><div class="ino">' +
                    cox +
                    "</div></div>"
                );
              }

              // Add one day
              const newDate = start.setDate(start.getDate() + 1);

              start = new Date(newDate);
            }

            // Hide
            $(elm).hide();
          }
        }
      });

      // Remove "myevent" that contain multiple days
      $("#calview-cal .myevent").each(function () {
        // Get start and end day
        let smon = $(this).attr("data-start-month");
        let sday = $(this).attr("data-start-day");
        let syear = $(this).attr("data-start-year");
        let emon = $(this).attr("data-end-month");
        let eday = $(this).attr("data-end-day");
        let eyear = $(this).attr("data-end-year");
        let dal = $(this).attr("data-all-day");
        let elm = $(this);

        // Multiple days event?
        if (
          smon !== emon ||
          sday !== eday ||
          syear !== eyear ||
          dal == "true"
        ) {
          // If all day but same day, or all day with multiple days
          if (smon + sday + syear == emon + eday + eyear) {
            // Add
            $(elm).addClass("longevent alldayone");
            // Get content
            let ct = $(elm).html();
            // Wrap
            $(elm).html('<div class="ino">' + ct + "</div>");
          } else {
            // Hide
            $(elm).hide();
          }
        }
      });

      // Replace temp class (if any)
      $(".longeventtmp").addClass("longevent").removeClass("longeventtmp");

      /* Fix all day events for calendar view
            ------------------------------------------------- */
      // Get rows
      $(".calview tr").each(function () {
        // Unique ids collection
        let unq = [];
        // Set row
        let row = $(this);

        // Find multiple days in row
        $(this)
          .find(".muldaysevents")
          .each(function () {
            // Get unique id
            let un = $(this).attr("data-unique");
            let dy = parseInt($(this).attr("data-start-day"));
            let mps = false;

            // Add item unique identifier
            $(this).addClass("uni" + un);

            // Value already added in array?
            for (let i = 0; i < unq.length; ++i) {
              // Match
              if (unq[i][0] == un) {
                mps = true;
                // Break loop
                break;
              }
            }

            // Add to array
            if (!mps) {
              // Add
              unq.push([un, dy]);
            }
          });

        // Sort asc
        unq.sort(function (a, b) {
          return a[0] - b[0];
        });

        // Reverse
        unq.reverse();

        // Loop unique multiple event ids
        for (let i = 0; i < unq.length; ++i) {
          // Get all lists
          $(row)
            .find(".list")
            .each(function () {
              // Prepend
              $(this).prepend(
                '<div class="mvplchld con' +
                  unq[i][0] +
                  '" data-unique="' +
                  unq[i][0] +
                  '"></div>'
              );
            });

          // First / last classes
          $(row)
            .find(".uni" + unq[i][0])
            .first()
            .addClass("lngfst");
          $(row)
            .find(".uni" + unq[i][0])
            .last()
            .addClass("lnglst");
        }

        // Find multiple day event, move to placeholders
        $(this)
          .find(".list")
          .each(function () {
            // The list
            let list = $(this);
            // Get events
            $(list)
              .find(".muldaysevents")
              .each(function () {
                // Get unique id
                let un = $(this).attr("data-unique");
                // Add event in placeholder
                $(list)
                  .find(".con" + un)
                  .html($(this).clone(true));
                // Delete
                $(this).remove();
              });
          });

        // Unique ids collection
        unq = [];

        // Find multiple days in row
        $(this)
          .find(".muldaysevents")
          .each(function () {
            // Get unique id
            let un = $(this).attr("data-unique");

            // Value already added in array?
            if (unq.indexOf(un) > -1) {
              // Add
              $(this).addClass("longchld");
            } else {
              // Add
              unq.push(un);
            }
          });
      });

      // Array
      let pdec = [],
        pdrp = [],
        color_count = 1;

      // Get already defined colors or create new ones
      $(".calendarlist .list ul li").each(function () {
        // Get color
        let un = $(this).attr("data-unique");
        let cl = $(this).attr("data-color");
        let rp = $(this).attr("data-repeat");
        let rr = $(this).attr("data-repeat-relation");
        let alfd = false;
        let ari = {};

        // Only events with a unique id (non-repeating events)
        if (typeof un !== "undefined" && rp != "true") {
          // Is the item already in the array?
          for (let i = 0; i < pdec.length; ++i) {
            if (pdec[i].unique == un) {
              alfd = true;
              break;
            }
          }

          // Not found in array, add it
          if (!alfd) {
            // Color count
            if (color_count == 18) {
              color_count = 1;
            } else {
              color_count++;
            }

            // Add to array
            ari = { unique: un, color: color_count };
            // Push
            pdec.push(ari);
          }
        }

        // Only repeating events
        if (typeof un !== "undefined" && rp == "true") {
          // Found in array variable
          alfd = false;

          // Is the item already in the array?
          for (let i = 0; i < pdrp.length; ++i) {
            if (pdrp[i].repeatid == rr) {
              alfd = true;
              break;
            }
          }

          // Not found in array, add it
          if (!alfd) {
            // Color count
            if (color_count == 18) {
              color_count = 1;
            } else {
              color_count++;
            }

            // Add to array
            ari = { repeatid: rr, color: color_count };
            // Push
            pdrp.push(ari);
          }
        }
      });

      // Loop through events again and apply the colors
      $(
        ".calendarlist .list ul li, .calview .myevent, .calview .longevent"
      ).each(function () {
        let alfd = false,
          alcl = "";
        // Get color
        let un = $(this).attr("data-unique");
        let rp = $(this).attr("data-repeat");
        let rr = $(this).attr("data-repeat-relation");
        let cl = $(this).attr("data-color");

        // Only events with a unique id
        if (typeof un !== "undefined" && rp != "true") {
          // Is the item already in the array?
          for (let i = 0; i < pdec.length; ++i) {
            // Match, get color
            if (pdec[i].unique == un) {
              /// Get color
              alcl = cl;
              alfd = true;
              // Break
              break;
            }
          }

          // Found
          if (alfd) {
            // Set color
            $(this)
              .find(".marker")
              .attr("class", "marker color" + alcl);
            $(this)
              .find(".dot")
              .attr("class", "dot color" + alcl);
          }
        }

        // Only repeating events
        if (typeof un !== "undefined" && rp == "true") {
          // Found in array variable
          (alfd = false), (alcl = "");

          // Is the item already in the array?
          for (let i = 0; i < pdrp.length; ++i) {
            // Match, get color
            if (pdrp[i].repeatid == rr) {
              /// Get color
              alcl = cl;
              alfd = true;
              // Break
              break;
            }
          }

          // Found
          if (alfd) {
            // Set color
            $(this)
              .find(".marker")
              .attr("class", "marker color" + alcl);
            $(this)
              .find(".dot")
              .attr("class", "dot color" + alcl);
          }
        }
      });

      // Copy events from calendar to schedule view
      $(".calview tr .td1, .calview tr .td2").each(function () {
        // Get date variables
        let mo = $(this).attr("data-month");
        let da = $(this).attr("data-day");
        let yr = $(this).attr("data-year");
        // Get content
        let con = $(this).html();
        // Add to list view
        $("#evtlst" + mo + da + yr).html(con);
        $("#evtlst" + mo + da + yr).addClass("row");
      });

      // Apply colors to multiple day events
      $(".muldaysevents, .alldayone").each(function () {
        // Any content?
        let cl = $(this).attr("data-color");
        // Get "ino"
        let ino = $(this).find(".ino").first();
        // Add color class
        $(ino).addClass("color" + cl);
        // Get background color
        let hexcolor = $(ino).css("backgroundColor");
        // Get hex color
        let hex = calm.contrasthexc(hexcolor);
        // Get contrast color
        let ccolr = calm.contrastinvert(hex, true).toLowerCase();
        // Add
        $(ino).addClass("co" + ccolr);
      });

      // Move events to placeholders (in calendar view)
      $("#maincalendar .list, #calview-list .list").each(function () {
        // Get list
        let lst = $(this);
        // Count placeholder childs
        let ctx = $(lst).find(".mvplchld").length;
        // Set count variable
        let mdecou = 1;
        // Loop throught all day event place holders
        $(this)
          .find(".mvplchld")
          .each(function () {
            // Add
            mdecou++;

            // Any content?
            let con = $(this).html();

            // If the container is empty
            if (con == "" && mdecou >= ctx) {
              // Get first "myevent" in list if any
              let xol = $(lst).find(".myevent");

              // If "myevent" is not inside a "mvplchld" element then move it to blank spaceholder
              if (!$(xol).parent().hasClass("mvplchld")) {
                // Clone it to placeholder
                $(this).html($(xol).clone(true));

                // Delete old object
                $(xol).remove();

                // Element found, break loop
                return false;
              }
            }
          });
      });

      // Move all-day one-day events into placeholders
      $("#maincalendar .list, #calview-list .list").each(function () {
        // Count
        let atx = $(this).find(".mvplchld").length;
        let ala = $(this).find(".alldayone").length;
        let alm = $(this).find(".mvplchld").last();
        let plchl = [];

        // No alm?
        if (!$(this).find(".mvplchld").length) {
          alm = $(this);
        }

        // Get available spots
        $(this)
          .find(".mvplchld")
          .each(function () {
            if ($(this).html() == "") {
              // Push to array
              plchl.push($(this));
            }
          });

        // Any "alldayone"'s?
        if (ala > 0) {
          // Counter
          let txi = 0;

          // Find all all-day one-day events
          $(this)
            .find(".alldayone")
            .each(function () {
              // Set object
              let nalm = null;

              // Any "mvplchld" containers? Set an object
              if (plchl.length > 0) {
                nalm = plchl[txi];
              }

              // Add
              txi++;

              // Placeholder found
              if ($(nalm).length) {
                // Clone it to placeholder
                $(nalm).html($(this).clone(true));

                // Delete old object
                $(this).remove();
              } else {
                // Any "mvplchld" container?
                if (atx > 0) {
                  $(alm).after($(this).clone(true));
                } else {
                  $(alm).prepend($(this).clone(true));
                }

                // Delete old object
                $(this).remove();
              }
            });
        }
      });

      // Remove placeholders that don't have content
      $("#calview-list .row").each(function () {
        // Set element
        let elm = $(this);

        $(this)
          .find(".mvplchld")
          .each(function () {
            // Any content?
            let cld = $(this).html();

            // Remove if empty
            if (cld == "") {
              $(this).remove();
            }
          });

        $(this)
          .find(".list")
          .each(function () {
            // Any content?
            let con = $(this).html();

            // Remove if empty
            if (con == "") {
              $(elm).remove();
            }
          });
      });

      // Attach
      $(".myevent, .longevent").each(function () {
        // Get set class
        let clk = $(this).hasClass("clickattached");

        // Not set?
        if (!clk) {
          $(this).on("click", function (event) {
            event.stopPropagation();
            calm.eventshow(this, event);
          });

          // Add
          $(this).addClass("clickattached");
        }
      });

      // Get "calview-list" list results
      let res = $("#calview-list #calendarlist").html();

      // Empty
      if (res == "") {
        $("#calview-list #calendarlist").html(
          '<div class="noresults"><p>No events found for this month..</p></div>'
        );
      }

      // Set view type
      calm.calviewset();
    },
    gototoday: function () {
      // Today
      let today = new Date();

      // Variables
      let day = calm.l2(today.getDate()),
        mon = calm.l2(today.getMonth() + 1),
        yer = today.getFullYear();

      // Update
      $("#calendar-controle").attr("data-month", mon);
      $("#calendar-controle").attr("data-year", yer);

      // Load calendar
      calm.calendarsetup();
    },
    l2: function (str) {
      // Variables
      let nst = str;

      // Number
      let n = parseInt(str);

      if (n <= 9) {
        nst = "0" + n;
      } else {
        nst = n;
      }

      return nst;
    },
    navprev: function (f) {
      // Get viewtype
      let typ = $("#calendar-controle").attr("data-view-type");
      let cal = $("#calendar-controle").attr("data-calendar");

      // Get current month and year
      let mon = $("#calendar-controle").attr("data-month");
      let yer = $("#calendar-controle").attr("data-year");

      // Integers
      mon = parseInt(mon);
      yer = parseInt(yer);

      // Next month or next month and year
      if (mon == 1) {
        mon = 12;
        yer = yer - 1;
      } else {
        mon = mon - 1;
      }

      // Update
      $("#calendar-controle").attr("data-month", mon);
      $("#calendar-controle").attr("data-year", yer);

      // Load calendar
      calm.loadcalendar("prev");

      // Change history
      window.history.pushState(
        { type: "calview", viewtype: typ, cal: cal, year: yer, month: mon },
        null,
        "/calendars/" + cal + "/" + typ + "/" + yer + "/" + mon
      );
    },
    navnext: function (f) {
      // Get viewtype
      let typ = $("#calendar-controle").attr("data-view-type");
      let cal = $("#calendar-controle").attr("data-calendar");

      // Get current month and year
      let mon = $("#calendar-controle").attr("data-month");
      let yer = $("#calendar-controle").attr("data-year");

      // Integers
      mon = parseInt(mon);
      yer = parseInt(yer);

      // Next month or next month and year
      if (mon == 12) {
        mon = 1;
        yer = yer + 1;
      } else {
        mon = mon + 1;
      }

      // Update
      $("#calendar-controle").attr("data-month", mon);
      $("#calendar-controle").attr("data-year", yer);

      // Load calendar
      calm.loadcalendar("next");

      // Change history
      window.history.pushState(
        { type: "calview", viewtype: typ, cal: cal, year: yer, month: mon },
        null,
        "/calendars/" + cal + "/" + typ + "/" + yer + "/" + mon
      );
    },
    resizecalview: function () {
      // Calview visible?
      if ($("#calview-cal").is(":visible")) {
        // Adjust cells
        calm.adjustcells();
      }
    },
    adjustcells: function () {
      // Reset scroll top
      window.scrollTo(0, 0);
      // Get height
      let cvh = $("#calview-cal").height();
      // Number of rows
      let rowc = $("#calview-cal table tr").length;
      // Get row height (round down)
      let rhrd = Math.floor(cvh / rowc);

      // Set height
      $("#maincalendar .td1, #maincalendar .td2").css("height", rhrd + "px");
      // Fix last row
      $("#maincalendar tr:last td").css("height", rhrd - 1 + "px");
    },
    adjustitems: function () {
      // Get calendar lists + items
      $("#maincalendar tr td").each(function () {
        // Variables
        let mset = false;
        // Get row height
        let rhrd = parseInt($(this)[0].style.height);

        // Loop lists
        $(this)
          .find(".list")
          .each(function () {
            // Variables
            let prevelm = null,
              prevelmset = false;
            // Get ID
            let lid = $(this);
            // Find items
            $(this)
              .find(".mvplchld, .myevent")
              .each(function () {
                // Only process visible elements
                if ($(this).is(":visible")) {
                  // Get dimensions
                  let oh = $(this).outerHeight();
                  let pt = $(this).position().top;

                  // If the items exceed the height of the cell
                  if (pt + oh + 22 > rhrd) {
                    // Does the "more" menu exists?
                    if ($(lid).find(".moreevt").length == 0) {
                      $(lid).append(
                        '<div class="moreevt"><div class="rp"><p>+0 more</p></div><div class="mnu"><div class="pt"><div class="dt"><p class="bg1"></p><p class="bg2"></p></div><div class="lst"></div></div></div></div>'
                      );
                    }

                    // Make sure to add the previous element to the "more" list
                    if (prevelm && !prevelmset) {
                      // Get parent class
                      let pvp = $(prevelm).parent().hasClass("mvplchld");

                      // Inside?
                      if (pvp) {
                        $(pvp).addClass("rshide");
                      } else {
                        $(prevelm).addClass("rshide");
                      }

                      // Don't process previous element again
                      prevelmset = true;
                    }

                    // Get parents wrapper container
                    let mvp = $(this).parent().hasClass("mvplchld");

                    // If "mvplchld" or "myevent"
                    if (mvp) {
                      $(mvp).addClass("rshide");
                    } else {
                      $(this).addClass("rshide");
                      if ($(this).text() == "") {
                        $(this).addClass("noc");
                      }
                    }

                    mset = true;
                  }

                  // Track previous element in loop
                  prevelm = $(this);
                }
              });

            // Count
            let count = $(lid).find(".rshide").not(".noc").length;

            // Update "more" count
            if (count > 0) {
              $(lid)
                .find(".moreevt .rp p")
                .text("+" + count + " more");
            } else {
              $(lid).find(".moreevt").hide();
            }
          });

        // Proceed
        if (mset) {
          // Update labels in popup
          $(this).find(".moreevt .bg1").text($(this).find(".dna").text());
          $(this).find(".moreevt .bg2").text($(this).find(".tnu span").text());
        }
      });

      // Attach
      $(".calendar .moreevt .rp")
        .off("click")
        .on("click", function (event) {
          event.stopPropagation();
          calm.eventsmorepopshow(this);
        });
    },
    evtclick: function (f, e) {
      let evttimer = null;
      // Does the element have a click attribute
      let clk = $(f).attr("data-click");

      // Already clicked?
      if (clk == "true") {
        // Clear
        calm.clearpopups();
        calm.eventsmorepophide();
        // Show
        calm.gotonewevent(f, "known");
        // Reset
        $(f).attr("data-click", "");
        // Clear
        clearTimeout(evttimer);
      } else {
        // Set click
        $(f).attr("data-click", "true");
        // Timed
        evttimer = setTimeout(function () {
          // Show (if none of the popups are visisble)
          if (
            !$("#moreevt-pop").hasClass("show") &&
            !$("#pop-evt-det").hasClass("show")
          ) {
            calm.quickshow(f);
          }

          // Clear
          calm.clearpopups();
          // Reset
          $(f).attr("data-click", "");

          // Clear
          clearTimeout(evttimer);
        }, 300);
      }
    },
    eventsmorepopshow: function (f) {
      // Find root list
      let bas = $(f).closest(".list");

      // Clear list
      $("#moreevt-pop .lst").html("");

      // Get long events
      $(bas)
        .find(".longevent")
        .each(function () {
          // Append to list
          $("#moreevt-pop .lst").append($(this).clone(true));
        });

      // Get normal events
      $(bas)
        .find(".myevent")
        .each(function () {
          // Append to list
          $("#moreevt-pop .lst").append($(this).clone(true));
        });

      // Remove class
      $("#moreevt-pop .lst .rshide").removeClass("rshide");

      // Update
      $("#moreevt-pop .mnu .dt").html(
        $(f).closest(".moreevt").find(".mnu .dt").html()
      );

      // Get cell object
      let cel = $(f).closest(".ddok");

      // Get dimensions
      let wh = $(window).height();
      let ww = $(window).width();
      let st = $(document).scrollTop();

      let pt = $(f).offset().top;
      let pl = $(f).offset().left;

      let cl = $(cel).offset().left;
      let ct = $(cel).offset().top;
      let cw = $(cel).width();
      let ch = $(cel).height();

      let pw = $("#moreevt-pop").outerWidth();
      let ph = $("#moreevt-pop").outerHeight();

      // New left + top
      let nl = cl - (pw / 2 - cw / 2);
      let nt = ct - 10;

      // Too wide (right)
      if (nl + pw > ww) {
        nl = ww - pw - 2;
      }

      // Too left
      if (nl < 0) {
        nl = 2;
      }

      // Too low
      if (nt + ph > wh) {
        nt = wh - ph - 2;
      }

      // Set popup top
      $("#moreevt-pop").css("top", nt + "px");
      $("#moreevt-pop").css("left", nl + "px");

      // Show
      setTimeout(function () {
        $("#moreevt-pop").addClass("show");
      }, 200);

      // Unbind
      $(document).off(".moreevtpop");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.moreevtpop", function () {
          calm.eventsmorepophide();
        });
      }, 200);
    },
    eventsmorepophide: function (f) {
      // Show
      $("#moreevt-pop").removeClass("show");

      // Unbind
      $(document).unbind(".topgroupcontext");
    },
    eventshow: function (f) {
      // Same object clicked?
      if (f == evo) {
        // Hide
        calm.eventhide();
      } else {
        // Data container to use. We list event details two places. One the calendar overview
        // and in all events view. ID's are the same. Use data based on the event view

        // External URL
        let exturl = $("#doc").attr("data-ext-web");
        // Event view variable (use calendar list items (used for normal calendar) by default)
        let evvctn = "calendar-list-items";

        // If in allevents list view
        if ($("#allevents-list").is(":visible")) {
          evvctn = "allevents-list";
        }

        // Store object
        (evd = $(f)), (evo = f);
        // Unbind
        $(document).unbind(".eventdetails");

        // Timed
        setTimeout(function () {
          // Bind
          $(document).on("click.eventdetails", function (event) {
            // Hide
            calm.eventhide();
          });
        }, 200);

        // Get event details
        let lid = $(f).attr("data-unique");
        // Get original event details (from events list)
        let tit = $("#" + evvctn + " #li-" + lid).attr("data-title");
        let unq = $("#" + evvctn + " #li-" + lid).attr("data-unique");
        let dlb = $("#" + evvctn + " #li-" + lid).attr("data-date-lbl");
        let loc = $("#" + evvctn + " #li-" + lid).attr("data-location");
        let rsv = $("#" + evvctn + " #li-" + lid).attr("data-rsvp");
        let rsa = $("#" + evvctn + " #li-" + lid).attr("data-attendees");
        let eds = $("#" + evvctn + " #li-" + lid).attr("data-event-adds");
        let al1 = $("#" + evvctn + " #li-" + lid).attr("data-alarm1");
        let cal = $("#" + evvctn + " #li-" + lid).attr("data-calendar");
        let col = $("#" + evvctn + " #li-" + lid).attr("data-color");
        let xco = $("#" + evvctn + " #li-" + lid).attr("data-xcopy");
        let rpt = $("#" + evvctn + " #li-" + lid).attr("data-repeat");
        let drru = $("#" + evvctn + " #li-" + lid).attr("data-rrule");
        let drrucpy = $("#" + evvctn + " #li-" + lid).attr("data-rrule-copy");
        let drruat = $("#" + evvctn + " #li-" + lid).attr("data-rrule-at");
        let drruex = $("#" + evvctn + " #li-" + lid).attr("data-rrule-exp");
        let txt = $(
          "#" + evvctn + " #li-" + lid + " .descctn .truncated"
        ).text();

        // If recurring rule copy, clear temporary id
        if (drrucpy == "true") {
          lid = lid.substring(0, lid.indexOf("_"));

          // Set base unique
          unq = lid;
        }

        // Event type
        if (drru != "") {
          $("#pop-evt-det .delete").attr("data-del-type", "recurring");
        } else if (rpt == "true") {
          $("#pop-evt-det .delete").attr("data-del-type", "series");
        } else {
          $("#pop-evt-det .delete").attr("data-del-type", "single");
        }

        // Update unique
        $("#pop-evt-det .delete").attr("data-del-unique", unq);

        // Reset disables
        $("#pop-evt-det .edit").removeClass("disabled");
        $("#pop-evt-det .delete").removeClass("disabled");
        $("#pop-evt-det-more .edit").removeClass("disabled");
        $("#pop-evt-det-more .delete").removeClass("disabled");

        // Hide
        $("#pop-evt-det .hed").hide();
        $("#pop-evt-det .dat").hide();
        $("#pop-evt-det .loc").hide();
        $("#pop-evt-det .rsvpena").hide();
        $("#pop-evt-det .rsvpgoi").hide();
        $("#pop-evt-det .evtads").hide();
        $("#pop-evt-det .alarm").hide();
        $("#pop-evt-det .calref").hide();
        $("#pop-evt-det .desc").hide();
        $("#pop-evt-det .repevt").hide();
        $("#pop-evt-det .extevt").hide();
        $("#pop-evt-det .recevt").hide();

        // Event Unique
        $("#pop-evt-det").attr("data-unique", lid);
        $("#pop-evt-det").attr("data-rrule-copy", drrucpy);
        $("#pop-evt-det").attr("data-rrule-at", drruat);

        // Headline
        if (tit != "") {
          $("#pop-evt-det .hed").show();
          $("#pop-evt-det .hed p").html(tit);
        }

        // Date
        if (dlb != "") {
          $("#pop-evt-det .dat").show();
          $("#pop-evt-det .dat p").html(dlb);
        }

        // Location
        if (loc != "") {
          $("#pop-evt-det .loc").show();
          $("#pop-evt-det .loc p").html(loc);
        }

        // RSVP
        if (rsv == "true") {
          $("#pop-evt-det .rsvpena").show();
          $("#pop-evt-det .rsvpgoi").show();
          $("#pop-evt-det .rsvpgoi p").html(rsa);
        } else {
          $("#pop-evt-det .evtads").show();
          $("#pop-evt-det .evtads p").html(eds);
        }

        // Alarm
        if (al1 != "") {
          $("#pop-evt-det .alarm").show();
          $("#pop-evt-det .alarm p").html(al1);
        }

        // Calendar reference
        if (cal != "") {
          $("#pop-evt-det .calref").show();
          $("#pop-evt-det .calref p").html(cal);
        }

        // External event?
        if (xco == "true") {
          // Show external
          $("#pop-evt-det .extevt").show();

          // Disable
          $("#pop-evt-det .edit").addClass("disabled");
          $("#pop-evt-det .delete").addClass("disabled");
          $("#pop-evt-det-more .edit").addClass("disabled");
          $("#pop-evt-det-more .delete").addClass("disabled");
        }

        // External event?
        if (drrucpy == "true") {
          // Disable
          $("#pop-evt-det-more .copy").addClass("disabled");
        }

        // Repeating event?
        if (rpt == "true") {
          $("#pop-evt-det .repevt").show();
        }

        // Recurrence
        if (drru != "") {
          // Show
          $("#pop-evt-det .recevt").show();

          // Update
          $("#pop-evt-det .recevt #rrule-rl").text(drruex);
        }

        // Event description
        if (txt != "") {
          $("#pop-evt-det .desc").show();
          $("#pop-evt-det .desc .dd").html(txt);
        }

        // Text truncated?
        if (
          $("#" + evvctn + " #li-" + lid + " .descctn").hasClass("istruncated")
        ) {
          $("#pop-evt-det .desc").addClass("istruncated");
        } else {
          $("#pop-evt-det .desc").removeClass("istruncated");
        }

        // Color
        $("#pop-evt-det .dot").attr("class", "dot color" + col);

        // Attach
        $("#pop-evt-det .desc .more").on("click", function () {
          calm.eventshowreadmore(this);
          return false;
        });

        // Update link
        $("#pop-evt-det .eventlink").attr("href", exturl + "/event/" + unq);

        // Remove markup
        $(".evtmarkup").removeClass("evtmarkup");

        // Add markup
        $(evd).addClass("evtmarkup");

        // Pop dimensions
        let w = $("#pop-evt-det").outerWidth();
        let h = $("#pop-evt-det").outerHeight();
        let x = $("#pop-evt-det").offset().left;
        let y = $("#pop-evt-det").offset().top;

        let wh = $(window).height();
        let ww = $(window).width();
        let st = $(window).scrollTop();

        // Event in calendar dimensions
        let ex = $(f).offset().left;
        let ey = $(f).offset().top;
        let ew = $(f).outerWidth();
        let eh = $(f).outerHeight();
        let rot = ey - st;

        // Set new left + top
        let nl = ex - w - 20;
        let nt = ey - 100;
        let li = 0;

        // If "new left" is less than 100px on left side, show on right side
        if (nl < 100) {
          nl = ex + ew + 20;
        }

        // Below minimum top
        if (nt + h + 50 > st + wh) {
          nt = st + wh - h - 50;
        }

        // If new top is less than 50px to top, then fix top to 50px
        if (nt < 50) {
          nt = 50;
        }

        // Calendar list view
        if ($("#calview-list").is(":visible")) {
          // Get offset position
          li = $(".view-list .lx .row .list .lbl").first().offset().left;

          nl = li + 50;
          nt = ey - 50;

          // Adjust
          if (ey - st - 50 + h > wh) {
            nt = nt - (ey - st - 50 + h - wh + 20);
          }
        }

        // All events list view
        if ($("#allevents-list").is(":visible")) {
          // Get offset position
          li = $("#allevents-list .lx .row .list .lbl").first().offset().left;

          nl = li + 50;
          nt = ey - 50;

          // Adjust
          if (ey - st - 50 + h > wh) {
            nt = nt - (ey - st - 50 + h - wh + 20);
          }
        }

        // Adjust
        $("#pop-evt-det").css("top", nt + "px");
        $("#pop-evt-det").css("left", nl + "px");

        // Show
        setTimeout(function () {
          $("#pop-evt-det").addClass("show");
        }, 200);
      }
    },
    eventhide: function () {
      // Remove markup
      $(evd).removeClass("evtmarkup");

      // Show
      $("#pop-evt-det").removeClass("show");

      // Reset
      $("#pop-evt-det").css("top", "auto");
      $("#pop-evt-det").css("left", "auto");

      // Unbind
      $(document).unbind(".eventdetails");

      // Clear delete popup (if any)
      calm.eventdeletehide();

      // Reset
      evo = null;
    },
    quickshow: function (f) {
      // Different or same as stored object?
      if (quickshowobj == f) {
        // Hide
        calm.quickhide();

        quickshowobj = null;

        return;
      } else {
        // Store
        quickshowobj = f;
      }

      // Remove error classes
      $(".inperr-t1").removeClass("inperr-t1");

      // Hide
      calm.clearpopups();

      // Unbind
      $(document).unbind(".quickshow");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.quickshow", function (event) {
          // Is the object timepicker?
          if (
            $(event.target).hasClass("ui-timepicker-selected") ||
            $(event.target).closest(".ui-datepicker").hasClass("ui-datepicker")
          ) {
            return;
          }

          // Prevent single / double click
          event.stopPropagation();

          // Hide
          calm.quickhide();
        });
      }, 200);

      // Get parsed data
      let dat_mon = $(f).attr("data-month");
      let dat_day = $(f).attr("data-day");
      let dat_year = $(f).attr("data-year");

      // Date variable for datepicker
      let pc_mo = parseInt(dat_mon) - 1;
      let pc_dy = parseInt(dat_day);

      // Update datepicker dates
      $("#pop-evt-new #date_start").datepicker(
        "setDate",
        new Date(dat_year, pc_mo, pc_dy)
      );
      $("#pop-evt-new #date_end").datepicker(
        "setDate",
        new Date(dat_year, pc_mo, pc_dy)
      );

      // Autosize
      dynamicselect.fitall();

      // Update
      $("#qck-month").val(dat_mon);
      $("#qck-day").val(dat_day);
      $("#qck-year").val(dat_year);

      // Pop dimensions
      let w = $("#pop-evt-new").outerWidth();
      let h = $("#pop-evt-new").outerHeight();
      let x = $("#pop-evt-new").offset().left;
      let y = $("#pop-evt-new").offset().top;

      let wh = $(window).height();
      let ww = $(window).width();
      let st = $(window).scrollTop();

      // Event in calendar dimensions
      let ex = $(f).offset().left;
      let ey = $(f).offset().top;
      let ew = $(f).outerWidth();
      let eh = $(f).outerHeight();

      // Set new left + top
      let nl = ex - w - 20;
      let nt = ey - 100;

      // If "new left" is less than 100px on left side, show on right side
      if (nl < 100) {
        nl = ex + ew + 20;
      }

      // Below minimum top
      if (nt + h + 50 > st + wh) {
        nt = st + wh - h - 50;
      }

      // If new top is less than 50px to top, then fix top to 50px
      if (nt < 50) {
        nt = 50;
      }

      // Adjust
      $("#pop-evt-new").css("top", nt + "px");
      $("#pop-evt-new").css("left", nl + "px");

      // Show
      setTimeout(function () {
        // Show
        $("#pop-evt-new").addClass("show");
        $("#pop-evt-new-bg").addClass("show");

        // Focus
        setTimeout(function () {
          $("#pop-evt-new .title").focus();
        }, 200);
      }, 200);

      // Append new marker
      $("body").append(
        '<div class="newmarker" id="newmarker" style="width:' +
          (ew - 4) +
          "px;left:" +
          (ex + 2) +
          "px;top:" +
          (ey + 2) +
          'px;"><div class="ine">Event</div></div>'
      );

      // Color select init
      colorpickerxo.colorevtqckinit();
    },
    quickhide: function () {
      // Hide
      $("#pop-evt-new").removeClass("show");
      $("#pop-evt-new-bg").removeClass("show");

      // Remove
      $(".newmarker").remove();

      // Unbind
      $(document).unbind(".quickshow");

      quickshowobj = null;
    },
    quickallday: function (f) {
      // Checked?
      let chk = $("#check-all-day").hasClass("selected");

      // Toggle
      if (chk) {
        // Timed hide
        setTimeout(function () {
          $(".picker .it2").hide();
          $(".picker .it3").hide();
          $(".picker .it4").hide();
        }, 200);

        // Update value
        $("#pop-evt-new #qck-all-day-event").val("true");
      } else {
        // Timed show
        setTimeout(function () {
          $(".picker .it2").show();
          $(".picker .it3").show();
          $(".picker .it4").show();
        }, 200);

        // Update value
        $("#pop-evt-new #qck-all-day-event").val("false");
      }
    },
    colorpicked: function (f) {
      // Focus
      $("#pop-evt-new .title").focus();
    },
    quickform: function (f) {
      // Remove error classes
      $(".inperr-t1").removeClass("inperr-t1");

      // Execute
      let execute = true;

      // Validate
      if (!validate.empty(f.title.value)) {
        // Execute
        execute = false;

        // Error
        $(f.title).addClass("inperr-t1");
      }

      // Valid?
      if (execute) {
        // Get date/times values
        let sda = $("#pop-evt-new #date_start").datepicker("getDate");
        let sdt = $("#pop-evt-new #date_start_time").val();
        let eda = $("#pop-evt-new #date_end").datepicker("getDate");
        let edt = $("#pop-evt-new #date_end_time").val();

        // Start / end
        let sdaf =
          ("0" + (sda.getMonth() + 1)).slice(-2) +
          "/" +
          ("0" + sda.getDate()).slice(-2) +
          "/" +
          sda.getFullYear();
        let edaf =
          ("0" + (eda.getMonth() + 1)).slice(-2) +
          "/" +
          ("0" + eda.getDate()).slice(-2) +
          "/" +
          eda.getFullYear();

        // Update form
        $("#pop-evt-new #qck-date-start-fin").val(sdaf);
        $("#pop-evt-new #qck-date-start-time-fin").val(sdt);
        $("#pop-evt-new #qck-date-end-fin").val(edaf);
        $("#pop-evt-new #qck-date-end-time-fin").val(edt);

        // Get data
        let dat = $("#pop-evt-new form").serialize();

        // Set loading
        $("#pop-evt-new .save").addClass("sbt-load-t1");
        // Disable submit
        $("#pop-evt-new .save").prop("disabled", true);
        // Saving
        notificator.show("Creating event");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-quick.php",
          data: dat,
          cache: false,
          dataType: "json",
          success: function (data) {
            // Clear loading
            $("#pop-evt-new .save").removeClass("sbt-load-t1");
            // Enable submit
            $("#pop-evt-new .save").prop("disabled", false);

            // Parse
            const obj = data;

            // Handle
            if (obj.meta.code == "200") {
              // Saved
              notificator.show("Event created", true);

              // Load calendar
              calm.loadcalendar();

              // Hide
              calm.quickhide();
            } else if (obj.meta.code == "429") {
              // Add a disable
              $("#pop-evt-new").addClass("exceednotice exceed2");

              // Run prevents
              plnlmtpop.preventfeatures();

              // Notify
              notificator.break();
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    quickmore: function (f) {
      // Go
      calm.gotonewevent(f, "more");
    },
    gotonewevent: function (f, tp) {
      // Variables
      let uni = "",
        mon = "",
        day = "",
        yer = "",
        tzid = "";
      let tzi = "",
        tid = "",
        tit = "",
        col = "",
        all = "false";
      let sda = "",
        sdt = "9:00am",
        eda = "",
        edt = "11:00am",
        sdaf = "",
        edaf = "";

      // Get general values
      uni = $("#calendar-controle").attr("data-unique");

      // Create new from overview or from existing item in calendar
      if (tp == "new") {
        // Get month + year in view
        mon = $("#calendar-controle").attr("data-month");
        day = $("#calendar-controle").attr("data-day");
        yer = $("#calendar-controle").attr("data-year");
        tzi = $("#calendar-controle").attr("data-timezone");
        tid = $("#calendar-controle").attr("data-id");

        // Start / end
        sdaf = mon + "/" + day + "/" + yer;
        edaf = mon + "/" + day + "/" + yer;
      } else if (tp == "more") {
        // Get values
        mon = $("#qck-month").val();
        day = $("#qck-day").val();
        yer = $("#qck-year").val();
        tzi = $("#calendar-controle").attr("data-timezone");
        tid = $("#calendar-controle").attr("data-id");
        tit = $("#qck-title").val();
        col = $("#qck-color").val();

        // Get date/times values
        sda = $("#date_start").datepicker("getDate");
        sdt = $("#date_start_time").val();
        eda = $("#date_end").datepicker("getDate");
        edt = $("#date_end_time").val();

        // All day?
        if ($("#check-all-day").hasClass("selected")) {
          all = "true";
        } else {
          all = "false";
        }

        // Start / end
        sdaf =
          ("0" + (sda.getMonth() + 1)).slice(-2) +
          "/" +
          ("0" + sda.getDate()).slice(-2) +
          "/" +
          sda.getFullYear();
        edaf =
          ("0" + (eda.getMonth() + 1)).slice(-2) +
          "/" +
          ("0" + eda.getDate()).slice(-2) +
          "/" +
          eda.getFullYear();

        // Calendar cookies
        cookies.create("ck_evt_type", "more", 1);
        cookies.create("ck_evt_start", sdaf, 1);
        cookies.create("ck_evt_start_time", sdt, 1);
        cookies.create("ck_evt_end", edaf, 1);
        cookies.create("ck_evt_end_time", edt, 1);
        cookies.create("ck_evt_title", tit, 1);
        cookies.create("ck_evt_color", col, 1);
        cookies.create("ck_evt_all_day", all, 1);
      } else {
        // Get month + year in view
        mon = $(f).attr("data-month");
        day = $(f).attr("data-day");
        yer = $(f).attr("data-year");
        tzi = $("#calendar-controle").attr("data-timezone");
        tid = $("#calendar-controle").attr("data-id");

        // Start / end
        sdaf = mon + "/" + day + "/" + yer;
        edaf = mon + "/" + day + "/" + yer;

        // Calendar cookies
        cookies.create("ck_evt_type", "known", 1);
        cookies.create("ck_evt_start", sdaf, 1);
        cookies.create("ck_evt_end", edaf, 1);
      }

      // Notify
      //notificator.show('Creating event');

      // Hide quick
      calm.quickhide();

      //alert('GOTO + remember to set cookies');

      // Goto
      location.href = "/calendars/" + uni + "/event/new";
    },
    clearpopups: function () {
      // Hide
      calm.eventhide();
      calm.eventsmorepophide();
      calm.allevtsviewhide();
    },
    eventshowreadmore: function (f) {
      // Show / Hide
      $("#pop-evt-det .desc .truncated").hide();
      $("#pop-evt-det .desc .fulltext").show();

      // Pop dimensions
      let w = $("#pop-evt-det").outerWidth();
      let h = $("#pop-evt-det").outerHeight();
      let x = $("#pop-evt-det").offset().left;
      let y = $("#pop-evt-det").offset().top;

      let wh = $(window).height();
      let ww = $(window).width();
      let st = $(window).scrollTop();

      // Event in calendar dimensions
      let ey = $(f).offset().top;
      let ew = $(f).outerWidth();
      let eh = $(f).outerHeight();

      // Set new left + top
      let nt = ey - 100;

      // Below minimum top
      if (nt + h + 50 > st + wh) {
        nt = st + wh - h - 50;
      }

      // If new top is less than 50px to top, then fix top to 50px
      if (nt < 50) {
        nt = 50;
      }

      // Calendar list view
      if ($("#calview-list").is(":visible")) {
        nt = st + 50;
      }

      // Adjust
      $("#pop-evt-det").css("top", nt + "px");
    },
    eventview: function () {
      // Hide
      calm.eventmorehide();

      return true;
    },
    eventdetails: function () {
      // Hide
      calm.eventmorehide();

      // Get calendar + unique
      let cal = $("#calendar-controle").attr("data-calendar");
      let uni = $("#pop-evt-det").attr("data-unique");

      // RRUle?
      let drrucpy = $("#pop-evt-det").attr("data-rrule-copy");
      let drruat = $("#pop-evt-det").attr("data-rrule-at");

      // Go
      if (drrucpy == "true") {
        location.href =
          "/calendars/" + cal + "/event/" + uni + "/view/@" + drruat;
      } else {
        location.href = "/calendars/" + cal + "/event/" + uni + "/view";
      }
    },
    eventedit: function (f) {
      // Action if not disabled
      if (!$(f).hasClass("disabled")) {
        // Hide
        calm.eventmorehide();
        // Get calendar + unique
        let cal = $("#calendar-controle").attr("data-calendar");
        let uni = $("#pop-evt-det").attr("data-unique");

        // RRUle?
        let drrucpy = $("#pop-evt-det").attr("data-rrule-copy");
        let drruat = $("#pop-evt-det").attr("data-rrule-at");

        // Go
        if (drrucpy == "true") {
          location.href =
            "/calendars/" + cal + "/event/" + uni + "/edit/@" + drruat;
        } else {
          location.href = "/calendars/" + cal + "/event/" + uni + "/edit";
        }
      }
    },
    eventdelete: function (f) {

      if(!$(f).hasClass('disabled')){

        // Hide context menu (if open)
        calm.eventmorehide();
        eventform1.eventviewmorehide();
        // Reset
        $("#confirm-delete-opts .readmorelink").show();
        $("#confirm-delete-opts #del-exp1-txt").hide();

        // Hide
        $("#confirm-delete-opts .load").hide();
        // Clear all options
        $("#confirm-delete-opts .dopt").hide();
        // Clear options
        $("#repeat-confirm-delete-opts-series li").removeClass("checked");
        // Set checked
        $("#repeat-confirm-delete-opts-series .this").addClass("checked");
        // Set default value
        $("#confirm-delete-opts #confirm_event_delete_type").val("this");

        // Get type
        let dltyp = $(f).attr("data-del-type");
        let dluni = $(f).attr("data-del-unique");

        // Clear recurring
        $("#confirm_event_delete_recurring").val("false");

        // Toggle
        if (dltyp == "single") {
          // Show
          $("#repeat-confirm-delete-opts-single").show();
        } else if (dltyp == "recurring") {
          // Show
          $("#repeat-confirm-delete-opts-recurring").show();

          $("#confirm_event_delete_recurring").val("true");
        } else if (dltyp == "series") {
          // Show
          $("#repeat-confirm-delete-opts-series").show();
        }

        // Update unique
        $("#confirm-delete-opts #confirm_event_delete_unique").val(dluni);

        // Show
        $("#confirm-delete-opts").show();
        $("#confirm-delete-opts-bg").show();

        // Show
        setTimeout(function () {
          $("#confirm-delete-opts").addClass("show");
          $("#confirm-delete-opts-bg").addClass("show");
        }, 200);

      }

    },
    eventdeletetoggle: function (f) {
      // Reset options
      $(
        "#confirm-delete-opts #repeat-confirm-delete-opts-series li"
      ).removeClass("checked");

      // Set selected
      $(f).addClass("checked");

      // Set value
      $("#confirm-delete-opts #confirm_event_delete_type").val(
        $(f).attr("data-option")
      );
    },

    eventdeleteconfirmed: function (f) {
      // Hide
      $("#confirm-delete-opts .load").hide();

      // Action if not disabled
      if (!$(f).hasClass("disabled")) {
        // Show
        $("#confirm-delete-opts .load").show();

        // Hide
        calm.eventmorehide();
        eventform1.eventviewmorehide();

        // Get data
        let dat = $("#confirm-delete-opts form").serialize();

        // Notify
        notificator.show("Deleting");

        // Get details
        let uni = $("#confirm_event_delete_unique").val();
        let typ = $("#confirm_event_delete_type").val();
        let rec = $("#confirm_event_delete_recurring").val();

        // Override type, set to recurring
        if (rec == "true") {
          typ = "recurring";
        }

        // Calendar view or specific event view?
        let cvi = $("#calendar-controle").attr("data-view-type"),
          cvi_xi = "calendar";

        // Set (override view type)
        if (cvi == "specific") {
          cvi_xi = "specific";
        }

        // Post
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-delete.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Notify
              notificator.show("Deleted", true);

              // Reload type
              if (cvi_xi == "specific") {
                // Get relation
                let cal = $("#calendar-controle").attr("data-calendar");

                // Go to event edit
                setTimeout(function () {
                  location.href = "/calendars/" + cal;
                }, 500);
              } else {
                // Reload if series and all or following OR recurring
                if (typ == "all" || typ == "following" || typ == "recurring") {
                  // Reload
                  location.reload();
                } else {
                  // Remove item from list
                  $("#calendar-list-items #li-" + uni).remove();

                  // Refresh
                  calm.calendarsetup();

                  // Hide
                  calm.clearpopups();

                  // Hide
                  $("#confirm-delete-opts .load").hide();
                }
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    eventdeletehide: function (f) {
      // Remove active
      $("#confirm-delete-opts").removeClass("show");
      $("#confirm-delete-opts-bg").removeClass("show");

      // Timed
      setTimeout(function () {
        // Bind
        $("#confirm-delete-opts").hide();
        $("#confirm-delete-opts-bg").hide();
      }, 500);
    },
    eventcopy: function () {
      // Hide
      calm.eventmorehide();

      // Get details
      let uni = $("#pop-evt-det").attr("data-unique");
      let csrf = $("#pop-evt-det").attr("data-csrf-copy");
      let csl = $("#calendar-controle").attr("data-view-type");

      // Hide
      calm.clearpopups();

      // Notify
      notificator.show("Duplicating event");

      // Set data
      let dat = "uni=" + uni + "&csrf=" + csrf;

      // Post
      $.ajax({
        type: "POST",
        url: "/source/web/actions/calendar/event-copy.php",
        data: dat,
        cache: false,
        dataType: "json",
        success: function (data) {
          // Parse
          const obj = data;

          // OK
          if (obj.meta.code == "200") {
            // Notify
            notificator.show("Duplicated", true);
            // Load
            calm.loadcalendar();

            // Reload?
            if (csl == "allevents") {
              location.reload();
            }
          } else if (obj.meta.code == "429") {
            // Add a disable
            $("#doc").addClass("exceednotice exceed2");

            // Run prevents
            plnlmtpop.preventfeatures();
            // Notify
            notificator.break();
          } else {
            // Notify
            notificator.break();
            // Error
            csrfpop.show();
          }
        },
      });
    },
    eventshare: function () {
      // Hide
      calm.eventmorehide();

      // Get calendar + unique
      let cal = $("#calendar-controle").attr("data-calendar");
      let uni = $("#pop-evt-det").attr("data-unique");

      // RRUle?
      let drrucpy = $("#pop-evt-det").attr("data-rrule-copy");
      let drruat = $("#pop-evt-det").attr("data-rrule-at");

      // Go
      if (drrucpy == "true") {
        location.href =
          "/calendars/" +
          cal +
          "/event/" +
          uni +
          "/view/@" +
          drruat +
          "/#share";
      } else {
        location.href = "/calendars/" + cal + "/event/" + uni + "/view/#share";
      }
    },
    eventmoreshow: function (f) {
      // Already visible?
      if ($(f).hasClass("active")) {
        // Hide
        calm.eventmorehide();
      } else {
        // Set active
        $("#pop-evt-det .more").addClass("active");

        // Show
        setTimeout(function () {
          $("#pop-evt-det-more").addClass("show");
        }, 200);

        // Unbind
        $(document).unbind(".evtdetmore");
        // Timed
        setTimeout(function () {
          // Bind
          $(document).on("click.evtdetmore", function () {
            calm.eventmorehide();
          });
        }, 200);
      }
    },
    eventmorehide: function () {
      // Remove active
      $("#pop-evt-det .more").removeClass("active");
      // Show
      $("#pop-evt-det-more").removeClass("show");
      // Unbind
      $(document).unbind(".evtdetmore");
    },
    allowajax: function (f) {
      allowajaxcalls = f;
    },
    allowedajax: function () {
      if (allowajaxcalls) {
        return true;
      }
      return false;
    },
    popstate: function (event) {
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate

      // Any event state?
      if (
        event.state &&
        Object.prototype.hasOwnProperty.call(event.state, "type") &&
        event.state.type == "calview"
      ) {
        // View type
        // Set view type
        if (Object.prototype.hasOwnProperty.call(event.state, "viewtype")) {
          $("#calendar-controle").attr("data-view-type", event.state.viewtype);
        }

        // Load calendar
        calm.loadcalendar("existing", event.state.year, event.state.month);
      }
    },
  };
})();

// Attach
$(document).ready(function () {
  calm.initialize();
});
$(window).on("popstate", function () {
  calm.popstate(event);
});
$(window).on("resize", function () {
  calm.resizecalview();
});

/* Context menu
---------------------------------------------------------------- */
var contextmenu = (function () {
  // Variables
  let contextobj,
    contextclass = "";

  return {
    initialize: function () {
      // Append
      $(".usecontext").on("click", function () {
        contextmenu.show(this);
        return false;
      });
      $(".context-t1").on("click", function (event) {
        event.stopPropagation();
      });
    },
    show: function (f) {
      // Get element
      let obj = $(f);
      // Build menu if it doesn't exists
      contextmenu.typehtml(f);
      // Get active class
      let act = $(obj).hasClass("active");

      // Active? (show / hide)
      if (act) {
        // Hide
        contextmenu.hide();
      } else {
        // Clear
        contextmenu.clearall(obj);
        // Get settings
        let ctx = $(obj).attr("data-context");
        // Get context class if any
        let ctxcls = $(obj).attr("data-context-class");
        // Get context align
        let ctxalg = $(obj).attr("data-context-align");
        // Adjust arrow
        let ctajar = $(obj).attr("data-adjust-arrow");
        contextobj = obj;

        // Add
        if (ctxcls !== undefined && ctxcls !== "") {
          // Add classes
          $(obj).addClass(ctxcls);
          $("#" + ctx).addClass(ctxcls);
          contextclass = ctxcls;
        }

        // Get positions and dimensions
        let l = $(obj).offset().left;
        let t = $(obj).offset().top;
        let h = $(obj).outerHeight();
        let w = $(obj).outerWidth();
        let cw = $("#" + ctx).outerWidth();
        let ch = $("#" + ctx).outerHeight();

        // Adjust
        $("#" + ctx).css("left", l + "px");
        $("#" + ctx).css("top", t + h + "px");

        // Set active
        $(obj).addClass("active");

        // Align
        if (ctxalg !== undefined) {
          $("#" + ctx).css("left", l + w - cw + "px");
        }

        // Adjust arrow
        if (ctajar == "true") {
          // Get arrow width
          let awi = parseInt($("#" + ctx + " .arr").outerWidth());
          // Locate the arrow position
          let arl = parseInt($(obj).find("i").position().left);

          // Set new left
          $("#" + ctx + " .arr").css("left", arl + awi / 2 + "px");
        }

        // Show
        $("#" + ctx).show();

        // Unbind
        $(document).unbind(".contextmenu");
        // Timed
        setTimeout(function () {
          // Bind
          $(document).on("click.contextmenu", function () {
            contextmenu.hide();
          });
        }, 200);
      }
    },
    hide: function () {
      // Remove active
      $(contextobj).removeClass("active");
      // Remove extra class from objects if any
      $(contextobj).removeClass(contextclass);
      $(".context").removeClass(contextclass);
      // Hide context menu
      $(".context").hide();
      // Unbind
      $(document).unbind(".contextmenu");
      // Clear
      contextobj = null;
    },
    clearallins: function () {
      // Get active context menus
      $(".context-t1").each(function () {
        // Remove
        $(this).removeClass("show");
        // Get ID
        let fid = $(this).attr("id");
        // Remove active on mother instance
        $('[data-context="' + fid + '"]').removeClass("active");
      });
    },
    clearall: function (obj) {
      // Loop
      $(".usecontext").each(function () {
        // Object different from selected?
        if (obj !== $(this)) {
          // Remove
          $(this).removeClass("active");
        }
      });

      // Remove extra class from objects if any
      $(contextobj).removeClass(contextclass);
      $(".context").removeClass(contextclass);
    },
    typehtml: function (f) {
      // Get id
      let fid = $(f).attr("data-context");
      // Get object
      let obj = $d(fid);
      // Set variable
      let html = "";
      // .nav-t1 user context
      if (fid == "ctx-usr-nvt1" && !obj) {
        html += '<div class="context context-t1" id="ctx-usr-nvt1">';
        html += '	<div class="px">';
        html += "		<ul>";
        html +=
          '			<li><a href="/account" title="">Account<span class="icon"><i class="material-icons">&#xE853;</i></span></a></li>';
        html +=
          '			<li><a href="/help" title="">Help<span class="icon"><i class="material-icons">&#xE887;</i></span></a></li>';
        html += '			<li><div class="sep"><div class="in"></div></div></li>';
        html +=
          '			<li><a href="/signout" title="" class="signout">Log out<span class="icon"><i class="material-icons">&#xE897;</i></span></a></li>';
        html += "		</ul>";
        html += '		<div class="arr"></div>';
        html += "	</div>";
        html += "</div>";

        // Append
        $("body").append(html);
      }
    },
  };
})();

/* Attach */
$(document).ready(function () {
  contextmenu.initialize();
});
$(window).on("resize", function () {
  contextmenu.hide();
});
$(window).on("scroll", function () {
  contextmenu.hide();
});

/* Code markup
---------------------------------------------------------------- */

var codemarkup = (function () {
  // Variables
  let codeobj;

  return {
    initialize: function () {
      // Append
      $(".code-t1 .more .lnk").on("click", function () {
        codemarkup.show(this);
        return false;
      });
      $(".code-t1 .more .drop a").on("click", function () {
        codemarkup.examples(this);
        return false;
      });
      $(".code-t1 .nav .inm a").on("click", function () {
        codemarkup.codeviewtoogle(this);
        return false;
      });
      $(".markup-t1").on("click", function () {
        codemarkup.selectable(this);
        return false;
      });
      $(".selectabletext").on("blur", function () {
        codemarkup.selectableinputblur(this);
      });
      $(".selectabletext").on("click", function () {
        codemarkup.selectableinputclick(this);
      });

      // Markup code
      codemarkup.markup();
    },
    markup: function () {
      // Exists?
      if ($(".codemarkup").length) {
        // Get markups
        $(".codemarkup").each(function () {
          // Get content
          let ctx = $(this).html();

          // Get the line number count
          let num = ctx.split(/\n/).length - 1;

          // One line wraps
          if (num == "0" || num == "1") {
            // Add
            $(this).addClass("inline");
            // Add auto avoid class to parent
            $(this).parent(".exmctn").first().addClass("autoheight");

            num = 1;
          }

          // Line number html
          let lhtml = "";

          // Loop
          for (let j = 0; j < num; j++) {
            // Collect
            lhtml += '<span class="num">' + (j + 1) + "</span>";
          }

          // Get content
          let str = ctx;

          // Replace tabs with spaces
          str = str.replace(/\t/g, "");

          // Get class
          let jso_cls = $(this).hasClass("json");

          // Normal markup or JSON
          if (!jso_cls) {
            // Values between ""
            str = str.replace(/"([^"]+)"/g, '"<span class="m5">$1</span>"');
            str = str.replace(/'([^']+)'/g, "'<span class=\"m5\">$1</span>'");

            // Rest
            str = str.replace(
              /&lt;html&gt;/gi,
              '&lt;<span class="m4">html</span>&gt;'
            );
            str = str.replace(
              /&lt;\/html&gt;/gi,
              '&lt;/<span class="m4">html</span>&gt;'
            );
            str = str.replace(
              /&lt;head&gt;/gi,
              '&lt;<span class="m4">head</span>&gt;'
            );
            str = str.replace(
              /&lt;\/head&gt;/gi,
              '&lt;/<span class="m4">head</span>&gt;'
            );
            str = str.replace(
              /&lt;style /gi,
              '&lt;<span class="m4">style</span> '
            );
            str = str.replace(
              /&lt;\/style&gt;/gi,
              '&lt;/<span class="m4">style</span>&gt;'
            );
            str = str.replace(
              /&lt;body&gt;/gi,
              '&lt;<span class="m4">body</span>&gt;'
            );
            str = str.replace(
              /&lt;\/body&gt;/gi,
              '&lt;/<span class="m4">body</span>&gt;'
            );
            str = str.replace(
              /&lt;script/gi,
              '&lt;<span class="m4">script</span>'
            );
            str = str.replace(
              /&lt;\/script&gt;/gi,
              '&lt;<span class="m4">/script</span>&gt;'
            );
            str = str.replace(/&lt;div/gi, '&lt;<span class="m4">div</span>');
            str = str.replace(
              /&lt;\/div&gt;/gi,
              '&lt;/<span class="m4">div</span>&gt;'
            );
            str = str.replace(/&lt;span/gi, '&lt;<span class="m4">span</span>');
            str = str.replace(
              /&lt;\/span&gt;/gi,
              '&lt;/<span class="m4">span</span>&gt;'
            );
            str = str.replace(/type=/gi, '<span class="m2">type</span>=');
            str = str.replace(/src=/gi, '<span class="m2">src</span>=');
            str = str.replace(/ async/gi, '<span class="m2"> async</span>');
            str = str.replace(/ defer/gi, '<span class="m2"> defer</span>');
            str = str.replace(/\/\*/gi, '<span class="m9c">/*');
            str = str.replace(/\*\//gi, "*/</span>");
            str = str.replace(/function/gi, '<span class="m4">function</span>');
            str = str.replace(/&lt;!--/gi, '<span class="m9c">&lt;!--');
            str = str.replace(/--&gt;/gi, "--&gt;</span>");

            // Replace timezone
            str = str.replace(
              /America\/Los_Angeles/gi,
              '<div class="timezonechg" title="Change Timezone">America/Los_Angeles</div>'
            );
            str = str.replace(
              /ic14/gi,
              '<div class="calendarchg" title="Select">ic14</div>'
            );
            str = str.replace(
              /\$ curl/gi,
              '<span class="markup-t5">$ curl</span>'
            );
          } else {
            str = str.replace(/\{/gi, '<span class="m6">{</span>');
            str = str.replace(/\}/gi, '<span class="m6">}</span>');
            str = str.replace(/\[/gi, '<span class="m6">[</span>');
            str = str.replace(/\]/gi, '<span class="m6">]</span>');
            str = str.replace(/\"id\"/gi, '<span class="m7">"id"</span>');
            str = str.replace(/\"meta\"/gi, '<span class="m7">"meta"</span>');
            str = str.replace(/\"code\"/gi, '<span class="m7">"code"</span>');
            str = str.replace(
              /\"calendar\"/gi,
              '<span class="m7">"calendar"</span>'
            );
            str = str.replace(
              /\"calendars\"/gi,
              '<span class="m7">"calendars"</span>'
            );
            str = str.replace(
              /\"uniquekey\"/gi,
              '<span class="m7">"uniquekey"</span>'
            );
            str = str.replace(
              /\"calendarname\"/gi,
              '<span class="m7">"calendarname"</span>'
            );
            str = str.replace(/\"title\"/gi, '<span class="m7">"title"</span>');
            str = str.replace(
              /\"description\"/gi,
              '<span class="m7">"description"</span>'
            );
            str = str.replace(
              /\"timezone\"/gi,
              '<span class="m7">"timezone"</span>'
            );
            str = str.replace(
              /\"followers_active\"/gi,
              '<span class="m7">"followers_active"</span>'
            );
            str = str.replace(
              /\"followers_total\"/gi,
              '<span class="m7">"followers_total"</span>'
            );
            str = str.replace(
              /\"events_total\"/gi,
              '<span class="m7">"events_total"</span>'
            );
            str = str.replace(
              /\"main_calendar\"/gi,
              '<span class="m7">"main_calendar"</span>'
            );
            str = str.replace(
              /\"date_create\"/gi,
              '<span class="m7">"date_create"</span>'
            );
            str = str.replace(
              /\"date_modified\"/gi,
              '<span class="m7">"date_modified"</span>'
            );
            str = str.replace(/\"rrule\"/gi, '<span class="m7">"rrule"</span>');
            str = str.replace(/\"rsvp\"/gi, '<span class="m7">"rsvp"</span>');
            str = str.replace(
              /\"rsvp_require\"/gi,
              '<span class="m7">"rsvp_require"</span>'
            );
            str = str.replace(
              /\"rsvp_template_id\"/gi,
              '<span class="m7">"rsvp_template_id"</span>'
            );
            str = str.replace(
              /\"paging\"/gi,
              '<span class="m7">"paging"</span>'
            );
            str = str.replace(
              /\"previous\"/gi,
              '<span class="m7">"previous"</span>'
            );
            str = str.replace(/\"next\"/gi, '<span class="m7">"next"</span>');
            str = str.replace(
              /\"status\"/gi,
              '<span class="m7">"status"</span>'
            );
            str = str.replace(
              /\"events_count\"/gi,
              '<span class="m7">"events_count"</span>'
            );
            str = str.replace(
              /\"events\"/gi,
              '<span class="m7">"events"</span>'
            );
            str = str.replace(/\"event\"/gi, '<span class="m7">"event"</span>');
            str = str.replace(
              /\"location\"/gi,
              '<span class="m7">"location"</span>'
            );
            str = str.replace(
              /\"organizer\"/gi,
              '<span class="m7">"organizer"</span>'
            );
            str = str.replace(
              /\"organizer_email\"/gi,
              '<span class="m7">"organizer_email"</span>'
            );
            str = str.replace(
              /\"date_start\"/gi,
              '<span class="m7">"date_start"</span>'
            );
            str = str.replace(
              /\"date_start_time\"/gi,
              '<span class="m7">"date_start_time"</span>'
            );
            str = str.replace(
              /\"date_start_ampm\"/gi,
              '<span class="m7">"date_start_ampm"</span>'
            );
            str = str.replace(
              /\"date_start_unix\"/gi,
              '<span class="m7">"date_start_unix"</span>'
            );
            str = str.replace(
              /\"date_end\"/gi,
              '<span class="m7">"date_end"</span>'
            );
            str = str.replace(
              /\"date_end_time\"/gi,
              '<span class="m7">"date_end_time"</span>'
            );
            str = str.replace(
              /\"date_end_ampm\"/gi,
              '<span class="m7">"date_end_ampm"</span>'
            );
            str = str.replace(
              /\"date_end_unix\"/gi,
              '<span class="m7">"date_end_unix"</span>'
            );
            str = str.replace(
              /\"all_day_event\"/gi,
              '<span class="m7">"all_day_event"</span>'
            );
            str = str.replace(
              /\"date_format\"/gi,
              '<span class="m7">"date_format"</span>'
            );
            str = str.replace(
              /\"reminder\"/gi,
              '<span class="m7">"reminder"</span>'
            );
            str = str.replace(
              /\"updated_times\"/gi,
              '<span class="m7">"updated_times"</span>'
            );
            str = str.replace(
              /\"calendar_id\"/gi,
              '<span class="m7">"calendar_id"</span>'
            );
            str = str.replace(
              /\"calendar_uniquekey\"/gi,
              '<span class="m7">"calendar_uniquekey"</span>'
            );
            str = str.replace(
              /\"calendar_title\"/gi,
              '<span class="m7">"calendar_title"</span>'
            );
            str = str.replace(
              /\"calendar_name\"/gi,
              '<span class="m7">"calendar_name"</span>'
            );
            str = str.replace(
              /\"calendar_followers_active\"/gi,
              '<span class="m7">"calendar_followers_active"</span>'
            );
            str = str.replace(
              /\"calendar_followers_total\"/gi,
              '<span class="m7">"calendar_followers_total"</span>'
            );
            str = str.replace(
              /\"calendar_events_total\"/gi,
              '<span class="m7">"calendar_events_total"</span>'
            );
            str = str.replace(
              /\"calendar_date_create\"/gi,
              '<span class="m7">"calendar_date_create"</span>'
            );
            str = str.replace(
              /\"calendar_date_modified\"/gi,
              '<span class="m7">"calendar_date_modified"</span>'
            );
            str = str.replace(/\"data\"/gi, '<span class="m7">"data"</span>');
            str = str.replace(/\"label\"/gi, '<span class="m7">"label"</span>');
            str = str.replace(
              /\"offset\"/gi,
              '<span class="m7">"offset"</span>'
            );
            str = str.replace(
              /\"eventname\"/gi,
              '<span class="m7">"eventname"</span>'
            );
            str = str.replace(
              /\"client\"/gi,
              '<span class="m7">"client"</span>'
            );
            str = str.replace(/\"start\"/gi, '<span class="m7">"start"</span>');
            str = str.replace(/\"end\"/gi, '<span class="m7">"end"</span>');
            str = str.replace(
              /\"duration\"/gi,
              '<span class="m7">"duration"</span>'
            );
            str = str.replace(
              /\"reference\"/gi,
              '<span class="m7">"reference"</span>'
            );
            str = str.replace(
              /\"service\"/gi,
              '<span class="m7">"service"</span>'
            );
            str = str.replace(
              /\"template\"/gi,
              '<span class="m7">"template"</span>'
            );
            str = str.replace(
              /\"template_id\"/gi,
              '<span class="m7">"template_id"</span>'
            );
            str = str.replace(/\"alarm\"/gi, '<span class="m7">"alarm"</span>');
            str = str.replace(
              /\"recurring\"/gi,
              '<span class="m7">"recurring"</span>'
            );
            str = str.replace(/\"uid\"/gi, '<span class="m7">"uid"</span>');
            str = str.replace(
              /\"method\"/gi,
              '<span class="m7">"method"</span>'
            );
            str = str.replace(/\"link\"/gi, '<span class="m7">"link"</span>');
            str = str.replace(
              /\"clicks\"/gi,
              '<span class="m7">"clicks"</span>'
            );
            str = str.replace(
              /\"created\"/gi,
              '<span class="m7">"created"</span>'
            );
            str = str.replace(
              /\"modified\"/gi,
              '<span class="m7">"modified"</span>'
            );
            str = str.replace(
              /\"action\"/gi,
              '<span class="m7">"action"</span>'
            );
            str = str.replace(
              /\"calname\"/gi,
              '<span class="m7">"calname"</span>'
            );
            str = str.replace(
              /\"custom_data\"/gi,
              '<span class="m7">"custom_data"</span>'
            );
            str = str.replace(
              /\"link_short\"/gi,
              '<span class="m7">"link_short"</span>'
            );
            str = str.replace(
              /\"link_long\"/gi,
              '<span class="m7">"link_long"</span>'
            );
            str = str.replace(
              /\"calendar_link_short\"/gi,
              '<span class="m7">"calendar_link_short"</span>'
            );
            str = str.replace(
              /\"calendar_link_long\"/gi,
              '<span class="m7">"calendar_link_long"</span>'
            );
            str = str.replace(
              /\"unique\"/gi,
              '<span class="m7">"unique"</span>'
            );
            str = str.replace(
              /\"rsvp_count\"/gi,
              '<span class="m7">"rsvp_count"</span>'
            );
            str = str.replace(
              /\"summary\"/gi,
              '<span class="m7">"summary"</span>'
            );
            str = str.replace(
              /\"rsvp_count\"/gi,
              '<span class="m7">"rsvp_count"</span>'
            );
            str = str.replace(/\"going\"/gi, '<span class="m7">"going"</span>');
            str = str.replace(/\"maybe\"/gi, '<span class="m7">"maybe"</span>');
            str = str.replace(
              /\"cant_go\"/gi,
              '<span class="m7">"cant_go"</span>'
            );
            str = str.replace(/\"rsvps\"/gi, '<span class="m7">"rsvps"</span>');
            str = str.replace(
              /\"header\"/gi,
              '<span class="m7">"header"</span>'
            );
            str = str.replace(
              /\"attending\"/gi,
              '<span class="m7">"attending"</span>'
            );
            str = str.replace(
              /\"attending_code\"/gi,
              '<span class="m7">"attending_code"</span>'
            );
            str = str.replace(/\"name\"/gi, '<span class="m7">"name"</span>');
            str = str.replace(/\"email\"/gi, '<span class="m7">"email"</span>');
            str = str.replace(/\"ip\"/gi, '<span class="m7">"ip"</span>');
            str = str.replace(/\"city\"/gi, '<span class="m7">"city"</span>');
            str = str.replace(
              /\"region\"/gi,
              '<span class="m7">"region"</span>'
            );
            str = str.replace(
              /\"country\"/gi,
              '<span class="m7">"country"</span>'
            );
            str = str.replace(
              /\"postal\"/gi,
              '<span class="m7">"postal"</span>'
            );
            str = str.replace(
              /\"userid\"/gi,
              '<span class="m7">"userid"</span>'
            );
            str = str.replace(
              /\"createdate\"/gi,
              '<span class="m7">"createdate"</span>'
            );
            str = str.replace(
              /\"modifydate\"/gi,
              '<span class="m7">"modifydate"</span>'
            );
            str = str.replace(
              /\"attendees\"/gi,
              '<span class="m7">"attendees"</span>'
            );
          }

          // Append frames
          $(this).html(
            '<div class="lines"><div class="bfnum">' +
              lhtml +
              '</div></div><div class="code"><div class="inn">' +
              str +
              '</div></div><div class="clr"></div>'
          );

          // Show
          $(this).show();
        });

        // Attach
        $(".codemarkup .code .inn").dblclick(function () {
          codemarkup.selectable(this);
          return false;
        });
        $(".timezonechg")
          .off("click")
          .on("click", function () {
            addresslookup.show(this);
            return false;
          });
        $(".calendarchg")
          .off("click")
          .on("click", function () {
            popselt1.show(this);
            return false;
          });

        // Get stored timezone cookie value
        let tzcook = cookies.read("timezone");

        // Any value?
        if (tzcook) {
          $(".timezonechg").text(tzcook);
        }

        // Get markups, set container heights
        $(".code-t1 .exmctn").each(function () {
          // Autoheight?
          let aucls = $(this).hasClass("autoheight");
          // Already visible?
          let exmvis = $(this).css("display");

          // Show container
          $(this).show();

          // Get objects
          let objhe = $(this).find(".example");
          let objhc = $(this).find(".codemarkup");

          // Already visible?
          let objhevis = $(objhe).css("display");
          let objhcvis = $(objhc).css("display");

          // Set visible
          $(objhe).show();
          $(objhc).show();

          // Get heights
          let he = $(objhe).height();
          let hc = $(objhc).height();

          // Hide containers again
          if (exmvis == "none") {
            $(this).hide();
          }
          if (objhevis == "none") {
            $(objhe).hide();
          }
          if (objhcvis == "none") {
            $(objhc).hide();
          }
        });
      }
    },
    show: function (f) {
      // Get element
      let obj = $(f);
      // Get active class
      let act = $(obj).hasClass("active");
      // Get settings
      let ctx = $(obj).attr("data-context");
      // Active? (show / hide)
      if (act) {
        // Remove active
        $(obj).removeClass("active");
        // Hide
        codemarkup.hide();
      } else {
        // Set active
        $(obj).addClass("active");
        // Show
        $("#" + ctx).show();
        // Unbind
        $(document).unbind(".codemenu");
        // Timed
        setTimeout(function () {
          // Bind
          $(document).on("click.codemenu", function () {
            codemarkup.hide();
          });
        }, 200);
      }
    },
    hide: function () {
      // Remove active
      $(codeobj).removeClass("active");
      // Hide context menu
      $(".code-t1 .more .drop").hide();

      // Unbind
      $(document).unbind(".codemenu");
    },
    examples: function (f) {
      // Get relation
      let rel = $(f).attr("data-rel");
      let box = $(f).attr("data-box");
      // Get example text
      let txt = $(f).text();
      // Update example text
      $("#" + box + " .more .lnk .txt").text(txt);
      // Reset selected
      $("#" + box + " .more .drop a").removeClass("selected");
      // Set selected
      $(f).addClass("selected");
      // Hide examples
      $("#" + box + " .exmctn").hide();
      // Show example
      $("#" + box + " #" + rel).show();
      // Set current
      $("#" + box).attr("data-active-id", rel);

      // Reset source/view
      $("#" + box + " .nav .inm a").removeClass("selected");
      $("#" + box + " .nav .inm a")
        .first()
        .addClass("selected");

      $("#" + box + " .code #" + rel + " .example").hide();
      $("#" + box + " .code #" + rel + " .codemarkup").show();

      // Hide drop down
      codemarkup.hide();
    },
    codeviewtoogle: function (f) {
      // Get view type
      let viw = $(f).attr("data-view");
      let box = $(f).attr("data-box");
      // Get active
      let fid = $("#" + box).attr("data-active-id");

      // Reset
      $("#" + box + " .nav .inm a").removeClass("selected");

      // Set active
      $(f).addClass("selected");

      // Hide
      $("#" + fid + " .example").hide();
      $("#" + fid + " .codemarkup").hide();
      $("#" + fid + " .jsonmarkup").hide();

      // Show
      if (viw == "code") {
        $("#" + fid + " .codemarkup").show();
      } else if (viw == "example") {
        $("#" + fid + " .example").show();
      } else if (viw == "json") {
        $("#" + fid + " .jsonmarkup").show();
      }
    },
    selectable: function (f) {
      let range = "";
      // Highlight text
      if (document.selection) {
        range = document.body.createTextRange();
        range.moveToElementText(f);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNodeContents(f);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    },
    selectableinputclick: function (f) {
      if (!$(f).attr("data-selected-all")) {
        try {
          $(f).selectionStart = 0;
          $(f).selectionEnd = $(f).value.length + 1;

          // Add atribute
          $(f).attr("data-selected-all", true);
        } catch (err) {
          // Select
          $(f).select();

          // Add atribute
          $(f).attr("data-selected-all", true);
        }
      }
    },
    selectableinputblur: function (f) {
      // Remove attribute
      if ($(f).attr("data-selected-all")) {
        // Remove
        $(f).removeAttr("data-selected-all");
      }
    },
  };
})();

// Ready
$(document).ready(function () {
  codemarkup.initialize();
});

/* Calendars popup
---------------------------------------------------------------- */
let popselt1 = (function () {
  return {
    initialize: function (f) {
      // Attach
      $(window).on("resize", function () {
        popselt1.hide();
      });
    },
    show: function (f) {
      // Remove container
      $("body").find(".popselt1ctn").remove();
      // Variables
      let html = "",
        ofw = $(f).width(),
        ofl = $(f).offset().left,
        oft = $(f).offset().top;
      // Popup
      html += '<div class="popsel-t1" id="popselt1">';
      html += '    <div class="inr">';
      html += '        <div class="px">';
      html += '            <p class="hd">Select calendar</p>';
      html += '            <div class="lst">';
      html += '	            <div class="fx">';
      html += '	            	<div class="ajx"></div>';
      html += '	            	<div class="load"></div>';
      html += "	            </div>";
      html += "            </div>";
      html += '            <div class="close"></div>';
      html += "        </div>";
      html += '        <div class="arr"></div>';
      html += "    </div>";
      html += "</div>";

      // Post
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax_select_calendars.php",
        success: function (data) {
          // Remove load
          $("#popselt1 .load").hide();
          // Append
          $("#popselt1 .ajx").html(data);
          // Attach
          $("#popselt1 .lst ul li a").on("click", function () {
            popselt1.select(this);
            return false;
          });
        },
      });

      // Add container
      $("body").append(
        '<div class="popselt1ctn" style="width:' +
          ofw +
          "px;left:" +
          ofl +
          "px;top:" +
          oft +
          'px;"><div class="psctnt1">' +
          html +
          "</div></div>"
      );
      // Show
      $("#popselt1").fadeIn("fast");
      // Attach
      $("#popselt1 .close").on("click", function () {
        popselt1.hide();
        return false;
      });
      $("#popselt1").on("click", function (event) {
        event.stopPropagation();
      });
      // Unbind
      $(document).unbind(".popselt1popup");
      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.popselt1popup", function () {
          popselt1.hide();
        });
      }, 200);
    },
    hide: function () {
      // Show
      $("#popselt1").fadeOut("fast");
    },
    select: function (f) {
      // Get id
      let fid = $(f).attr("data-unique");
      // Update
      $(".calendarchg").text(fid);
      // Hide
      popselt1.hide();
    },
  };
})();

// Attach
$(document).ready(function () {
  popselt1.initialize();
});

/* Form sign in
---------------------------------------------------------------- */
var formsignin = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("form-signin")) {
        // Attach
        $("#form-signin").on("submit", function () {
          return formsignin.validate(this);
        });
        $("#form-signin .remember").on("click", function () {
          formsignin.remember(this);
          return false;
        });
        $("#form-signin .submit").on("click", function () {
          formsignin.setinternal();
        });
        $("#form-signin .email").on("click", function () {
          formsignin.emexp(this);
        });

        // Google reCAPTCHA
        grecapform.run($("#form-signin"));
      }
    },
    emexp: function (f) {
      // Remove
      $("#form-signin .elmhide").removeClass("elmhide");
      // Focus
      $(f).focus();
    },
    validate: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");
      $(".warnings").hide();

      // Execute
      let execute = true;
      // Using normal sign in or connect
      if (f.external.value == "false") {
        // Validate
        if (!validate.email(f.email.value)) {
          execute = false;
          // Error
          $(f.email).addClass("inperr");
        }

        // Validate
        if (!validate.empty(f.password.value)) {
          // Execute
          execute = false;
          // Error
          $(f.password).addClass("inperr");
        }

        // Show all fields
        formsignin.emexp(f.email);
      } else {
        // Validate
        if (!validate.empty(f.external_name.value)) {
          // Execute
          execute = false;
        }

        // Validate
        if (!validate.empty(f.external_email.value)) {
          // Execute
          execute = false;
        }

        // Validate
        if (!validate.empty(f.external_id.value)) {
          // Execute
          execute = false;
        }
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#form-signin").serialize();

        // Set loading
        $('#form-signin input[type="submit"]').addClass("sbt-load-t1");

        // Disable submit
        $('#form-signin input[type="submit"]').prop("disabled", true);

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/signin.php",
          data: dat,
          cache: false,
          dataType: "json",
          success: function (data) {
            const obj = data;

            // OK
            if (obj.meta.code == "200") {
              // Handle
              if (obj.user.found == "false") {
                if (obj.user.external == "true") {
                  // External user not found
                  $("#warn-connect-user").show();

                  // Clear loading + Enable submit
                  setTimeout(function () {
                    // Remove loading
                    $("#form-signin .submit").removeClass("sbt-load-t1");
                    $("#form-signin .submit").prop("disabled", false);

                    // Re-enable SSO options
                    $("#google-login").removeClass("disabled");
                    $("#facebook-login").removeClass("disabled");
                    $("#microsoft-login").removeClass("disabled");
                    $("#linkedin-login").removeClass("disabled");
                  }, 500);

                  // Reexecute reCAPTCHA
                  grecapform.execute($("#form-signin"));
                } else {
                  //not found and external is false

                  // Normal user not found
                  $("#warn-incorrect").show();

                  // Clear loading + Enable submit
                  setTimeout(function () {
                    $("#form-signin .submit").removeClass("sbt-load-t1");
                    $("#form-signin .submit").prop("disabled", false);
                  }, 500);

                  // Reexecute reCAPTCHA
                  grecapform.execute($("#form-signin"));
                }
              } else if (
                obj.user.found == "true" &&
                obj.user.account == "one"
              ) {
                // Redirect
                const returnPath =
                  obj.user.return_path != "" && obj.user.return_path
                    ? obj.user.return_path
                    : "/calendars";

                location.href = returnPath;
              } else if (
                obj.user.found == "true" &&
                obj.user.account == "multiple"
              ) {
                // Redirect
                location.href = "/signin/accounts";
              } else {
                // General error
                alert("General error. Please try again..");

                // Clear loading + Enable submit
                setTimeout(function () {
                  // Remove loading
                  $("#form-signin .submit").removeClass("sbt-load-t1");
                  $("#form-signin .submit").prop("disabled", false);

                  // Re-enable SSO options
                  $("#google-login").removeClass("disabled");
                  $("#facebook-login").removeClass("disabled");
                  $("#microsoft-login").removeClass("disabled");
                  $("#linkedin-login").removeClass("disabled");
                }, 500);

                // Reexecute reCAPTCHA
                grecapform.execute($("#form-signin"));
              }
            } else if (obj.meta.code == "400" && obj.meta.reason == "buhh") {
              // Notify
              notificator.break();

              // Error
              $("#warn-incorrect-recaptcha").show();
              // Clear loading + Enable submit
              setTimeout(function () {
                // Remove loading
                $("#form-signin .submit").removeClass("sbt-load-t1");
                $("#form-signin .submit").prop("disabled", false);

                // Re-enable SSO options
                $("#google-login").removeClass("disabled");
                $("#facebook-login").removeClass("disabled");
                $("#microsoft-login").removeClass("disabled");
                $("#linkedin-login").removeClass("disabled");
              }, 500);

              // Reexecute reCAPTCHA
              grecapform.execute($("#form-signin"));
            } else {
              // Notify
              notificator.break();
              // Error
              csrfpop.show();
              // Clear loading + Enable submit
              setTimeout(function () {
                // Remove loading
                $("#form-signin .submit").removeClass("sbt-load-t1");
                $("#form-signin .submit").prop("disabled", false);
                // Re-enable SSO options
                $("#google-login").removeClass("disabled");
                $("#facebook-login").removeClass("disabled");
                $("#microsoft-login").removeClass("disabled");
                $("#linkedin-login").removeClass("disabled");
              }, 500);
              // Reexecute reCAPTCHA
              grecapform.execute($("#form-signin"));
            }
          },
        });
      } else {
        // Using external or normal
        if (f.external.value == "false") {
          // Show error
          $("#warn-insuff").show();
        } else {
          // Show connect error
          $("#warn-connect").show();
          // Re-enable SSO options
          $("#google-login").removeClass("disabled");
          $("#facebook-login").removeClass("disabled");
          $("#microsoft-login").removeClass("disabled");
          $("#linkedin-login").removeClass("disabled");
        }
      }
      return false;
    },
    remember: function (f) {
      // Get relation
      let rel = $(f).attr("data-rel");
      // Get checked class
      let cls = $("#" + rel).hasClass("checked");

      // Checked?
      if (cls) {
        // Remove
        $("#" + rel).removeClass("checked");
        // Update
        $("#remember").val("false");
      } else {
        // Remove
        $("#" + rel).addClass("checked");
        // Update
        $("#remember").val("true");
      }
    },
    setinternal: function () {
      $("#external").val("false");
    },
  };
})();

/* Attach */
$(document).ready(function () {
  formsignin.initialize();
});

/* Form sign up
---------------------------------------------------------------- */
var formsignup = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("form-signup")) {
        // Attach
        $("#form-signup").on("submit", function () {
          return formsignup.validate(this);
        });
        $("#form-signup .remember").on("click", function () {
          formsignup.remember(this);
          return false;
        });
        $("#form-signup .submit").on("click", function () {
          formsignup.setinternal();
        });
        $("#form-signup .email").on("click", function () {
          formsignup.emexp(this);
        });
        // Google reCAPTCHA
        grecapform.run($("#form-signup"));
      }

      // Select plan
      if ($d("form-selectplan")) {
        // Attach
        $("#form-selectplan .plns .cl").on("click", function () {
          formsignup.planselect(this);
          return false;
        });
        $("#form-selectplan .plan_frequency").on("click", function () {
          formsignup.planfrequency(this);
        });
        $("#enterpriseplanform form").on("submit", function () {
          return formsignup.validateenterprise(this);
        });
        $("#enterpriseplanform .skip").on("click", function () {
          formsignup.gototools(this, "enterprise");
        });
        $("#hobbyplanform .skip").on("click", function () {
          formsignup.gototools(this, "hobby");
        });
        $("#err-transaction .additional").on("click", function () {
          formsignup.additionalfields(this);
          return false;
        });
        $("#signupplans").on("submit", function () {
          return formsignup.validatesignup(this);
        });
        // GTM: Get checkout
        $("#signupplans .submit").on("click", function () {
          googtagman.getaccountsignupcheckout();
        });

        // Ball animation
        $("#signup-account .balls").on(
          "animationend webkitAnimationEnd oAnimationEnd",
          function () {
            formsignup.switchdone();
          }
        );

        // Invoke initial
        formsignup.planinit();
        // Stripe init
        pypobskt.stripeinit();
      }

      // Attach
      $(".pri-t1 .cl").on("mouseover", function () {
        formsignup.primouseover(this);
        return false;
      });
      $(".pri-t1 .cl").on("mouseout", function () {
        formsignup.primouseout(this);
        return false;
      });
    },
    emexp: function (f) {
      // Remove
      $("#form-signup .elmhide").removeClass("elmhide");
      // Focus
      $(f).focus();
    },
    planinit: function () {
      // Get plan
      let pln = $("#form-selectplan").attr("data-plan");

      // Invoke
      if (pln == "smallbusiness") {
        $("#pln-smallbusiness").click();
      } else if (pln == "professional") {
        $("#pln-professional").click();
      } else if (pln == "enterprise") {
        $("#pln-enterprise").click();
      } else if (pln == "hobby") {
        $("#pln-hobby").click();
      }
    },
    planselect: function (f) {
      // Get plan
      let pln = $(f).attr("data-plan");
      let plnsav = $(f).attr("data-save-percent");
      // Get flow
      let orig_flow = $("#orig_flow").val();
      // Get domain
      let domain = $("#domain").val();
      let domain_url = "";

      //set domain url
      if (domain !== "") {
        domain_url = "&domain=" + domain;
      }

      // Clear
      $("#form-selectplan .plns .cl").removeClass("active");
      // Set active
      $(f).addClass("active");
      // Update form
      $("#form-selectplan").attr("data-plan", pln);
      // Remove warnings
      $(".warning").hide();
      // Hide
      $("#your-order").hide();
      $("#creditcardform").hide();
      $("#hobbyplanform").hide();
      $("#enterpriseplanform").hide();
      $("#sum-smallbusiness-month").hide();
      $("#sum-smallbusiness-year").hide();
      $("#sum-professional-month").hide();
      $("#sum-professional-year").hide();
      $("#pln-exp-smallbusiness").hide();
      $("#pln-exp-professional").hide();
      $("#pln-exp-enterprise").hide();
      $("#pln-exp-hobby").hide();

      let pri_mo = "";
      let pri_yr = "";

      // Toggle
      if (pln == "smallbusiness") {
        // Show
        $("#your-order").show();
        $("#creditcardform").show();
        $("#pln-exp-smallbusiness").show();

        // Get price variables
        pri_mo = $("#pln-smallbusiness").attr("data-unit-price-month");
        pri_yr = $("#pln-smallbusiness").attr("data-unit-price-year");

        // Toogle
        if ($("#your-order .plan_frequency").prop("checked")) {
          $("#pln-smallbusiness .unit").text("$" + pri_yr + "/mo");
          $("#sum-smallbusiness-year").show();
          $("#signupplans .inpplan").val("2");
          $("#sess_plan").val("2");
          if (orig_flow !== "") {
            window.history.pushState(
              { urlPath: "/select-plan/" + orig_flow + "/?pid=2" + domain_url },
              "",
              "/select-plan/" + orig_flow + "/?pid=2" + domain_url
            );
          } else {
            window.history.pushState(
              { urlPath: "/select-plan?pid=2" + domain_url },
              "",
              "/select-plan/?pid=2" + domain_url
            );
          }
        } else {
          $("#pln-smallbusiness .unit").text("$" + pri_mo + "/mo");
          $("#sum-smallbusiness-month").show();
          $("#signupplans .inpplan").val("1");
          $("#sess_plan").val("1");
          if (orig_flow !== "") {
            window.history.pushState(
              { urlPath: "/select-plan/" + orig_flow + "/?pid=1" + domain_url },
              "",
              "/select-plan/" + orig_flow + "/?pid=1" + domain_url
            );
          } else {
            window.history.pushState(
              { urlPath: "/select-plan?pid=1" },
              "",
              "/select-plan/?pid=1" + domain_url
            );
          }
        }

        // Update save
        $("#your-order .ab2 .yr span").html("(Save " + plnsav + "%)");
      } else if (pln == "professional") {
        // Show
        $("#your-order").show();
        $("#creditcardform").show();
        $("#pln-exp-professional").show();

        // Get price variables
        pri_mo = $("#pln-professional").attr("data-unit-price-month");
        pri_yr = $("#pln-professional").attr("data-unit-price-year");

        // Toogle
        if ($("#your-order .plan_frequency").prop("checked")) {
          $("#pln-professional .unit").text("$" + pri_yr + "/mo");
          $("#sum-professional-year").show();
          $("#signupplans .inpplan").val("4");
          $("#sess_plan").val("4");
          if (orig_flow !== "") {
            window.history.pushState(
              { urlPath: "/select-plan/" + orig_flow + "/?pid=4" + domain_url },
              "",
              "/select-plan/" + orig_flow + "/?pid=4" + domain_url
            );
          } else {
            window.history.pushState(
              { urlPath: "/select-plan?pid=4" + domain_url },
              "",
              "/select-plan/?pid=4" + domain_url
            );
          }
        } else {
          $("#pln-professional .unit").text("$" + pri_mo + "/mo");
          $("#sum-professional-month").show();
          $("#signupplans .inpplan").val("3");
          $("#sess_plan").val("3");
          if (orig_flow !== "") {
            window.history.pushState(
              { urlPath: "/select-plan/" + orig_flow + "/?pid=3" + domain_url },
              "",
              "/select-plan/" + orig_flow + "/?pid=3" + domain_url
            );
          } else {
            window.history.pushState(
              { urlPath: "/select-plan?pid=3" + domain_url },
              "",
              "/select-plan/?pid=3" + domain_url
            );
          }
        }

        // Update save
        $("#your-order .ab2 .yr span").html("(Save " + plnsav + "%)");
      } else if (pln == "enterprise") {
        // Show
        $("#enterprisewarn").show();
        $("#enterpriseplanform").show();
        $("#pln-exp-enterprise").show();
        if (orig_flow !== "") {
          window.history.pushState(
            { urlPath: "/select-plan/" + orig_flow + "/?pid=5" + domain_url },
            "",
            "/select-plan/" + orig_flow + "/?pid=5" + domain_url
          );
        } else {
          window.history.pushState(
            { urlPath: "/select-plan?pid=5" + domain_url },
            "",
            "/select-plan/?pid=5" + domain_url
          );
        }
      } else if (pln == "hobby") {
        // Show
        $("#hobbywarn").show();
        $("#hobbyplanform").show();
        $("#pln-exp-hobby").show();
        if (orig_flow !== "") {
          window.history.pushState(
            { urlPath: "/select-plan/" + orig_flow + domain_url },
            "",
            "/select-plan/" + orig_flow + domain_url
          );
        } else {
          window.history.pushState(
            { urlPath: "/select-plan" + domain_url },
            "",
            "/select-plan" + domain_url
          );
        }
      }
    },
    planfrequency: function (f) {
      // Frequency
      let freq = $(f).prop("checked");

      // Get unit prices
      let p1_pri_mo = $("#pln-smallbusiness").attr("data-unit-price-month");
      let p1_pri_yr = $("#pln-smallbusiness").attr("data-unit-price-year");
      let p2_pri_mo = $("#pln-professional").attr("data-unit-price-month");
      let p2_pri_yr = $("#pln-professional").attr("data-unit-price-year");

      // Update labels
      if (freq) {
        $("#pln-smallbusiness .unit").text("$" + p1_pri_yr + "/mo");
        $("#pln-professional .unit").text("$" + p2_pri_yr + "/mo");
      } else {
        $("#pln-smallbusiness .unit").text("$" + p1_pri_mo + "/mo");
        $("#pln-professional .unit").text("$" + p2_pri_mo + "/mo");
      }

      // Reset summarys
      $("#sum-smallbusiness-month").hide();
      $("#sum-smallbusiness-year").hide();
      $("#sum-professional-month").hide();
      $("#sum-professional-year").hide();

      // Get plan
      let pln = $("#form-selectplan").attr("data-plan");
      // Get flow
      let orig_flow = $("#form-selectplan").attr("data-plan-orig");
      // Get domain
      let domain = $("#form-selectplan").attr("data-plan-domain");
      let domain_url = "";

      //set domain url
      if (domain !== "") {
        domain_url = "&domain=" + domain;
      }

      // Toggle
      if (pln == "smallbusiness") {
        if (freq) {
          $("#sum-smallbusiness-year").show();
          $("#signupplans .inpplan").val("2");
          $("#sess_plan").val("2");
          if (orig_flow !== "") {
            window.history.pushState(
              { urlPath: "/select-plan/" + orig_flow + "/?pid=2" + domain_url },
              "",
              "/select-plan/" + orig_flow + "/?pid=2" + domain_url
            );
          } else {
            window.history.pushState(
              { urlPath: "/select-plan?pid=2" + domain_url },
              "",
              "/select-plan/?pid=2" + domain_url
            );
          }
        } else {
          $("#sum-smallbusiness-month").show();
          $("#signupplans .inpplan").val("1");
          $("#sess_plan").val("1");
          if (orig_flow !== "") {
            window.history.pushState(
              { urlPath: "/select-plan/" + orig_flow + "/?pid=1" + domain_url },
              "",
              "/select-plan/" + orig_flow + "/?pid=1" + domain_url
            );
          } else {
            window.history.pushState(
              { urlPath: "/select-plan?pid=1" + domain_url },
              "",
              "/select-plan/?pid=1" + domain_url
            );
          }
        }
      } else if (pln == "professional") {
        if (freq) {
          $("#sum-professional-year").show();
          $("#signupplans .inpplan").val("4");
          $("#sess_plan").val("4");
          if (orig_flow !== "") {
            window.history.pushState(
              { urlPath: "/select-plan/" + orig_flow + "/?pid=4" + domain_url },
              "",
              "/select-plan/" + orig_flow + "/?pid=4" + domain_url
            );
          } else {
            window.history.pushState(
              { urlPath: "/select-plan?pid=4" + domain_url },
              "",
              "/select-plan/?pid=4" + domain_url
            );
          }
        } else {
          $("#sum-professional-month").show();
          $("#signupplans .inpplan").val("3");
          $("#sess_plan").val("3");
          if (orig_flow !== "") {
            window.history.pushState(
              { urlPath: "/select-plan/" + orig_flow + "/?pid=3" + domain_url },
              "",
              "/select-plan/" + orig_flow + "/?pid=3" + domain_url
            );
          } else {
            window.history.pushState(
              { urlPath: "/select-plan?pid=3" + domain_url },
              "",
              "/select-plan/?pid=3" + domain_url
            );
          }
        }
      }
    },
    additionalfields: function (f) {
      // Show
      $("#card-extra-opts").show();
      // Go to warning
      const t = $("#err-transaction").offset().top - 20;
      // Scroll to top
      $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
    },
    gototools: function (f, pck) {
      // Set cookie
      cookies.create("account_type", pck, 365);
      // Go to
      // location.href = '/calendars';
      location.href = "/signup/complete";
    },
    switchtoplans: function () {
      // Scroll to top
      $("html, body").animate({ scrollTop: 0 }, "slow", "easeInOutQuart");

      // Invoke indicator
      $("#signup-account .indi-t1 .balls").addClass("load");
      //get plan id value
      let plan_id = $("#plan_id").val();
      //get domain value
      let domain = $("#domain").val();
      //set domain url to empty
      let domain_url = "";
      //if domain is not empty set url to defined value
      if (domain !== "") {
        domain_url = "/?domain=" + domain;
      }
      //direct to a plan or not?
      if (plan_id == "smallbusiness") {
        //direct to select-plan page
        window.location.href = "/select-plan" + domain_url;
      } else {
        //direct to select-plan page
        window.location.href = "/select-plan/" + plan_id + domain_url;
      }
    },
    switchdone: function () {
      // Timed

      // Show / hide
      $("#signup-account").hide();
      $("#signup-plan").show();

      //}, 1000);
    },
    validateenterprise: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");
      $(".warnings").hide();

      // Execute
      let execute = true;

      // Validate
      if (!validate.empty(f.company.value)) {
        execute = false;

        // Error
        $(f.company).addClass("inperr");
      }

      if (!validate.empty(f.message.value)) {
        execute = false;

        // Error
        $(f.message).addClass("inperr");
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#enterpriseplanform form").serialize();
        // Notify
        notificator.show("Processing message");
        // Disable submit
        $("#enterpriseplanform .submit").prop("disabled", true);

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/message.enterprise.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // Handle
            if (obj.message.sent == "true") {
              // Set cookie
              cookies.create("account_type", "enterprise", 365);
              // Redirect
              setTimeout(function () {
                // location.href = '/calendars';
                location.href = "/signup/complete";
              }, 1000);
            } else {
              // Timed
              setTimeout(function () {
                // Clear loading
                notificator.break();
                // Enable submit
                $("#enterpriseplanform .submit").prop("disabled", false);
              }, 2000);
              // General error
              alert("General error. Please try again..");
            }
          },
        });
      } else {
        // Show error
        $("#err-form-enterprise").show();
      }
      return false;
    },
    validatesignup: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");
      $(".warning").hide();

      // Execute
      let execute = true;
      // Get plan
      let freq = "";

      // Monthly/annual?
      if ($("#your-order .plan_frequency").prop("checked")) {
        freq = "annual";
      } else {
        freq = "monthly";
      }

      // Update
      $("#signupplans .inpfrequency").val(freq);

      // Execute?
      if (execute) {
        // Call
        pypobskt.pypobsktintrovalidate();
      }

      return false;
    },
    validate: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");
      $(".warnings").hide();

      // Execute
      let execute = true;

      // Using normal sign in or connect
      if (f.external.value == "false") {
        // Validate
        if (!validate.empty(f.fullname.value)) {
          execute = false;

          // Error
          $(f.fullname).addClass("inperr");
        }

        // Validate
        if (!validate.email(f.email.value)) {
          execute = false;

          // Error
          $(f.email).addClass("inperr");
        }

        // Validate
        if (!validate.empty(f.password.value) || !validate.passwordLength(f.password.value) || !validate.containsUppercase(f.password.value) || !validate.containsLowercase(f.password.value)) {
          // Execute
          execute = false;

          // Error
          $(f.password).addClass("inperr");
        }

        // Expand
        formsignup.emexp(f.email);
      } else {
        // Validate
        if (!validate.empty(f.external_name.value)) {
          // Execute
          execute = false;
        }

        // Validate
        if (!validate.empty(f.external_email.value)) {
          // Execute
          execute = false;
        }

        // Validate
        if (!validate.empty(f.external_id.value)) {
          // Execute
          execute = false;
        }
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#form-signup").serialize();
        // Notify
        notificator.show("Creating account");
        // Disable submit
        $("#form-signup .submit").prop("disabled", true);

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/signup.php",
          data: dat,
          dataType: "json",
          cache: false,
          success: function (data) {
            // Parse
            const obj = data;

            // OK
            if (obj.meta.code == "200") {
              // Handle
              if (obj.user.created == "true") {
                // Notify
                notificator.show("Account created", true);

                // Update client ID
                $("#clientid").val(obj.user.clientid);

                // Switch
                formsignup.switchtoplans();
              } else {
                // Timed
                setTimeout(function () {
                  // Clear loading
                  notificator.break();

                  // Enable submit
                  $("#form-signup .submit").prop("disabled", false);
                }, 2000);

                // Reexecute reCAPTCHA
                grecapform.execute($("#form-signup"));

                // General error
                alert("General error. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Show warnings
              if (obj.meta.uexias == "true") {
                // Show (account already exists)
                $("#warn-insuff-alr").show();

                // Re-enable SSO options
                $("#google-login").removeClass("disabled");
                $("#facebook-login").removeClass("disabled");
                $("#microsoft-login").removeClass("disabled");
                $("#linkedin-login").removeClass("disabled");
              } else if (obj.meta.reason == "buhh") {
                // Show
                $("#warn-incorrect-recaptcha").show();

                // Re-enable SSO options
                $("#google-login").removeClass("disabled");
                $("#facebook-login").removeClass("disabled");
                $("#microsoft-login").removeClass("disabled");
                $("#linkedin-login").removeClass("disabled");
              } else if (obj.meta.reason == "invalid_email") {
                $("#warn-invalid-email").show();
                $(f.email).addClass("inperr");
              } else {
                csrfpop.show();
              }

              // Timed enable
              setTimeout(function () {
                // Enable submit
                $("#form-signup .submit").prop("disabled", false);
              }, 500);

              // Reexecute reCAPTCHA
              grecapform.execute($("#form-signup"));

              // Scroll to top
              $("html, body").animate(
                { scrollTop: 0 },
                "slow",
                "easeInOutQuart"
              );
            }
          },
        });
      } else {
        // Set top
        let t = 0;

        // Using external or normal
        if (f.external.value == "false") {
          // Show error
          $("#warn-insuff").show();
          // Get warning top
          t = $("#warn-insuff").offset().top - 30;
        } else {
          // Show connect error
          $("#warn-connect").show();
        }

        // Scroll to top
        $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
      }

      return false;
    },
    remember: function (f) {
      // Get relation
      let rel = $(f).attr("data-rel");
      // Get checked class
      let cls = $("#" + rel).hasClass("checked");

      // Checked?
      if (cls) {
        // Remove
        $("#" + rel).removeClass("checked");

        // Update
        $("#remember").val("false");
      } else {
        // Remove
        $("#" + rel).addClass("checked");
        // Update
        $("#remember").val("true");
      }
    },
    setinternal: function () {
      $("#external").val("false");
    },
    primouseover: function () {
      // Add
      $(".pri-t1 .highlight").addClass("tmpoff");
    },
    primouseout: function () {
      // Add
      $(".pri-t1 .highlight").removeClass("tmpoff");
    },
  };
})();

/* Attach */
$(document).ready(function () {
  formsignup.initialize();
});

/* Form forgot
---------------------------------------------------------------- */

var formforgot = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("form-forgot")) {
        // Attach
        $("#form-forgot").on("submit", function () {
          return formforgot.validate(this);
        });
        // Google reCAPTCHA
        grecapform.run($("#form-forgot"));
      }
    },
    validate: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");
      $(".warnings").hide();

      // Execute
      let execute = true;

      // Validate
      if (!validate.email(f.email.value)) {
        execute = false;
        // Error
        $(f.email).addClass("inperr");
      }

      // Valid?
      if (execute) {
        // Get data
        const dat = $("#form-forgot").serialize();
        // Notify
        notificator.show("Loading..");
        // Disable submit
        $("#form-forgot .submit").prop("disabled", true);

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/forgot.php",
          data: dat,
          cache: false,
          dataType: "json",
          success: function (data) {
            // Parse
            const obj = data;

            // OK
            if (obj.meta.code == "200") {
              // Clear loading
              notificator.break();

              // Enable submit
              setTimeout(function () {
                $("#form-forgot .submit").prop("disabled", false);
              }, 1500);

              // Show
              $("#warn-success").show();
            } else if (obj.meta.code == "400" && obj.meta.reason == "buhh") {
              // Clear loading
              notificator.break();
              // Show
              $("#warn-incorrect-recaptcha").show();
              // Enable submit
              setTimeout(function () {
                $("#form-forgot .submit").prop("disabled", false);
              }, 1500);

              // Reexecute reCAPTCHA
              grecapform.execute($("#form-forgot"));
            } else {
              // Notify
              notificator.break();
              // Error
              csrfpop.show();
              // Reexecute reCAPTCHA
              grecapform.execute($("#form-forgot"));
            }
          },
        });
      } else {
        // Show error
        $("#warn-insuff").show();
      }

      return false;
    },
  };
})();

/* Attach */
$(document).ready(function () {
  formforgot.initialize();
});

/* Form forgot reset
---------------------------------------------------------------- */

var formforgotreset = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("form-forgot-reset")) {
        // Attach
        $("#form-forgot-reset").on("submit", function () {
          return formforgotreset.validate(this);
        });
      }
    },
    validate: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");
      $(".warnings").hide();

      // Execute
      let execute = true,
        error_num = 0;

      // Validate
      if (!validate.empty(f.password1.value)) {
        execute = false;

        // Error
        $(f.password1).addClass("inperr");
      }

      // Validate
      if (!validate.empty(f.password2.value)) {
        execute = false;

        // Error
        $(f.password2).addClass("inperr");
      }

      // Validate
      if (f.password1.value != f.password2.value) {
        execute = false;
        error_num = 1;

        // Error
        $(f.password1).addClass("inperr");
        $(f.password2).addClass("inperr");
      }

      // Validate
      if (!validate.passwordLength(f.password1.value) || !validate.containsUppercase(f.password1.value) || !validate.containsLowercase(f.password1.value)) {
        execute = false;
      }

      // Validate
      if (f.email.value == "" || f.token.value == "") {
        execute = false;
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#form-forgot-reset").serialize();

        // Notify
        notificator.show("Loading..");

        // Disable submit
        $("#form-forgot-reset .submit").prop("disabled", true);

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/forgot-reset.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Clear loading
            notificator.break();

            // Enable submit
            setTimeout(function () {
              $("#form-forgot-reset .submit").prop("disabled", false);
            }, 1500);

            // Parse
            const obj = parseResponse(data);
            // Handle
            if (obj.user.success == "true") {
              // Redirect
              location.href = "/account";
            } else {
              // General error
              alert("Invalid token. Please try again..");
              // Redirect
              location.href = "/forgot-password";
            }
          },
        });
      } else {
        // Handle error
        if (error_num == 1) {
          // Show error
          $("#warn-nomatch").show();
        } else {
          // Show error
          $("#warn-insuff").show();
        }
      }

      return false;
    },
  };
})();

/* Attach */
$(document).ready(function () {
  formforgotreset.initialize();
});

/* Form invitation signup
---------------------------------------------------------------- */

var forminvitesignup = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("form-invited")) {
        // Attach
        $("#form-invited").on("submit", function () {
          return forminvitesignup.validate(this);
        });
        $("#form-invited .submit").on("click", function () {
          forminvitesignup.setinternal();
        });
        $("#form-invited .email").on("click", function () {
          forminvitesignup.emexp(this);
        });

        // Google reCAPTCHA
        grecapform.run($("#form-invited"));
      }
    },
    emexp: function (f) {
      // Remove
      $("#form-invited .elmhide").removeClass("elmhide");
      // Focus
      $(f).focus();
    },
    validate: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");
      $(".warnings").hide();
      // Execute
      let execute = true;

      // Using normal sign in or connect
      if (f.external.value == "false") {
        // Validate
        if (!validate.empty(f.fullname.value)) {
          execute = false;
          // Error
          $(f.fullname).addClass("inperr");
        }

        // Validate
        if (!validate.email(f.email.value)) {
          execute = false;
          // Error
          $(f.email).addClass("inperr");
        }

        // Validate
        if (!validate.empty(f.password.value) || !validate.passwordLength(f.password.value) || !validate.containsUppercase(f.password.value) || !validate.containsLowercase(f.password.value)) {
          // Execute
          execute = false;
          // Error
          $(f.password).addClass("inperr");
        }

        // Expand
        forminvitesignup.emexp(f.email);
      } else {
        // Validate
        if (!validate.empty(f.external_name.value)) {
          // Execute
          execute = false;
        }

        // Validate
        if (!validate.empty(f.external_email.value)) {
          execute = false;
        }

        // Validate
        if (!validate.empty(f.external_id.value)) {
          execute = false;
        }
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#form-invited").serialize();
        // Notify
        notificator.show("Loading..");

        // Disable submit
        $("#form-invited .submit").prop("disabled", true);
        $("#form-invited .submit").addClass("disabled");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/signup-invite.php",
          data: dat,
          cache: false,
          success: function (data) {
            notificator.break();

            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Handle
              if (obj.user.created == "true") {
                // Redirect
                location.href = "/calendars";
              } else {
                // User already exists
                if (obj.user.user_exists == "true") {
                  // Show error
                  $("#warn-usrexists").show();

                  // Enable
                  $("#form-invited .submit").prop("disabled", false);
                  $("#form-invited .submit").removeClass("disabled");
                } else {
                  // General error
                  alert("General error. Please try again..");

                  // Enable
                  $("#form-invited .submit").prop("disabled", false);
                  $("#form-invited .submit").removeClass("disabled");
                }

                // Reexecute reCAPTCHA
                grecapform.execute($("#form-invited"));
              }
            } else if (obj.meta.code == "400") {
              // Enable
              $("#form-invited .submit").prop("disabled", false);
              $("#form-invited .submit").removeClass("disabled");

              if (obj.meta.reason == "buhh") {
                // Show
                $("#warn-incorrect-recaptcha").show();
                // Reexecute reCAPTCHA
                grecapform.execute($("#form-invited"));
              }

              if (obj.meta.reason == "invalid_email") {
                $("#warn-invalid-email").show();
                $(f.email).addClass("inperr");
              }
            } else {
              // Error
              csrfpop.show();

              // Reexecute reCAPTCHA
              grecapform.execute($("#form-invited"));
            }
          },
        });
      } else {
        // Using external or normal
        if (f.external.value == "false") {
          // Show error
          $("#warn-insuff").show();
        } else {
          // Show connect error
          $("#warn-connect").show();

          // Reexecute reCAPTCHA
          grecapform.execute($("#form-invited"));
        }
      }

      return false;
    },
    setinternal: function () {
      $("#external").val("false");
    },
  };
})();

/* Attach */
$(document).ready(function () {
  forminvitesignup.initialize();
});

/* Users
---------------------------------------------------------------- */

var userslist = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("usersmng")) {
        // Attach
        $("#usersmng .invitebtn").on("click", function () {
          userslist.inviteshow(this);
        });
        $("#usersmng .accesslevel").on("change", function () {
          userslist.accesslevelchange(this);
        });
        $("#usersmng .delusr").on("click", function () {
          userslist.deleteuser(this);
          return false;
        });
        $("#usersmng .delinvi").on("click", function () {
          userslist.deleteinvite(this);
          return false;
        });
      }
    },
    inviteshow: function (f) {
      // Unbind
      $(document).unbind(".userslistwin");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.userslistwin", function () {
          // Hide
          userslist.invitehide();
        });
      }, 200);

      // Is invite already generated?
      let inv = $("#users-man-pop").html();

      // Generated?
      if (inv == "") {
        // Notify
        notificator.show("Loading invite");
        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/templates/ajax_invite_form.php",
          cache: false,
          success: function (data) {
            // Notify break
            notificator.break();

            // Append
            $("#users-man-pop").html(data);

            // Show
            $("#users-man-pop").show();
            $("#users-man-popbg").show();

            // Timed show
            setTimeout(function () {
              // Show
              $("#users-man-pop").addClass("show");
              $("#users-man-popbg").addClass("show");
            }, 200);

            // Attach
            $("#users-man-form").on("submit", function () {
              return userslist.validate(this);
            });
            $("#users-man-form .close a").on("click", function () {
              userslist.invitehide(this);
              return false;
            });
            $("#users-man-pop").on("click", function (event) {
              event.stopPropagation();
              $(".invitelevel").trigger("chosen:close");
            });
            $("#inviteurl").on("click", function () {
              userslist.focustoken(this);
            });
            // Google reCAPTCHA
            grecapform.run($("#users-man-form"));

            // Adjust window
            userslist.winadjust();
          },
        });
      } else {
        // Show
        $("#users-man-pop").show();
        $("#users-man-popbg").show();

        // Timed show
        setTimeout(function () {
          // Show
          $("#users-man-pop").addClass("show");
          $("#users-man-popbg").addClass("show");
        }, 200);
        // Hide confirm if visible
        $("#warn-user-invited").hide();
        // Adjust window
        userslist.winadjust();
        // Reset
        $("#usf-email").val("");
        $(".invitelevel").val("2");

        // Reexecute reCAPTCHA
        grecapform.execute($("#users-man-form"));
      }
    },
    focustoken: function (f) {
      // Select text
      $(f).focus();

      // Select text
      $(f).select();
    },
    winadjust: function () {
      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#users-man-pop").height();
      let pw = $("#users-man-pop").width();

      // New top
      let nt = st + (wh / 2 - ph / 2);

      // Update positions
      $("#users-man-pop").css("top", nt + "px");
    },
    invitehide: function (f) {
      // Hide
      $("#users-man-pop").hide();
      $("#users-man-popbg").hide();

      // Remove
      $("#users-man-pop").removeClass("show");
      $("#users-man-popbg").removeClass("show");

      // Unbind
      $(document).unbind(".userslistwin");
    },
    validate: function (f) {
      // Variables
      let execute = true;

      // Validate
      if (!validate.email(f.email.value)) {
        execute = false;

        // Error
        $(f.email).addClass("inperr");
      }

      // Execute
      if (execute) {
        // Remove warnings
        $(".warnings").hide();

        // Get data
        let dat = $("#users-man-form").serialize();

        // Set loading
        $('#users-man-form input[type="submit"]').addClass("sbt-load-t1");

        // Disable submit
        $('#users-man-form input[type="submit"]').prop("disabled", true);

        // Notify
        notificator.show("Sending invite");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/user-invite.php",
          data: dat,
          dataType: "json",
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Clear loading
              $('#users-man-form input[type="submit"]').removeClass(
                "sbt-load-t1"
              );

              // Enable submit
              $('#users-man-form input[type="submit"]').prop("disabled", false);

              // Warnings or success
              if (obj.user.exists == "true") {
                // Show
                $("#warn-user-exists").show();

                // Notify
                notificator.break();
              } else if (
                obj.user.invite == "invite" ||
                obj.user.invite == "reinvite"
              ) {
                // Show
                $("#warn-user-invited").show();

                // Notify
                notificator.show("Invite sent", true);

                // Reset
                $("#usf-email").val("");

                $(".invitelevel").val("2");

                // Trigger
                $(".invitelevel").trigger("chosen:updated");
              } else {
                // General error
                alert("General error. Please try again..");
              }

              // Reexecute reCAPTCHA
              grecapform.execute($("#users-man-form"));
            } else if (obj.meta.code == "429") {
              // Add a disable
              $("#doc").addClass("exceednotice exceed8");
              // Run prevents
              plnlmtpop.preventfeatures();
              // Notify
              notificator.break();
            } else {
              // Notify
              notificator.break();
              // Error
              csrfpop.show();
              // Reexecute reCAPTCHA
              grecapform.execute($("#users-man-form"));
            }
          },
        });
      }

      return false;
    },
    accesslevelchange: function (f) {
      // Clear warnings
      $(".warnings").hide();
      // Get user id
      let uid = $(f).attr("data-userid");
      // CSRF
      let csrf = $(f).attr("data-csrf");
      // Get value
      let val = $(f).val();
      // Get original value
      let org = $(f).attr("data-value");
      // Remember the object
      let elm = f;
      // Notify
      notificator.show("Changing role");

      // Set data
      let dat = "uid=" + uid + "&role=" + val + "&csrf=" + csrf;

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/actions/user/role-save.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);

          // OK
          if (obj.meta.code == "200") {
            // Updated
            if (obj.user.updated == "true") {
              // Notify
              notificator.show("Role updated", true);

              // Reload
              if (org == "1") {
                setTimeout(function () {
                  location.reload();
                }, 500);
              }
            }

            // Not updated
            if (obj.user.updated == "false") {
              // Show error
              $("#warn-oneadmin").show();

              // Notify
              notificator.break();

              // Reset
              $(elm).val(org);

              // Trigger
              $(elm).trigger("chosen:updated");

              // Go to warning
              let t = $("#warn-oneadmin").offset().top - 200;

              // Scroll to top
              $("html, body").animate(
                { scrollTop: t },
                "slow",
                "easeInOutQuart"
              );
            }
          } else {
            // Notify
            notificator.break();

            // Error
            csrfpop.show();
          }
        },
        error: function () {
          // Fail
          alert("Failed to update the role change..");

          // Reset
          $(elm).val(org);

          // Trigger
          $(elm).trigger("chosen:updated");
        },
      });
    },
    deleteuser: function (f) {
      // Get user id
      let rel = $(f).attr("data-rel");

      // CSRF
      let csrf = $(f).attr("data-csrf");

      // Confirm
      let con = confirm(
        "Are you sure you want to delete the user? This action cannot be undone."
      );

      // Delete
      if (con) {
        // Notify
        notificator.show("Deleting user");

        // Set data
        let dat = "uid=" + rel + "&csrf=" + csrf;

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/user-delete.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Updated
              if (obj.user.deleted == "true") {
                // Notify
                notificator.show("User deleted", true);

                // Reload
                setTimeout(function () {
                  location.reload();
                }, 500);
              }

              // Not updated
              if (obj.user.deleted == "false") {
                // Show error
                $("#warn-oneadmin").show();

                // Notify
                notificator.break();

                // Go to warning
                let t = $("#warn-oneadmin").offset().top - 200;

                // Scroll to top
                $("html, body").animate(
                  { scrollTop: t },
                  "slow",
                  "easeInOutQuart"
                );
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
          error: function () {
            // Fail
            alert("Failed to delete the user..");
          },
        });
      }
    },
    deleteinvite: function (f) {
      // Get user id
      let rel = $(f).attr("data-rel");

      // CSRF
      let csrf = $(f).attr("data-csrf");

      // Confirm
      let con = confirm("Are you sure you want to delete the invitation?");

      // Delete
      if (con) {
        // Notify
        notificator.show("Deleting invitation");

        // Set data
        let dat = "id=" + rel + "&csrf=" + csrf;

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/invitation-delete.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Updated
              if (obj.invitation.deleted == "true") {
                // Notify
                notificator.show("Invitation deleted", true);

                // Reload
                setTimeout(function () {
                  location.reload();
                }, 500);
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
          error: function () {
            // Fail
            alert("Failed to delete the user..");
          },
        });
      }
    },
  };
})();

// Attach
$(document).ready(function () {
  userslist.initialize();
});

/* Checks
---------------------------------------------------------------- */

var checks = (function () {
  return {
    initialize: function () {
      // Loop through checkboxes, set check
      $(".check-t1").each(function () {
        // Already set?
        let cls = $(this).hasClass("isset");

        // Not set
        if (!cls) {
          // Attach
          $(this)
            .find(".check, .label")
            .on("click", function () {
              return checks.checkset(this);
            });

          $(this).addClass("isset");

          // Get relation
          let rel = $(this).attr("id");

          // Selected?
          let chk = $(this).hasClass("selected");

          // Toogle underlaying checkbox
          if (chk) {
            // Check
            $("#" + rel + " input[type=checkbox]").prop("checked", true);
          } else {
            // Uncheck
            $("#" + rel + " input[type=checkbox]").prop("checked", false);
          }
        }
      });
    },
    checkset: function (f) {
      // Allowed?
      if (!$(f).parents(".check-t1").hasClass("disabled")) {
        // Get relation
        let rel = $(f).attr("data-rel");

        // Get class
        let chk = $("#" + rel).hasClass("selected");

        // Mandatory?
        let man = $("#" + rel).hasClass("mandatory");

        // Manuel toogle? If set, then this script is ignorred
        let mln = $("#" + rel).hasClass("manuel");

        // Allow check / uncheck if not mandatory
        if (!man && !mln) {
          // Add / remove
          if (chk) {
            // Set inactive
            $("#" + rel).removeClass("selected");

            // Uncheck
            //$('#' + rel + ' input[type=checkbox]').removeAttr('checked');
            $("#" + rel + " input[type=checkbox]").prop("checked", false);

            // Update "inpval" (if it exists)
            $("#" + rel + "-inpval").val("false");
            $("#" + rel)
              .find(".checkvalue")
              .val("false");
          } else {
            // Set active
            $("#" + rel).addClass("selected");

            // Check
            //$('#' + rel + ' input[type=checkbox]').attr('checked', 'checked');
            $("#" + rel + " input[type=checkbox]").prop("checked", true);

            // Update "inpval" (if it exists)
            $("#" + rel + "-inpval").val("true");
            $("#" + rel)
              .find(".checkvalue")
              .val("true");
          }
        }
      }
    },
  };
})();

/* Attach */
$(document).ready(function () {
  checks.initialize();
});

/* Radios
---------------------------------------------------------------- */

let radios = (function () {
  return {
    initialize: function () {
      // Loop through radios, set check
      $(".radio-t1").each(function () {
        // Already set?
        let cls = $(this).hasClass("isset");

        // Not set
        if (!cls) {
          // Attach
          $(this)
            .find(".radio, .label")
            .on("click", function () {
              return radios.radioset(this);
            });
          $(this).addClass("isset");
          // Get selected class
          let chk = $(this).hasClass("selected");

          // Checked?
          if (chk) {
            // Check radio
            $(this).find("input[type=radio]").prop("checked", true);
          }
        }
      });
    },
    radioset: function (f) {
      // Get relation
      let rel = $(f).attr("data-rel");

      // Uncheck/reset radios
      $("#" + rel + " input[type=radio]").prop("checked", false);
      $("#" + rel + " .radio-t1").removeClass("selected");

      // Set active
      $(f).closest(".radio-t1").addClass("selected");
      // Check radio
      $(f).closest(".radio-t1").find("input[type=radio]").prop("checked", true);
    },
  };
})();

/* Attach */
$(document).ready(function () {
  radios.initialize();
});

/* MISC
---------------------------------------------------------------- */
var misc = (function () {
  // Variables
  let intgc = 1;

  return {
    initialize: function () {
      // Share
      $(".gotoshare").on("click", function () {
        misc.gotoshare(this);
        return false;
      });
      // Menu anchor
      $(
        ".menu-t1 .anchor, .menu-t2 .anchor, .nav-t2 .anchor, #sidebarleft .menu-t1 a"
      ).on("click", function () {
        misc.anchor(this);
        return false;
      });
      // Content anchor
      $(".anchorinl").on("click", function () {
        misc.anchorinl(this);
        return false;
      });
      // Frontpage
      $("#frontpage .btnmore").on("click", function () {
        misc.frtmore(this);
        return false;
      });
      // Trial
      $(".accounttriallink").on("click", function () {
        misc.trialaccount(this, '');
        return false;
      });
      // Any welcome popup?
      if ($("#pop-welcome").length) {
        // Initialize
        misc.welcomeinit();
        // Welcome popup close
        $(".welcomeclose").on("click", function () {
          misc.welcomeclose(this);
          return false;
        });
      }

      // Load more stats
      if ($(".loadmorestats").length) {
        // Attach
        $(".loadmorestats").on("click", function () {
          misc.loadmorestats(this);
          return false;
        });
      }

      // Integrations switch
      misc.integrations();
      // Preload images
      $(document.body).append(
        '<div class="preload"><img src="https://cdn.addevent.com/legacy2000/gfx/icon-load-t2a.svg" alt="" /><img src="https://cdn.addevent.com/legacy2000/gfx/icon-load-t2c.svg" alt="" /><img src="https://cdn.addevent.com/legacy2000/gfx/icon-loading-t1.svg" alt="" /><img src="https://cdn.addevent.com/legacy2000/gfx/icon-load-t1.gif" alt="" /><img src="https://cdn.addevent.com/legacy2000/gfx/icon-arrow-down-t1.svg" alt="" /><img src="https://cdn.addevent.com/legacy2000/gfx/input-radio-t1.png" alt="" /><img src="https://cdn.addevent.com/legacy2000/gfx/tooltip-arrows-t1.svg" alt="" /></div>'
      );
    },
    integrations: function () {
      // Exists?
      if ($("#intg-mc1").length) {
        // Timed switch
        setInterval(function () {
          // Reset all
          $(".intg-t1 .it5").removeClass("active");
          // Set active
          $(".intg-t1 #intg-mc" + intgc).addClass("active");

          // Add / reset
          if (intgc == "5") {
            intgc = 1;
          } else {
            intgc++;
          }
        }, 3000);
      }
    },
    sharefacebook: function (f) {
      // Get url to share
      let url = $(f).attr("data-href");

      // Get variables
      let ref = "https://www.facebook.com/sharer/sharer.php?u=" + url;
      let ww = 500,
        wh = 250;
      let wl = screen.width / 2 - ww / 2;
      let wt = screen.height / 2 - wh / 2;

      // Open
      window.open(
        ref,
        "ATE",
        "width=" + ww + ",height=" + wh + ",left=" + wl + ",top=" + wt
      );
    },
    sharetwitter: function (f) {
      // Get url to share
      let url = $(f).attr("data-href");
      let nam = $(f).attr("data-name");
      let txt = encodeURIComponent(nam);

      // Get variables
      let ref =
        "https://twitter.com/intent/tweet?text=" + txt + "&related=&url=" + url;
      let ww = 500,
        wh = 280;
      let wl = screen.width / 2 - ww / 2;
      let wt = screen.height / 2 - wh / 2;

      // Open
      window.open(
        ref,
        "ATE",
        "width=" + ww + ",height=" + wh + ",left=" + wl + ",top=" + wt
      );
    },
    gotoshare: function () {
      // Get top
      const stp = $("#anchor-share").offset().top - 35;

      // Scroll
      setTimeout(function () {
        $("html, body").animate({ scrollTop: stp }, "slow", "easeInOutQuart");
      }, 800);
    },
    anchor: function (f) {
      // Get section
      let sec = $(f).attr("data-rel");
      // Get top + opera heights
      let th = $(".top").outerHeight() + $(".opera").outerHeight();

      // Exists?
      if ($("#anchor-" + sec).length) {
        // Locate top
        let t = $("#anchor-" + sec).offset().top - th - 35;

        // Scroll to top
        $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
      }
    },
    observeanchor: function (f) {
      // Scroll top
      let t = $(document).scrollTop();
      // Closest
      let store,
        found = false;

      let ty = $(".top").outerHeight() + $(".opera").outerHeight();
      let xi = $(window).height();

      // Loop
      $("*[id^='anchor']").each(function () {
        // Get reference
        let fid = $(this).attr("id");
        let fit = $(this).offset().top;

        // Closest to top
        if (fit <= t + xi) {
          store = fid.replace(/anchor-/g, "");
        }
      });

      // Reset
      $("#sidebarleft .menu-t1 a, .cont-t1 .menu-t1 a").removeClass("selected");

      $("#sidebarleft .menu-t1, .cont-t1 .menu-t1")
        .find("[data-rel='" + store + "']")
        .addClass("selected");
    },
    anchorinl: function (f) {
      // Get section
      let sec = $(f).attr("data-rel");
      // Get ref
      let ref = $(f).attr("href");
      // Get top + opera heights
      let th = $(".top").outerHeight() + $(".opera").outerHeight();

      // Exists?
      if ($(ref).length) {
        // Locate top
        const t = $(ref).offset().top - th - 35;
        // Scroll to top
        $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
      }
    },
    observefixmenu: function () {
      // Exists?
      if ($(".cont-t1 .right .pad").length) {
        // Scroll top
        let t = $(document).scrollTop();
        let th = $(".top").outerHeight();
        let ot = parseInt($(".top").css("top"));
        let ty = $(".cont-t1 .right .pad").position().top;

        // Add/remove class
        if (t + th + ot >= ty) {
          $("#doc").addClass("fxlftmnu");
        } else {
          $("#doc").removeClass("fxlftmnu");
        }
      }
    },
    frtmore: function (f) {
      // Locate top
      let t = $("#anchor-services").offset().top - 50;

      // Scroll to top
      $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
    },
    welcomeinit: function () {
      // Set top
      $("#pop-welcome").css("top", "50px");

      // Show
      $("#pop-welcome").show();
      $("#pop-welcome-bg").show();
    },
    welcomeclose: function () {
      // Hide
      $("#pop-welcome").hide();
      $("#pop-welcome-bg").hide();

      // Start welcome sceries
      if (typeof introt1 !== "undefined" && introt1 !== null) {
        introt1.initialize();
      }
    },
    trialaccount: function (f, re) {
      if (!$(f).hasClass("working")) {

        // Add
        $(f).addClass("working");

        // Notify
        if(re != 'trial_confirm'){
          notificator.show("Activating..");
        }

        // Get current plan
        let curPln = $('#account-guid').attr('data-account-plan-name');

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/account-trial.php",
          cache: false,
          success: function (data) {

            // Parse
            const obj = parseResponse(data);

            // Confirmation or reload
            if(re == 'trial_confirm'){

              // Timed confirm
              setTimeout(function(){

                // Hide
                $('#pop-plan-limit .frtr .trial').css('visibility', 'hidden');

                // Show
                $('#pop-plan-limit .frtr .done').show();

                // Alert
                windowalert.run(
                    function(){

                      // Done
                      notificator.show("Reloading..");

                      // Reload
                      setTimeout(function(){
                        location.reload();
                      }, 800);

                    },{
                      "tit":"Your free trial is activated!",
                      "desc":"Congratulations. You can now use all of our paid features until " + obj.data.date + ". After that, your account will revert to <span style=\"text-transform:capitalize;\">" + curPln + "</span> status.",
                      "btnok":"OK"
                    }
                );

              }, 2500);

            }else{

              // Done
              notificator.show("Activated", true);

              // Reload
              setTimeout(function () {
                // Reload
                location.reload();
              }, 1500);

            }

          },
        });
      }
    },
    goback: function (f) {
      // Get back url
      let url = $("#doc").attr("data-ref");
      let prev = window.location.href;
      // Back
      window.history.go(-1);

      // Timed check
      setTimeout(function () {
        if (window.location.href == prev) {
          window.location.href = url;
        }
      }, 500);
    },
    codemirrorexamples: function (f) {
      // Run
      $(".codemirrorexamples").each(function (index, elem) {
        // Get content (to trim last line)
        let x = $(this).html();

        // Any last line?
        if (x.lastIndexOf("\n") > 0) {
          x = x.substring(0, x.lastIndexOf("\n"));
        }

        // Update
        $(this).html(x);

        // Run code mirror
        CodeMirror.fromTextArea(elem, {
          lineWrapping: true,
          mode: "text/html",
          lineNumbers: false,
          readOnly: false,
        });
      });
    },
    loadmorestats: function (f) {
      // Get id
      let rel = $(f).attr("data-rel");
      // Get base
      let bas = $(f).closest(".loadmore");
      // Remove
      $(bas).remove();
      // Show
      $("#" + rel).css("display", "contents");
    },
  };
})();

/* Attach */
$(document).ready(function () {
  misc.initialize();
});
$(window).on("scroll", function () {
  misc.observeanchor();
});
$(window).on("scroll", function () {
  misc.observefixmenu();
});

/* Info pop
---------------------------------------------------------------- */

var infopop = (function () {
  return {
    initialize: function () {
      // Find tooltip
      $(".infopoplnk").each(function () {
        // Get class
        let cls = $(this).hasClass("infopopset");

        // Not set
        if (!cls) {
          // Append
          $(this).on("mouseover", function () {
            infopop.show(this);
          });
          $(this).on("mouseout", function () {
            infopop.hide(this);
          });
          // Add class
          $(this).addClass("infopopset");
        }
      });
    },
    show: function (f) {
      // Add
      $(f).find(".infopop").addClass("show");
    },
    hide: function (f) {
      // Remove
      $(f).find(".infopop").removeClass("show");
    },
  };
})();

/* Attach */
$(document).ready(function () {
  infopop.initialize();
});

/* Sticky pop
---------------------------------------------------------------- */

var stickypop = (function () {
  // Variables
  let rowobj = null;

  return {
    initialize: function () {
      // Attach
      if ($(".tip-t2").length) {
        // Attach
        $(".ussta-t1 .row").on("mouseover", function () {
          stickypop.row(this);
        });
        $(".ussta-t1 .row").on("click", function () {
          stickypop.hide(this);
        });
        // Prevent
        $(".tooltip-t2").on("click", function (event) {
          event.stopPropagation();
        });
        // Find tooltip
        $(".tip-t2").each(function () {
          // Get class
          let cls = $(this).hasClass("tipt2set");
          // Not set
          if (!cls) {
            // Append
            $(this).on("mouseover", function () {
              stickypop.show(this);
            });
            // Add class
            $(this).addClass("tipt2set");
          }
        });
      }
    },
    show: function (f) {
      // Get base
      let bas = $(f).closest(".td1");
      // Get tooltip
      let obj = $(bas).find(".tooltip-t2");
      // Show
      $(obj).show();
      // Get height
      let h = $(obj).height();
      console.log(h);
      // Set top
      $(obj).css("top", -h - 22 + "px");
    },
    hide: function (f) {
      // Remove
      $(".tooltip-t2").hide();
    },
    row: function (f) {
      if (f != rowobj) {
        rowobj = f;
        // Hide sticky popup
        stickypop.hide();
      }
    },
  };
})();

/* Attach */
$(document).ready(function () {
  stickypop.initialize();
});

/* Custom templates
---------------------------------------------------------------- */

var eventform4 = (function () {
  return {
    initialize: function () {
      // Initialize form
      eventform4.forminit();
    },
    forminit: function () {
      // Attach
      $(".sec-t1 .btn .onetimenewtemplate").on("click", function () {
        eventform4.newtemplate(this, "1");
        return false;
      });
      $(".sec-t1 .btn .multiplenewtemplate").on("click", function () {
        eventform4.newtemplate(this, "2");
        return false;
      });
      $(".sec-t1 .btn .multipleembednewtemplate").on("click", function () {
        eventform4.newtemplate(this, "3");
        return false;
      });

      // Exists?
      if ($(".eventform4").length) {
        // Attach
        $(".eventform4 form").on("submit", function () {
          return eventform4.validate(this);
        });
        $(".eventform4 .sbt-t1 .cancel").on("click", function () {
          eventform4.cancel(this);
          return false;
        });
        $(".eventform4 .sbt-t1 .restore").on("click", function () {
          eventform4.templaterestore(this);
          return false;
        });
        $(".eventform4 .sbt-t1 .preview").on("click", function () {
          eventform4.templateview(this);
          return false;
        });
      }
    },
    validate: function (f) {
      // Execute
      let execute = true,
        gotofrm = true;
      // Title
      if (!validate.empty(f.title.value)) {
        // Stop
        execute = false;
        // Show error
        $("#title_err").show();

        // Focus field
        f.title.focus();
      } else {
        // Hide
        $("#title_err").hide();
      }

      // Get relation (to determine if the template is new or being edited)
      let rel = $(".eventform4 #relation").val();
      // Data
      let dat = $(".eventform4 form").serialize();

      // Post?
      if (execute) {
        // Show loader + disable submit
        $(".eventform4 .save").prop("disabled", true);

        // Notify
        if (rel > "0") {
          notificator.show("Saving template");
        } else {
          notificator.show("Creating template");
        }

        // Post
        $.ajax({
          type: "POST",
          data: dat,
          url: "/source/web/actions/templates/template-save.php",
          dataType: "json",
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Enable submit
              $(".eventform4 .save").prop("disabled", false);
              // Notify
              if (rel > "0") {
                // Notify
                notificator.show("Template saved", true);
                // Reload template
                setTimeout(function () {
                  location.href =
                    obj.template.returnpath + "/" + obj.template.id + "/#saved";
                }, 800);
              } else {
                // Notify
                notificator.show("Template created", true);
                // Reload template
                setTimeout(function () {
                  location.href =
                    obj.template.returnpath + "/" + obj.template.id + "/#saved";
                }, 800);
              }
            } else {
              // Notify
              notificator.break();
              // Error
              csrfpop.show();
            }
          },
        });
      } else {
        // Show error?
        if (gotofrm) {
          // Show
          $("#warn-insuff").show();
          // Scroll
          $("html, body").animate({ scrollTop: 0 }, "slow", "easeInOutQuart");
        }
      }

      return false;
    },
    templaterestore: function (f) {
      // Confirm
      let con = confirm(
        "Are you sure you want to insert the original template?"
      );

      // Delete
      if (con) {
        // Saving
        notificator.show("Loading template");
        // Get calendar id
        let fid = $("#eventform4 #clientid").val();
        // Get template type
        let tty = $("#eventform4 #templatetype").val();
        // URL
        let url = "";

        // URL (1 = one-time event / 2 = multiple events  / 3 = embed calendar)
        if (tty == "1") {
          url =
            "/source/web/templates/ajax_templates_one_time_events.php?client=" +
            fid;
        } else if (tty == "2") {
          url =
            "/source/web/templates/ajax_templates_multiple_events.php?client=" +
            fid;
        } else if (tty == "3") {
          url =
            "/source/web/templates/ajax_templates_calendar_embed.php?client=" +
            fid;
        }

        // Ajax
        $.ajax({
          url: url,
          cache: false,
          success: function (data) {
            // Update codemirror value
            editor.setValue(data);
            // Saved
            notificator.show("Template inserted", true);
            // Locate top
            let t = $("#eventform4").offset().top;
            // Scroll to top
            $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
          },
        });
      }
    },
    templateview: function (f) {
      // Open
      window.open($(f).attr("data-link"));
    },
    newtemplate: function (f, t) {
      // Go to
      location.href = $(f).attr("data-href");
    },
    cancel: function (f) {
      // Go to previous page
      let url = $("#doc").attr("data-ref");

      // Go back or go to base
      if (
        (url.indexOf("events") > -1 && url.indexOf("templates") > -1) ||
        ((url.indexOf("subscription-calendar") > -1 ||
          url.indexOf("calendar") > -1) &&
          url.indexOf("templates") > -1)
      ) {
        // Go back
        history.back();
      } else {
        // Go to
        location.href = $("#eventform4 .returnpath").val();
      }
    },
  };
})();

// Ready
$(document).ready(function () {
  eventform4.initialize();
});

/* Recurring rules
---------------------------------------------------------------- */
var recurrules = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("repeat-evt")) {
        // Append
        $("#repeat_type").on("change", function () {
          recurrules.repeattype(this);
        });
        $("#repeat-evt .days ul li").on("click", function () {
          recurrules.dayinweektoogle(this);
          return false;
        });
        $("#repeat-evt .bts .save").on("click", function () {
          recurrules.save(this);
          return false;
        });
        $("#repeat-evt .bts .cancel").on("click", function () {
          recurrules.hide(this);
          return false;
        });

        $("#repeat-confirm-opts ul li").on("click", function () {
          recurrules.setedittype(this);
          return false;
        });
        $("#repeat-confirm-opts .bts .save").on("click", function () {
          recurrules.confirmoptsave(this);
          return false;
        });
        $("#repeat-confirm-opts .bts .cancel").on("click", function () {
          recurrules.confirmopthide(this);
          return false;
        });
        $("#repeat-confirm-opts form").on("submit", function () {
          return false;
        });

        $("#repeat-confirm-delete-opts ul li").on("click", function () {
          recurrules.setdeletetype(this);
          return false;
        });
        $("#repeat-confirm-delete-opts .bts .save").on("click", function () {
          recurrules.confirmdeletesave(this);
          return false;
        });
        $("#repeat-confirm-delete-opts .bts .cancel").on("click", function () {
          recurrules.confirmdeletehide(this);
          return false;
        });
        $("#repeat-confirm-delete-opts form").on("submit", function () {
          return false;
        });

        // Get first day
        let fd = $("#calendar-controle").attr("data-firstday");

        // Date picker (start)
        $("#days_to_date_start").datepicker({
          defaultDate: "+1w",
          showWeek: true,
          firstDay: fd,
          dateFormat: "M d, yy",
          autoclose: true,
          //dateFormat: "mm/dd/yy",
          hideIfNoPrevNext: true,
          onSelect: function (dat) {
            // Update
            recurrules.datesfromto();
          },
          onClose: function (selectedDate) {
            // Set min date
            $("#days_to_date_end").datepicker(
              "option",
              "minDate",
              selectedDate
            );
          },
        });

        // Date picker (end)
        $("#days_to_date_end").datepicker({
          defaultDate: "+1w",
          showWeek: true,
          firstDay: 0,
          dateFormat: "M d, yy",
          autoclose: true,
          hideIfNoPrevNext: true,
          onSelect: function (dat) {
            // Update
            recurrules.datesfromto();
          },
          onClose: function (selectedDate) {
            // Set max date
            $("#days_to_date_start").datepicker(
              "option",
              "maxDate",
              selectedDate
            );
          },
        });

        // Week picker
        $("#weeks_selector").datepicker({
          disabledaypick: true, // Custom function
          numberOfMonths: 1,
          showWeek: true,
          firstDay: 1,
          stepMonths: 1,
          showOtherMonths: false,
          onChangeMonthYear: function () {},
          onDone: function () {
            // Replace content
            recurrules.replace_weeks_calendar();
            // Adjust cols
            recurrules.adjustcols();
          },
        });

        // Day picker
        $("#days_selector").datepicker({
          numberOfMonths: 1,
          showWeek: true,
          firstDay: fd,
          stepMonths: 1,
          showOtherMonths: false,
          onChangeMonthYear: function () {},
          onDone: function () {
            // Replace content
            recurrules.replace_days_specific_calendar();
            // Adjust cols
            recurrules.adjustcols();
          },
        });

        // Set scroll position
        window.scrollTo(0, 0);
        // Get initial data
        recurrules.initialdata();
      }
    },
    show: function () {
      // Get initial data
      recurrules.initialdata();
      // Show bg
      $("#repeat-evt-bg").show();
      // Show
      $("#repeat-evt").show();
      // Adjust cols
      recurrules.adjustcols();
      // Get position of ticker
      let tck = $(".evtseries .btn");

      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#repeat-evt").height();
      let pw = $("#repeat-evt").width();

      $("#repeat-evt").css("top", st - 20 + wh / 2 - ph / 2 + "px");
      $("#repeat-evt").css("marginLeft", -(pw / 2) + "px");

      // Parse dates
      let d1 = new Date($("#date_start").val());
      let d2 = new Date($("#date_start").val());

      // Add one month
      d2.setMonth(d2.getMonth() + 1);

      // Update start end
      $("#days_selector").datepicker("setDate", d1);
      $("#weeks_selector").datepicker("setDate", d1);

      // Any repeat rule? If not, set value based on forms start date
      let reru = $("#repeat_rule").val();
      if (reru == "") {
        $("#days_to_date_start").datepicker("setDate", d1);
        $("#days_to_date_end").datepicker("setDate", d2);
        $("#days_to_date_end").datepicker("option", "minDate", d1);
      }
    },
    hide: function () {
      // Clear check
      if ($("#repeat").val() == "false") {
        $(".evtseries .btn").removeClass("selected");
      }

      // Hide
      $("#repeat-evt").hide();
      // Hide bg
      $("#repeat-evt-bg").hide();
    },
    remove: function (f) {
      // Remove
      $("#repeat").val("true");
      // Invoke
      eventform1.repeat();
    },
    save: function () {
      // Execute
      let execute = true;
      let dys = "";
      // Get repeat type
      let rpt = $("#repeat_type").val();
      // Validate "weekdays_to_date"
      if (rpt == "weekdays_to_date") {
        // Get weekdays
        let chkcount = $("#repeat-evt .days .checked").length;
        // Any weekdays checked?
        if (chkcount < 1) {
          // Error
          alert("Please select at least one day..");
          // Dont execute
          execute = false;
        }
      }

      // Validate "weekdays_specific_weeks"
      if (rpt == "weekdays_specific_weeks") {
        // Get weekdays
        let chkcount = $("#repeat-evt .days .checked").length;

        // Any weekdays checked?
        if (chkcount < 1) {
          // Error
          alert("Please select at least one day..");
          // Dont execute
          execute = false;
        }

        // Any weeks selected?
        let vwks = $("#repeat-data-weeks").text();
        // Validate
        if (vwks == "") {
          // Dont execute
          execute = false;

          // Error
          alert("Please select at least one Week..");
        }
      }

      // Validate "days_one_by_one"
      if (rpt == "days_one_by_one") {
        // Any weeks selected?
        dys = $("#repeat-data-specific-days").text();

        // Validate
        if (dys == "") {
          // Dont execute
          execute = false;
          // Error
          alert("Please select at least one Date..");
        }
      }

      // Save?
      if (execute) {
        // Get variables
        let typ = $("#repeat-data-repeat-type").text();
        let wkd = $("#repeat-data-weekdays").text();
        let wks = $("#repeat-data-weeks").text();
        dys = $("#repeat-data-specific-days").text();
        let dft = $("#repeat-data-date-from-to").text();

        // String
        let str =
          "repeat_type:" +
          typ +
          "|weekdays:" +
          wkd +
          "|weeks:" +
          wks +
          "|days:" +
          dys +
          "|date_from_to:" +
          dft;
        // Update rule
        $("#repeat_rule").val(str);
        $("#repeat-data").text(str);

        // Check
        $(".evtseries .btn").addClass("selected");

        // Set true
        $("#repeat").val("true");

        // Show template select
        $(".eventform1 #evttmprow").show();

        // Show explainatory
        $("#repeat-form-exp").show();

        // All day enabled?
        if ($("#all_day").val() == "true") {
          $(".picker .time-t1, .picker .time-t2").addClass("disabled");
          $(".picker .time-t1, .picker .time-t2").show();
        }

        // Call
        recurrules.formuihandle();
        // Hide
        recurrules.hide();
        // Summary
        recurrules.summary(true);
        // Toogle RRULE / event series
        recurrules.rruleevtseries();
      }
    },
    confirmoptshow: function () {
      // Show bg
      $("#repeat-evt-bg").show();

      // Show
      $("#repeat-confirm-opts").show();

      // Timed show
      setTimeout(function () {
        $("#repeat-confirm-opts").addClass("show");
      }, 200);

      // Hide loader + enable submit
      $("#repeat-confirm-opts .load").css("visibility", "hidden");
      $("#repeat-confirm-opts .save").prop("disabled", false);
      $("#repeat-confirm-opts .save").removeClass("disabled");

      // Get position of ticker
      let tck = $(".evtseries .btn");

      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#repeat-confirm-opts").height();
      let pw = $("#repeat-confirm-opts").width();
    },
    confirmopthide: function () {
      // Hide
      $("#repeat-confirm-opts").removeClass("show");

      // Timed show
      setTimeout(function () {
        $("#repeat-confirm-opts").hide();
      }, 200);

      // Hide bg
      $("#repeat-evt-bg").hide();

      // Hide loader
      $("#repeat-confirm-opts .load").css("visibility", "hidden");
    },
    confirmoptsave: function () {
      // Set changed value
      $("#repeat_change").val(
        $("#repeat-confirm-opts ul .checked").attr("data-option")
      );

      // Set confirmed attribute
      $(".eventform1 form").attr("data-repeat-save", "true");

      // Submit form
      $(".eventform1 form").submit();

      // Show loader + disable submit
      $("#repeat-confirm-opts .load").css("visibility", "visible");
      $("#repeat-confirm-opts .save").prop("disabled", true);
      $("#repeat-confirm-opts .save").addClass("disabled");
    },
    confirmdeleteshow: function () {
      // Show bg
      $("#repeat-evt-bg").show();

      // Show
      $("#repeat-confirm-delete-opts").show();

      // Hide loader + enable submit
      $("#repeat-confirm-delete-opts .load").css("visibility", "hidden");
      $("#repeat-confirm-delete-opts .save").prop("disabled", false);

      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#repeat-confirm-delete-opts").height();
      let pw = $("#repeat-confirm-delete-opts").width();

      $("#repeat-confirm-delete-opts").css(
        "top",
        st - 20 + wh / 2 - ph / 2 + "px"
      );
      $("#repeat-confirm-delete-opts").css("marginLeft", -(pw / 2) + "px");
    },
    confirmdeletehide: function () {
      // Hide
      $("#repeat-confirm-delete-opts").hide();
      // Hide bg
      $("#repeat-evt-bg").hide();
      // Hide loader
      $("#repeat-confirm-delete-opts .load").css("visibility", "hidden");
    },
    confirmdeletesave: function () {
      // Get event id
      let rel = $(".eventform1 #relation").val();
      // Calendar reference
      let ref = $(".eventform1 #reference_cal").val();
      // Delete type
      let dty = $("#repeat_event_delete_type").val();
      // Calendar uniquekey
      let unq = $(".eventform1 #uniquekey_cal").val();
      // CSRF
      let csrf = $(".eventform1 #csrf").val();
      // Show loader + disable submit
      $("#repeat-confirm-delete-opts .load").css("visibility", "visible");
      $("#repeat-confirm-delete-opts .save").prop("disabled", true);

      // Notify
      notificator.show("Deleting event");
      // Set data
      let dat =
        "id=" +
        rel +
        "&unique_cal=" +
        unq +
        "&reference=" +
        ref +
        "&deletetype=" +
        dty +
        "&csrf=" +
        csrf;

      // Post
      $.ajax({
        type: "POST",
        url: "/source/web/actions/events/multiple-calendar-event-delete.php",
        data: dat,
        cache: false,
        dataType: "json",
        success: function (data) {
          // Parse
          const obj = data;

          // OK
          if (obj.meta.code == "200") {
            // Notify
            notificator.show("Deleted", true);
            // Redirect
            setTimeout(function () {
              location.href =
                "/subscription-calendar/" + obj.event.uniquekey + "/events";
            }, 1000);

            // Hide
            recurrules.confirmdeletehide();
            // Hide loader + enable submit
            $("#repeat-confirm-delete-opts .load").css("visibility", "hidden");
            $("#repeat-confirm-delete-opts .save").prop("disabled", false);
          } else {
            // Notify
            notificator.break();
            // Error
            csrfpop.show();
          }
        },
      });
    },
    adjustmid: function () {
      // Get width
      let pw = $("#repeat-evt").width();
      // Set center
      $("#repeat-evt").css("marginLeft", -(pw / 2) + "px");
    },
    datesfromto: function () {
      // Get start date
      let start = $("#days_to_date_start").datepicker("getDate");
      let end = $("#days_to_date_end").datepicker("getDate");

      // Get dates
      let sdaf =
        ("0" + (start.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + start.getDate()).slice(-2) +
        "/" +
        start.getFullYear();
      let edaf =
        ("0" + (end.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + end.getDate()).slice(-2) +
        "/" +
        end.getFullYear();

      // String
      let str = '{"start":"' + sdaf + '","end":"' + edaf + '"};';
      // Update value
      $("#repeat-data-date-from-to").text(str);

      // Every
      recurrules.summary();
    },
    initialdata: function () {
      // Variables
      let str = "";
      // Get initial data
      let dat = $("#repeat_rule").val();

      // Any data?
      if (dat != "") {
        // Split
        let sor = dat.split("|");

        // Loop
        for (let i in sor) {
          if (sor[i] != "") {
            // Get array name
            let ast = sor[i].split(":");

            // Repeat type
            if (ast[0] == "repeat_type") {
              str = sor[i];

              // Remove identifier
              str = str.replace("repeat_type:", "");

              // Splitter
              str = str.replace(";", "");

              // Object
              let itm = eval("(" + str + ")");

              // Set selected
              $("#repeat_type").val(itm.type);

              // Set specific value
              $("#repeat-data-repeat-type").text(str);

              // Invoke
              recurrules.repeattype();
            }

            // Weeksdays
            if (ast[0] == "weekdays") {
              str = sor[i];
              // Remove identifier
              str = str.replace("weekdays:", "");
              // Split
              let car = str.split(";");
              // Loop
              for (let i in car) {
                if (car[i] != "") {
                  // Object
                  let itm = eval("(" + car[i] + ")");

                  // Check
                  if (itm.enabled) {
                    $("#repeat-day-" + itm.day).addClass("checked");
                  }
                }
              }

              // Set specific value
              $("#repeat-data-weekdays").text(str);
              // Invoke
              recurrules.weekmarkup();
            }

            // Weeks
            if (ast[0] == "weeks") {
              str = sor[i];
              // Remove identifier
              str = str.replace("weeks:", "");
              // Set specific value
              $("#repeat-data-weeks").text(str);
              // Invoke
              recurrules.weekmarkup();
            }

            // Days
            if (ast[0] == "days") {
              str = sor[i];
              // Remove identifier
              str = str.replace("days:", "");
              // Set specific value
              $("#repeat-data-specific-days").text(str);
              // Invoke
              recurrules.daymarkup();
            }

            // Dates from to
            if (ast[0] == "date_from_to") {
              str = sor[i];
              // Remove identifier
              str = str.replace("date_from_to:", "");
              // Splitter
              str = str.replace(";", "");
              // Object
              let itm = eval("(" + str + ")");

              // Parse dates
              let d1 = new Date(itm.start);
              let d2 = new Date(itm.end);

              // Update start end
              $("#days_to_date_start").datepicker("setDate", d1);
              $("#days_to_date_end").datepicker("setDate", d2);

              // Set specific value
              $("#repeat-data-date-from-to").text(str);
            }
          }
        }
      } else {
        // No data, set defaults
        // Set repeat type
        $("#repeat_type").val($("#repeat_type option:first").val());

        // Get start / end date
        let s1 = $("#days_to_date_start").val();
        let s2 = $("#days_to_date_end").val();

        // String
        str = '{"start":"' + s1 + '","end":"' + s2 + '"}';
        // Set value
        $("#repeat-data-date-from-to").text(str);
        // Invoke
        recurrules.repeattype();
      }

      // Summary
      recurrules.summary();
    },
    repeattype: function () {
      // Get selected
      let rety = $("#repeat_type").find(":selected").val();

      // Hide selectors
      $(".repeat-t1 .dts").hide();
      $(".repeat-t1 .wkcls").hide();
      $(".repeat-t1 .daycls").hide();

      // Reset
      $("#repeat-evt").removeClass(
        "weekdays_to_date weekdays_specific_weeks days_one_by_one"
      );
      $("#repeat-evt").addClass(rety);

      // Days to date
      if (rety == "weekdays_to_date") {
        // Show dates selector
        $(".repeat-t1 .dts").show();

        // Show days selector
        $(".repeat-t1 .cols .col1").show();

        // Handle form
        recurrules.formuihandle(rety);
      }

      // Days specific weeks
      if (rety == "weekdays_specific_weeks") {
        // Show weeks selector
        $(".repeat-t1 .wkcls").show();

        // Show days selector
        $(".repeat-t1 .cols .col1").show();

        // Handle form
        recurrules.formuihandle(rety);
      }

      // Dates specific
      if (rety == "days_one_by_one") {
        // Show dates selector
        $(".repeat-t1 .daycls").show();

        // Hide days selector
        $(".repeat-t1 .cols .col1").hide();

        // Handle form
        recurrules.formuihandle(rety);
      }

      // String
      let str = '{"type":"' + rety + '"};';
      // Update
      $("#repeat-data-repeat-type").text(str);
      // Get label
      let lbl = $("#repeat_type").find(":selected").attr("data-label");

      // Update
      $("#repeat-label").text(lbl);

      // Summary
      recurrules.summary();
    },
    formuihandle: function (retry) {
      // Repeat rule on?
      let rer = $("#repeat").val();

      // On
      if (rer == "true") {
        // Enable picker
        $("#date_start").datepicker("option", "disabled", true);
        $("#date_end").datepicker("option", "disabled", true);

        // Remove classes
        $("#date_start").addClass("disabled");
        $("#date_end").addClass("disabled");

        // Disable inputs
        $("#date_start").prop("readonly", true);
        $("#date_end").prop("readonly", true);

        // Hide
        $(".picker .date-t1").hide();
        $(".picker .date-t2").hide();

        // Disabled tip
        $(".distip").show();
      } else {
        // Enable picker
        $("#date_start").datepicker("option", "disabled", false);
        $("#date_end").datepicker("option", "disabled", false);

        // Remove classes
        $("#date_start").removeClass("disabled");
        $("#date_end").removeClass("disabled");

        // Enable inputs
        $("#date_start").prop("readonly", false);
        $("#date_end").prop("readonly", false);

        // Show
        $(".picker .date-t1").show();
        $(".picker .date-t2").show();

        // Disabled tip
        $(".distip").hide();
      }
    },
    weekclick: function (f) {
      // Get object and class
      let obj = $(f).parents("tr");

      // Get class
      let sel = $(obj).hasClass("selected");

      // Get attribute
      let wek = $(obj).attr("data-week");
      let lcy = $(obj).attr("data-year");

      // String
      let str = "";

      // Leading zero
      if (wek.length == 1 && wek != "0") {
        wek = "0" + wek;
      }

      // Toogle
      if (sel) {
        // Remove class
        $(".week" + wek + "-" + lcy).removeClass("selected");

        // Get val
        str = $("#repeat-data-weeks").text();

        // Replace
        //str = str.replace(lcy + wek + "/" + lcy + "/" + wek + ",", "");
        str = str.replace('{"year":"' + lcy + '","week":"' + wek + '"};', "");

        // Update
        $("#repeat-data-weeks").text(str);
      } else {
        // Add class
        $(".week" + wek + "-" + lcy).addClass("selected");
        // Get val
        str = $("#repeat-data-weeks").text();
        // Replace
        str += '{"year":"' + lcy + '","week":"' + wek + '"};';

        // Update
        $("#repeat-data-weeks").text(str);
      }

      /*
            @ Sort array - year week
            */

      // Set sort array
      let marr = [];

      // Split
      let sor = str.split(";");

      // Loop
      for (let i in sor) {
        if (sor[i] != "") {
          // Object
          let itm = eval("(" + sor[i] + ")");

          // Add to array
          marr[i] = {};
          marr[i]["yearweek"] = itm.year + itm.week;
          marr[i]["year"] = itm.year;
          marr[i]["week"] = itm.week;
        }
      }

      // Sort array
      marr.sort(function (a, b) {
        return a.yearweek - b.yearweek;
      });

      // Reset string
      str = "";

      // Loop sorted array
      for (let i in marr) {
        // Add
        str +=
          '{"year":"' +
          marr[i]["year"] +
          '","week":"' +
          marr[i]["week"] +
          '"};';
      }

      // Set sorted values
      $("#repeat-data-weeks").text(str);
      // Markup selected weeks
      recurrules.weekmarkup();
      // Every
      recurrules.summary();
    },
    dayclick: function (f) {
      // Get object and class
      let obj = $(f);

      // Get class
      let sel = $(obj).hasClass("dspselected");

      // Get attributes
      let mon = $(obj).attr("data-month");
      let day = $(obj).attr("data-day");
      let yer = $(obj).attr("data-year");

      // String
      let str = "";

      // Leading zeros
      if (mon.length == 1 && mon != "0") {
        mon = "0" + mon;
      }
      if (day.length == 1 && day != "0") {
        day = "0" + day;
      }

      // Toogle
      if (sel) {
        // Remove class
        $(obj).removeClass("dspselected");

        // Get val
        str = $("#repeat-data-specific-days").text();

        // Replace
        str = str.replace(
          '{"month":"' + mon + '","day":"' + day + '","year":"' + yer + '"};',
          ""
        );

        // Update
        $("#repeat-data-specific-days").text(str);
      } else {
        // Add class
        $(obj).addClass("dspselected");
        // Get val
        str = $("#repeat-data-specific-days").text();
        // Add
        str +=
          '{"month":"' + mon + '","day":"' + day + '","year":"' + yer + '"};';
        // Update
        $("#repeat-data-specific-days").text(str);
      }

      /*
            @ Sort array - year week
            */

      // Set sort array
      let marr = [];
      // Split
      let sor = str.split(";");

      // Loop
      for (let i in sor) {
        if (sor[i] != "") {
          // Object
          let itm = eval("(" + sor[i] + ")");

          // Add to array
          marr[i] = {};
          marr[i]["yearmonthday"] = itm.year + itm.month + itm.day;
          marr[i]["month"] = itm.month;
          marr[i]["day"] = itm.day;
          marr[i]["year"] = itm.year;
        }
      }

      // Sort array
      marr.sort(function (a, b) {
        return a.yearmonthday - b.yearmonthday;
      });

      // Reset string
      str = "";

      // Loop sorted array
      for (let i in marr) {
        // Add
        str +=
          '{"month":"' +
          marr[i]["month"] +
          '","day":"' +
          marr[i]["day"] +
          '","year":"' +
          marr[i]["year"] +
          '"};';
      }

      // Set sorted values
      $("#repeat-data-specific-days").text(str);

      // Markup selected weeks
      recurrules.daymarkup();

      // Every
      recurrules.summary();
    },
    weekmarkup: function () {
      /*
            @ Loop through days, add day shortnames to container as classes
            */

      // Get days for classes
      let cda = $("#repeat-data-weekdays").text();
      // Split
      let car = cda.split(";");
      // Days class string
      let dstr = "";

      // Loop
      for (let i in car) {
        if (car[i] != "") {
          // Object
          let itm = eval("(" + car[i] + ")");

          if (itm.enabled) {
            dstr += itm.day + " ";
          }
        }
      }

      // Update days picked as classes
      $("#repeat-weekdaynames").attr("class", dstr);

      /*
            @ Loop through weeks, add selected class to tr if visible this month
            */

      // Get data
      let dat = $("#repeat-data-weeks").text();
      // Split
      let sor = dat.split(";");

      // Loop
      for (let i in sor) {
        if (sor[i] != "") {
          // Object
          let itm = eval("(" + sor[i] + ")");

          $(".week" + itm.week + "-" + itm.year).addClass("selected");
        }
      }
    },
    daymarkup: function () {
      /*
            @ Loop through days, add selected class if visible this month
            */

      // Get data
      let dat = $("#repeat-data-specific-days").text();

      // Split
      let sor = dat.split(";");

      // Loop
      for (let i in sor) {
        if (sor[i] != "") {
          // Object
          let itm = eval("(" + sor[i] + ")");

          $(".dat" + itm.month + itm.day + itm.year).addClass("dspselected");
        }
      }
    },
    dayinweektoogle: function (f) {
      // Get class
      let cls = $(f).hasClass("checked");

      // Has class?
      if (cls) {
        // Remove
        $(f).removeClass("checked");
      } else {
        // Add
        $(f).addClass("checked");
      }

      // Days
      let daysall = "";

      // Loop
      $("#repeat-evt .days ul li").each(function () {
        // Get short
        let sht = $(this).attr("data-short");

        // Checked?
        if ($(this).hasClass("checked")) {
          daysall += '{"day":"' + sht + '","enabled":true};';
        } else {
          daysall += '{"day":"' + sht + '","enabled":false};';
        }
      });

      // Update
      $("#repeat-data-weekdays").text(daysall);

      // Markup selected weeks
      recurrules.weekmarkup();

      // Every
      recurrules.summary();
    },
    replace_weeks_calendar: function () {
      // Variables
      let wobj = null,
        theweek = null,
        theday = null,
        themonth = null,
        theyear = null,
        counter = 0,
        exh = "";
      // Get
      let moyr = $("#weeks_selector .ui-datepicker .ui-datepicker-month")
        .text()
        .toLowerCase();

      // Loop though trs
      $("#weeks_selector .ui-datepicker table tbody tr").each(function () {
        // Remember object
        wobj = $(this);
        // Reset
        (theweek = null), (themonth = null), (theday = null), (theyear = null);
        // Find week / month / year
        $(this)
          .find("td")
          .each(function () {
            // Week
            if (theweek == null) {
              if ($(this).hasClass("ui-datepicker-week-col")) {
                theweek = $(this).text();

                // Adjust
                if (theweek.length == 1 && theweek != "0") {
                  theweek = "0" + theweek;
                }
              }
            }

            // Get month + year
            let mon = $(this).attr("data-month");
            let yer = $(this).attr("data-year");

            // Any month?
            if (typeof mon != "undefined") {
              themonth = mon.toString();
              // Adjust
              if (themonth.length == 1 && themonth != "0") {
                themonth = "0" + themonth;
              }
            }

            // Any year?
            if (typeof yer != "undefined") {
              theyear = yer;
            }
          });

        // Adjust year if week 53 in january
        if (
          (moyr == "january" && theweek == "52") ||
          (moyr == "january" && theweek == "53")
        ) {
          theyear = parseInt(theyear) - 1;
        }

        // Reset counter
        counter = 0;

        // Day names as classes
        let dayclasses = [
          "cwek",
          "mon",
          "tue",
          "wed",
          "thu",
          "fri",
          "sat",
          "sun",
        ];

        // Loop though tds again
        $(this)
          .find("td")
          .each(function () {
            // Get inner text
            let txt = $(this).text();

            // Add day name class to td if number
            if (!isNaN(parseFloat(txt)) && isFinite(txt)) {
              $(this).addClass(dayclasses[counter]);
            }

            // Week
            exh = "";
            if ($(this).hasClass("ui-datepicker-week-col")) {
              exh = '<div class="wkarr"></div>';
            }

            // The day
            theday = txt;

            // Adjust month and day
            if (themonth.toString().length == 1 && themonth != "0") {
              themonth = "0" + themonth;
            }
            //if(theday.toString().length == 1 && theday != '0'){txt = '0' + theday;}
            if (theday.toString().length == 1 && theday != "0") {
              txt = theday;
            }

            // Replace existing jquery html
            $(this).html(
              '<div class="nww dat' +
                themonth +
                theday +
                theyear +
                '" data-week="' +
                theweek +
                '" data-month="' +
                themonth +
                '" data-year="' +
                theyear +
                '">' +
                txt +
                exh +
                "</div>"
            );

            // Add
            counter++;

            // Disable clicks on cells
            $(this).addClass("ui-datepicker-unselectable");
          });

        // Add identifier class
        $(wobj).addClass("week" + theweek + "-" + theyear);

        // Add attributes
        $(wobj).attr("data-week", theweek);
        $(wobj).attr("data-year", theyear);
      });

      // Append click
      $("#weeks_selector .nww").on("click", function () {
        recurrules.weekclick(this);
        return false;
      });

      // Markup selected weeks
      recurrules.weekmarkup();
    },

    replace_days_specific_calendar: function () {
      // Variables
      let wobj = null,
        theweek = null,
        themonth = null,
        theday = null,
        theyear = null,
        counter = 0;
      // Get
      let moyr = $("#days_selector .ui-datepicker .ui-datepicker-month")
        .text()
        .toLowerCase();

      // Loop though trs
      $("#days_selector .ui-datepicker table tbody tr").each(function () {
        // Remember object
        wobj = $(this);
        // Reset
        (theweek = null), (themonth = null), (theyear = null);

        // Find week / month / year
        $(this)
          .find("td")
          .each(function () {
            // Week
            if (theweek == null && $(this).hasClass("ui-datepicker-week-col")) {
              theweek = $(this).text();

              // Adjust
              if (theweek.length == 1 && theweek != "0") {
                theweek = "0" + theweek;
              }
            }

            // Get month + year
            let mon = $(this).attr("data-month");
            let yer = $(this).attr("data-year");

            // Any month?
            if (typeof mon != "undefined") {
              // Real month
              mon = parseInt(mon) + 1;
              themonth = mon.toString();

              // Adjust
              if (themonth.length == 1 && themonth != "0") {
                themonth = "0" + themonth;
              }
            }

            // Any year?
            if (yer != undefined) {
              theyear = yer;
            }
          });

        // Reset counter
        counter = 0;

        // Day names as classes
        let dayclasses = [
          "cwek",
          "mon",
          "tue",
          "wed",
          "thu",
          "fri",
          "sat",
          "sun",
        ];

        // Loop though tds again
        $(this)
          .find("td")
          .each(function () {
            // Get inner text
            let txt = parseInt($(this).text());

            // Day attribute
            theday = txt;

            // Leading zero
            if (theday.length == 1 && theday != "0") {
              theday = "0" + theday;
            }

            // Add day name class to td if number
            if (!isNaN(parseFloat(txt)) && isFinite(txt)) {
              $(this).addClass(dayclasses[counter]);
            } else {
              txt = "&nbsp;";
            }

            // Adjust month
            if (themonth.toString().length == 1 && themonth != "0") {
              themonth = "0" + themonth;
            }

            // Valid day?
            if (!isNaN(parseFloat(theday)) && isFinite(theday)) {
              if (theday.toString().length == 1 && theday != "0") {
                theday = "0" + theday;
              }
            } else {
              theday = "";
            }

            // Replace existing jquery html
            $(this).html(
              '<div class="nww dat' +
                themonth +
                theday +
                theyear +
                '" data-week="' +
                theweek +
                '" data-month="' +
                themonth +
                '" data-day="' +
                theday +
                '" data-year="' +
                theyear +
                '">' +
                txt +
                "</div>"
            );

            // Add
            counter++;

            // Disable clicks on cells
            $(this).addClass("ui-datepicker-unselectable");
          });

        // Add identifier class
        $(wobj).addClass("week" + theweek + "-" + theyear);
        // Add attributes
        $(wobj).attr("data-week", theweek);
        $(wobj).attr("data-year", theyear);
      });

      // Append click
      $(
        "#days_selector .mon .nww, #days_selector .tue .nww, #days_selector .wed .nww, #days_selector .thu .nww, #days_selector .fri .nww, #days_selector .sat .nww, #days_selector .sun .nww"
      ).on("click", function () {
        recurrules.dayclick(this);
        return false;
      });

      // Markup selected days
      recurrules.daymarkup();
    },

    summary: function (save) {
      // Month names
      let monthnames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Strings
      let str = "",
        stx = "";

      // Get start end times
      let time_start = $("#date_start_time").val();
      let time_end = $("#date_end_time").val();
      let all_day = $("#all_day").val();

      // All day or not?
      if (all_day == "true") {
        // Start
        str += "The event is an all day event.<br /><br />";
        stx += "The event is an all day event. ";
      } else {
        // Start
        str +=
          "The event series starts at " +
          time_start +
          " and ends at " +
          time_end +
          ".<br /><br />";
        stx +=
          "The event series starts at " +
          time_start +
          " and ends at " +
          time_end +
          ". ";
      }

      // Get selected repeat type
      let rety = $("#repeat_type").find(":selected").val();

      // Days to date
      if (rety == "weekdays_to_date") {
        // Variables
        str += "The event will be duplicated to ";
        stx += "The event will be duplicated to ";

        // Days string
        let dystr = "";

        // Days loop
        $("#repeat-evt .days .checked").each(function () {
          // Get day name
          let dnam = $(this).attr("data-label");

          // Update values
          dystr += dnam + ", ";
        });

        // Remove last comma from days
        dystr = dystr.replace(/,\s*$/, "");

        // No weekdays selected
        if (dystr == "") {
          dystr = "<br />(please select at least one day)";
        }

        // Add to string
        str += dystr + ".<br /><br />";
        stx += dystr + ". ";

        // Month names
        let monthnames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        // Get start date
        let dsv = $("#days_to_date_start").val();
        let dev = $("#days_to_date_end").val();

        // Convert
        let ds = new Date(dsv);
        let de = new Date(dev);

        // Start date string
        let dsstr =
          "Starting " +
          monthnames[ds.getMonth()] +
          " " +
          ds.getDate() +
          ", " +
          ds.getFullYear() +
          " and<br />";
        let destr =
          "ending " +
          monthnames[de.getMonth()] +
          " " +
          de.getDate() +
          ", " +
          de.getFullYear() +
          ".";

        // Start date string (for form summary)
        let dsstx =
          "Starting " +
          monthnames[ds.getMonth()] +
          " " +
          ds.getDate() +
          ", " +
          ds.getFullYear() +
          " and ";
        let destx =
          "ending " +
          monthnames[de.getMonth()] +
          " " +
          de.getDate() +
          ", " +
          de.getFullYear() +
          ".";

        // Add to string
        str += dsstr + destr;
        stx += dsstx + destx;
      }

      // Days specific weeks
      if (rety == "weekdays_specific_weeks") {
        // Variables
        str += "The event will be duplicated to ";
        stx += "The event will be duplicated to ";

        // Days string
        let dystr = "";

        // Days loop
        $("#repeat-evt .days .checked").each(function () {
          // Get day name
          let dnam = $(this).attr("data-label");

          // Update values
          dystr += dnam + ", ";
        });

        // Remove last comma from days
        dystr = dystr.replace(/,\s*$/, "");

        // No weekdays selected
        if (dystr == "") {
          dystr = "<br />(please select at least one day)";
        }

        // Add to string
        str += dystr + ".<br /><br />";
        stx += dystr + ". ";

        // Variables
        let wkfound = false,
          wkstr = "",
          wkstx = "",
          wekyer = "";

        // Get data
        let dat = $("#repeat-data-weeks").text();

        // Split
        let sor = dat.split(";");

        // Loop
        for (let i in sor) {
          if (sor[i] != "") {
            // Object
            let itm = eval("(" + sor[i] + ")");

            // New year?
            if (itm.year != wekyer && itm.year != "") {
              // Week year
              wekyer = itm.year;
              // Remove last comma
              wkstr = wkstr.replace(/,\s*$/, "");
              // Add
              wkstr +=
                "</li><li><em>Year " + itm.year + ":</em> weeks&nbsp;&rarr; ";
              wkstx += "Year " + itm.year + ": weeks&nbsp;&rarr; ";
            }

            // Add
            wkstr += itm.week + ", ";
            wkstx += itm.week + ", ";

            wkfound = true;
          }
        }

        // Last li
        let lstli = "</li>";

        if (!wkfound) {
          lstli = "";
        }

        // Remove last comma
        wkstr = wkstr.replace(/,\s*$/, "");
        wkstx = wkstx.replace(/,\s*$/, "");

        // Add to string
        str += "<strong>These weeks:</strong><ul>" + wkstr + lstli + "</ul>";
        stx += "These weeks: " + wkstx + ".";
      }

      // Dates specific
      if (rety == "days_one_by_one") {
        // String
        str += "The event will be duplicated to these dates:<br />";
        stx += "The event will be duplicated these dates: ";

        // Get days
        let dat = $("#repeat-data-specific-days").text();

        // Split
        let car = dat.split(";"),
          spedfnd = false;

        // Loop
        for (let i in car) {
          if (car[i] != "") {
            // Object
            let itm = eval("(" + car[i] + ")");

            // Convert
            let ds = new Date(itm.month + "/" + itm.day + "/" + itm.year);

            // Date pretty
            let dsstr =
              monthnames[ds.getMonth()] +
              ". " +
              ds.getDate() +
              ", " +
              ds.getFullYear();

            // Add
            str += dsstr + ", ";
            stx += dsstr + ", ";

            // Found
            spedfnd = true;
          }
        }

        // No weekdays selected
        if (!spedfnd) {
          str += "<br />(please select at least one Date)";
          stx += "<br />(please select at least one Date)";
        }

        // Remove last comma
        str = str.replace(/,(\s+)?$/, "");
        stx = stx.replace(/,(\s+)?$/, "");
      }

      // Update
      $("#repeat-summary").html(str);

      // If save, update exp on form
      if (save) {
        $("#repeat-form-exp-rule").html(stx);
      }

      // Adjust cols
      recurrules.adjustcols();

      // Toogle RRULE / event series
      recurrules.rruleevtseries();
    },
    adjustcols: function () {
      // Highest
      let hgcl = 0;

      // Get cols hight
      $(
        "#repeat-evt .bdy .col1 .px, #repeat-evt .bdy .col2 .px, #repeat-evt .bdy .col3 .px"
      ).each(function () {
        // Get height
        let h = $(this).outerHeight();

        // Higher?
        if (h > hgcl) {
          hgcl = h;
        }
      });

      $(
        "#repeat-evt .bdy .col1, #repeat-evt .bdy .col2, #repeat-evt .bdy .col3"
      ).css("height", hgcl + "px");

      // Adjust position
      recurrules.adjustmid();
    },
    setedittype: function (f) {
      // Get value
      let val = $(f).attr("data-option");

      $("#repeat_event_edit_type").val(val);

      // Remove checked
      $("#repeat-confirm-opts ul li").removeClass("checked");

      // Set checked
      $(f).addClass("checked");
    },
    setdeletetype: function (f) {
      // Get value
      let val = $(f).attr("data-option");

      $("#repeat_event_delete_type").val(val);

      // Remove checked
      $("#repeat-confirm-delete-opts ul li").removeClass("checked");

      // Set checked
      $(f).addClass("checked");
    },
    rruleevtseries: function () {
      // Get recurring + RRULE values
      let rep = $("#repeat").val();
      let rru = $("#recurring_rule").val();
      let rcu = $("#recurring").val();

      // Alert
      if (rep == "true" && rcu == "true") {
        // Notify
        alert(
          "Recurring rules can't be used with event series. \nThe recurring rule has been removed."
        );
      }

      // Event series enabled?
      if (rep == "true") {
        // Disable and reset RRULE
        $("#recurring").val("false");
        $("#recurring_rule").val("");
        $("#recurring-form-exp").hide();
        $("#check-recurring").removeClass("selected");
        $("#check-recurring").addClass("disabled");
      } else {
        // Enable
        $("#check-recurring").removeClass("disabled");
      }
    },
  };
})();

/* Contact form
---------------------------------------------------------------- */
var contactform = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("form-contact")) {
        // Attach
        $("#form-contact").on("submit", function () {
          return contactform.validate(this);
        });
      }
    },
    validate: function (f) {
      // Remove messages
      $("#message-sent").hide();
      $("#message-warning").hide();

      // Execute
      let execute = true;
      // Validate
      if (!validate.empty(f.subject.value)) {
        // Execute
        execute = false;
      }

      // Validate
      if (!validate.empty(f.content.value)) {
        // Execute
        execute = false;
      }

      // Validate
      if (!validate.empty(f.email.value)) {
        // Execute
        execute = false;
      }

      // Send or error?
      if (execute) {
        // Get data
        let dat = $("#form-contact").serialize();

        // Disable submit
        $('#form-contact input[type="submit"]').prop("disabled", true);

        // Notify
        notificator.show("Sending");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/misc/contact.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);
            // OK
            if (obj.meta.code == "200") {
              // Show sent
              $("#message-sent").show();
              // Go to warning
              let t = $("#message-sent").offset().top - 100;

              // Scroll to top
              $("html, body").animate(
                { scrollTop: t },
                "slow",
                "easeInOutQuart"
              );
              // Notify
              notificator.show("Sent", true);

              // Disable submit
              $('#form-contact input[type="submit"]').addClass("disable");
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      } else {
        // Show
        $("#message-warning").show();
        // Enable submit
        $('#form-contact input[type="submit"]').prop("disabled", false);
        // Go to warning
        let t = $("#message-warning").offset().top - 100;

        // Scroll to top
        $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
      }

      return false;
    },
  };
})();

// Ready
$(document).ready(function () {
  contactform.initialize();
});

/* Domain input
---------------------------------------------------------------- */
var dominp = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("form-domainadd")) {
        // Attach
        $("#form-domainadd").on("submit", function () {
          return dominp.validate(this);
        });
        // Load domains
        dominp.loaddomains();
      }
    },
    validate: function (f) {
      // Remove messages
      $("#domains-success").hide();
      $("#domains-error").hide();
      $("#domains-prevent").hide();
      $("#domains-not-allowed").hide();
      $("#domain-already-registered").hide();

      // Execute
      let execute = true;

      // Validate
      if (!validate.empty(f.domain.value)) {
        // Execute
        execute = false;
      }

      // Set variables
      let don = f.domain.value, count = 0;

      // Remove not allowed characters. Add a count if any matches are found.
      don.replace(/ /g, function(x){count+=1;});
      don.replace(/"/g, function(x){count+=1;});
      don.replace(/'/g, function(x){count+=1;});
      don.replace(/=/g, function(x){count+=1;});
      don.replace(/\*/g, function(x){count+=1;});

      if(count){
        execute = false;
      }

      // Send or error?
      if (execute) {
        // Get data
        let dat = $("#form-domainadd").serialize();
        // Disable submit
        $('#form-domainadd input[type="submit"]').prop("disabled", true);
        // Notify
        notificator.show("Saving");
        // Get plan type
        let plt = $("#form-domainadd").attr("data-plan");
        let t = null;

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/misc/domains.add.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.status_code === 200) {
              // Show sent
              $("#domains-success").show();
              // Go to warning
              t = $("#domains-success").offset().top - 120;
              // Scroll to top
              $("html, body").animate(
                { scrollTop: t },
                "slow",
                "easeInOutQuart"
              );
              // Notify
              notificator.show("Saved", true);
              // Enable submit
              $('#form-domainadd input[type="submit"]').prop("disabled", false);
              // Reset
              $("#form-domainadd .dominp .inp input").val("");
              // Given all the checks in place, just reload page
              setTimeout(function () {
                location.reload();
              }, 1000);
            } else {
              // Notify
              notificator.break();

              if (obj.data.reason == "exceed") {
                // Add a disable
                $("#form-domainadd").addClass("preventformsubmit exceed7");
                // Run prevents
                plnlmtpop.preventfeatures();
                // Show warning
                plnlmtpop.show($("#form-domainadd"));

                // Enable submit
                $('#form-domainadd input[type="submit"]').prop(
                  "disabled",
                  false
                );
              } else if (obj.data.reason == "prevent") {
                // Show
                $("#domains-prevent").show();
                // Go to warning
                t = $("#domains-prevent").offset().top - 120;
                // Scroll to top
                $("html, body").animate(
                  { scrollTop: t },
                  "slow",
                  "easeInOutQuart"
                );

                // Enable submit
                $('#form-domainadd input[type="submit"]').prop(
                  "disabled",
                  false
                );
              } else if (obj.data.reason == "invalid") {
                $("#domains-error").show();
                // Go to warning
                let t = $("#domains-error").offset().top - 120;
                // Scroll to top
                $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");

                // Enable submit
                $('#form-domainadd input[type="submit"]').prop(
                  "disabled",
                  false
                );
              } else if (obj.data.reason == "exists") {
                // Show
                $("#domain-already-registered").show();
                // Go to warning
                t = $("#domain-already-registered").offset().top - 120;
                // Scroll to top
                $("html, body").animate(
                  { scrollTop: t },
                  "slow",
                  "easeInOutQuart"
                );
                // Enable submit
                $('#form-domainadd input[type="submit"]').prop(
                  "disabled",
                  false
                );
              } else {
                // Error
                csrfpop.show();
              }
            }
          },
        });
      } else {
        // Show
        $("#domains-error").show();
        // Enable submit
        $('#form-domainadd input[type="submit"]').prop("disabled", false);
        // Go to warning
        let t = $("#domains-error").offset().top - 120;
        // Scroll to top
        $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
      }

      return false;
    },
    loaddomains: function () {
      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/actions/misc/domains.list.php",
        cache: false,
        success: function (data) {
          // Update
          $("#domsajax").html(data);
          // Attach
          $("#domsajax .dompagi a").on("click", function () {
            dominp.navigatelist(this);
            return false;
          });
          $("#domsajax .domslist .delete").on("click", function () {
            dominp.domdelete(this);
            return false;
          });
          $("#domsajax .domslist .disable").on("click", function () {
            dominp.domdisable(this);
            return false;
          });
          // Get total
          let tot = $("#domsajax .domslist").attr("data-count");

          // Update
          $(".domtotal").text(tot);
        },
      });
    },
    navigatelist: function (f) {
      // Get URL
      let ref = $(f).attr("href");

      // Notify
      notificator.show("Loading");

      // Ajax
      $.ajax({
        type: "POST",
        url: ref,
        cache: false,
        success: function (data) {
          // Update
          $("#domsajax").html(data);
          // Attach
          $("#domsajax .dompagi a").on("click", function () {
            dominp.navigatelist(this);
            return false;
          });
          $("#domsajax .domslist .delete").on("click", function () {
            dominp.domdelete(this);
            return false;
          });
          $("#domsajax .domslist .disable").on("click", function () {
            dominp.domdisable(this);
            return false;
          });

          // Get total
          let tot = $("#domsajax .domslist").attr("data-count");
          // Update
          $(".domtotal").text(tot);
          // Notify
          notificator.break();
          // Go to warning
          let t = $("#domsajax .domslist").offset().top - 20;
          // Scroll to top
          $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
        },
      });
    },
    domdelete: function (f) {
      // Get id
      let fid = $(f).attr("data-id");
      let csrf = $(f).attr("data-csrf");
      // Notify
      notificator.show("Deleting");
      // Get data
      let dat = "id=" + fid + "&csrf=" + csrf;

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/actions/misc/domains.delete.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);

          // OK
          if (obj.status_code === 200) {
            // Remove
            $(f).closest(".row").remove();
            // Notify
            notificator.show("Deleted", true);

            // Given all the checks in place, just reload page
            setTimeout(function () {
              location.reload();
            }, 1000);
          } else {
            // Notify
            notificator.break();
            // Error
            csrfpop.show();
          }
        },
      });
    },
    domdisable: function (f) {
      // Get id
      let fid = $(f).attr("data-id");
      let csrf = $(f).attr("data-csrf");
      // Disabled?
      let dis = $(f).closest(".row").hasClass("domdisabled");
      // Disabled parameter
      let dispar = "";

      // Toogle
      if (dis) {
        // Notify
        notificator.show("Unblocking");
        dispar = "false";
      } else {
        // Notify
        notificator.show("Blocking");
        dispar = "true";
      }

      // Get data
      let dat = "id=" + fid + "&disabled=" + dispar + "&csrf=" + csrf;

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/actions/misc/domains.disable.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);

          // OK
          if (obj.status_code === 200) {
            // Given all the checks in place, just reload page
            setTimeout(function () {
              location.reload();
            }, 500);
          } else {
            // Error
            if (obj.data.message === "plan-limit-met") {
              // Break
              notificator.break();
              // Show
              $("#domains-not-allowed").show();
              // Go to warning
              let t = $("#domains-not-allowed").offset().top - 120;

              // Scroll to top
              $("html, body").animate(
                { scrollTop: t },
                "slow",
                "easeInOutQuart"
              );
            } else {
              // Notify
              notificator.break();
              // Error
              csrfpop.show();
            }
          }
        },
      });
    },
  };
})();

// Ready
$(document).ready(function () {
  dominp.initialize();
});

/* Subscription management
---------------------------------------------------------------- */
let pypobskt = (function () {
  // Global variables
  let stripeobj = null,
    stripeinit = false;

  return {
    initialize: function () {
      // Plans upgrade
      $(".prib .cel a:not([disabled])")
        .filter(".btn:not(.entsignup)")
        .on("click", function () {
          pypobskt.pllimngshow(this, "");
          return false;
        });
      // Change card
      $(".changecard").on("click", function () {
        pypobskt.pllimngchangecard(this);
        return false;
      });
      // Global card update
      if ($("#cardchangeglobal").length) {
        // Attach
        $("#cardchangeglobal").on("click", function () {
          pypobskt.cardchangeglobalinit(this);
        });
        // Initialize Stripe
        pypobskt.stripeinit();
      }

      // Thank you screen
      $("#pypo-thanks .close-t1, #pypo-thanks .planconfirmed").on(
        "click",
        function () {
          pypobskt.pllithkshide(this);
          return false;
        }
      );
      // Prevent
      $("#pypo-plans").on("click", function (event) {
        event.stopPropagation();
      });
      // Clone
      $(".prib .hobby").clone(true, true).appendTo(".pribmobile .hobby");
      $(".prib .smallbusiness")
        .clone(true, true)
        .appendTo(".pribmobile .smallbusiness");
      $(".prib .professional")
        .clone(true, true)
        .appendTo(".pribmobile .professional");
      $(".prib .enterprise")
        .clone(true, true)
        .appendTo(".pribmobile .enterprise");

      // Any pre-defined opens, get hash?
      let hsh = window.location.hash;

      // Any pre-selected plan?
      if (hsh) {
        // Remove #
        hsh = hsh.replace("#", "");
        // Reset
        let lnkh = "",
          plan = "",
          cycle = "",
          present = false;

        // Remove
        $("#planprepop").remove();
        // Plan match?
        if (hsh == "small-business-monthly") {
          plan = "smallbusiness";
          cycle = "monthly";
          present = true;
        } else if (hsh == "small-business-annual") {
          plan = "smallbusiness";
          cycle = "annual";
          present = true;
        } else if (hsh == "professional-monthly") {
          plan = "professional";
          cycle = "monthly";
          present = true;
        } else if (hsh == "professional-annual") {
          plan = "professional";
          cycle = "annual";
          present = true;
        }

        // Collect
        lnkh +=
          '<div id="planprepop" data-plan="' +
          plan +
          '" data-cycle="' +
          cycle +
          '"></div>';

        // Append
        $("body").append(lnkh);

        // Trigger
        if (present) {
          pypobskt.pllimngshow($("#planprepop"), cycle);
        }
      }
    },
    pllimngshow: function (f, cyc) {
      // Get action
      let act = $(f).attr("data-plan");
      // Unbind
      $(document).unbind(".pllimngpop");
      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.pllimngpop", function (event) {
          // Hide
          pypobskt.plliplshide();
        });
      }, 200);

      // Data
      let dat = "plan=" + act;
      // Notificator
      notificator.show("Loading..");

      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax.account.selectplans.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Append
          $("#pypo-plans").html(data);

          // Notificator
          notificator.break();

          // Attach
          $("#pypo-form").on("submit", function () {
            return pypobskt.pypobsktvalidate(this);
          });
          $("#pypo-plans .close-t1").on("click", function () {
            pypobskt.plliplshide(this);
            return false;
          });
          $("#pypo-plans .op-t2 .rw").on("click", function () {
            pypobskt.oprwsel(this);
            return false;
          });
          $("#pypo-plans .moropts a").on("click", function () {
            pypobskt.pllimngexpopts(this);
            return false;
          });

          // Get dimensions
          let st = $(window).scrollTop();

          // Adjust
          $("#pypo-plans").css("top", st + 40 + "px");

          // Remove hide class
          $("#pypo-plans").removeClass("hide");
          $("#pypo-plans-bg").removeClass("hide");

          // Show
          $("#pypo-plans").show();
          $("#pypo-plans-bg").show();

          // GTM: Get checkout
          $("#pypo-form .submit").on("click", function () {
            googtagman.getaccountcheckout();
          });

          // Timed show
          setTimeout(function () {
            // Show
            $("#pypo-plans").addClass("show");
            $("#pypo-plans-bg").addClass("show");
          }, 200);

          // Stripe init
          pypobskt.stripeinit();
          // Tooltip
          tooltip.initialize();

          // Any pre-selected cycle?
          if (cyc == "monthly" || cyc == "annual") {
            // Trigger click (show all options if the plan is monthly)
            if (cyc == "monthly") {
              $("#pypo-plans #moreopts a").trigger("click");
            }

            // Select the cycle
            $("#pypo-plans .op-t2 ." + cyc).trigger("click");
          }
        },
      });
    },
    plliplshide: function (f) {
      // Unbind
      $(document).unbind(".plliplspop");

      // Hide
      $("#pypo-plans").removeClass("show");
      $("#pypo-plans-bg").removeClass("show");

      // Timed show
      setTimeout(function () {
        // Hide
        $("#pypo-plans").hide();
        $("#pypo-plans-bg").hide();
      }, 200);
    },
    oprwsel: function (f) {
      // Get current plan (if any)
      let cpl = $("#pypo-form .current_plan").val();

      // Get plan and pricing
      let pln = $(f).attr("data-cycle");
      let pri = $(f).attr("data-price");
      let pnu = $(f).attr("data-plan-num");
      let cou = $(f).attr("data-coupon");
      let planId = $(f).attr("data-plan-id");
      let planTypeName = $(f).attr("data-plan-type");
      let paymentSchedule = $(f).attr("data-payment-schedule");

      // Allowed?
      if (cpl != pnu) {
        // Reset
        $("#pypo-plans .op-t2 .rw").removeClass("active");
        // Set selected
        $(f).addClass("active");
        // Remove plan class
        $("#pypo-plans .rel").removeClass("annual monthly");

        // Monthly vs annual
        if (pln == "month") {
          $("#pypo-plans .rel").addClass("monthly");
        } else {
          $("#pypo-plans .rel").addClass("annual");
        }

        // Update form values
        $("#pypo-form .inpplan").val(pnu);
        $("#pypo-form .inpplan_cycle").val(pln);
        $("#pypo-form .inpplan_id").val(planId);
        $("#pypo-form .inpplan_type_name").val(planTypeName);
        $("#pypo-form .inpplan_payment_schedule").val(paymentSchedule);
        $("#pypo-form .coupon").val(cou);

        // Enable save
        $("#pypo-form .submit").attr("disabled", false);
        $("#pypo-form .submit").removeClass("disabled");
      }
    },
    pypobsktvalidate: function (f) {
      let dat = null;
      // Remove error classes
      $(".inperr").removeClass("inperr");
      $(".warning").hide();
      // Disable
      $("#pypo-form .submit").attr("disabled", true);
      $("#pypo-form .submit").addClass("loading");
      // Signup or change of plans?
      let typ = $("#pypo-form").attr("data-plan-change");

      // Amplitude
      try {
        aeTracker.trackUpgradeSelectPlanButtonClicked(
          parseInt($("#pypo-form .inpplan_id").val()),
          $("#pypo-form .inpplan_type_name").val(),
          $("#pypo-form .inpplan_payment_schedule").val()
        );
      } catch (e) {
        console.log(e);
      }

      // Toggle
      if (typ == "true") {
        // Notificator
        notificator.show("Processing");
        // Get data
        dat = $("#pypo-form").serialize();

        // POST
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/subscription.change.php",
          data: dat,
          cache: false,
          dataType: "json",
          success: function (data) {
            // Get obj
            const obj = data;

            // OK?
            if (obj.status_code !== 200) {
              // Show
              $("#err-card").show();
              $("#err-card p").text(obj.error.description);
              // Enable
              $("#pypo-form .submit").attr("disabled", false);
              $("#pypo-form .submit").removeClass("loading");
            } else {
              // Change type
              if (obj.type == "subscription_change") {
                if (
                  obj.subscription.latest_invoice.payment_intent !== null &&
                  obj.subscription.latest_invoice.payment_intent !== ""
                ) {
                  // OK or "requires action"?
                  if (
                    obj.subscription.latest_invoice.payment_intent.status ==
                      "requires_action" ||
                    obj.subscription.latest_invoice.payment_intent.status ==
                      "requires_source_action"
                  ) {
                    // Show
                    $("#err-card").show();
                    $("#err-card p").text(
                      "Payment method awaits authentication"
                    );

                    stripeobj
                      .confirmCardPayment(
                        obj.subscription.latest_invoice.payment_intent
                          .client_secret
                      )
                      .then(function (result) {
                        // Authentication status
                        if (result.paymentIntent) {
                          // Success
                          // Adjust plan
                          pypobskt.pllimngadjustplan(result.paymentIntent.id);
                        } else {
                          // Enable
                          $("#pypo-form .submit").attr("disabled", false);
                          $("#pypo-form .submit").removeClass("loading");
                          // Show
                          $("#err-card").show();
                          $("#err-card p").text(
                            "Payment method authentication was canceled or failed."
                          );
                        }
                      });
                  } else if (
                    obj.subscription.latest_invoice.payment_intent.status ==
                    "succeeded"
                  ) {
                    // Success
                    // Adjust plan
                    pypobskt.pllimngadjustplan(
                      obj.subscription.latest_invoice.payment_intent.id
                    );
                  } else {
                    // Something went wrong
                    alert(
                      "Sorry, something went wrong.. Please try again or contact support. "
                    );
                  }
                } else if (obj.subscription.latest_invoice.paid == "true") {
                  // User e.g. downgraded and we did not get a payment intent
                  pypobskt.pllimngadjustplan(obj.subscription.customer);
                } else {
                  // User e.g. downgraded and we did not get a payment intent
                  pypobskt.pllimngadjustplan(obj.subscription.customer);
                }
              } else if (obj.type == "subscription_reactivate") {
                // Redirect to confirm
                //location.href = '/account/?action=plan_change_success';
              } else if (obj.type == "subscription_cancel") {
                // Redirect to confirm
                //location.href = '/account/?action=plan_change_success';
              }
            }
          },
        });
      } else {
        // Notificator
        notificator.show("Proceeding to checkout..");
        // Get data
        dat = $("#pypo-form").serialize();

        // POST
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/plan.session.signup.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            if (200 == obj.status_code && obj.session_id != "") {
              // Notificator
              setTimeout(function () {
                notificator.break();
              }, 800);

              // Redirect to checkout
              stripeobj.redirectToCheckout({ sessionId: obj.session_id });
            } else if (obj.status_code !== 200 && obj.error !== "") {
              // Error
              alert(obj.error);
            } else {
              // Error
              alert("Something went wrong. Please try again. ");
            }

            // Enable
            $("#pypo-form .submit").attr("disabled", false);
            $("#pypo-form .submit").removeClass("loading");
          },
        });
      }

      return false;
    },
    pypobsktintrovalidate: function (f) {
      // Disable
      $("#signupplans .submit").prop("disabled", true);
      $("#signupplans .submit").addClass("disable");
      // Notificator
      notificator.show("Proceeding to checkout..");
      // Get data
      let dat = $("#signupplans").serialize();

      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/actions/user/plan.session.signup.intro.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);

          // Enable
          $("#signupplans .submit").prop("disabled", false);
          $("#signupplans .submit").removeClass("disable");

          // OK
          if (200 == obj.status_code && obj.session_id != "") {
            // Notificator
            setTimeout(function () {
              notificator.break();
            }, 800);

            // Redirect to checkout
            stripeobj.redirectToCheckout({ sessionId: obj.session_id });
          } else if (obj.status_code !== 200 && obj.error != "") {
            // Error
            alert(obj.error);
          } else {
            // Error
            alert("Something went wrong. Please try again. ");
          }
        },
      });
    },
    pllimngadjustplan: function (pii) {
      // Data
      let dat = "payment_intent=" + pii;

      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/actions/user/subscription.adjustplan.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Get obj
          const obj = parseResponse(data);

          // OK
          if (obj.status_code == "200") {
            // Redirect to confirm
            location.href = "/account/?action=plan_change_success";
          } else {
            // Something went wrong
            alert(
              "Sorry, something went wrong.. Please try again or contact support. "
            );
          }
        },
      });
    },
    stripeinit: function () {
      // Initialized?
      if (!stripeinit) {
        // Stripe script
        let ss = document.createElement("script");
        ss.type = "text/javascript";
        ss.src = "https://js.stripe.com/v3/";
        ss.async = true;
        // Apply
        document.body.appendChild(ss);

        // Observe
        ss.onload = function () {
          // Create a Stripe client.
          stripeobj = Stripe(stripeApiKey);
        };

        stripeinit = true;
      }
    },
    pllithkshide: function (f) {
      // Hide
      $("#pypo-thanks").removeClass("show");
      $("#pypo-thanks-bg").removeClass("show");
      // Reload
      window.location.href = "/account/#membership";
      // Timed hide
      setTimeout(function () {
        // Hide
        $("#pypo-thanks").hide();
        $("#pypo-thanks-bg").hide();
      }, 800);
    },
    pllimngchangecard: function (f) {
      // Stripe init
      pypobskt.stripeinit();
      // Notificator
      notificator.show("Redirecting..");

      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/actions/user/plan.session.changecard.php",
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);

          // OK
          if (200 == obj.status_code && obj.session_id != "") {
            // Notificator
            setTimeout(function () {
              notificator.break();
            }, 800);
            // Redirect to checkout
            stripeobj.redirectToCheckout({ sessionId: obj.session_id });
          } else if (obj.status_code !== 200 && obj.error != "") {
            // Error
            alert(obj.error);
          } else {
            // Error
            alert("Something went wrong. Please try again. ");
          }
        },
      });
    },
    cardchangeglobalinit: function (f) {
      // Get data
      let acc_id = $(f).attr("data-client-id");
      let stc_id = $(f).attr("data-stripe-customer-id");

      // Notificator
      notificator.show("Redirecting..");

      // Disable
      $("#cardchangeglobal").attr("disabled", true);
      $("#cardchangeglobal").addClass("loading");

      // Get data
      let dat =
        "account_clientid=" +
        acc_id +
        "&stripe_customer_id=" +
        stc_id +
        "&type=global";

      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/actions/user/plan.session.changecard.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);
          // OK
          if (200 == obj.status_code && obj.session_id != "") {
            // Notificator
            setTimeout(function () {
              notificator.break();
            }, 800);

            // Redirect to checkout
            stripeobj.redirectToCheckout({ sessionId: obj.session_id });
          } else if (obj.status_code !== 200 && obj.error != "") {
            // Error
            alert(obj.error);
          } else {
            // Error
            alert("Something went wrong. Please try again. ");
          }
        },
      });
    },
    pllimngexpopts: function (f) {
      // Remove to show
      $("#pypo-plans .cycleopt").removeClass("hidets");
      // Hide
      $("#pypo-plans #moreopts").hide();
    },
  };
})();

// Attach
$(document).ready(function () {
  pypobskt.initialize();
});

/* Pricing tooltip
---------------------------------------------------------------- */
var prictooltip = (function () {
  let prielm = null;

  return {
    initialize: function () {
      // Append
      $(".icon-tooltip").on("mouseover", function () {
        prictooltip.show(this);
      });
      $(".icon-tooltip").on("mouseout", function () {
        prictooltip.hide(this);
      });
    },
    show: function (f) {
      // Get parent
      let par = $(f).closest(".rl");

      // Get element
      prielm = $(par).find(".tooltip");

      // Show
      $(prielm).show();

      // Get top
      let tt = parseInt($(f).position().top);

      // Get height
      let hh = $(par).find(".tooltip").outerHeight();

      // Adjust top
      $(par)
        .find(".tooltip")
        .css("top", tt - hh - 15 + "px");
    },
    hide: function (f) {
      // Hide
      $(prielm).hide();
    },
  };
})();

// Attach
$(document).ready(function () {
  prictooltip.initialize();
});

/* Account
---------------------------------------------------------------- */
var account = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("account")) {
        // Fullname
        $("#fullname-org .edit a").on("click", function () {
          account.fullname(this);
          return false;
        });
        $("#fullname-edit .sbt-t1 .cancel").on("click", function () {
          account.fullnamecancel(this);
          return false;
        });
        $("#form-fullname").on("submit", function () {
          return account.fullnamesave(this);
        });

        // Username
        $("#username-org .edit a").on("click", function () {
          account.username(this);
          return false;
        });
        $("#username-edit .sbt-t1 .cancel").on("click", function () {
          account.usernamecancel(this);
          return false;
        });
        $("#form-username").on("submit", function () {
          return account.usernamesave(this);
        });

        // Password
        $("#password-org .edit a").on("click", function () {
          account.password(this);
          return false;
        });
        $("#password-edit .sbt-t1 .cancel").on("click", function () {
          account.passwordcancel(this);
          return false;
        });
        $("#form-password").on("submit", function () {
          return account.passwordsave(this);
        });

        // Convert to normal
        $("#account .connecttonormal").on("click", function () {
          account.connecttonormal(this);
          return false;
        });

        // Account info
        $("#account-info-org .edit a").on("click", function () {
          account.accountinfo(this);
          return false;
        });
        $("#account-info .sbt-t1 .cancel").on("click", function () {
          account.accountinfocancel(this);
          return false;
        });
        $("#form-account-info").on("submit", function () {
          return account.accountinfosave(this);
        });

        // Invoice email
        $("#invoice-email-org .edit a").on("click", function () {
          account.invoiceemail(this);
          return false;
        });
        $("#invoice-email-edit .sbt-t1 .cancel").on("click", function () {
          account.invoiceemailcancel(this);
          return false;
        });
        $("#form-invoice-email").on("submit", function () {
          return account.invoiceemailsave(this);
        });

        // Delete user
        $(".deleteuser").on("click", function () {
          account.deleteuser(this);
          return false;
        });

        // Tabs + setting section
        $("#account .inltbs-t1 a").on("click", function () {
          account.proftabcl(this);
          return false;
        });
        $("#account .accsettings").on("submit", function () {
          return account.proftasbt(this);
        });
        $("#account .accsettings .cancel").on("click", function () {
          account.proftabck(this);
          return false;
        });
        $("#account #sets_tifm").val($("#sets_tifm").attr("data-selected"));

        // OK
        $("#planconfpop .confirm").on("click", function () {
          account.gotoaccount(this);
          return false;
        });
        $("#planconfpop .confirmglobal").on("click", function () {
          account.gotoaccountglobal(this);
          return false;
        });

        // Extras
        $(".extra-t1 .check").on("click", function () {
          account.extracheck(this);
        });
        $("#account-addon-multiple").on("submit", function () {
          return account.addonmultiple(this);
        });
        $("#account-addon-domains").on("submit", function () {
          return account.addondomains(this);
        });

        // Delete account
        $(".accountdelete").on("click", function () {
          account.accountdeleteshow(this);
          return false;
        });
        $("#pop-account-delete").on("click", function (event) {
          event.stopPropagation();
        });
        $("#pop-account-delete .submit").on("click", function () {
          account.accountdeleteconfirm(this);
          return false;
        });
        $("#pop-account-delete .cancel").on("click", function () {
          account.accountdeletehide(this);
          return false;
        });

        // Links
        $("#account .changeplanlnk").on("click", function () {
          account.planchangelnk(this);
          return false;
        });
        $("#account .planbreakdown").on("click", function () {
          account.planbreakdown(this);
          return false;
        });
        $(".limitedusage, .softhardlimit").on("click", function () {
          account.gotoplanfaq(this);
          return false;
        });
      }

      // Sign out
      $(".signout").on("click", function () {
        account.signout(this);
        return false;
      });
    },
    fullname: function (f) {
      // Hide
      $("#fullname-org").hide();
      // Show
      $("#fullname-edit").show();
      // Focus
      $("#fullname-edit #fullname").focus();
      // Clear warnings
      $(".inperr").removeClass("inperr");
    },
    fullnamesave: function (f) {
      // Clear warnings
      $(".warnings").hide();
      // Get value
      let val = $("#fullname-edit #fullname").val();

      if (!validate.empty(val)) {
        // Error
        $("#fullname-edit #fullname").addClass("inperr");
      } else {
        // Remove error
        $("#fullname-edit #fullname").removeClass("inperr");
        // Notify
        notificator.show("Saving");
        // Get data
        let dat = $("#form-fullname").serialize();
        // POST
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/change-fullname.php",
          data: dat,
          cache: false,
          dataType: "json",
          success: function (data) {
            // Parse
            const obj = data;
            // OK
            if (obj.meta.code == "200") {
              // Update name
              $("#fullname_blank").val(val);

              // Notify
              notificator.show("Saved", true);
              // Close
              account.fullnamecancel();

              // Reload
              setTimeout(function () {
                // Reload
                location.reload();
              }, 100);
            } else {
              // Notify
              notificator.break();
              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    fullnamecancel: function (f) {
      // Show
      $("#fullname-org").show();
      // Hide
      $("#fullname-edit").hide();
    },
    username: function (f) {
      // Hide
      $("#username-org").hide();
      // Show
      $("#username-edit").show();
      // Focus
      $("#username-edit #email").focus();
      // Clear warnings
      $(".inperr").removeClass("inperr");
    },
    usernamesave: function (f) {
      // Clear warnings
      $(".warnings").hide();
      $("#username-warnbox").hide();
      // Get value
      let val = $("#username-edit #email").val();

      if (!validate.email(val)) {
        // Error
        $("#username-edit #email").addClass("inperr");
      } else {
        // Remove error
        $("#username-edit #email").removeClass("inperr");
        // Notify
        notificator.show("Saving");
        // Get data
        let dat = $("#form-username").serialize();

        // POST
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/change-email.php",
          data: dat,
          cache: false,
          dataType: "json",
          success: function (data) {
            // Parse
            const obj = data;
            // OK
            if (obj.meta.code == "200") {
              // Does e-mail already exists?
              if (obj.user.exists == "true") {
                // Show
                $("#warn-confirm-email-exists").show();
                $("#username-warnbox").show();

                // Notify
                notificator.break();
                // Hide external login text
                $("#warn-ceex").hide();
                // Attached to an external login?
                if (obj.user.service != "normal" && obj.user.service != "") {
                  // Show
                  $("#warn-ceex").show();

                  // Update
                  $("#warn-cees").html(obj.user.service);
                }
              } else {
                // Show confirm email
                $("#warn-confirm-email").show();
                $("#username-warnbox").show();

                // Notify
                notificator.break();
              }
            } else if (
              obj.meta.code == "400" ||
              obj.user.require == "password"
            ) {
              // Notify
              notificator.break();
              // Show password prompt
              promptpw.show();
              // Invoke
              promptpw.invoke("change.email");
            } else {
              // Notify
              notificator.break();
              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    usernamecancel: function (f) {
      // Show
      $("#username-org").show();
      // Hide
      $("#username-edit").hide();
      // Hide
      $("#username-warnbox").hide();
      $(".warnings").hide();
    },
    password: function (f) {
      // Hide
      $("#password-org").hide();
      // Show
      $("#password-edit").show();
      // Reset
      $("#password-edit #password1").val("");
      $("#password-edit #password2").val("");
      $("#password-edit #password3").val("");
      // Clear warnings
      $(".inperr").removeClass("inperr");
      // Focus
      $("#password-edit #password1").focus();
    },
    passwordsave: function (f) {
      // Get value
      let val1 = $("#password-edit #password1").val();
      let val2 = $("#password-edit #password2").val();
      let val3 = $("#password-edit #password3").val();

      // Clear warnings
      $(".warnings").hide();
      $("#password-warnbox").hide();

      // Errors
      let error = false;

      if (!validate.empty(val1)) {
        // Error
        error = true;

        // Add error class
        $("#password-edit #password1").addClass("inperr");
      }

      if (!validate.empty(val2)) {
        // Error
        error = true;
        // Add error class
        $("#password-edit #password2").addClass("inperr");
      }

      if (!validate.empty(val3)) {
        // Error
        error = true;
        // Add error class
        $("#password-edit #password3").addClass("inperr");
      }

      // Revalidate
      if (!error) {
        if (val2 != val3 || !validate.passwordLength(val2) || !validate.containsUppercase(val2) || !validate.containsLowercase(val2)) {
          // Show
          $("#warn-password-wrong").show();
          $("#password-warnbox").show();
        } else {
          // Notify
          notificator.show("Saving");
          // Get data
          let dat = $("#form-password").serialize();

          // POST
          $.ajax({
            type: "POST",
            url: "/source/web/actions/user/change-password.php",
            data: dat,
            cache: false,
            success: function (data) {
              // Parse
              const obj = parseResponse(data);

              // OK
              if (obj.meta.code == "200") {
                // Changed?
                if (obj.user.passwordchanged == "false") {
                  // Show error
                  $("#warn-password-incorrect").show();
                  $("#password-warnbox").show();

                  // Notify
                  notificator.break();
                } else {
                  // Show OK
                  $("#warn-password-changed").show();
                  $("#password-warnbox").show();

                  // Notify
                  notificator.show("Saved", true);

                  // Show
                  $("#password-org").show();

                  // Hide
                  $("#password-edit").hide();

                  // Timed
                  setTimeout(function () {
                    // Hide
                    $(".warnings").hide();
                    $("#password-warnbox").hide();
                  }, 5000);
                }
              } else {
                // Notify
                notificator.break();
                // Error
                csrfpop.show();
              }
            },
          });
        }
      }

      return false;
    },
    passwordcancel: function (f) {
      // Show
      $("#password-org").show();
      // Hide
      $("#password-edit").hide();
      // Hide
      $(".warnings").hide();
      $("#password-warnbox").hide();
    },
    connecttonormal: function (f) {
      // Allowed?
      if (!$(f).hasClass("disabled")) {
        // Confirm
        let con = confirm(
          "Please confirm that you want to convert your sign-in type to a normal sign-in."
        );

        // Delete
        if (con) {
          // Notify
          notificator.show("Resetting password");
          // Timed
          setTimeout(function () {
            // CSRF
            let csrf = $(f).attr("data-csrf");
            // Set data
            let dat = "csrf=" + csrf;

            // Post
            $.ajax({
              type: "POST",
              url: "/source/web/actions/user/change-password-reset.php",
              data: dat,
              cache: false,
              success: function () {
                // Notify
                notificator.show("Please check your email..", true);
                // Disable
                $("#account .connecttonormal").addClass("disabled");
                // Timed
                setTimeout(function () {
                  // Reload
                  location.reload();
                }, 5000);
              },
            });
          }, 3000);
        }
      }
    },
    accountinfo: function (f) {
      // Hide
      $("#account-info-org").hide();
      // Show
      $("#account-info").show();
      // Focus
      $("#company_name").focus();
    },
    accountinfosave: function () {
      // Get data
      let dat = $("#form-account-info").serialize();
      // Notify
      notificator.show("Saving");

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/actions/user/change-company.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);

          // OK
          if (obj.meta.code == "200") {
            // Notify
            notificator.show("Saved", true);
            // Update
            $("#account-info-summery-txt").html(obj.user.company);
            // Hide
            account.accountinfocancel();
          } else {
            // Notify
            notificator.break();
            // Error
            csrfpop.show();
          }
        },
      });

      return false;
    },
    accountinfocancel: function (f) {
      // Show
      $("#account-info-org").show();
      // Hide
      $("#account-info").hide();
    },
    invoiceemail: function (f) {
      // Hide
      $("#invoice-email-org").hide();

      // Show
      $("#invoice-email-edit").show();

      // Focus
      $("#invoice_email").focus();
    },
    invoiceemailsave: function (f) {
      // Remove error
      $(".inperr").removeClass("inperr");

      // Get value
      let val = $("#form-invoice-email #invoice_email").val();

      // Execute
      let execute = true;

      // Validate
      if (val !== "") {
        if (!validate.email(val)) {
          // Error
          $("#form-invoice-email #invoice_email").addClass("inperr");
          execute = false;
        }
      }

      // Execute?
      if (execute) {
        // Get data
        let dat = $("#form-invoice-email").serialize();
        // Notify
        notificator.show("Saving");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/change-invoice-email.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Notify
              notificator.show("Saved", true);
              // Update
              $("#invoice_email_blank").val(obj.user.email);
              // Hide
              account.invoiceemailcancel();
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    invoiceemailcancel: function (f) {
      // Show
      $("#invoice-email-org").show();
      // Hide
      $("#invoice-email-edit").hide();
    },
    signout: function (f) {
      // Go to
      location.href = "/source/web/actions/user/signout.php";
    },
    deleteuser: function (f) {
      alert("delete user");
    },
    gotoaccount: function () {
      // Go to account
      location.href = "/account";
    },
    gotoaccountglobal: function () {
      // Go to account
      location.href = "/";
    },
    extracheck: function (f) {
      // Get main
      let obj = $(f).closest(".extra-t1");
      // Get class
      let avi = $(obj).hasClass("notavailable");
      // Ok to proceed
      if (!avi) {
        // Get status
        let chk = $(f).hasClass("checked");

        // Toogle status
        if (chk) {
          $(f).removeClass("checked");
          $(obj).find(".chkval").val("false");
        } else {
          $(f).addClass("checked");
          $(obj).find(".chkval").val("true");
        }

        // Show submit
        $(obj).find(".sbt").show();
      }
    },
    addonmultiple: function (f) {
      // Hide error
      $("#account .warning").hide();
      // Disable
      $("#account-addon-multiple .submit").prop("disabled", true);
      // Notify
      notificator.show("Saving");
      // Get data
      let dat = $("#account-addon-multiple").serialize();

      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/actions/user/addon-multiple.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Get obj
          const obj = parseResponse(data);

          // OK?
          if (obj.meta.code == "400") {
            // Notificator
            notificator.break();
            // Show error
            $("#err-form-multiple").show();
            // Enable
            $("#account-addon-multiple .submit").prop("disabled", false);
          } else {
            // Notify
            notificator.show("Saved", true);
            // Keep submit disabled
            $("#account-addon-multiple .submit").prop("disabled", false);
            // Show confirmation popup
            $("#form-addon-multiple-success").show();
          }
        },
      });

      return false;
    },
    addondomains: function (f) {
      // Hide error
      $("#account .warning").hide();
      // Disable
      $("#account-addon-domains .submit").prop("disabled", true);
      // Notify
      notificator.show("Saving");
      // Get data
      let dat = $("#account-addon-domains").serialize();

      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/actions/user/addon-domains.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Get obj
          const obj = parseResponse(data);

          // OK?
          if (obj.meta.code == "400") {
            // Notificator
            notificator.break();
            // Show error
            $("#err-form-domains").show();
            // Enable
            $("#account-addon-domains .submit").prop("disabled", false);
          } else {
            // Notify
            notificator.show("Saved", true);
            // Keep submit disabled
            $("#account-addon-domains .submit").prop("disabled", false);
            // Show confirmation popup
            $("#form-addon-domains-success").show();
          }
        },
      });

      return false;
    },
    accountdeleteshow: function (f) {
      // Unbind
      $(document).unbind(".accountdelete");
      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.accountdelete", function (event) {
          // Hide
          account.accountdeletehide();
        });
      }, 200);

      // Show
      $("#pop-account-delete").show();
      $("#pop-account-delete-bg").show();
      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#pop-account-delete").outerHeight();
      let nt = st + (wh / 2 - ph / 2) - 30;

      // Set popup top
      $("#pop-account-delete").css("top", nt + "px");
    },
    accountdeletehide: function () {
      // Show
      $("#pop-account-delete").hide();
      $("#pop-account-delete-bg").hide();

      // Unbind
      $(document).unbind(".accountdelete");
    },
    accountdeleteconfirm: function () {
      // Confirm
      let con = confirm(
        "Double checking. Are you sure you want to delete your account?"
      );

      // Delete
      if (con) {
        // Notify
        notificator.show("Deleting account");
        // Disable
        $("#pop-account-delete .submit").prop("disabled", true);

        // Post
        $.ajax({
          type: "POST",
          url: "/source/web/actions/account/account-delete.php",
          cache: false,
          success: function () {
            // Notify
            notificator.show("Account deleted", true);
            // Timed
            setTimeout(function () {
              // Log out
              location.href = "/source/web/actions/user/signout.php";
            }, 1000);
          },
        });
      }
    },
    planchangelnk: function (f) {
      // Show
      notificator.show("Loading..");
      // Goto
      location.href = "/account/plan";
    },
    planbreakdown: function (f) {
      // Goto
      location.href = "/account/breakdown";
    },
    gotoplanfaq: function (f) {
      // Go to faq
      let t = $("#plansfaq").offset().top - 20;

      // Scroll to top
      $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
    },
    proftabcl: function (f) {
      // Reset and hide
      $("#account .inltbs-t1 a").removeClass("active");
      $("#account .section").hide();
      // Set active
      $(f).addClass("active");
      // Get relation
      let rel = $(f).attr("data-rel");

      // Show
      $("#" + rel).show();
    },
    proftasbt: function (f) {
      // Variables
      let execute = true;
      // Data
      let dat = $(f).serialize();

      // Continue
      if (execute) {
        // Disable submit
        $(".accsettings .save").prop("disabled", true);
        $(".accsettings .save").addClass("disabled");

        // Notify
        notificator.show("Saving..");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/settings.save.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Clear
              $(".accsettings .save").prop("disabled", false);
              $(".accsettings .save").removeClass("disabled");

              // Notify
              notificator.show("Saved", true);
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    proftabck: function () {
      // Back to "account"
      location.href = "/account";
    },
  };
})();

// Attach
$(document).ready(function () {
  account.initialize();
});

/* Password prompt
---------------------------------------------------------------- */

var promptpw = (function () {
  return {
    initialize: function () {
      // Submit
      $("#pwprompt-pop #pwacccd_form").on("submit", function () {
        return promptpw.verify(this);
      });
      // Prevent
      $("#pwprompt-pop").on("click", function (event) {
        event.stopPropagation();
      });
    },
    show: function (f) {
      // Show
      $("#pwprompt-pop").show();
      $("#pwprompt-bg").show();
      // Unbind
      $(document).unbind(".promptpwpop");
      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.promptpwpop", function () {
          promptpw.hide();
        });
      }, 200);
    },
    hide: function (f) {
      // Hide
      $("#pwprompt-pop").hide();
      $("#pwprompt-bg").hide();
      // Clear
      $("#pwprompt-pop .pwsbt").prop("disabled", false);
      $("#pwprompt-pop .pwsbt").removeClass("loading");
      // Unbind
      $(document).unbind(".promptpwpop");
    },
    invoke: function (vl) {
      // Update
      $("#pwprompt-pop #pwacccd_invoke").val(vl);
    },
    verify: function (f) {
      // Variables
      let execute = true;
      // Hide warnings
      $("#pwprompt-pop .error").hide();
      // Check if an access code has been entered
      if (!validate.empty(f.code.value)) {
        execute = false;
      }

      // Execute?
      if (execute) {
        // Get data
        let dat = $("#pwprompt-pop form").serialize();
        // Disable submit
        $("#pwprompt-pop .pwsbt").prop("disabled", true);
        $("#pwprompt-pop .pwsbt").addClass("loading");
        // Get invoke value (if any)
        let ivl = $("#pwprompt-pop #pwacccd_invoke").val();

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/verify-password.php",
          data: dat,
          cache: false,
          dataType: "json",
          success: function (data) {
            // Parse
            const obj = data;

            // OK
            if (obj.meta.code == "200") {
              // Hide
              promptpw.hide();
              // Any invoke?
              if (ivl != "") {
                if (ivl == "change.email") {
                  // Trigger
                  setTimeout(function () {
                    $("#form-username").trigger("submit");
                  }, 500);
                }
              }
            } else {
              // Expired?
              if (obj.user.csrf == "expired") {
                // Hide
                promptpw.hide();

                // Notify
                notificator.break();

                // Error
                csrfpop.show();
              } else {
                // Timed alert
                setTimeout(function () {
                  // Alert
                  $("#pwprompt-pop #pwprompt-incorrect").show();

                  // Clear
                  $("#pwprompt-pop .pwsbt").prop("disabled", false);
                  $("#pwprompt-pop .pwsbt").removeClass("loading");
                }, 400);
              }
            }
          },
        });
      } else {
        // Show
        $("#pwprompt-pop #pwprompt-nocode").show();
      }

      return false;
    },
  };
})();

// Attach
$(document).ready(function () {
  promptpw.initialize();
});

/* Invoices
---------------------------------------------------------------- */
var invoices = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("invoicesajax")) {
        // Load invoices
        invoices.loadinvoices();
      }
    },
    loadinvoices: function () {
      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/actions/misc/invoices.list.php",
        cache: false,
        success: function (data) {
          // Update
          $("#invoicesajax").html(data);
          // Attach
          $("#invoicesajax .dompagi a").on("click", function () {
            invoices.navigatelist(this);
            return false;
          });
        },
      });
    },
    navigatelist: function (f) {
      // Get URL
      let ref = $(f).attr("href");
      // Notify
      notificator.show("Loading");

      // Ajax
      $.ajax({
        type: "POST",
        url: ref,
        cache: false,
        success: function (data) {
          // Update
          $("#invoicesajax").html(data);
          // Attach
          $("#invoicesajax .dompagi a").on("click", function () {
            invoices.navigatelist(this);
            return false;
          });
          // Notify
          notificator.break();
          // Go to warning
          let t = $("#invoicesajax").offset().top - 20;

          // Scroll to top
          $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
        },
      });
    },
  };
})();

// Ready
$(document).ready(function () {
  invoices.initialize();
});

/* Tools popup
---------------------------------------------------------------- */
var toolslnk = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($(".toolslnk").length) {
        // Attach
        $(".toolslnk .bt a").on("click", function () {
          toolslnk.show(this);
          return false;
        });
      }
    },
    show: function (f) {
      if ($(".toolslnk a").hasClass("active")) {
        // Hide
        toolslnk.hide();
      } else {
        // Set active
        $(".toolslnk a").addClass("active");
        // Show
        $(".toolslnk .toolsdrop").addClass("show");
        // Unbind
        $(document).unbind(".toolslnkmenu");

        // Timed
        setTimeout(function () {
          // Bind
          $(document).on("click.toolslnkmenu", function () {
            toolslnk.delay();
          });
        }, 200);
      }
    },
    delay: function () {
      // Delayed hide
      setTimeout(function () {
        toolslnk.hide();
      }, 200);
    },
    hide: function () {
      // Remove active
      $(".toolslnk a").removeClass("active");
      // Hide
      $(".toolslnk .toolsdrop").removeClass("show");

      // Unbind
      $(document).unbind(".toolslnkmenu");
    },
  };
})();

// Attach
$(document).ready(function () {
  toolslnk.initialize();
});

/* Read more
---------------------------------------------------------------- */

var readmore = (function () {
  return {
    initialize: function () {
      // Attach
      $(".readmorelink").on("click", function () {
        readmore.show(this);
        return false;
      });
    },
    show: function (f) {
      // Get rel
      let rel = $(f).attr("data-rel");
      // Show
      $("#" + rel + "-txt").show();
      // Remove read more
      $(f).hide();
    },
  };
})();

// Attach
$(document).ready(function () {
  readmore.initialize();
});

/* Mobile menu
---------------------------------------------------------------- */
var mobilemenu = (function () {
  return {
    initialize: function () {
      // Attach
      $(".mmmore").on("click", function () {
        mobilemenu.toogle(this);
        return false;
      });
      // Any menus to expand?
      if (!$(".cont-t1 .auto .con .left").length) {
        $(".mmmore").hide();
      }
    },
    toogle: function (f) {
      // Visible?
      let dis = $(".cont-t1 .auto .con .left").css("display");

      // Toogle
      if (dis == "block") {
        $(".cont-t1 .auto .con .left").hide();
      } else {
        $(".cont-t1 .auto .con .left").show();
      }
    },
  };
})();

// Attach
$(document).ready(function () {
  mobilemenu.initialize();
});

/* Event form (calendar events)
---------------------------------------------------------------- */

var eventform1 = (function () {
  // Variables
  let listover,
    contextobj = null,
    map,
    gscript = false,
    frmini = null,
    frmfin = null;

  return {
    initialize: function () {
      // Exists?
      if ($d("eventform1")) {
        // Get visible form fields
        eventform1.formfieldsinit();

        // Initialize form
        eventform1.forminit();
      }

      // Exists?
      if ($d("event-view")) {
        // Get visible form fields
        eventform1.eventviewinit();
      }

      // Exists?
      if ($d("tab-rsvp-attendees") || $d("tab-attendees")) {
        // Subscribers list
        eventform1.sublistinit();
      }
    },
    forminit: function () {
      // Attach
      $(".eventform1 form").on("submit", function () {
        return eventform1.validate(this);
      });
      $(
        ".eventform1 #check-all-day .check, .eventform1 #check-all-day .label"
      ).on("click", function () {
        eventform1.allday(this);
      });
      $(".eventform1 .zone .close").on("click", function () {
        eventform1.tzidremove(this);
        return false;
      });
      $(".eventform1 #rspvtmp").on("click", function () {
        eventform1.rspvtmpshow(this);
        return false;
      });
      $(".eventform1 #rspvtmp .drp .itm").on("click", function () {
        eventform1.rspvtmpselect(this);
        return false;
      });
      $(".eventform1 #check-rsvp .check, .eventform1 #check-rsvp .label").on(
        "click",
        function () {
          eventform1.rspvtoogle(this);
        }
      );
      $(".planlimitok").on("click", function () {
        eventform1.rsvplimithide(this);
      });
      $(".eventform1 .evtseries .btn").on("click", function () {
        eventform1.repeat(this);
      });
      $(".eventform1 .editrule").on("click", function () {
        recurrules.show();
        return false;
      });
      $(".eventform1 .removerule").on("click", function () {
        recurrules.remove();
        return false;
      });
      $(".eventform1 #template_rep").on("change", function () {
        eventform1.templateremember(this);
        return false;
      });
      $(
        ".eventform1 #check-recurring .check, .eventform1 #check-recurring .label"
      ).on("click", function () {
        eventform1.recurringtoggle(this);
      });
      $(".eventform1 .editrecurring").on("click", function () {
        eventform1.recurringshow(this);
        return false;
      });

      // Get first day
      let fd = $("#calendar-controle").attr("data-firstday");

      // Get time format
      let tfm = $("#calendar-controle").attr("data-timeformat");

      // Timepicker
      if (tfm == "2") {
        $(".picker .time").timepicker({
          showDuration: true,
          timeFormat: "H:i",
          step: 15,
        });
      } else {
        $(".picker .time").timepicker({
          showDuration: true,
          timeFormat: "g:ia",
          step: 15,
        });
      }

      // Datepicker
      $(".picker .date")
        .datepicker({
          showWeek: true,
          firstDay: fd,
          dateFormat: "M d, yy",
          autoclose: true,
        })
        .on("change", function (e) {
          // Timed, get date
          setTimeout(function () {
            // Get start date
            let sda = $("#date_start").datepicker("getDate");
            let eda = $("#date_end").datepicker("getDate");

            // Set min date
            $(".picker #date_end").datepicker("option", "minDate", sda);

            // Get dates
            let sdaf =
              ("0" + (sda.getMonth() + 1)).slice(-2) +
              "/" +
              ("0" + sda.getDate()).slice(-2) +
              "/" +
              sda.getFullYear();
            let edaf =
              ("0" + (eda.getMonth() + 1)).slice(-2) +
              "/" +
              ("0" + eda.getDate()).slice(-2) +
              "/" +
              eda.getFullYear();

            // Update
            $(".picker #date_start").attr("data-date", sdaf);
            $(".picker #date_end").attr("data-date", edaf);

            // Any recurring value, validate it
            if ($("#recurring").val() == "true") {
              // Update
              rrule.rruleexp($("#recurring_rule").val());
            }
          }, 200);
        });

      // On change
      $(".picker .time").on("changeTime", function () {
        // Update
        recurrules.summary(true);
      });

      // Date/time pair
      $(".picker").datepair({
        parseDate: function (input) {
          return $(input).datepicker("getDate");
        },
        updateDate: function (input, dateObj) {
          return $(input).datepicker("setDate", dateObj);
        },
      });

      // Update selects
      $("#reminder1").val($("#reminder1").attr("data-selected"));
      $("#template_rep").val($("#template_rep").attr("data-selected"));
      $("#freebusytransp").val($("#freebusytransp").attr("data-selected"));

      // Update remembered template
      //eventform1.templateremembered();

      // Get value
      let adv = $("#all_day").val();

      // If all day, set value
      if (adv == "true") {
        // Checked
        $("#check-all-day").addClass("selected");

        // Repeat enabled?
        if ($("#repeat").val() == "true") {
          $(".picker .time-t1, .picker .time-t2").addClass("disabled");
          $(".picker .time-t1, .picker .time-t2").show();
        } else {
          $(".picker .time-t1, .picker .time-t2").hide();
        }

        // Add
        $(".picker").addClass("alldayslim");
      }

      // Get repeat value
      let rev = $("#repeat").val();

      // If true, set check and show explainatory
      if (rev == "true") {
        // Checked
        $(".evtseries .btn").addClass("selected");

        // Show template select
        $(".eventform1 #evttmprow").show();
      }

      // Recurring rules composer
      recurrules.initialize();

      // Get repeat rule value
      let rer = $("#repeat_rule").val();
      let rez = $("#repeat").val();

      // If any rule and rule turned on, show rule explainatory
      if (rer != "" && rez == "true") {
        // Show
        $(".eventform1 #repeat-form-exp").show();

        // Summary
        recurrules.summary(true);
      }

      // Get initialize form values
      eventform1.form_values_init();

      // Any recurring rule?
      let rcv = $("#recurring").val();
      let rrl = $("#recurring_rule").val();

      // Any rule?
      if (rcv == "true" && rrl != "") {
        // Get recurring rule
        let exp = rrule.rruleexp(rrl);

        // Show + update rule
        $("#recurring-form-exp").show();
        $("#recurring-exp-rule").text(exp);

        $("#check-recurring").addClass("selected");
      }

      // Disable form if external
      let xtt = $(".eventform1 form").attr("data-xcopy");

      // Disable
      if (xtt == "true") {
        // Disable
        $(".eventform1 form input, .eventform1 form textarea").prop(
          "readonly",
          true
        );
        $(".eventform1 form .evtseries").hide();
        $(".eventform1 form .zone").hide();
        $(".eventform1 form .sbt-t1").hide();
        $(".eventform1 form input").off("focus");
        $(".eventform1 form input").off("blur");
        $(".eventform1 .rsvpchk").remove();
        $(".eventform1 .mor-t1").hide();
        $(
          ".eventform1 #check-all-day .label, .eventform1 #check-all-day .check"
        ).off("click");
        //$('.eventform1 #colorevt-event').hide();
        $(".eventform1 .editrecurring").hide();
        $(
          ".eventform1 #check-recurring .label, .eventform1 #check-recurring .check, .eventform1 #check-recurring"
        ).off("click");
        $(".drp-flt-t1").hide();
      }

      // Color picker
      colorpickerxo.colorevtinit();
    },
    eventviewinit: function () {
      // Attach
      $("#event-view .des-t1 .more").on("click", function () {
        eventform1.truncatedshow(this);
        return false;
      });
      $("#tab-rsvp-attendees .row").on("click", function () {
        eventform1.attendeedetailsshow();
        return false;
      });
      $("#tab-rsvp-seats #check-limittoo").on("click", function () {
        eventform1.rsvpseatslimit();
        return false;
      });
      $(
        "#tab-rsvp-notifications #check-notify-everytime, #tab-rsvp-notifications #check-notify-summarize"
      ).on("click", function () {
        eventform1.rsvpnotifyfocus();
        return false;
      });
      $("#evtviewctx").on("click", function () {
        eventform1.eventviewmoreshow(this);
        return false;
      });
      $("#evtviewctx-mnu a").on("click", function () {
        return eventform1.eventviewmoreitem(this);
      });
      $("#evtviewctx-mnu").on("click", function (event) {
        event.stopPropagation();
      });

      // RSVP box in event view
      $("#rsvp-more-settings .emailattendees").on("click", function () {
        eventform1.rspvupdtshow(this);
        return false;
      });

      // Refresh token to external attendees list
      $(".attnlstrefresh").on("click", function () {
        eventform1.attnlstrefresh(this);
        return false;
      });
      $(".attnlstlink").on("click", function () {
        eventform1.attnlstlink(this);
        return false;
      });

      // Embed examples
      $(".embedbox1 .foot .nav a").on("click", function () {
        eventform1.embedboxset(this);
        return false;
      });
      $(".embedbox1 .foot .codesource").on("click", function () {
        eventform1.embedboxsource(this);
        return false;
      });

      // Send update form
      $("#pop-snd-upd .cancel").on("click", function () {
        eventform1.rspvupdthide(this);
        return false;
      });
      $("#pop-snd-upd form").on("submit", function () {
        return eventform1.rspvupdtvalidate(this);
      });
      $("#pop-snd-upd").on("click", function (event) {
        event.stopPropagation();
      });

      // Forms
      $("#rsvp-more-settings .rsvpsetfrm").on("submit", function () {
        return eventform1.formrsvpsettings(this);
      });

      // Update select
      $("#rsvp_inactive_before").val(
        $("#rsvp_inactive_before").attr("data-selected")
      );

      // Template change and set
      $(".frmtmpsetsel").on("change", function () {
        eventform1.frmtmpsetcng(this);
        return false;
      });
      $(".frmtmpsetsave").on("click", function () {
        eventform1.frmtmpsetsave(this);
        return false;
      });
      $(".frmtmpsetclose").on("click", function () {
        eventform1.frmtmpsetclose(this);
        return false;
      });

      // Get hash
      let hsh = window.location.hash;

      // Scroll to share?
      if (hsh == "share" || hsh == "#share") {
        // Get top + opera heights
        let th = $(".top").outerHeight() + $(".opera").outerHeight();

        // Locate top
        let t = $("#anchor-eventpage").offset().top - th - 35;

        // Scroll to top
        setTimeout(function () {
          $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
        }, 500);
      }

      // Any rule?
      if ($("#rrule-rl").length > 0) {
        // Get rule
        let rrl = $("#rrule-rl").attr("data-rule");

        // Humanized
        let rhu = rrule.rruleexp(rrl);

        // Update
        $("#rrule-rl p").text("Repeats: " + rhu);
      }
    },
    embedboxset: function (f) {
      // Reset
      $(f).closest(".embedbox1").find(".foot a").removeClass("active");
      $(f).closest(".embedbox1").find(".demo-t1").removeClass("show hide");

      // Slide out
      $(f).closest(".embedbox1").find(".demo-t1").addClass("noani");

      // Get relation
      let rel = $(f).attr("data-rel");

      // Remove slide out from next to display object
      $("#" + rel).removeClass("noani");

      // Copy buttons
      $(f).closest(".embedbox1").find(".codcop").hide();
      $("#" + rel + "-copy").show();

      // Show
      setTimeout(function () {
        $("#" + rel).addClass("show");
      }, 200);

      // Set active
      $(f).addClass("active");
    },
    embedboxsource: function (f) {
      // Get active item
      let obj = $(f).closest(".embedbox1").find(".nav .active");

      // Get relation
      let rel = $(obj).attr("data-rel");

      // Content
      let con = "";

      // Get content
      if (!$("#" + rel).find(".codecopyarea textarea").length) {
        con = $("#" + rel)
          .find(".table .cell")
          .html();
      } else {
        con = $("#" + rel)
          .find(".codecopyarea textarea")
          .val();
      }

      // Popup HTML
      eventform1.copycodepopuphtml();

      // Set html
      $("#codecopy-pop-code").text(con);

      // Show popup
      eventform1.codecopyshow();
    },
    copycodepopuphtml: function () {
      // Get object
      let obj = $d("codecopy-pop");

      // Set variable
      let html = "";

      // .nav-t1 user context
      if (!obj) {
        html += '<div class="popup-t2-bg" id="codecopy-pop-bg"></div>';
        html += '<div class="popup-t2" id="codecopy-pop">';
        html += '    <div class="rel">';
        html += '        <div class="opt-t1">';
        html += '            <div class="hed-t1">';
        html += "                <p>Source code <em>(for developers)</em></p>";
        html += "            </div>";
        html += '            <div class="close">';
        html +=
          '                <a href="#" title=""><i class="material-icons">close</i></a>';
        html += "           </div>";
        html += '            <div class="des-t1">';
        html +=
          "                <p>Copy the code below and paste it into your desired source. </p>";
        html += "            </div>";
        html +=
          '            <div class="code-copy" id="codecopy-pop-code"></div>';
        html += "        </div>";
        html += "    </div>";
        html += "</div>";

        // Append
        $("body").append(html);

        // Attach
        $("#codecopy-pop .close a").on("click", function () {
          eventform1.codecopyhide(this);
          return false;
        });
        $("#codecopy-pop").on("click", function (event) {
          event.stopPropagation();
        });
        $("#codecopy-pop-code").on("click", function () {
          codemarkup.selectable(this);
          return false;
        });
      }
    },
    codecopyshow: function (nws) {
      // Unbind
      $(document).unbind(".codecopy");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.codecopy", function (event) {
          // Hide
          eventform1.codecopyhide();
        });
      }, 200);

      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#codecopy-pop").outerHeight();

      let nt = st + wh / 2 - ph / 2;

      // Set popup top
      $("#codecopy-pop").css("top", nt + "px");

      // Show
      setTimeout(function () {
        $("#codecopy-pop").addClass("show");
        $("#codecopy-pop-bg").addClass("show");
      }, 200);
    },
    codecopyhide: function () {
      // Show
      $("#codecopy-pop").removeClass("show");
      $("#codecopy-pop-bg").removeClass("show");

      // Unbind
      $(document).unbind(".codecopy");
    },
    truncatedshow: function (f) {
      // Show / Hide
      $(f).closest(".descctn").find(".truncated").hide();
      $(f).closest(".descctn").find(".fulltext").show();
    },
    allday: function (f) {
      // Get class
      let chk = $("#all_day").val();

      // Event series?
      let ser = $("#repeat").val();

      // Toogle
      if (chk == "false") {
        // Hide
        if (ser == "true") {
          $(".picker .time-t1, .picker .time-t2").addClass("disabled");
          $(".picker .time-t1, .picker .time-t2").show();
        } else {
          $(".picker .time-t1, .picker .time-t2").hide();
        }

        // Add
        $(".picker").addClass("alldayslim");

        // Val
        $("#all_day").val("true");
      } else {
        // no longer an all day event; set start and end dates to 9:00am and 10:00am respectively
        $("#date_start_time").val('9:00am');
        $("#date_end_time").val('10:00am');

        // Show
        if (ser == "true") {
          $(".picker .time-t1, .picker .time-t2").removeClass("disabled");
          $(".picker .time-t1, .picker .time-t2").show();
        } else {
          $(".picker .time-t1, .picker .time-t2").show();
        }

        // Remove
        $(".picker").removeClass("alldayslim");

        // Val
        $("#all_day").val("false");
      }

      // Summary
      recurrules.summary(true);
    },
    tzidremove: function (f) {
      // Clear
      $(".eventform1 .timezone").val("");

      // Hide / show
      $(".eventform1 #tzid-on").hide();
      $(".eventform1 #tzid-off").show();
    },
    formfieldsshow: function (f) {
      // Get element
      let obj = $(f);

      // Get active class
      let act = $(obj).hasClass("show");

      // Get settings
      let ctx = $(obj).attr("data-context");

      // Active? (show / hide)
      if (act) {
        // Hide
        eventform1.formfieldshide();
      } else {
        // Set active
        $(obj).addClass("active");

        // Show
        $("#" + ctx).addClass("show");

        // Unbind
        $(document).unbind(".evtfrmopts");

        // Timed
        setTimeout(function () {
          // Bind
          $(document).on("click.evtfrmopts", function () {
            eventform1.formfieldshide();
          });
        }, 200);
      }
    },
    formfieldshide: function () {
      // Remove active
      $(".form-t1-add .btn").removeClass("show");

      // Hide context menu
      $(".form-t1-add .context-t1").removeClass("show");

      // Unbind
      $(document).unbind(".evtfrmopts");
    },
    formfieldstoogle: function (f) {
      // Get relation
      let rel = $(f).attr("data-rel");
      // Object
      let obj = $(".eventform1 ." + rel);
      // Is the field visible?
      let fld = $(obj).css("display");

      // Toogle field
      if (fld == "none") {
        // Set drop
        $(f).addClass("checked");

        // Show
        $(obj).show();

        // Remember
        cookies.create(rel, "true", 365);
      } else {
        // Set drop
        $(f).removeClass("checked");

        // Hide
        $(obj).hide();

        // Remember
        cookies.create(rel, "false", 365);

        // Internal name
        if (rel == "fldname") {
          $(".eventform1 #eventname").val("");
        }

        // Location
        if (rel == "fldlocation") {
          $(".eventform1 #location").val("");
        }

        // Organizer + email
        if (rel == "fldorganizer") {
          $(".eventform1 #organizer").val("");
          $(".eventform1 #organizer_email").val("");
        }
      }
    },
    formfieldsinit: function () {
      // Append
      $(".form-t1-add .btn").on("click", function () {
        eventform1.formfieldsshow(this);
        return false;
      });
      $(".form-t1-add .context-t1 a").on("click", function () {
        eventform1.formfieldstoogle(this);
        return false;
      });

      // New event?
      let ncls = $(".eventform1").hasClass("newevent");

      // Get states
      let nam = $(".eventform1 .fldname").css("display");
      let loc = $(".eventform1 .fldlocation").css("display");
      let org = $(".eventform1 .fldorganizer").css("display");
      let frb = $(".eventform1 .fldfreebusy").css("display");

      // Get values
      let v_nam = $(".eventform1 #eventname").val();
      let v_loc = $(".eventform1 #location").val();
      let v_org = $(".eventform1 #organizer").val();
      let v_org_ema = $(".eventform1 #organizer_email").val();
      //var v_frb = $('.eventform1 .fldfreebusy').css('display');

      // Cookies
      let nam_cook = cookies.read("fldname");
      let loc_cook = cookies.read("fldlocation");
      let org_cook = cookies.read("fldorganizer");
      let frb_cook = cookies.read("fldfreebusy");

      // Internal name
      if (nam_cook == "false" && v_nam == "") {
        // Check
        $("#formfieldsctx #ffname").removeClass("checked");

        // Show
        $(".eventform1 .fldname").hide();
      } else {
        // Check
        $("#formfieldsctx #ffname").addClass("checked");

        // Show
        $(".eventform1 .fldname").show();
      }

      // Location
      if (loc_cook == "false" && v_loc == "") {
        // Check
        $("#formfieldsctx #fflocation").removeClass("checked");

        // Show
        $(".eventform1 .fldlocation").hide();
      } else {
        // Check
        $("#formfieldsctx #fflocation").addClass("checked");

        // Show
        $(".eventform1 .fldlocation").show();
      }

      // Organizer
      if (org_cook == "false" && v_org == "" && v_org_ema == "") {
        // Check
        $("#formfieldsctx #fforganizer").removeClass("checked");

        // Show
        $(".eventform1 .fldorganizer").hide();
      } else {
        // Check
        $("#formfieldsctx #fforganizer").addClass("checked");

        // Show
        $(".eventform1 .fldorganizer").show();
      }

      // Free/busy
      if (frb == "block" || (frb_cook == "true" && ncls)) {
        // Check
        $("#formfieldsctx #fffreebusy").addClass("checked");

        // Show
        $(".eventform1 .fldfreebusy").show();
      }
    },
    rspvtoogle: function (f) {
      // Get class
      let chk = $("#rsvp").val();

      // Toogle
      if (chk == "false") {
        // Show
        $(".eventform1 #rspvtmp").show();
        $(".eventform1 #rspvupdt").show();
        $(".eventform1 .attn-t1").show();
        $("#anc-rspv-link").show();
        $("#con-rspv").show();

        // Notice
        if ($("#eventform1").hasClass("newevent")) {
          $("#rsvp-mopts").show();
          $("#rsvp-mopts").css("display", "inline-block");
        }

        // Val
        $("#rsvp").val("true");

        // Set cookie
        cookies.create("rsvp", "true", 365);

        // RSVP limit
        //eventform1.rsvplimitshow();
      } else {
        // Hide
        $(".eventform1 #rspvtmp").hide();
        $(".eventform1 #rspvupdt").hide();
        $(".eventform1 .attn-t1").hide();
        $("#anc-rspv-link").hide();
        $("#con-rspv").hide();

        // Notice
        $("#rsvp-mopts").hide();

        // Val
        $("#rsvp").val("false");

        // Set cookie
        cookies.create("rsvp", "false", 365);
      }
    },
    rspvtmpshow: function (f) {
      // Unbind
      $(document).unbind(".rspvtmp");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.rspvtmp", function (event) {
          // Hide
          eventform1.rspvtmphide();
        });
      }, 200);

      // Show
      $("#rspvtmp .drp").show();
    },
    rspvtmphide: function () {
      // Show
      $("#rspvtmp .drp").hide();

      // Unbind
      $(document).unbind(".rspvtmp");
    },
    rspvtmpselect: function (f) {
      // Get form id + label
      let fid = $(f).attr("data-formid");
      let lbl = $(f).attr("data-label");

      // Update
      $("#rsvp_form_id").val(fid);

      // Clear
      $("#rspvtmp .drp .itm").removeClass("selected");

      $(f).addClass("selected");

      // Update label
      $("#rspvtmp .lbl").text(lbl);

      // Set title
      $("#rspvtmp .lbl").attr("title", lbl);

      // Hide
      setTimeout(function () {
        eventform1.rspvtmphide();
      }, 200);

      // Set cookie
      cookies.create("rsvpfid", fid, 365);
    },
    rspvmore: function (f) {
      // Locate top
      let t = $("#anchor-rsvp-settings").offset().top - 40;

      // Scroll to top
      $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
    },
    rsvplimitshow: function () {
      // Get plan
      let pln = $("#plan-limitation-rsvp").attr("data-plan");

      // Hobby?
      if (pln == "hobby") {
        // Show
        $("#plan-limitation-rsvp").addClass("show");
        $("#plan-limitation-rsvp-bg").addClass("show");

        // Get dimensions
        let wh = $(window).height();
        let st = $(document).scrollTop();
        let ph = $("#plan-limitation-rsvp").outerHeight();
        let nt = st + (wh / 2 - ph / 2) - 30;

        // No negative
        if (nt < 50) {
          nt = 50;
        }

        // Set popup top
        $("#plan-limitation-rsvp").css("top", nt + "px");
      }
    },
    rsvplimithide: function () {
      // Hide
      $("#plan-limitation-rsvp").removeClass("show");
      $("#plan-limitation-rsvp-bg").removeClass("show");
    },
    recurringtoggle: function (f) {
      if (!$("#check-recurring").hasClass("disabled")) {
        // Get class
        let chk = $("#recurring").val();
        // Toogle
        if (chk == "false") {
          // Show
          rrule.show();
        } else {
          // Val
          $("#recurring").val("false");
          $("#recurring_rule").val("");
          $("#check-recurring").removeClass("selected");
          // Hide
          $("#recurring-form-exp").hide();
        }
      }
    },
    recurringshow: function (f) {
      // Show
      rrule.show();
    },

    attendeedetailsshow: function (f) {
      //alert('show details about the attendee');
    },
    rsvpseatslimit: function (f) {
      // Focus
      $("#tab-rsvp-seats #rsvpmaxnum").focus();
    },
    rsvpnotifyfocus: function (f) {
      // Focus
      $("#tab-rsvp-notifications #notify_signup_emails").focus();
    },
    eventviewmoreshow: function (f) {
      // Show
      $("#evtviewctx").addClass("active");
      $("#evtviewctx-mnu").addClass("show");

      // Unbind
      $(document).unbind(".eventviewmore");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.eventviewmore", function () {
          eventform1.eventviewmorehide();
        });
      }, 200);
    },
    eventviewmorehide: function (f) {
      // Hide
      $("#evtviewctx").removeClass("active");
      $("#evtviewctx-mnu").removeClass("show");

      // Unbind
      $(document).unbind(".eventviewmore");
    },
    eventviewmoreitem: function (f) {
      // Get type + unique
      let typ = $(f).attr("data-type");
      let cal = $(f).attr("data-calendar");
      let uni = $(f).attr("data-unique");
      let dis = $(f).hasClass("disabled");

      // Accept items that are enabled
      if (!dis) {
        // Toggle
        if (typ == "view") {
          // Hide context menu
          eventform1.eventviewmorehide();
          return true;
        } else if (typ == "edit") {
          // Hide context menu
          eventform1.eventviewmorehide();
          // Location
          location.href = "/calendars/" + cal + "/event/" + uni + "/edit";

          return false;
        } else if (typ == "copy") {
          // Hide context menu
          eventform1.eventviewmorehide();
          // Get details
          uni = $(f).attr("data-unique");
          let csrf = $(f).attr("data-csrf");
          // Notify
          notificator.show("Duplicating event");
          // Set data
          let dat = "uni=" + uni + "&csrf=" + csrf;

          // Post
          $.ajax({
            type: "POST",
            url: "/source/web/actions/calendar/event-copy.php",
            data: dat,
            cache: false,
            success: function (data) {
              // Parse
              const obj = parseResponse(data);
              // OK
              if (obj.meta.code == "200") {
                // Notify
                notificator.show("Duplicated", true);
                // Get relation
                let cal = $("#calendar-controle").attr("data-calendar");

                // Go to event edit
                setTimeout(function () {
                  location.href =
                    "/calendars/" +
                    cal +
                    "/event/" +
                    obj.event.uniquekey +
                    "/edit";
                }, 500);
              } else if (obj.meta.code == "429") {
                // Add a disable
                $("#doc").addClass("exceednotice exceed2");
                // Run prevents
                plnlmtpop.preventfeatures();
                // Notify
                notificator.break();
              } else {
                // Notify
                notificator.break();
                // Error
                csrfpop.show();
              }
            },
          });

          return false;
        } else if (typ == "delete") {
          // Call
          calm.eventdelete(f);

          return false;
        }
      } else {
        return false;
      }
    },
    formrsvpsettings: function (f) {
      // Clear errors
      $(".inperr-t2").removeClass("inperr-t2");
      // Variables
      let execute = true;

      // Validate
      if (f.type.value == "notifications") {
        // Any email?
        if (!validate.empty(f.notify_signup_emails.value)) {
          // Stop
          execute = false;

          // Show error
          $(f.notify_signup_emails).addClass("inperr-t2");
        }
      }

      // Data
      let dat = $(f).serialize();

      // Continue
      if (execute) {
        // Disable submit
        $(f).find(".save").prop("disabled", true);
        $(f).find(".save").addClass("disabled");

        // Notify
        notificator.show("Saving..");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-settings.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Clear
              $(f).find(".save").prop("disabled", false);
              $(f).find(".save").removeClass("disabled");

              // Notify
              notificator.show("Saved", true);
            } else {
              // Notify
              notificator.break();
              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    rspvupdtshow: function (f) {
      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#pop-snd-upd").outerHeight();
      let nt = st + 30;

      // No negative
      if (nt < 50) {
        nt = 50;
      }

      // Set popup top
      $("#pop-snd-upd").css("top", nt + "px");

      // Show
      setTimeout(function () {
        $("#pop-snd-upd").addClass("show");
        $("#pop-snd-upd-bg").addClass("show");
      }, 200);
    },
    rspvupdthide: function (f) {
      // Hide
      $("#pop-snd-upd").removeClass("show");
      $("#pop-snd-upd-bg").removeClass("show");
    },
    rspvupdtvalidate: function (f) {
      // Execute
      let execute = true;

      // Any values checked?
      if (
        !(
          $("#check-rsvp-sndto1 input").is(":checked") ||
          $("#check-rsvp-sndto2 input").is(":checked") ||
          $("#check-rsvp-sndto3 input").is(":checked")
        )
      ) {
        // Alert
        alert('Please select a "Send to" option..');
      }

      // Clear errors
      $(".inperr-t2").removeClass("inperr-t2");

      // Title
      if (!validate.empty(f.title.value)) {
        // Stop
        execute = false;

        // Show error
        $(f.title).addClass("inperr-t2");
      }

      // Description
      if (!validate.empty(f.description.value)) {
        // Stop
        execute = false;

        // Show error
        $(f.description).addClass("inperr-t2");
      }

      // Data
      let dat = $("#pop-snd-upd form").serialize();

      // Continue
      if (execute) {
        // Disable submit
        $("#pop-snd-upd .save").prop("disabled", true);
        $("#pop-snd-upd .save").addClass("disabled");
        $("#pop-snd-upd .cancel").prop("disabled", true);
        $("#pop-snd-upd .cancel").addClass("disabled");

        // Notify
        notificator.show("Sending..");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-update-send.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code === 200) {
              // Clear
              $("#pop-snd-upd .cancel").prop("disabled", false);
              $("#pop-snd-upd .cancel").removeClass("disabled");

              // Timed
              setTimeout(function () {
                // Notify
                notificator.show("Sent successfully", true);
              }, 2000);

              // Timed
              setTimeout(function () {
                // Hide
                eventform1.rspvupdthide();

                // Clear content
                $(f.description).val("");
              }, 4500);
            } else {
              // Notify
              notificator.break();
              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    attnlstrefresh: function (f) {
      // Get variables
      let uni = $(f).attr("data-unique");
      let csrf = $(f).attr("data-csrf");
      // External URL
      let exturl = $("#doc").attr("data-ext-web");
      // Notify
      notificator.show("Refreshing");
      // Set data
      let dat = "unique=" + uni + "&csrf=" + csrf;

      // Post
      $.ajax({
        type: "POST",
        url: "/source/web/actions/calendar/calendar-event-attn-list-refresh.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);

          // OK
          if (obj.meta.code === 200) {
            // Notify
            notificator.show("Refreshed", true);

            // Update
            $("#attnlstlinkinp").val(
              exturl + "/event/" + uni + "/attendees/?token=" + obj.data.code
            );
          } else {
            // Notify
            notificator.break();

            // Error
            csrfpop.show();
          }
        },
      });
    },
    attnlstlink: function () {
      // Open
      window.open($("#attnlstlinkinp").val());
    },

    validate: function (f) {
      // Execute
      let execute = true,
        gotofrm = true;

      // Title
      if (!validate.empty(f.title.value)) {
        // Stop
        execute = false;

        // Show error
        setTimeout(function () {
          $("#title").addClass("inperr-t2");
          setTimeout(function () {
            $("#title").removeClass("inperr-t2");
          }, 1000);
        }, 300);

        // Focus field
        f.title.focus();
      } else {
        // Hide
        $("#title_err").hide();
      }

      // Set new edited values - if any
      eventform1.form_values_finish();
      // Get repeat value
      let rer = $(".eventform1 #repeat").val();
      // Get relation (to determine if the event is new or being edited)
      let rel = $(".eventform1 #relation").val();
      // Compare initial form values with edited form values, any difference?
      let edt = eventform1.form_values_compare();
      // If recurring is set to true and the form has been edited, promt for options
      if (rer == "true" && edt && rel > "0") {
        // Show popup
        recurrules.confirmoptshow();
        // Dont execute form
        execute = false;

        // Disable error message / scroll up
        gotofrm = false;

        // Get options submit override
        let ost = $("#eventform1 form").attr("data-repeat-save");

        // Reset form
        $("#eventform1 form").removeAttr("data-repeat-save");

        // Execute form
        if (ost == "true") {
          execute = true;
        }
      }

      // Data
      let dat = $(".eventform1 form").serialize();

      // Post?
      if (execute) {
        // Show loader + disable submit
        $(".eventform1 .save").prop("disabled", true);

        // Notify
        if ($(".eventform1").hasClass("newevent")) {
          notificator.show("Creating event");
        } else {
          notificator.show("Saving event");
        }

        // Post
        $.ajax({
          type: "POST",
          data: dat,
          url: "/source/web/actions/calendar/event-save.php",
          dataType: "json",
          cache: false,
          async: true,
          success: function (data, status, xhr) {
            console.log(data);
            // Parse
            let obj = data;

            // OK
            if (obj.meta.code === 200) {
              // Enable submit
              $(".eventform1 .save").prop("disabled", false);

              // Notify
              if ($(".eventform1").hasClass("newevent")) {
                // Notify
                notificator.show("Event created", true);

                // Go to event
                setTimeout(function () {
                  if (obj.event.gotooverview == "true") {
                    location.href = "/calendars/" + obj.event.uniquekey_cal;
                  } else {
                    location.href =
                      "/calendars/" +
                      obj.event.uniquekey_cal +
                      "/event/" +
                      obj.event.uniquekey +
                      "/view";
                  }
                }, 500);
              } else {
                // Notify
                notificator.show("Event saved", true);

                // Go to event
                setTimeout(function () {
                  if (obj.event.gotooverview == "true") {
                    location.href = "/calendars/" + obj.event.uniquekey_cal;
                  } else {
                    location.href =
                      "/calendars/" +
                      obj.event.uniquekey_cal +
                      "/event/" +
                      obj.event.uniquekey +
                      "/view";
                  }
                }, 500);
              }
            } else if (obj.meta.code == "429") {
              // Add a disable
              if (obj.meta.data.type == "add") {
                $("#doc").addClass("exceednotice exceed10");
              } else {
                $("#doc").addClass("exceednotice exceed11");
              }

              $("#account-plnlmt-info").attr(
                "data-current-events",
                obj.meta.data.current_events
              );
              $("#account-plnlmt-info").attr(
                "data-extra-events",
                obj.meta.data.extra_events
              );
              $("#account-plnlmt-info").attr(
                "data-limit-events",
                obj.meta.data.limit_events
              );

              // Run prevents
              plnlmtpop.preventfeatures();

              // Notify
              notificator.break();
            } else {
              // Notify
              notificator.break();
              // Error
              csrfpop.show();
            }
          },
        });
      } else {
        // Show error?
        if (gotofrm) {
          // Show
          $("#warn-insuff").show();

          // Scroll
          $("html, body").animate({ scrollTop: 0 }, "slow", "easeInOutQuart");
        }
      }

      return false;
    },
    neweventlink: function (f) {
      // Goto
      location.href = "/events/new";
    },
    mouseover: function (f) {
      // Different from previous?
      if (f != listover) {
        // Clear
        $(".eventform1list .tab .row").removeClass("over");

        $(f).addClass("over");

        listover = f;
      }
    },
    repeat: function (f) {
      // Get class
      let chk = $("#repeat").val();

      // Get repeat rule value
      let rer = $("#repeat_rule").val();

      // Toogle
      if (chk == "true") {
        // Val
        $("#repeat").val("false");

        // Visible
        $(".eventform1 #repeat-form-exp").hide();
        // Hide template select
        $(".eventform1 .evttmprow").hide();
        $(".eventform1 #template_hidval").val("");
        // Remove
        $(".evtseries .btn").removeClass("selected");
      } else {
        // Any rule?
        if (rer == "") {
          // Show form
          recurrules.show();
        } else {
          // Val
          $("#repeat").val("true");

          // Hidden
          $(".eventform1 #repeat-form-exp").show();

          // Show template select
          $(".eventform1 .evttmprow").show();
          $(".eventform1 #template_hidval").val(
            $(".eventform1 #template_rep").find(":selected").val()
          );

          // Add
          $(".evtseries .btn").addClass("selected");

          // All day enabled?
          if ($("#all_day").val() == "true") {
            $(".picker .time-t1, .picker .time-t2").addClass("disabled");
            $(".picker .time-t1, .picker .time-t2").show();
          }
        }
      }

      // Invoke
      recurrules.repeattype();
    },
    form_values_init: function () {
      // Get data
      let dat = $(".eventform1 form").serialize();

      // Update init values
      frmini = dat;
    },
    form_values_finish: function () {
      // Get data
      let dat = $(".eventform1 form").serialize();

      // Update init values
      frmfin = dat;
    },
    form_values_compare: function () {
      // Equal / not qual
      let equ = false;

      // Compare and return
      if (frmini != frmfin) {
        equ = true;
      }

      return equ;
    },
    templateremember: function (f) {
      // Get selected value
      let etv = $(".eventform1 #template_rep").find(":selected").val();
      // Update form value
      $(".eventform1 #template_hidval").val(etv);

      // Remember template
      cookies.create("evt_tpl_id", etv, 365);
    },
    frmtmpsetcng: function (f) {
      // Get rel
      let rel = $(f).attr("data-rel");

      // Show
      $("#" + rel + "-btn").show();
      $("#" + rel + "-btx").hide();
    },
    frmtmpsetclose: function (f) {
      // Get rel
      let rel = $(f).attr("data-rel");

      // Show
      $("#" + rel + "-btn").hide();
      $("#" + rel + "-btx").show();
    },
    frmtmpsetsave: function (f) {
      // Get variables
      let rel = $(f).attr("data-rel");

      // Get selected
      let val = $("#" + rel)
        .find(":selected")
        .val();
      let cal = $("#" + rel).attr("data-cal");
      let csrf = $("#" + rel).attr("data-csrf");
      let typ = $("#" + rel).attr("data-type");

      // Notify
      notificator.show("Saving");

      // Set data
      let dat =
        "cal=" + cal + "&template=" + val + "&type=" + typ + "&csrf=" + csrf;

      // Post
      $.ajax({
        type: "POST",
        url: "/source/web/actions/calendar/calendar-template-set.php",
        data: dat,
        cache: false,
        dataType: "json",
        success: function (data) {
          // Parse
          const obj = data;

          // OK
          if (obj.meta.code == "200") {
            // Notify
            notificator.show("Saved", true);

            // Hide
            $("#" + rel + "-btn").hide();
            $("#" + rel + "-btx").show();

            // Update link
            if (typ == "event") {
              // Remember
              cookies.create("cal_tmp_evt", val, 365);
              // Update link
              $("#selevttmp-lnk").attr("href", "/templates/events/" + val);
            }
          } else {
            // Notify
            notificator.break();
            // Error
            csrfpop.show();
          }
        },
      });
    },
    sublistinit: function () {
      // Attach
      $("#tab-rsvp-attendees .conlist-t1 .lnk").on("click", function () {
        eventform1.subshow(this);
        return false;
      });
      $("#tab-attendees .conlist-t1 .lnk").on("click", function () {
        eventform1.subshow(this);
        return false;
      });
    },
    subshow: function (f) {
      // Unbind
      $(document).unbind(".attnsubpop");
      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.attnsubpop", function (event) {
          // Hide
          eventform1.subhide();
        });
      }, 200);

      // Get id's
      let user_id = $(f).attr("data-id");
      let evt_id = $(f).attr("data-evt-id");

      // Notify
      notificator.show("Loading user");

      // Set data
      let dat = "user_id=" + user_id + "&evt_id=" + evt_id;

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax_rsvp_attendee_item.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Notify break
          notificator.break();

          // Append
          $("#attnsub-pop").html(data);

          // Show
          $("#attnsub-pop").show();
          $("#attnsub-pop-popbg").show();

          // Get scroll top
          let st = $(document).scrollTop();

          // Update positions
          $("#attnsub-pop").css("top", st + 50 + "px");

          // Timed show
          setTimeout(function () {
            // Show
            $("#attnsub-pop").addClass("show");
            $("#attnsub-pop-bg").addClass("show");
          }, 200);

          // Initialize
          checks.initialize();
          radios.initialize();

          // Get elements
          $("#attnsub-pop select").each(function () {
            // Get default text
            let val = $(this).attr("data-fill");
            // Any predined value?
            if (typeof val !== "undefined" && val !== "") {
              // Update
              $(this).val(val);
            }
          });

          // Get radios
          $("#attnsub-pop .input-multiple-radios").each(function () {
            // Get value
            let val = $(this).find(".radiovalue").attr("data-fill");

            // Find radious
            $(this)
              .find(".radio")
              .each(function () {
                // Get zone
                let rava = $(this).attr("data-value");

                // Match
                if (rava == val) {
                  // Set checked
                  $(this).addClass("checked");
                  $(this).find(".chk input").prop("checked", true);
                }
              });
          });

          // Attach
          $("#attnsub-pop .tab-t1 a").on("click", function () {
            eventform1.subtabset(this);
            return false;
          });
          $("#attnsub-pop .close a").on("click", function () {
            eventform1.subhide(this);
            return false;
          });
          $("#attnsub-pop .cancel").on("click", function () {
            eventform1.subhide(this);
            return false;
          });
          $("#attnsub-pop .input-multiple-radios .radio").on(
            "click",
            function () {
              eventform1.radioclick(this);
              return false;
            }
          );

          // Form
          $("#attnsub-form").on("submit", function () {
            return eventform1.subvalidate(this);
          });

          // Prevent
          $("#attnsub-pop").on("click", function (event) {
            event.stopPropagation();
          });

          // Load map
          eventform1.subinitmap();
        },
      });
    },
    subhide: function (f) {
      // Timed hide
      setTimeout(function () {
        $("#attnsub-pop").hide();
        $("#attnsub-pop-bg").hide();
      }, 300);

      // Hide
      $("#attnsub-pop").removeClass("show");
      $("#attnsub-pop-bg").removeClass("show");

      // Unbind
      $(document).unbind(".attnsubpop");
    },
    subtabset: function (f) {
      // Reset
      $("#attnsub-pop .tab-t1 a").removeClass("active");
      $("#attnsub-pop .tabin").hide();

      // Get relation
      let rel = $(f).attr("data-rel");

      // Show
      $("#" + rel).show();

      // Set active
      $(f).addClass("active");
    },
    radioclick: function (f) {
      // Get value
      let val = $(f).attr("data-value");

      // Reset
      $(f)
        .closest(".input-multiple-radios")
        .find(".radio")
        .removeClass("checked");

      $(f).addClass("checked");

      // Update
      $(f).closest(".input-multiple-radios").find(".radiovalue").val(val);
    },
    subinitmap: function () {
      // Does map exists?
      if ($("#map").length && !gscript) {
        // Get Google maps
        $.getScript(
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyD20yeadofoNcyi7FjOTAl7ne5lpDVHR00",
          function () {
            eventform1.subloadmap();
          }
        );

        gscript = true;
      } else {
        // Load map
        eventform1.subloadmap();
      }
    },
    subloadmap: function () {
      // Set defaults
      let zoom = 5;

      // Does map exists?
      if ($("#map").length) {
        // Get lat/lng
        let clng = $("#map").attr("data-lng");
        let clat = $("#map").attr("data-lat");
        let zlbl = $("#map").attr("data-zoom-level");

        // Any variables?
        if (clng && clng && clng != "" && clat != "") {
          // Initialize location
          let latLng = new google.maps.LatLng(clat, clng);

          // Override zoom level?
          if (typeof zlbl !== "undefined" && zlbl !== "") {
            zoom = parseInt(zlbl);
          }

          // Load map
          map = new google.maps.Map(document.getElementById("map"), {
            center: latLng,
            zoom: zoom,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
          });

          // Define icon
          let mapicon = {
            url: "https://cdn.addevent.com/legacy2000/gfx/icon-map-pin-t1.svg",
            scaledSize: new google.maps.Size(50, 50),
          };

          // Set map icon
          let marker = new google.maps.Marker({
            position: latLng,
            map: map,
            icon: mapicon,
          });
        }
      }
    },
    subvalidate: function (f) {
      // Variables
      let execute = true;
      // Hide
      $("#attnsub-form .warning").hide();
      // Email is mandatory
      if (!validate.email($("#attnsub-form #email").val())) {
        // Execute
        execute = false;
        // Error
        $("#attnsub-form #warn-email").show();
      }

      // Execute
      if (execute) {
        // Remove warnings
        $(".warnings").hide();
        // Get data
        let dat = $("#attnsub-form").serialize();

        // Disable submit
        $("#attnsub-form .save").prop("disabled", true);
        $("#attnsub-form .save").addClass("disabled");

        // Notify
        notificator.show("Saving");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-attendee-save.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);
            // OK
            if (obj.meta.code == "200") {
              // Notify
              notificator.show("Saved", true);
              // Timed hide
              setTimeout(function () {
                // Hide
                eventform1.subhide();
              }, 300);
              // Timed reload
              setTimeout(function () {
                // Refresh search box or just normal list
                if ($("#searchbox").hasClass("show")) {
                  // Resubmit form
                  $("#searchboxfrm").submit();
                } else {
                  // Refresh
                  location.reload();
                }
              }, 1000);
            } else {
              // Error
              alert("Something went wrong. Please try again.");

              // Redirect
              setTimeout(function () {
                // Redirect
                location.reload();
              }, 200);
            }
          },
          error: function () {
            // Error
            alert("An error has occurred. Please try again.");
          },
        });
      }
      return false;
    },
  };
})();

// Ready
$(document).ready(function () {
  eventform1.initialize();
});

/* Calendar form
---------------------------------------------------------------- */
var eventform2 = (function () {
  // Variables
  let syncing = false,
    optscount = 500,
    map,
    gscript = false;
  return {
    initialize: function () {
      // Exists?
      if ($d("eventform2")) {
        // Get visible form fields
        eventform2.formfieldsinit();
        // Initialize form
        eventform2.forminit();
      }

      // Exists?
      if ($d("calendar-view")) {
        // Get visible form fields
        eventform2.calviewinit();
      }

      // Exists?
      if ($d("con-subscribers")) {
        // Subscribers list
        eventform2.sublistinit();
      }
    },
    forminit: function () {
      // Attach
      $("#radio-manage-1, #radio-manage-2").on("click", function () {
        eventform2.managecalendar(this);
      });
      $("#radio-subinfo-1, #radio-subinfo-2").on("click", function () {
        eventform2.managesubinfo(this);
      });

      // Attach (external feed)
      $("#add-external-popup").on("click", function () {
        eventform2.popextshow(this);
        return false;
      });
      $("#external-cals-list .del a").on("click", function () {
        eventform2.extcalendardelete(this);
        return false;
      });
      $("#external-cals-list .sync a").on("click", function () {
        eventform2.extcalendarsync(this);
        return false;
      });
      $("#external-cals-list .chk a").on("click", function () {
        return false;
      });
      $("#external-cals-list .colpck").on("click", function () {
        eventform2.extcolorsshow(this);
        return false;
      });
      $("#external-cals-list .colpck .opts").on("click", function () {
        event.stopPropagation();
      });

      // Loop colors, set standard
      if ($("#external-cals-list .colpck").length) {
        // Copy current colors, apply to external calendars
        $(".paluse").html($("#paluse").html());

        // Attach
        $("#external-cals-list .colpck .opts li").on("click", function () {
          eventform2.extcolorsselect(this);
          return false;
        });

        // Loop colors for each external calendar
        $("#external-cals-list .colpck").each(function () {
          // Get number
          let clpknu = $(this).find(".paluse").attr("data-event-color-number");

          // Set checked
          $(this)
            .find("[data-color-num='" + clpknu + "']")
            .addClass("checked");

          // Get color
          let chcl = $(this).find(".checked").attr("data-color");

          // Set color
          $(this).find(".co").css("background", chcl);
        });
      }

      // Attach
      $(".cusfields .addfield").on("click", function () {
        eventform2.cusfieldsshow(this, { new: "true" });
        return false;
      });
      $(".cusfields .lst-t1 .edit").on("click", function () {
        eventform2.cusfieldsshow(this, { new: "false" });
        return false;
      });
      $(".cusfields .lst-t1 .delete").on("click", function () {
        eventform2.cusfielddelete(this);
        return false;
      });
      $(".eventform2 form").on("submit", function () {
        return eventform2.validate(this);
      });
      // Update selects
      $("#weekday").val($("#weekday").attr("data-selected"));

      // Color picker
      colorpickerxo.colorevtinit();
    },
    calviewinit: function () {
      // Attach
      $("#calendar-view .des-t1 .more").on("click", function () {
        eventform2.truncatedshow(this);
        return false;
      });
      //$('#tab-rsvp-attendees .row').on('click', function(){eventform2.attendeedetailsshow();return false;});
      //$('#tab-rsvp-seats #check-limittoo').on('click', function(){eventform2.rsvpseatslimit();return false;});
      $("#calviewctx").on("click", function () {
        eventform2.calviewmoreshow(this);
        return false;
      });
      $("#calviewctx-mnu a").on("click", function () {
        return eventform2.calviewmoreitem(this);
      });
      $("#calviewctx-mnu").on("click", function (event) {
        event.stopPropagation();
      });

      // Embed examples
      $(".embedbox1 .foot .nav a").on("click", function () {
        eventform2.embedboxset(this);
        return false;
      });
      $(".embedbox1 .foot .codesource").on("click", function () {
        eventform2.embedboxsource(this);
        return false;
      });

      // Template change and set
      $(".frmtmpsetsel").on("change", function () {
        eventform2.frmtmpsetcng(this);
        return false;
      });
      $(".frmtmpsetsave").on("click", function () {
        eventform2.frmtmpsetsave(this);
        return false;
      });
      $(".frmtmpsetclose").on("click", function () {
        eventform2.frmtmpsetclose(this);
        return false;
      });

      // Get hash
      let hsh = window.location.hash;
      // Scroll to share?
      if (hsh == "share" || hsh == "#share") {
        // Get top + opera heights
        let th = $(".top").outerHeight() + $(".opera").outerHeight();
        // Locate top
        let t = $("#anchor-eventpage").offset().top - th - 35;

        // Scroll to top
        setTimeout(function () {
          $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
        }, 500);
      }
    },
    popextshow: function (f) {
      // Popup HTML
      eventform2.popextshowpopuphtml(f);
      // Get variables
      let did = $(f).attr("data-id");
      let dud = $(f).attr("data-uid");
      let upg = $(f).attr("data-upgrade");

      // Upgrade needed?
      if (upg == "true") {
        // Add prevent class to save button
        $("#pop-ext .save").addClass("preventbuttonsave unlk9");
        // Rerun preventer
        plnlmtpop.preventfeatures();
      }

      $("#pop-ext").attr("data-id", did);
      $("#pop-ext").attr("data-uid", dud);
      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#pop-ext").outerHeight();
      let nt = st + wh / 2 - ph / 2;

      // Set popup top
      $("#pop-ext").css("top", nt + "px");

      // Timed
      setTimeout(function () {
        // Show
        $("#pop-ext").addClass("show");
        $("#pop-ext-bg").addClass("show");

        // Focus
        $("#ext-cal-url").focus();
      }, 200);

      // Unbind
      $(document).unbind(".popext");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.popext", function () {
          eventform2.popexthide();
        });
      }, 200);
    },
    popextshowpopuphtml: function (f) {
      // Get object
      let obj = $d("pop-ext");
      // CSRF
      let csrf = $(f).attr("data-csrf");
      // Set variable
      let html = "";

      // .nav-t1 user context
      if (!obj) {
        html += '<div class="popup-t1-bg" id="pop-ext-bg"></div>';
        html += '<div class="popup-t1" id="pop-ext">';
        html += '	<div class="rel">';
        html += '		<div class="opt-t1">';
        html += '			<div class="hed-t1">';
        html += "				<p>Add external calendar</p>";
        html += "			</div>";
        html += '			<div class="close">';
        html += '				<a href="#" title=""><i class="material-icons">close</i></a>';
        html += "			</div>";
        html += '			<div class="des-t4">';
        html += "				<p>";
        html +=
          "					Add the URL to the external calendar you want to import. The calendar must be in a valid iCalendar format (.ics). ";
        html +=
          "					When the URL has been added, the system will run a check to examine if the calendar feed is valid or needs additional information.";
        html += "				</p>";
        html += "			</div>";
        html += '			<div class="warni-wrap-t1 hide" id="external-cals-err1">';
        html += '				<div class="warni-t1 cb18d21">';
        html += "					<p>";
        html +=
          "						The system check did not find any events in the calendar. Please check the URL. ";
        html += "						The calendar must be in a valid iCalendar format (.ics).";
        html += "					</p>";
        html += '					<div class="ico-t1">';
        html += '						<i class="material-icons">error</i>';
        html += "					</div>";
        html += "				</div>";
        html += "			</div>";
        html += '			<div class="inp-t1">';
        html += '				<div class="rp">';
        html +=
          '					<input type="text" name="calendar_url" id="ext-cal-url" value="" placeholder="E.g. https://calendar.google.com/../basic.ics" class="markup" spellcheck="false" data-csrf="' +
          csrf +
          '" />';
        html += '					<div class="ico-t1">';
        html += '						<div class="rl">';
        html += '							<i class="material-icons">event</i>';
        html += "						</div>";
        html += "					</div>";
        html += "				</div>";
        html += "			</div>";
        html += '			<div class="tim-t1" id="external-timezone">';
        html += '				<div class="warni-wrap-t2" id="external-cals-err2">';
        html += '					<div class="warni-t1 ce25e01">';
        html += "						<p>";
        html += "							The system check was not able to detect a time zone.<br />";
        html +=
          "							<strong>Hint:</strong> Where are the events in your calendar located?<br />";
        html += "							Please select a time zone below and save again.";
        html += "						</p>";
        html += '						<div class="ico-t1">';
        html += '							<i class="material-icons">error</i>';
        html += "						</div>";
        html += "					</div>";
        html += "				</div>";
        html += '				<div class="zon-t1">';
        html +=
          '					<input type="hidden" name="calendar_timezone" value="" id="ext-cal-zone" readonly="readonly" placeholder="Timezone" class="poptimezone" />';
        html +=
          '					<div class="btn" data-pop-rel="adrlook-add-cal" data-cls-rel="poptimezone">';
        html += '						<div class="lbl">';
        html += "							Select time zone..";
        html += "						</div>";
        html += '						<div class="arr">';
        html += '							<i class="material-icons">expand_more</i>';
        html += "						</div>";
        html += "					</div>";
        html += '					<div class="ico-t1">';
        html += '						<div class="rl">';
        html += '							<i class="material-icons">language</i>';
        html += "						</div>";
        html += "					</div>";
        html += "				</div>";
        html += "			</div>";
        html += "		</div>";
        html += '		<div class="opt-t4 popact">';
        html += '			<div class="fl">';
        html += '				<div class="load"></div>';
        html += '				<div class="btn">';
        html +=
          '					<input type="button" value="Save" class="save button-t1 b2086bf" data-label-default="Save" data-label-busy="Analyzing.." />';
        html += "				</div>";
        html += '				<div class="btn">';
        html +=
          '					<input type="button" value="Cancel" class="cancel button-t1 bffffff" />';
        html += "				</div>";
        html += "			</div>";
        html += "		</div>";
        html += "	</div>";
        html += "</div>";

        // Append
        $("body").append(html);

        // Attach
        $("#pop-ext .close a").on("click", function () {
          eventform2.popexthide(this);
          return false;
        });
        $("#pop-ext").on("click", function (event) {
          event.stopPropagation();
          addresslookup.hide();
        });
        $("#pop-ext .cancel").on("click", function () {
          eventform2.popexthide(this);
        });
        $("#pop-ext .save").on("click", function () {
          eventform2.extcalendarsave(this);
        });
        $("#external-timezone .btn").on("click", function () {
          addresslookup.show(this, "exttzid");
          return false;
        });
      }
    },
    popexthide: function (f) {
      // Show
      $("#pop-ext").removeClass("show");
      $("#pop-ext-bg").removeClass("show");
      // Get label
      let lbl = $("#pop-ext .save").attr("data-label-default");
      // Error hide
      $("#ext-cal-url-err").hide();
      // Set default
      $("#pop-ext .popact .save").val(lbl);
      // Disable
      $("#pop-ext .popact .save").prop("disabled", false);
      // Show loader
      $("#pop-ext .popact .load").hide();
      // Reset
      $("#ext-cal-url").val("");
      // Hide errors
      $("#external-cals-err1").hide();
      $("#external-cals-err2").hide();
      // Hide timezone input
      $("#external-timezone").hide();
      // Hide
      addresslookup.hide();
      // Unbind
      $(document).unbind(".popext");
    },
    extloadlist: function (saved) {
      // Exists?
      if ($d("external-cals-list")) {
        // CSRF
        let csrf = $("#csrf").val();

        // Get calendar relation
        let dat = $(".eventform2 #relation").serialize() + "&csrf=" + csrf;

        // Reload calendars
        $.ajax({
          type: "POST",
          url: "/source/web/templates/ajax_calendar_sync_list.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Update
            $("#external-cals-list").html(data);

            // Show saved
            if (saved) {
              notificator.show("Saved", true);
            }

            // Attach
            $("#external-cals-list .del a").on("click", function () {
              eventform2.extcalendardelete(this);
              return false;
            });
            $("#external-cals-list .sync a").on("click", function () {
              eventform2.extcalendarsync(this);
              return false;
            });
            $("#external-cals-list .chk a").on("click", function () {
              return false;
            });
            $("#external-cals-list .colpck").on("click", function () {
              eventform2.extcolorsshow(this);
              return false;
            });
            $("#external-cals-list .colpck .opts").on("click", function () {
              event.stopPropagation();
            });

            // Loop colors, set standard
            if ($("#external-cals-list .colpck").length) {
              // Copy current colors, apply to external calendars
              $(".paluse").html($("#paluse").html());

              // Attach
              $("#external-cals-list .colpck .opts li").on(
                "click",
                function () {
                  eventform2.extcolorsselect(this);
                  return false;
                }
              );

              // Loop colors for each external calendar
              $("#external-cals-list .colpck").each(function () {
                // Get number
                let clpknu = $(this)
                  .find(".paluse")
                  .attr("data-event-color-number");

                // Set checked
                $(this)
                  .find("[data-color-num='" + clpknu + "']")
                  .addClass("checked");

                // Get color
                let chcl = $(this).find(".checked").attr("data-color");

                // Set color
                $(this).find(".co").css("background", chcl);
              });
            }

            // Tooltip
            tooltip.initialize();
            // Saved and reloaded
            if (saved) {
              // Locate top
              let t = $("#external-cals-list").offset().top - 200;

              // Scroll to top
              $("html, body").animate(
                { scrollTop: t },
                "slow",
                "easeInOutQuart"
              );

              // Notify (break)
              notificator.break();
            } else {
              // Notify (break)
              notificator.break();
            }
          },
        });
      }
    },
    extcalendarpaletteupdate: function (f, col) {
      // Update palette(s)
      $("#external-cals-list .paluse").html(col);

      // Attach
      $("#external-cals-list .colpck .opts li").on("click", function () {
        eventform2.extcolorsselect(this);
        return false;
      });

      // Collect user access
      $("#external-cals-list .paluse").each(function () {
        // Get color number
        let ecn = $(this).attr("data-event-color-number");
        // Set counter
        let ecnc = 0;

        // Collect user access
        $(this)
          .find("li")
          .each(function () {
            // Add
            ecnc++;

            // Match in selected color number
            if (ecnc == ecn) {
              // Get the background color (if any)
              let ecbg = $(this).attr("data-color");

              // Get parent color picker
              let par = $(this).closest(".colpck");

              // Update color
              if (ecbg == "") {
                $(par)
                  .find(".co")
                  .css(
                    "background",
                    "url(https://cdn.addevent.com/legacy2000/gfx/back-trans-t1.png) no-repeat 50% 50%"
                  );
              } else {
                $(par).find(".co").css("background-color", ecbg);
              }

              // Set background size
              $(par).find(".co").css("background-size", "24px auto");

              // Set checked
              $(par).find(".co").addClass("checked");
            }
          });
      });
    },
    extcalendardelete: function (f) {
      // Confirm
      let con = confirm(
        "Are you sure you want to delete the external calendar?"
      );

      // Delete
      if (con) {
        // Notify
        notificator.show("Deleting feed");
        // Get id
        let fid = $(f).attr("data-id");
        // CSRF
        let csrf = $(f).attr("data-csrf");
        // Data
        let dat = "id=" + fid + "&csrf=" + csrf;
        // Reload calendars
        $.ajax({
          type: "POST",
          url: "/source/web/actions/sync/sync-delete.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);
            // OK
            if (obj.meta.code == "200") {
              // Load list
              eventform2.extloadlist();
              // Notify
              notificator.show("Deleted");
            } else {
              // Notify
              notificator.break();
              // Error
              csrfpop.show();
            }
          },
        });
      }
    },
    extcolorsshow: function (f) {
      // Hide tooltip
      tooltip.hide();

      // Remove tooltip
      $(f).removeClass("tip tipset");

      // Unbind
      $(f).unbind("mouseover");

      // Set title
      $(f).attr("title", $(f).attr("data-title"));

      // Unbind
      $(document).unbind(".extcolorpop");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.extcolorpop", function (event) {
          // Hide
          eventform2.extcolorshide();
        });
      }, 200);

      // Clear all
      $(".colpck").removeClass("active");

      // Show
      $(f).show();

      // Show
      setTimeout(function () {
        // Set active
        $(f).addClass("active");
      }, 200);
    },
    extcolorshide: function () {
      // Clear all
      $(".colpck").removeClass("active");

      // Unbind
      $(document).unbind(".extcolorpop");
    },
    extcolorsselect: function (f) {
      // Get color
      let col = $(f).attr("data-color");
      let cln = $(f).attr("data-color-num");

      // Get base
      let bas = $(f).closest(".colpck");

      // Get ID + CSRF
      let cid = $(bas).attr("data-id");
      let csrf = $(bas).attr("data-csrf");

      // Update color
      $(bas).find(".co").css("background", col);
      $(bas).find(".opts .paluse").attr("data-event-color-number", cln);

      // Clear all
      $(bas).find(".paluse li").removeClass("checked");

      // Set checked
      $(f).addClass("checked");

      // Hide
      eventform2.extcolorshide();

      // Notify
      notificator.show("Updating");

      // Set data
      let dat = "id=" + cid + "&color=" + cln + "&csrf=" + csrf;

      // Save
      $.ajax({
        type: "POST",
        url: "/source/web/actions/sync/sync-color.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);

          // OK
          if (obj.meta.code == "200") {
            // Load list
            eventform2.extloadlist();

            // Notify
            notificator.show("Updated", true);
          } else {
            // Notify
            notificator.break();

            // Error
            csrfpop.show();
          }
        },
      });
    },
    extcalendarsync: function (f) {
      // Show loader
      $(f).find(".syncing").show();
      // Hide tooltip
      tooltip.hide();

      // Syncing?
      if (!syncing) {
        // Get id
        let fid = $(f).attr("data-id");
        let uid = $(f).attr("data-uid");
        // Syncing
        syncing = true;
        // Set data
        let dat = "xsync_id=" + fid + "&xsync_rel=" + uid;
        // Reload calendars
        $.ajax({
          type: "POST",
          url: "/source/syncs/sync-calendar.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Load list
            eventform2.extloadlist();
            // Syncing
            syncing = false;
            // Alert
            setTimeout(function () {
              alert("Calendar has been synced..");
            }, 500);
          },
        });
      }
    },
    extcalendarsave: function () {
      // Execute
      let execute = true;
      // Get value
      let val = $("#ext-cal-url").val();
      let tzi = $("#ext-cal-zone").val();
      let csrf = $("#csrf").val();

      // Timezone needed?
      let dis = $("#external-timezone").css("display");

      // Validate
      if (!validate.empty(val)) {
        // Execute
        execute = false;
      }

      // Timezone needed?
      if (dis == "block" && tzi == "") {
        // Execute
        execute = false;
      }

      // Execute?
      if (!execute) {
        // Error
        $("#ext-cal-url-err").show();
      } else {
        // Get label
        let lbb = $("#pop-ext .save").attr("data-label-busy");
        // OK
        $("#ext-cal-url-err").hide();
        // Set analyzing
        $("#pop-ext .popact .save").val(lbb);
        // Disable
        $("#pop-ext .popact .save").prop("disabled", true);
        // Show loader
        $("#pop-ext .popact .load").show();
        // Set data variable
        let dat;
        // Get data
        if (dis == "block") {
          dat = $(
            "#ext-cal-url, #ext-cal-zone, .eventform2 #relation, .eventform2 #csrf"
          ).serialize();
        } else {
          dat = $(
            "#ext-cal-url, .eventform2 #relation, .eventform2 #csrf"
          ).serialize();
        }

        // Notify
        notificator.show("Analyzing feed");

        // Post
        $.ajax({
          type: "POST",
          url: "/source/web/actions/sync/sync-check.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);
            let lbl = null;
            // OK
            if (obj.meta.code == "200") {
              // Invalid calendar
              if (obj.calendar.valid == "false") {
                // Notify (break)
                notificator.break();
                // Show error
                $("#external-cals-err1").show();
                $("#external-cals-err2").hide();
                // Hide loader
                $("#pop-ext .popact .load").hide();
                // Get label
                lbl = $("#pop-ext .save").attr("data-label-default");
                // Set default
                $("#pop-ext .popact .save").val(lbl);
                // Enable
                $("#pop-ext .popact .save").prop("disabled", false);
              } else {
                // Valid calendar but no timezone
                if (
                  obj.calendar.valid == "true" &&
                  obj.calendar.timezone == ""
                ) {
                  // Notify (break)
                  notificator.break();
                  // Show error
                  $("#external-cals-err1").hide();
                  $("#external-cals-err2").show();
                  // Show timezone picker
                  $("#external-timezone").show();
                  // Hide loader
                  $("#pop-ext .popact .load").hide();

                  // Get label
                  lbl = $("#pop-ext .save").attr("data-label-default");

                  // Set default
                  $("#pop-ext .popact .save").val(lbl);

                  // Enable
                  $("#pop-ext .popact .save").prop("disabled", false);
                } else if (obj.calendar.saved == "true") {
                  // Notify (break)
                  notificator.show("Saving feed");
                  // Hide
                  eventform2.popexthide(this);
                  // Get calendar relation
                  let dat = $(".eventform2 #relation").serialize();

                  // Reload calendars
                  $.ajax({
                    type: "POST",
                    url: "/source/web/templates/ajax_calendar_sync_list.php",
                    data: dat,
                    cache: false,
                    success: function (data) {
                      // Get userid
                      let uid = $("#pop-ext").attr("data-uid");

                      // Set data
                      let dat =
                        "xsync_id=" + obj.calendar.xsync + "&xsync_rel=" + uid;

                      // Reload calendars
                      $.ajax({
                        type: "POST",
                        url: "/source/syncs/sync-calendar.php",
                        data: dat,
                        cache: false,
                        success: function (data) {
                          // Load list
                          eventform2.extloadlist(true);
                        },
                      });
                    },
                  });
                }
              }
            } else {
              // Fatal error
              alert(
                "An error has occurred while analyzing the URL. Please check the URL.."
              );
            }
          },
        });
      }
    },

    embedboxset: function (f) {
      // Reset
      $(f).closest(".embedbox1").find(".foot a").removeClass("active");
      $(f).closest(".embedbox1").find(".demo-t1").removeClass("show hide");
      // Slide out
      $(f).closest(".embedbox1").find(".demo-t1").addClass("noani");
      // Get relation
      let rel = $(f).attr("data-rel");

      // Remove slide out from next to display object
      $("#" + rel).removeClass("noani");

      // Copy buttons
      $(f).closest(".embedbox1").find(".codcop").hide();
      $("#" + rel + "-copy").show();

      // Show
      setTimeout(function () {
        $("#" + rel).addClass("show");
      }, 200);

      // Set active
      $(f).addClass("active");
    },
    embedboxsource: function (f) {
      // Get active item
      let obj = $(f).closest(".embedbox1").find(".nav .active");
      // Get relation
      let rel = $(obj).attr("data-rel");
      // Content
      let con = "";

      // Get content
      if (!$("#" + rel).find(".codecopyarea textarea").length) {
        con = $("#" + rel)
          .find(".table .cell")
          .html();
      } else {
        con = $("#" + rel)
          .find(".codecopyarea textarea")
          .val();
      }

      // Popup HTML
      eventform2.copycodepopuphtml();
      // Set html
      $("#codecopy-pop-code").text(con);
      // Show popup
      eventform2.codecopyshow();
    },
    copycodepopuphtml: function () {
      // Get object
      let obj = $d("codecopy-pop");
      // Set variable
      let html = "";
      // .nav-t1 user context
      if (!obj) {
        html += '<div class="popup-t2-bg" id="codecopy-pop-bg"></div>';
        html += '<div class="popup-t2" id="codecopy-pop">';
        html += '    <div class="rel">';
        html += '        <div class="opt-t1">';
        html += '            <div class="hed-t1">';
        html += "                <p>Source code <em>(for developers)</em></p>";
        html += "            </div>";
        html += '            <div class="close">';
        html +=
          '                <a href="#" title=""><i class="material-icons">close</i></a>';
        html += "           </div>";
        html += '            <div class="des-t1">';
        html +=
          "                <p>Copy the code below and paste it in the HTML source of your email, newsletter <br />or campaign. Fully customizable, restyle it to fit your needs.</p>";
        html += "            </div>";
        html +=
          '            <div class="code-copy" id="codecopy-pop-code"></div>';
        html += "        </div>";
        html += "    </div>";
        html += "</div>";

        // Append
        $("body").append(html);

        // Attach
        $("#codecopy-pop .close a").on("click", function () {
          eventform2.codecopyhide(this);
          return false;
        });
        $("#codecopy-pop").on("click", function (event) {
          event.stopPropagation();
        });
        $("#codecopy-pop-code").on("click", function () {
          codemarkup.selectable(this);
          return false;
        });
      }
    },
    codecopyshow: function () {
      // Unbind
      $(document).unbind(".codecopy");
      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.codecopy", function (event) {
          // Hide
          eventform2.codecopyhide();
        });
      }, 200);

      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#codecopy-pop").outerHeight();
      let nt = st + wh / 2 - ph / 2;
      // Set popup top
      $("#codecopy-pop").css("top", nt + "px");
      // Show
      setTimeout(function () {
        $("#codecopy-pop").addClass("show");
        $("#codecopy-pop-bg").addClass("show");
      }, 200);
    },
    codecopyhide: function () {
      // Show
      $("#codecopy-pop").removeClass("show");
      $("#codecopy-pop-bg").removeClass("show");
      // Unbind
      $(document).unbind(".codecopy");
    },
    truncatedshow: function (f) {
      // Show / Hide
      $(f).closest(".descctn").find(".smalltext").hide();
      $(f).closest(".descctn").find(".truncated").hide();
      $(f).closest(".descctn").find(".fulltext").show();
    },
    formfieldsshow: function (f) {
      // Get element
      let obj = $(f);
      // Get active class
      let act = $(obj).hasClass("show");
      // Get settings
      let ctx = $(obj).attr("data-context");
      // Active? (show / hide)
      if (act) {
        // Hide
        eventform2.formfieldshide();
      } else {
        // Set active
        $(obj).addClass("active");
        // Show
        $("#" + ctx).addClass("show");
        // Unbind
        $(document).unbind(".evtfrmopts");
        // Timed
        setTimeout(function () {
          // Bind
          $(document).on("click.evtfrmopts", function () {
            eventform2.formfieldshide();
          });
        }, 200);
      }
    },
    formfieldshide: function () {
      // Remove active
      $(".form-t1-add .btn").removeClass("show");

      // Hide context menu
      $(".form-t1-add .context-t1").removeClass("show");

      // Unbind
      $(document).unbind(".evtfrmopts");
    },
    formfieldstoogle: function (f) {
      // Get relation
      let rel = $(f).attr("data-rel");
      // Object
      let obj = $(".eventform2 ." + rel);
      // Is the field visible?
      let fld = $(obj).css("display");

      // Toogle field
      if (fld == "none") {
        // Set drop
        $(f).addClass("checked");
        // Show
        $(obj).show();
        // Remember
        cookies.create(rel, "true", 365);
      } else {
        // Set drop
        $(f).removeClass("checked");

        // Hide
        $(obj).hide();
        // Remember
        cookies.create(rel, "false", 365);
        // Reset input
        $(".eventform2 ." + rel + " input").val("");
        // Get select type
        let rid = $(".eventform2 ." + rel + " select").attr("id");

        // Reset select
        if (rid == "template") {
          $(".eventform2 ." + rel + " select").val("");
        } else {
          $(".eventform2 ." + rel + " select").val("0");
        }
      }
    },
    formfieldsinit: function () {
      // Append
      $(".form-t1-add .btn").on("click", function () {
        eventform2.formfieldsshow(this);
        return false;
      });
      $(".form-t1-add .context-t1 a").on("click", function () {
        eventform2.formfieldstoogle(this);
        return false;
      });
      // New event?
      let ncls = $(".eventform2").hasClass("newcalendar");
      // Get states
      let nam = $(".eventform2 .fldname").css("display");
      // Cookies
      let nam_cook = cookies.read("fldname");
      // Name
      if (nam == "block" || (nam_cook == "true" && ncls)) {
        // Check
        $("#formfieldsctx #ffname").addClass("checked");
        // Show
        $(".eventform2 .fldname").show();
      }
    },
    calviewmoreshow: function (f) {
      // Show
      $("#calviewctx").addClass("active");
      $("#calviewctx-mnu").addClass("show");
      // Unbind
      $(document).unbind(".calviewmore");
      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.calviewmore", function () {
          eventform2.calviewmorehide();
        });
      }, 200);
    },
    calviewmorehide: function (f) {
      // Hide
      $("#calviewctx").removeClass("active");
      $("#calviewctx-mnu").removeClass("show");

      // Unbind
      $(document).unbind(".calviewmore");
    },
    calviewmoreitem: function (f) {
      let con = null;
      let dat = null;
      // Action if not disabled
      if (!$(f).hasClass("disabled")) {
        // Get type + unique
        let typ = $(f).attr("data-type");
        let cal = $(f).attr("data-calendar");
        let uni = $(f).attr("data-unique");

        // Toggle
        if (typ == "view") {
          // Hide context menu
          eventform2.calviewmorehide();
          return true;
        } else if (typ == "edit") {
          // Hide context menu
          eventform2.calviewmorehide();
          // Location
          location.href = "/calendars/" + cal + "/edit";

          return false;
        } else if (typ == "copy") {
          // Hide context menu
          eventform2.calviewmorehide();

          // Get details
          let csrf = $(f).attr("data-csrf");
          // Notify
          notificator.show("Duplicating calendar");
          // Set data
          dat = "cal=" + cal + "&csrf=" + csrf;

          // Post
          $.ajax({
            type: "POST",
            url: "/source/web/actions/calendar/calendar-copy.php",
            data: dat,
            cache: false,
            success: function (data) {
              // Parse
              const obj = parseResponse(data);

              // OK
              if (obj.meta.code == "200") {
                // Notify
                notificator.show("Duplicated", true);
                // Go to event edit
                setTimeout(function () {
                  location.href =
                    "/calendars/" + obj.calendar.uniquekey + "/edit";
                }, 500);
              } else if (obj.meta.code == "429") {
                // Add a disable
                if (obj.meta.data.type == "calendar") {
                  $("#calviewctx").addClass("exceednotice exceed1");
                } else {
                  $("#calviewctx").addClass("exceednotice exceed9");
                }

                $("#account-plnlmt-info").attr(
                  "data-current-events",
                  obj.meta.data.current_events
                );
                $("#account-plnlmt-info").attr(
                  "data-extra-events",
                  obj.meta.data.extra_events
                );
                $("#account-plnlmt-info").attr(
                  "data-limit-events",
                  obj.meta.data.limit_events
                );

                // Run prevents
                plnlmtpop.preventfeatures();

                // Notify
                notificator.break();
              } else {
                // Notify
                notificator.break();
                // Error
                csrfpop.show();
              }
            },
          });

          return false;
        } else if (typ == "delete") {
          // Hide context menu
          eventform2.calviewmorehide();
          // Get details
          let csrf = $(f).attr("data-csrf");
          // Confirm
          con = confirm("Are you sure you want to delete the calendar?");

          // Delete
          if (con) {
            // Notify
            notificator.show("Deleting calendar");

            // Set data
            dat = "cal=" + cal + "&csrf=" + csrf;

            // Post
            $.ajax({
              type: "POST",
              url: "/source/web/actions/calendar/calendar-delete.php",
              data: dat,
              cache: false,
              success: function (data) {
                // Parse
                const obj = parseResponse(data);

                // OK
                if (obj.meta.code == "200") {
                  // Notify
                  notificator.show("Deleted", true);

                  // Go to event edit
                  setTimeout(function () {
                    location.href = "/calendars";
                  }, 500);
                } else {
                  // Notify
                  notificator.break();
                  // Error
                  csrfpop.show();
                }
              },
            });
          }

          return false;
        } else if (typ == "primary") {
          // Hide context menu
          eventform2.calviewmorehide();
          // Get details
          let csrf = $(f).attr("data-csrf");
          // Confirm
          con = confirm(
            "Are you sure you want to make this calendar your primary calendar?"
          );
          // Delete
          if (con) {
            // Notify
            notificator.show("Changing..");
            // Set data
            dat = "cal=" + cal + "&csrf=" + csrf;
            // Post
            $.ajax({
              type: "POST",
              url: "/source/web/actions/calendar/calendar-primary.php",
              data: dat,
              cache: false,
              success: function (data) {
                // Parse
                const obj = parseResponse(data);

                // OK
                if (obj.meta.code == "200") {
                  // Notify
                  notificator.show("Changed", true);

                  // Go to event edit
                  setTimeout(function () {
                    location.reload();
                  }, 500);
                } else {
                  // Notify
                  notificator.break();

                  // Error
                  csrfpop.show();
                }
              },
            });
          }

          return false;
        }
      }

      return false;
    },
    validate: function (f) {
      // Execute
      let execute = true,
        gotofrm = true;

      // Title
      if (!validate.empty(f.title.value)) {
        // Stop
        execute = false;

        // Show error
        setTimeout(function () {
          $("#title").addClass("inperr-t2");
          setTimeout(function () {
            $("#title").removeClass("inperr-t2");
          }, 500);
        }, 300);

        // Focus field
        f.title.focus();
      }

      // Set to blank
      let umu = "";

      // Collect user access
      $("#eventform2 #manage-calendar-users .check-t1").each(function () {
        // Get ID
        let fid = $(this).attr("data-id");

        // Selected?
        if ($(this).hasClass("selected")) {
          // Collect
          umu = umu + fid + ",";
        }
      });

      // Update value
      $("#eventform2 .usermanageuser").val(umu);

      // Set to blank
      let usf = "",
        usfj = "";

      // Collect user access
      $("#eventform2 #radio-scbrs .sortoption").each(function () {
        // Get ID
        let fid = $(this).attr("data-id");

        // Checked variable
        let chk = 0;

        // Selected?
        if ($(this).find(".check-t1").hasClass("selected")) {
          chk = 1;
        }

        // Collect
        usf = usf + fid + ",";
        usfj = usfj + fid + ":" + chk + ",";
      });

      // Update values
      $("#eventform2 .subinfofields").val(usf);
      $("#eventform2 .subinfofieldsarr").val(usfj);

      // Get relation (to determine if the calendar is new or being edited)
      let rel = $(".eventform2 #relation").val();

      // Data
      let dat = $(".eventform2 form").serialize();

      // Post?
      if (execute) {
        // Show loader + disable submit
        $(".eventform2 .save").prop("disabled", true);
        $(".eventform2 .save").addClass("disabled");

        // Notify
        if (rel > "0") {
          notificator.show("Saving calendar");
        } else {
          notificator.show("Creating calendar");
        }

        // Post
        $.ajax({
          type: "POST",
          data: dat,
          url: "/source/web/actions/calendar/calendar-save.php",
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Enable
              $(".eventform2 .save").prop("disabled", false);
              $(".eventform2 .save").removeClass("disabled");

              // Notify
              if (rel > "0") {
                // Notify
                notificator.show("Calendar saved", true);

                // Timed
                setTimeout(function () {
                  // Go to view
                  //location.href = $('#doc').attr('data-ref');
                  location.href =
                    "/calendars/" + obj.calendar.uniquekey + "/view";
                }, 500);
              } else {
                // Notify
                notificator.show("Calendar created", true);

                // Timed
                setTimeout(function () {
                  // Go to view
                  location.href = "/calendars/" + obj.calendar.uniquekey;
                }, 500);
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
        // Show error?
        if (gotofrm) {
          // Scroll
          $("html, body").animate({ scrollTop: 0 }, "slow", "easeInOutQuart");
        }
      }

      return false;
    },
    managecalendar: function (f) {
      // Get value
      let val = $(f).attr("data-value");

      if (val == "1") {
        // Show
        $("#manage-calendar-users").addClass("disabled");
      } else {
        // Show
        $("#manage-calendar-users").removeClass("disabled");
      }

      // Form value
      $(".eventform2 .usermanage").val(val);
    },
    managesubinfo: function (f) {
      // Get value
      let val = $(f).attr("data-value");

      if (val == "1") {
        // Show
        $("#customfield-sortable").addClass("disabled");
      } else {
        // Show
        $("#customfield-sortable").removeClass("disabled");
      }

      // Form value
      $(".eventform2 .subinfo").val(val);
    },
    cusfieldsshow: function (f, opts) {
      // Get relation if any
      let rel = $(f).attr("data-rel");

      // New?
      if (opts.new == "true") {
        // Remove relation
        rel = "";

        // Notify
        notificator.show("Loading editor");
      } else {
        // Notify
        notificator.show("Loading editor");
      }

      // Set data
      let dat = "id=" + rel;

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax_events_me_customfields.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Notify
          notificator.break();

          // Append
          $("#cusfields-pop").html(data);

          // Get select default value
          let sdv = $("#cusfield-datatype").attr("data-default");

          $("#cusfield-datatype").val(sdv);

          // Attach
          $("#cusfields-pop .cancel").on("click", function () {
            eventform2.cusfieldshide(this);
            return false;
          });
          $("#cusfields-pop .datatype").on("change", function () {
            eventform2.cusfieldchange(this);
          });
          $("#cusfields-pop .addoption").on("click", function () {
            eventform2.cusoptionadd(this);
          });
          $("#cusfields-form").on("submit", function () {
            return eventform2.cusvalidate(this);
          });
          $("#cusfields-pop").on("click", function (event) {
            event.stopPropagation();
          });

          // Keypress on add option
          $("#cusfield-option-text").keydown(function (event) {
            // If Tabulator or Enter
            if (event.which == "9" || event.which == "13") {
              // Trigger
              eventform2.cusoptionadd();

              // Prevent
              event.preventDefault();
            }
          });

          // Attach events
          eventform2.cusoptionsattach();

          // Sort
          sortables.initialize();

          // Unbind
          $(document).unbind(".cusfieldswin");

          // Timed
          setTimeout(function () {
            // Bind
            $(document).on("click.cusfieldswin", function (event) {
              // Hide
              eventform2.cusfieldshide();
            });
          }, 200);

          // Get dimensions
          let wh = $(window).height();
          let st = $(document).scrollTop();
          let ph = $("#cusfields-pop").height();
          let pw = $("#cusfields-pop").width();

          // New top
          let nt = st + (wh / 2 - ph / 2);

          // Adjust to minimum
          if (nt < 30) {
            nt = 30;
          }

          // Update positions
          $("#cusfields-pop").css("top", nt + "px");

          // Timed show
          setTimeout(function () {
            // Show
            $("#cusfields-pop").addClass("show");
            $("#cusfields-pop-bg").addClass("show");

            // New?
            if (opts.new == "true") {
              // Reset
              $("#cusfield-title").val("");
              $("#cusfield-datatype").val("text");
              $("#cusfields-pop-opts").hide();
              $("#cusfields-pop-opts .opttext").val("");

              // Focus
              $("#cusfield-title").focus();
            }
          }, 200);
        },
      });
    },
    cusfieldshide: function () {
      // Hide
      $("#cusfields-pop-bg").removeClass("show");
      $("#cusfields-pop").removeClass("show");

      // Unbind
      $(document).unbind(".cusfieldswin");
    },
    cusfieldchange: function (f) {
      // Get value
      let val = $(f).val();

      // Toogle (multiple)
      if (val == "select" || val == "radios") {
        // Show
        $("#cusfields-pop-opts").show();
      } else {
        // Hide
        $("#cusfields-pop-opts").hide();
      }

      // Toogle (querystring parameters)
      if (val == "querystring_hidden" || val == "querystring_text") {
        $("#cusfields-pop-querystring").show();
      } else {
        $("#cusfields-pop-querystring").hide();
      }

      // Adjust
      //eventform2.cusadjustwin();
    },
    cusfielddelete: function (f) {
      // Allowed?
      if (!$(f).hasClass("disabled")) {
        // Confirm
        let con = confirm(
          "Are you sure you want to remove the field? \n\nAll associated data in your calendar will be removed.\nPlease note: This process cannot be undone."
        );

        // Delete
        if (con) {
          // Get id
          let fid = $(f).attr("data-id");

          // Notify
          notificator.show("Field removed", true);

          // Remove
          $("#customfield-" + fid).remove();
        }
      }
    },
    cusvalidate: function (f) {
      // Variables
      let execute = true,
        optionscount = 0;

      // Hide warnings
      $(".warnings").hide();

      // Get option value
      let opt = $("#cusfield-datatype").val();

      // Field text
      let tit = $("#cusfield-title").val();

      // Option type
      if (opt == "select" || opt == "radios") {
        // Count the number of options (max 15)
        $("#cusfield-options .fieldoptionval").each(function () {
          // Get text values
          let txvl = $(this).find(".opttext").val();

          // Valid?
          if (validate.empty(txvl)) {
            // Add
            optionscount++;
          }
        });

        // None found?
        if (optionscount < 2) {
          execute = false;
        }
      } else if (opt == "querystring_hidden" || opt == "querystring_text") {
        // Any querystring?
        if (!validate.empty(f.querystring.value)) {
          execute = false;
        }
      }

      // Any descriptive text?
      if (!validate.empty(tit)) {
        execute = false;
      }

      // Execute?
      if (execute) {
        // Get data
        let dat = $("#cusfields-form").serialize();

        // Get relation
        let rel = $("#cusfields-relation").val();

        // Disable submit
        $("#cusfields-form .save").prop("disabled", true);
        $("#cusfields-form .save").addClass("disabled");

        // Saving / Creating
        if (rel != "") {
          notificator.show("Saving field");
        } else {
          notificator.show("Creating field");
        }

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/calendar-subscriber-cusfield-save.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Clear
              $("#cusfields-form .save").prop("disabled", false);
              $("#cusfields-form .save").removeClass("disabled");

              // Handle
              if (obj.field.new == "true" || obj.field.new == "false") {
                // Created
                if (obj.field.new == "true") {
                  // Saved
                  notificator.show("Field created", true);
                }

                // Saved
                if (obj.field.new == "false") {
                  // Saved
                  notificator.show("Field saved", true);
                }

                // Hide
                eventform2.cusfieldshide();

                // Get item
                eventform2.cusfieldsload(obj.field.id, obj.field.new);
              } else {
                // General error
                alert("General error. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      } else {
        // Option type
        if (
          (opt == "select" && optionscount < 2) ||
          (opt == "radios" && optionscount < 2)
        ) {
          // Show
          $("#warn-selsuff").show();
        } else if (opt == "querystring_hidden" || opt == "querystring_text") {
          // Show
          $("#warn-quesuff").show();
        } else {
          // Show
          $("#warn-insuff").show();
        }
      }

      return false;
    },
    cusoptionadd: function () {
      // Get text
      let val = $("#cusfield-option-text").val();

      // Variables
      let htout = "",
        optionscount = 0;

      // Count the number of options (max 15)
      $("#cusfield-options .fieldoptionval").each(function () {
        // Add
        optionscount++;
      });

      // Empty?
      if (validate.empty(val) && optionscount < 15) {
        // Add
        optscount++;

        // Html
        htout +=
          '<div class="des-t2 sortoption fieldoptionval nocallback" id="field_opt_n' +
          optscount +
          '">';
        htout += '	<div class="lft">';
        htout += '		<div class="pd">';
        htout +=
          '			<input type="text" name="field_option[]" value="' +
          val +
          '" maxlength="50" placeholder="Option Text" class="opttext" />';
        htout += "		</div>";
        htout += "	</div>";
        htout += '	<div class="rgt">';
        htout += "		<ul>";
        htout +=
          '			<li><a href="#" title="" class="sort" data-rel="field_opt_n' +
          optscount +
          '" tabindex="-1"><i class="material-icons">reorder</i></a></li>';
        htout +=
          '			<li><a href="#" title="" class="delete" data-rel="field_opt_n' +
          optscount +
          '" tabindex="-1"><i class="material-icons">delete</i></a></li>';
        htout += "		</ul>";
        htout += "	</div>";
        htout += '	<div class="clr"></div>';
        htout += "</div>";

        // Append to list
        $("#cusfield-options").append(htout);

        // Reset
        $("#cusfield-option-text").val("");

        // Focus
        $("#cusfield-option-text").focus();

        // Attach events
        eventform2.cusoptionsattach();

        // Toogle adder
        eventform2.cusoptionscounttoogle();

        // Destroy
        //$(".sortable").sortable("destroy");

        // Sortable
        sortables.initialize();
      }
    },
    cusoptionscounttoogle: function () {
      // Variables
      let optionscount = 0;

      // Count the number of options (max 15)
      $("#cusfield-options .fieldoptionval").each(function () {
        // Add
        optionscount++;
      });

      // Show / hide
      if (optionscount >= 15) {
        // Hide
        $("#cusfield-adder").hide();
      } else {
        // Show
        $("#cusfield-adder").show();
      }
    },
    cusoptionremove: function (f) {
      // Get relation
      let rel = $(f).attr("data-rel");

      // Remove
      $("#" + rel).remove();

      // Toogle adder
      eventform2.cusoptionscounttoogle();

      // Destroy
      //$(".sortable").sortable("destroy");

      // Sortable
      sortables.initialize();
    },
    cusoptionsattach: function () {
      // Loop
      $("#cusfield-options .fieldoptionval").each(function () {
        // Get delete
        let delobj = $(this).find(".delete");

        // Already attached?
        let delcls = $(delobj).hasClass("isset");

        // Not set?
        if (!delcls) {
          // Attach
          $(delobj).on("click", function () {
            eventform2.cusoptionremove(this);
            return false;
          });
        }
      });
    },
    cusfieldsload: function (id, few) {
      // Set data
      let dat = "id=" + id;

      // Post
      $.ajax({
        type: "POST",
        url: "/source/web/actions/calendar/calendar-subscriber-cusfield-item.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Append
          if (few == "true") {
            $("#customfield-sortable").append(data);
          } else {
            $("#customfield-" + id).replaceWith(data);
          }

          // Checks
          checks.initialize();

          // Attach
          $("#customfield-" + id + " .edit").on("click", function () {
            eventform2.cusfieldsshow(this, { new: "false" });
            return false;
          });
          $("#customfield-" + id + " .delete").on("click", function () {
            eventform2.cusfielddelete(this);
            return false;
          });
        },
      });
    },
    frmtmpsetcng: function (f) {
      // Get rel
      let rel = $(f).attr("data-rel");

      // Show
      $("#" + rel + "-btn").show();
      $("#" + rel + "-btx").hide();
    },
    frmtmpsetclose: function (f) {
      // Get rel
      let rel = $(f).attr("data-rel");

      // Show
      $("#" + rel + "-btn").hide();
      $("#" + rel + "-btx").show();
    },
    frmtmpsetsave: function (f) {
      // Get variables
      let rel = $(f).attr("data-rel");

      // Get selected
      let val = $("#" + rel)
        .find(":selected")
        .val();
      let cal = $("#" + rel).attr("data-cal");
      let csrf = $("#" + rel).attr("data-csrf");
      let typ = $("#" + rel).attr("data-type");

      // Notify
      notificator.show("Saving");

      // Set data
      let dat =
        "cal=" + cal + "&template=" + val + "&type=" + typ + "&csrf=" + csrf;

      // Post
      $.ajax({
        type: "POST",
        url: "/source/web/actions/calendar/calendar-template-set.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);

          // OK
          if (obj.meta.code == "200") {
            // Notify
            notificator.show("Saved", true);

            // Hide
            $("#" + rel + "-btn").hide();
            $("#" + rel + "-btx").show();

            // Update link
            if (typ == "calendar-embed") {
              // Remember
              cookies.create("cal_tmp_emb", val, 365);

              // Update link
              $("#selcalembtmp-lnk").attr(
                "href",
                "/templates/calendar-embed/" + val
              );
            } else if (typ == "calendar") {
              // Remember
              cookies.create("cal_tmp", val, 365);

              // Update link
              $("#selcaltmp-lnk").attr("href", "/templates/calendar/" + val);
            }
          } else {
            // Notify
            notificator.break();

            // Error
            csrfpop.show();
          }
        },
      });
    },
    sublistinit: function () {
      // Attach
      $("#tab-subscribers .conlist-t1 .lnk").on("click", function () {
        eventform2.subshow(this);
        return false;
      });
    },
    subshow: function (f) {
      // Unbind
      $(document).unbind(".attnsubpop");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.attnsubpop", function (event) {
          // Hide
          eventform2.subhide();
        });
      }, 200);

      // Get id's
      let user_id = $(f).attr("data-id");
      let cal_id = $(f).attr("data-cal-id");

      // Notify
      notificator.show("Loading user");

      // Set data
      let dat = "user_id=" + user_id + "&cal_id=" + cal_id;

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax_subscriber_item.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Notify break
          notificator.break();

          // Append
          $("#attnsub-pop").html(data);

          // Show
          $("#attnsub-pop").show();
          $("#attnsub-pop-popbg").show();

          // Get scroll top
          let st = $(document).scrollTop();

          // Update positions
          $("#attnsub-pop").css("top", st + 50 + "px");

          // Timed show
          setTimeout(function () {
            // Show
            $("#attnsub-pop").addClass("show");
            $("#attnsub-pop-bg").addClass("show");
          }, 200);

          // Initialize
          checks.initialize();
          radios.initialize();

          // Get elements
          $("#attnsub-pop select").each(function () {
            // Get default text
            let val = $(this).attr("data-fill");

            // Any predined value?
            if (typeof val !== "undefined" && val !== "") {
              // Update
              $(this).val(val);
            }
          });

          // Get radios
          $("#attnsub-pop .input-multiple-radios").each(function () {
            // Get value
            let val = $(this).find(".radiovalue").attr("data-fill");

            // Find radious
            $(this)
              .find(".radio")
              .each(function () {
                // Get zone
                let rava = $(this).attr("data-value");

                // Match
                if (rava == val) {
                  // Set checked
                  $(this).addClass("checked");
                  $(this).find(".chk input").prop("checked", true);
                }
              });
          });

          // Attach
          $("#attnsub-pop .tab-t1 a").on("click", function () {
            eventform2.subtabset(this);
            return false;
          });
          $("#attnsub-pop .close a").on("click", function () {
            eventform2.subhide(this);
            return false;
          });
          $("#attnsub-pop .cancel").on("click", function () {
            eventform2.subhide(this);
            return false;
          });
          $("#attnsub-pop .input-multiple-radios .radio").on(
            "click",
            function () {
              eventform2.radioclick(this);
              return false;
            }
          );
          // Form
          $("#attnsub-form").on("submit", function () {
            return eventform2.subvalidate(this);
          });
          // Prevent
          $("#attnsub-pop").on("click", function (event) {
            event.stopPropagation();
          });

          // Load map
          eventform2.subinitmap();
        },
      });
    },
    subhide: function (f) {
      // Timed hide
      setTimeout(function () {
        $("#attnsub-pop").hide();
        $("#attnsub-pop-bg").hide();
      }, 300);

      // Hide
      $("#attnsub-pop").removeClass("show");
      $("#attnsub-pop-bg").removeClass("show");

      // Unbind
      $(document).unbind(".attnsubpop");
    },
    subtabset: function (f) {
      // Reset
      $("#attnsub-pop .tab-t1 a").removeClass("active");
      $("#attnsub-pop .tabin").hide();

      // Get relation
      let rel = $(f).attr("data-rel");

      // Show
      $("#" + rel).show();

      // Set active
      $(f).addClass("active");
    },
    radioclick: function (f) {
      // Get value
      let val = $(f).attr("data-value");

      // Reset
      $(f)
        .closest(".input-multiple-radios")
        .find(".radio")
        .removeClass("checked");

      $(f).addClass("checked");

      // Update
      $(f).closest(".input-multiple-radios").find(".radiovalue").val(val);
    },
    subinitmap: function () {
      // Does map exists?
      if ($("#map").length && !gscript) {
        // Get Google maps
        $.getScript(
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyD20yeadofoNcyi7FjOTAl7ne5lpDVHR00",
          function () {
            eventform2.subloadmap();
          }
        );

        gscript = true;
      } else {
        // Load map
        eventform2.subloadmap();
      }
    },
    subloadmap: function () {
      // Does map exists?
      if ($("#map").length) {
        // Get lat/lng
        let clng = $("#map").attr("data-lng");
        let clat = $("#map").attr("data-lat");

        // Any variables?
        if (clng && clng && clng != "" && clat != "") {
          // Initialize location
          let latLng = new google.maps.LatLng(clat, clng);

          // Load map
          map = new google.maps.Map(document.getElementById("map"), {
            center: latLng,
            zoom: 5,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
          });

          // Define icon
          let mapicon = {
            url: "https://cdn.addevent.com/legacy2000/gfx/icon-map-pin-t1.svg",
            scaledSize: new google.maps.Size(50, 50),
          };

          // Set map icon
          let marker = new google.maps.Marker({
            position: latLng,
            map: map,
            icon: mapicon,
          });
        }
      }
    },
    subvalidate: function (f) {
      // Variables
      let execute = true;

      // Execute
      if (execute) {
        // Remove warnings
        $(".warnings").hide();

        // Get data
        let dat = $("#attnsub-form").serialize();

        // Disable submit
        $("#attnsub-form .save").prop("disabled", true);
        $("#attnsub-form .save").addClass("disabled");

        // Notify
        notificator.show("Saving");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/calendar-subscriber-save.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);
            // OK
            if (obj.meta.code == "200") {
              // Notify
              notificator.show("Saved", true);

              // Timed hide
              setTimeout(function () {
                // Hide
                eventform2.subhide();
              }, 300);

              // Timed reload
              setTimeout(function () {
                // Refresh search box or just normal list
                if ($("#searchbox").hasClass("show")) {
                  // Resubmit form
                  $("#searchboxfrm").submit();
                } else {
                  // Refresh
                  location.reload();
                }
              }, 1000);
            } else {
              // Error
              alert("Something went wrong. Please try again.");

              // Redirect
              setTimeout(function () {
                // Redirect
                location.reload();
              }, 200);
            }
          },
          error: function () {
            // Error
            alert("An error has occurred. Please try again.");
          },
        });
      }

      return false;
    },
  };
})();

// Ready
$(document).ready(function () {
  eventform2.initialize();
});

/* Location field
---------------------------------------------------------------- */

var locationfld = (function () {
  let frmact = false;

  return {
    initialize: function () {
      // Exists?
      if ($(".fldlocation").length) {
        // Attach
        $(".fldlocation .locations").on("click", function () {
          locationfld.listshow(this);
          return false;
        });
        $(".fldlocation .remove").on("click", function () {
          locationfld.locremove(this);
          return false;
        });
        $("#pop-loc .close-t1").on("click", function () {
          locationfld.listhide(this, true);
          return false;
        });
        $("#pop-loc .goback").on("click", function () {
          locationfld.locformcancel(this);
          return false;
        });
        $("#pop-loc .newdrp a").on("click", function () {
          locationfld.locoptsshow(this);
          return false;
        });
        $("#loc-opt-typs .itm").on("click", function () {
          locationfld.locoptsitem(this);
          return false;
        });

        // Prevent
        $("#pop-loc").on("click", function (event) {
          // Prevent
          event.stopPropagation();
          // Hide
          locationfld.locoptshide();
        });

        // Form mousedown
        $("#pop-loc").on("mousedown", function () {
          frmact = true;
        });

        // Form mouseup
        $("#pop-loc").on("mouseup", function () {
          // Re-enable
          setTimeout(function () {
            frmact = false;
          }, 200);
        });

        // Prevent
        $("#loc-opt-typs").on("click", function (event) {
          event.stopPropagation();
        });
      }
    },
    locitemuse: function (f) {
      // Get id + value
      let fid = $(f).attr("data-id");
      let val = $(f).attr("data-value");

      // Pass location
      $("#location").val(val);
      $("#location_rel").val(fid);

      // Set read-only
      $(".fldlocation #location").prop("readonly", true);

      // Show "close"
      $(".fldlocation .remove").show();

      // Hide
      locationfld.listhide(f, true);
    },
    locitemedit: function (f) {
      // Get base
      let bas = $(f).closest(".itm");
      // Get id
      let fid = $(bas).attr("data-id");
      // Get type
      let typ = $(bas).attr("data-type");

      // Update action
      $("#loc-cnt-det").attr("data-mode", "edit");
      // Show load
      $("#pop-loc-load").show();
      // Show and hides
      $("#pop-loc-ovrv").hide();
      $("#loc-recent-list").hide();
      $("#pop-loc-edt").show();
      $("#loc-cnt-det").show();

      // Hide icons + labels
      $("#pop-loc-edt .ico").hide();
      $("#pop-loc-edt .txt").hide();

      // Show icon + label
      $("#pop-loc-edt .ico" + typ).show();
      $("#pop-loc-edt .lb" + typ).show();

      // Set data
      let dat = "id=" + fid + "&action=edit";
      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax.location.type.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Update
          $("#loc-cnt-det").html(data);
          // Hide load
          setTimeout(function () {
            $("#pop-loc-load").hide();
          }, 200);
          $("#loc-cnt-det #location_country").val(
            $("#loc-cnt-det #location_country").attr("data-value")
          );
          $("#loc-cnt-det #location_state").val(
            $("#loc-cnt-det #location_state").attr("data-value")
          );

          // Attach
          $("#loc-cnt-det .location_country").on("change", function () {
            locationfld.addresscountry(this);
            return false;
          });
          $("#loc-det-form").on("submit", function () {
            return locationfld.locformvalidate(this);
          });
          $("#loc-det-form .cancel").on("click", function () {
            locationfld.locformcancel(this);
            return false;
          });
          $("#loc-det-form .dl a").on("click", function () {
            locationfld.locformdelete(this);
            return false;
          });
        },
      });
    },
    listshow: function (f) {
      // Reset all
      $("#loc-recent-list").hide();
      $(".pop-loc-t1 .tpx-t1").hide();
      $("#loc-cnt-det").hide();

      // Show recent list
      $("#loc-recent-list").show();

      // Get dimensions
      let wh = $(window).height();
      let ww = $(window).width();
      let st = $(document).scrollTop();
      let pw = $(f).width();
      let ph = $(f).height();
      let pt = $(f).offset().top;
      let pl = $(f).offset().left;
      let lw = $("#pop-loc").outerWidth();
      let lh = $("#pop-loc").outerHeight();

      // Set left + top based on the drop down
      let nl = pl - lw + pw + 5;
      let nt = pt - lh / 2;

      // Adjust top (is too low)
      if (nt + lh > wh + st) {
        nt = pt - lh + ph - 20;
      }

      // Adjust top (less than scroll top)
      if (nt < st) {
        nt = pt - 30;
      }

      // Show
      $("#pop-loc").show();
      $("#pop-loc-ovrv").show();

      // Set popup top
      $("#pop-loc").css("top", nt + "px");
      $("#pop-loc").css("left", nl + "px");

      // Show load
      $("#pop-loc-load").show();

      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax.location.all.php",
        cache: false,
        success: function (data) {
          // Update
          $("#loc-recent-list").html(data);

          // Hide load
          setTimeout(function () {
            $("#pop-loc-load").hide();
          }, 100);

          // Attach
          $("#loc-recent-list .itm").on("click", function () {
            locationfld.locitemuse(this);
            return false;
          });
          $("#loc-recent-list .itm .edit").on("click", function () {
            locationfld.locitemedit(this);
            return false;
          });
        },
      });

      // Show
      setTimeout(function () {
        $("#pop-loc").addClass("show");
      }, 200);

      // Unbind
      $(document).unbind(".fldlocationpop");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.fldlocationpop", function () {
          locationfld.listhide();
        });
      }, 200);
    },
    listhide: function (f, x) {
      // Override close prevent option
      if (x) {
        frmact = false;
      }

      if (!frmact) {
        // Hide
        $("#pop-loc").removeClass("show");
        // Timed
        setTimeout(function () {
          // Hide
          $("#pop-loc").hide();
        }, 200);
      }
    },
    listreload: function () {
      // Reset all
      $("#loc-recent-list").hide();
      $(".pop-loc-t1 .tpx-t1").hide();
      $("#loc-cnt-det").hide();

      // Show recent list
      $("#loc-recent-list").show();

      // Show
      $("#pop-loc").show();
      $("#pop-loc-ovrv").show();

      // Show load
      $("#pop-loc-load").show();

      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax.location.all.php",
        cache: false,
        success: function (data) {
          // Update
          $("#loc-recent-list").html(data);

          // Hide load
          setTimeout(function () {
            $("#pop-loc-load").hide();
          }, 200);

          // Attach
          $("#loc-recent-list .itm").on("click", function () {
            locationfld.locitemuse(this);
            return false;
          });
          $("#loc-recent-list .itm .edit").on("click", function () {
            locationfld.locitemedit(this);
            return false;
          });
        },
      });
    },
    locoptsshow: function (f) {
      // Get dimensions
      let pt = $(f).offset().top;
      let pl = $(f).offset().left;
      let ph = $(f).outerHeight();
      let pw = $(f).outerWidth();
      let cw = $("#loc-opt-typs").width();

      // Adjust
      let nt = pt + ph;
      let nl = pl - cw + pw + 10;

      // Show
      $("#loc-opt-typs").show();

      // Set popup top
      $("#loc-opt-typs").css("top", nt + "px");
      $("#loc-opt-typs").css("left", nl + "px");

      // Timed show
      setTimeout(function () {
        $("#loc-opt-typs").addClass("show");
      }, 200);

      // Unbind
      $(document).unbind(".fldlocationtyps");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.fldlocationtyps", function () {
          locationfld.locoptshide();
        });
      }, 200);
    },
    locoptshide: function (f) {
      // Hide
      $("#loc-opt-typs").removeClass("show");

      // Timed hide
      setTimeout(function () {
        $("#loc-opt-typs").hide();
      }, 200);

      // Unbind
      $(document).unbind(".fldlocationtyps");
    },
    locoptsitem: function (f) {
      // Get type
      let typ = $(f).attr("data-type");

      // Hide
      locationfld.locoptshide();

      // Show and hides
      $("#pop-loc-ovrv").hide();
      $("#loc-recent-list").hide();
      $("#pop-loc-edt").show();
      $("#loc-cnt-det").show();

      // Hide icons + labels
      $("#pop-loc-edt .ico").hide();
      $("#pop-loc-edt .txt").hide();

      // Show icon + label
      $("#pop-loc-edt .ico" + typ).show();
      $("#pop-loc-edt .lb" + typ).show();

      // Show load
      $("#pop-loc-load").show();

      // Set data
      let dat = "action=new&type=" + typ;

      // POST
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax.location.type.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Update
          $("#loc-cnt-det").html(data);

          // Hide load
          setTimeout(function () {
            // Hide load
            $("#pop-loc-load").hide();
            // Focus
            $("#pop-loc-edt #location_tag").focus();
          }, 200);

          $("#loc-cnt-det #location_country").val(
            $("#loc-cnt-det #location_country").attr("data-value")
          );
          $("#loc-cnt-det #location_state").val(
            $("#loc-cnt-det #location_state").attr("data-value")
          );

          // Attach
          $("#loc-cnt-det .location_country").on("change", function () {
            locationfld.addresscountry(this);
            return false;
          });
          $("#loc-det-form").on("submit", function () {
            return locationfld.locformvalidate(this);
          });
          $("#loc-det-form .cancel").on("click", function () {
            locationfld.locformcancel(this);
            return false;
          });
          $("#loc-det-form .dl a").on("click", function () {
            locationfld.locformdelete(this);
            return false;
          });
        },
      });
    },
    addresscountry: function () {
      // Get selected value
      let val = $("#loc-cnt-det .location_country option:selected").val();

      // Show / hide US states
      if (val == "USA") {
        $("#loc-cnt-det .usstates").show();
      } else {
        $("#loc-cnt-det .usstates").hide();
        $("#location_state").val("");
      }
    },
    locformvalidate: function (f) {
      // Execute
      let execute = true;
      // Remove error classes
      $("#loc-type-edtfrm .inperr").removeClass("inperr");
      // Get type
      let typ = $("#loc-type-edtfrm").val();

      // Validate based on type
      if (typ == "address") {
        // Validate
        if (!validate.empty(f.location_address.value)) {
          // Execute
          execute = false;
          // Error
          $(f.location_address).addClass("inperr");
        }
      } else {
        // Validate
        if (!validate.empty(f.location.value)) {
          // Execute
          execute = false;
          // Error
          $(f.location).addClass("inperr");
        }
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#loc-det-form").serialize();
        // Set loading
        $("#loc-det-form .save").addClass("disabled");
        // Disable submit
        $("#loc-det-form .save").prop("disabled", true);
        // Notificator
        notificator.show("Saving location");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/location-save.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Clear loading + Enable submit
              setTimeout(function () {
                $("#loc-det-form .save").removeClass("disabled");
                $("#loc-det-form .save").prop("disabled", false);
              }, 500);

              // Notificator
              notificator.show("Saved", true);
              // Reload
              locationfld.listreload();
            } else {
              // Break
              notificator.break();
              // Some went wrong
              alert("Something went wrong. Reloading the page. ");
              // Reload
              location.reload();
            }
          },
        });
      }

      return false;
    },
    locformcancel: function (f) {
      // Reset all
      $("#loc-recent-list").hide();
      $(".pop-loc-t1 .tpx-t1").hide();
      $("#loc-cnt-det").hide();

      // Show recent list
      $("#loc-recent-list").show();

      // Show
      $("#pop-loc").show();
      $("#pop-loc-ovrv").show();
    },
    locformdelete: function (f) {
      // Get id
      let fid = $(f).attr("data-id");
      // CSRF
      let csrf = $(f).attr("data-csrf");
      // Confirm
      let con = confirm("Are you sure you want to delete the location?");

      // Delete
      if (con) {
        // Notify
        notificator.show("Deleting location");
        // Set data
        let dat = "id=" + fid + "&csrf=" + csrf;

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/location-delete.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Notify
              notificator.show("Loction deleted", true);
              // Reload
              setTimeout(function () {
                locationfld.listreload();
              }, 500);
            } else {
              // Notify
              notificator.break();
              // Alert
              alert("Failed to delete the location..");
              // Reload
              location.reload();
            }
          },
          error: function () {
            // Fail
            alert("Failed to delete the location..");
          },
        });
      }
    },
    locremove: function () {
      // Hide
      $(".fldlocation .remove").hide();
      // Clear
      $(".fldlocation #location").val("");
      $(".fldlocation #location_rel").val("");
      // Set read-only
      $(".fldlocation #location").prop("readonly", false);
    },
  };
})();

// Ready
$(document).ready(function () {
  locationfld.initialize();
});

/* Password protected page
---------------------------------------------------------------- */

var pwprofrm = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($d("pwprofrm")) {
        // Attach
        $("#pwprofrm #pwprofrm_form").on("submit", function () {
          return pwprofrm.pwprosaveform(this);
        });
        $("#pwprofrm .inppagepw").on(
          "click focus change keypress",
          function () {
            pwprofrm.pwprosaveshow();
          }
        );
        $("#pwprofrm .page_pwpr_use").on("change", function () {
          pwprofrm.pwproswitchchange(this);
        });
        $("#pwprofrm .sbt .close").on("click", function () {
          pwprofrm.pwprosavehide();
          return false;
        });
      }
    },
    pwprosaveform: function (f) {
      // Variables
      let execute = true;

      // Hide warnings
      $("#selpwprwrn").hide();
      // Checked or not (use password or not?) (check if a password has been input)
      if ($("#page_pwpr_swi_chk_val").val() == "true") {
        if (!validate.empty(f.pagelockcode.value)) {
          execute = false;
        }
      }

      // Execute?
      if (execute) {
        // Get data
        let dat = $("#pwprofrm #pwprofrm_form").serialize();
        // Disable submit
        $("#pwprofrm .sbtpwpr").prop("disabled", true);
        $("#pwprofrm .sbtpwpr").addClass("disabled");

        // Saving
        notificator.show("Saving");
        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/calendar-event-password-save.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Clear
              $("#pwprofrm .sbtpwpr").prop("disabled", false);
              $("#pwprofrm .sbtpwpr").removeClass("disabled");
              // Saved
              notificator.show("Saved", true);
              // Hide
              $("#selpwpr-btn").hide();
              // Timed reload
              setTimeout(function () {
                location.reload();
              }, 200);
            } else {
              // Notify
              notificator.break();
              // Alert
              alert("Something went wrong. Please try again..");
              // Error
              location.reload();
            }
          },
        });
      } else {
        // Show
        $("#selpwprwrn").show();
      }

      return false;
    },
    pwprosaveshow: function (f) {
      // Show
      $("#selpwpr-btn").show();
    },
    pwprosavehide: function (f) {
      // Hide
      $("#selpwpr-btn").hide();
      // Get originals
      let org_pw = $("#pwprofrm .inppagepw").attr("data-original");
      let org_sw = $("#pwprofrm .page_pwpr_use").attr("data-original");

      // Set original password
      $("#pwprofrm .inppagepw").val(org_pw);

      // Set check
      if (org_sw == "true") {
        $("#pwprofrm .page_pwpr_use").prop("checked", true);
        $("#page_pwpr_swi_chk_val").val("true");
      } else {
        $("#pwprofrm .page_pwpr_use").prop("checked", false);
        $("#page_pwpr_swi_chk_val").val("false");
      }
    },
    pwproswitchchange: function (f) {
      // Update val
      if ($(f).is(":checked")) {
        $("#page_pwpr_swi_chk_val").val("true");
      } else {
        $("#page_pwpr_swi_chk_val").val("false");
      }

      // Show
      $("#selpwpr-btn").show();
    },
  };
})();

// Ready
$(document).ready(function () {
  pwprofrm.initialize();
});

/* Color pick
---------------------------------------------------------------- */

var colorpickerxo = (function () {
  // Picker
  let picker = null,
    pickerelm = null,
    allow = true;

  return {
    initialize: function (f) {
      // Exists?
      if ($(".changepalette").length) {
        // Attach
        $(".changepalette").on("click", function () {
          colorpickerxo.paletteshow(this);
        });
        // Prevent
        $("#palettelist").on("click", function (event) {
          event.stopPropagation();
        });

        // Load colors
        if ($("#palettelist").length) {
          // Load
          colorpickerxo.paletteload();
          // Color picker (pack)
          colorpickerxo.clinit();
        }
      }

      // Event color pick
      if ($(".colorevt").length) {
        // Initialize
        colorpickerxo.colorevtinit();
      }
    },
    paletteload: function (id) {
      // Get calendar if
      let fid = $("#palettelist").attr("data-calendar-id");
      let pal = $("#palettelist").attr("data-palette-id");
      // Set data
      let dat = "calendar_id=" + fid + "&palette_id=" + pal;

      // Post
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax.palette.dropdown.lists.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Append
          $("#palettelist").html(data);

          // Attach
          $("#palettelist .newpalette").on("click", function () {
            colorpickerxo.palettenewedit(this);
            return false;
          });
          $("#palettelist .editpalette").on("click", function () {
            colorpickerxo.palettenewedit(this);
            return false;
          });
          $("#palettelist .tab .rw").on("click", function () {
            colorpickerxo.paletteset(this);
            return false;
          });

          // Any id?
          if (typeof id !== "undefined" && id !== "") {
            // Get scroll top of active element
            let st =
              $("#palettelist .plx" + id).offset().top -
              $("#palettelist .nsi").offset().top +
              $("#palettelist .nsi").scrollTop();

            // Go to position
            $("#palettelist .nsi").animate(
              { scrollTop: st },
              1,
              "easeInOutQuart"
            );

            // Timed
            setTimeout(function () {
              $("#palettelist").css("visibility", "visible");
            }, 40);
          }
        },
      });
    },
    paletteshow: function (f) {
      // Unbind
      $(document).unbind(".palettewin");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.palettewin", function (event) {
          // Hide
          colorpickerxo.palettehide();
        });
      }, 200);

      // Get positions and dimensions
      let l = $(f).position().left;
      let t = $(f).position().top;
      let h = $(f).outerHeight();
      let w = $(f).outerWidth();
      let cw = $("#palettelist").outerWidth();
      let ch = $("#palettelist").outerHeight();

      // Adjust
      $("#palettelist").css("left", l - 20 + "px");
      $("#palettelist").css("top", t - ch / 2 + "px");
      // Show
      $("#palettelist").show();

      // Any active, scroll to?
      if ($("#palettelist .active").length) {
        // Hide temporary
        $("#palettelist").css("visibility", "hidden");

        // Get scroll top of active element
        let st =
          $("#palettelist .active").offset().top -
          $("#palettelist .nsi").offset().top +
          $("#palettelist .nsi").scrollTop();
        // Go to position
        $("#palettelist .nsi").animate({ scrollTop: st }, 1, "easeInOutQuart");

        // Timed
        setTimeout(function () {
          $("#palettelist").css("visibility", "visible");
        }, 40);
      }
    },
    palettehide: function (f) {
      // Palette visible?
      let dis_clp = $("#colorpick").css("display");
      let dis_plp = $("#palettepop").css("display");

      // Allowed?
      if (dis_clp != "block" && dis_plp != "block") {
        // Unbind
        $(document).unbind(".palettewin");

        // Hide
        $("#palettelist").hide();
      }
    },
    palettenewedit: function (f) {
      // Get variables
      let calid = $(f).attr("data-calendar-id");
      let palid = $(f).attr("data-id");
      let dtype = $(f).attr("data-type");

      // Notify
      notificator.show("Loading");
      let dat = null;

      // Set data
      if (dtype == "new") {
        dat = "palette=new&calendar_id=" + calid;
      } else {
        dat = "palette=edit&calendar_id=" + calid + "&palette_id=" + palid;
      }

      // Post
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax.palette.pop.newchange.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Append
          $("#palettepop").html(data);
          // Notify
          notificator.break();
          // Unbind
          $(document).unbind(".palettepop");
          // Timed
          setTimeout(function () {
            // Bind
            $(document).on("click.palettepop", function (event) {
              // Hide
              colorpickerxo.palettepophide();
            });
          }, 200);

          // Show
          $("#palettepop").show();
          $("#palettepop-bg").show();

          // Get dimensions
          let wh = $(window).height();
          let st = $(document).scrollTop();
          let ph = $("#palettepop").height();
          let pw = $("#palettepop").width();
          // New top
          let nt = st + (wh / 2 - ph / 2);

          // Adjust to minimum
          if (nt < 30) {
            nt = 30;
          }

          // Update positions
          $("#palettepop").css("top", nt + "px");

          // Attach
          $("#palettepop .cancel").on("click", function () {
            colorpickerxo.palettepophide(this);
            return false;
          });
          $("#palettepop form").on("submit", function () {
            return colorpickerxo.paletteformsave(this);
          });
          $("#palettepop .vtcolors ul li").on("click", function () {
            colorpickerxo.clshow(this);
            return false;
          });

          // Prevent
          $("#palettepop").on("click", function (event) {
            if ($("#colorpick").css("display") == "block") {
              colorpickerxo.clhide(this);
            }

            // Prevent
            event.stopPropagation();
          });
        },
      });
    },
    palettepophide: function (f) {
      // Palette visible?
      let dis = $("#colorpick").css("display");

      // Allowed?
      if (dis != "block") {
        // Hide
        $("#palettepop").hide();
        $("#palettepop-bg").hide();

        // Unbind
        $(document).unbind(".colorpickwin");
      }
    },
    paletteformsave: function (f) {
      // Remove error classes
      $(".inperr-t2").removeClass("inperr-t2");
      // Execute
      let execute = true;

      // Validate
      if (!validate.empty(f.palette_name.value)) {
        // Execute
        execute = false;

        // Error
        $(f.palette_name).addClass("inperr-t2");
      }

      // Color found?
      let cfnd = false;

      // Loop
      $("#palettepop .vtcolors ul li input").each(function () {
        // Variables
        let val = $(this).val();

        // Any color?
        if (val != "") {
          cfnd = true;
        }
      });

      // Validate
      if (!cfnd) {
        // Execute
        execute = false;

        // Error
        $("#palettepop .lb-t1").addClass("inperr-t2");
      }

      // Valid?
      if (execute) {
        // Status
        notificator.show("Saving");
        // Get data
        let dat = $("#palettepop form").serialize();
        // Disable submit
        $("#palettepop .save").prop("disabled", true);

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/palette-newchange.php",
          data: dat,
          cache: false,
          success: function (data) {
            $("#palettepop .save").removeClass("disabled");
            // Disable submit
            $("#palettepop .save").prop("disabled", false);
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.palette.saved == "true") {
              // Saved
              notificator.show("Saved", true);
              // Reload color list
              colorpickerxo.paletteload(obj.palette.id);
              // Hide window
              colorpickerxo.palettepophide();
            } else {
              // Error
              alert("Something went wrong. Please try reloading the page.");
            }
          },
        });
      }

      return false;
    },
    paletteset: function (f) {
      // Reset list
      $("#palettelist .rw").removeClass("active");
      $(f).addClass("active");
      // Get palette id
      let fid = $(f).attr("data-id");
      // Get colors + nam
      let cols = $(f).find(".vlcolors ul").html();
      let nam = $(f).find(".nam p").text();

      // Update in example
      $("#paluse").html(cols);

      // Update external calendars (if any)
      eventform2.extcalendarpaletteupdate(f, cols);

      // Update title
      $(".palname").text(nam);
      // Update form palette value
      $(".paletteidinp").val(fid);

      // Hide
      colorpickerxo.palettehide(f);
    },
    clshow: function (f) {
      // Get color
      let col = $(f).attr("data-color");

      // Any color?
      if (col == "" || typeof col == "undefined") {
        col = "#000000";
      }

      // Update
      picker.color = col;
      // Save element
      pickerelm = f;
      // Unbind
      $(document).unbind(".colorpickwin");
      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.colorpickwin", function (event) {
          // Hide
          colorpickerxo.clhide();
        });
      }, 200);

      // Get positions and dimensions
      let l = $(f).offset().left;
      let t = $(f).offset().top;
      let h = $(f).outerHeight();
      let w = $(f).outerWidth();
      let cw = $("#colorpick").outerWidth();
      let ch = $("#colorpick").outerHeight();

      // Adjust
      $("#colorpick").css("left", l - cw / 2 + w / 2 - 5 + "px");
      $("#colorpick").css("top", t - ch - 5 + "px");

      // Show
      $("#colorpick").show();

      // Bind
      $("#colorpick").on("mousedown", function () {
        allow = false;
      });
      $("#colorpick").on("mouseup", function () {
        setTimeout(function () {
          allow = true;
        }, 100);
      });
      $(document).on("mouseup", function () {
        setTimeout(function () {
          allow = true;
        }, 100);
      });
    },
    clhide: function (f) {
      // Allowed?
      if (allow) {
        // Hide
        $("#colorpick").hide();

        // Unbind
        $(document).unbind(".colorpickwin");
      }
    },
    clsave: function (f) {
      // Update color
      $(pickerelm).css("background", picker.color);

      // Set attribute
      $(pickerelm).attr("data-color", picker.color);

      // Update input
      $(pickerelm).find("input").val(picker.color);

      allow = true;
      // Hide
      colorpickerxo.clhide();
    },
    clclear: function (f) {
      // Update color
      $(pickerelm).css("background", "");

      // Set attribute
      $(pickerelm).attr("data-color", "");

      // Update input
      $(pickerelm).find("input").val("");

      allow = true;
      // Hide
      colorpickerxo.clhide();
    },
    clswitch: function (f) {
      // Hide
      $(".a-color-picker-hsl").hide();
      $(".a-color-picker-rgb").hide();
      $(".a-color-picker-single-input").hide();

      // Get view
      let vie = $(f).attr("data-view");

      // Switch
      if (vie == "hex") {
        $(".a-color-picker-hsl").show();
        $(f).attr("data-view", "hsl");
      } else if (vie == "hsl") {
        $(".a-color-picker-rgb").show();
        $(f).attr("data-view", "rgb");
      } else if (vie == "rgb") {
        $(".a-color-picker-single-input").show();
        $(f).attr("data-view", "hex");
      }
    },
    clinit: function () {
      // Generate
      if (!$("#colorpick").length) {
        // Load color picker
        fileload.load(
          "https://cdn.addevent.com/legacy2000/js/acolorpicker.js",
          "js"
        );
        // Observe external file
        let colorpobj = setInterval(function () {
          // Color picker found and loaded?
          if (typeof AColorPicker != "undefined") {
            // Attach and initialize object
            colorpickerxo.clinitattc();
            // Clear
            clearInterval(colorpobj);
          }
        }, 500);
      }
    },
    clinitattc: function () {
      // Reset
      let html = "";
      // Collect
      html += '<div id="colorpick">';
      html += '	<div class="inc">';
      html += '		<div class="colorpickxo"></div>';
      html += '		<div class="btn">';
      html +=
        '			<div class="lf"><input type="button" value="Clear" class="clear button-t1 bffffff" /></div>';
      html +=
        '			<div class="rg"><input type="button" value="Use color" class="save button-t1 bffffff" /></div>';
      html += "		</div>";
      html += '		<div class="arr"></div>';
      html += "	</div>";
      html += "</div>";

      // Append
      $("body").append(html);

      // Initialize
      picker = AColorPicker.createPicker({
        attachTo: ".colorpickxo",
        palette: AColorPicker.PALETTE_MATERIAL_CHROME,
      });

      // Hide / show
      $(".a-color-picker-hsl").hide();
      $(".a-color-picker-rgb").hide();
      $(".a-color-picker-single-input").show();
      // Bind
      $(".a-color-picker .switch").on("click", function () {
        colorpickerxo.clswitch(this);
      });
      $("#colorpick .save").on("click", function () {
        colorpickerxo.clsave(this);
      });
      $("#colorpick .clear").on("click", function () {
        colorpickerxo.clclear(this);
      });

      // Prevent
      $("#colorpick").on("click", function (event) {
        event.stopPropagation();
      });
    },
    colorevtinit: function () {
      // Attach
      $("#colorevt-event .btn").on("click", function () {
        colorpickerxo.colorevtshow(this);
        return false;
      });
      $("#colorevt-event .vtcolors ul li").on("click", function () {
        colorpickerxo.colorevtset(this);
        return false;
      });
      // Prevent
      $("#colorevtdrop").on("click", function (event) {
        event.stopPropagation();
      });

      /* Set first color to selected (type: event)
            -------------------------------------------- */
      // Set default value
      let fv = "0";

      // Get pre-existing color value (if any)
      if ($("#eventform1").length) {
        fv = $("#eventform1 .inpcolor").val();
      }
      if ($("#eventform2").length) {
        fv = $("#eventform2 .inpcolor").val();
      }
      if ($("#meetingform1").length) {
        fv = $("#meetingform1 .inpcolor").val();
      }

      // Any value?
      if (typeof fv !== "undefined" && fv != "0") {
        // Loop
        $("#colorevt-event .vtcolors ul li").each(function () {
          // Variables
          let cid = $(this).attr("data-cid");
          let col = $(this).attr("data-color");

          // Match, update color
          if (cid == fv) {
            $("#colorevt-event .circ").css("background", col);
            $(this).addClass("checked");
          }
        });
      } else if (fv == "") {
        // Do nothing, leave color at blank
      } else {
        // Loop
        $("#colorevt-event .vtcolors ul li").each(function () {
          // Variables
          let cid = $(this).attr("data-cid");
          let col = $(this).attr("data-color");

          // Update
          $("#colorevt-event .circ").css("background", col);
          $("#eventform1 .inpcolor").val(cid);
          $(this).addClass("checked");

          // Break loop
          return false;
        });
      }
    },
    colorevtshow: function (f) {
      // Get dimensions
      let rw = $("#colorevtdrop .vtcolors ul li").width() + 5;
      let rc = $("#colorevtdrop .vtcolors ul li").length;
      let nx = 0;

      // Fit box
      if (rc > 10) {
        nx = Math.ceil(rc / 2) * rw + 12;
      } else {
        nx = rc * rw + 12;
      }

      // Adjust
      $("#colorevtdrop").css("width", nx + "px");

      // Unbind
      $(document).unbind(".colorevtdrop");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.colorevtdrop", function (event) {
          // Hide
          colorpickerxo.colorevthide();
        });
      }, 200);

      // Show
      $("#colorevtdrop").addClass("show");
    },
    colorevthide: function () {
      // Hide
      $("#colorevtdrop").removeClass("show");
      // Unbind
      $(document).unbind(".colorevtdrop");
    },
    colorevtset: function (f) {
      // Reset
      $("#colorevtdrop .vtcolors ul .checked").removeClass("checked");

      $(f).addClass("checked");
      // Get color id
      let cid = $(f).attr("data-cid");
      // Get color
      let col = $(f).attr("data-color");

      // Update color
      $("#colorevt-event .circ").css("background", col);

      // Update color id
      $(".form-t1 .inpcolor").val(cid);

      // Hide
      colorpickerxo.colorevthide();
    },
    colorevtqckinit: function () {
      // Attach
      $("#colorevt-quick .btn").on("click", function () {
        colorpickerxo.colorevtqckshow(this);
        return false;
      });
      $("#colorevt-quick .vtcolors ul li").on("click", function () {
        colorpickerxo.colorevtqckset(this);
        return false;
      });

      // Prevent
      $("#colorevt-quick").on("click", function (event) {
        event.stopPropagation();
      });

      /* Set first color to selected (type: quick)
            -------------------------------------------- */

      // Get predefined color value
      let cvpf = $("#pop-evt-new #qck-color").val();

      // Reset
      $("#colorevt-quick .vtcolors ul li").removeClass("checked");

      // Variables
      let cid = null,
        col = null,
        cico = 0;

      // Loop
      $("#colorevt-quick .vtcolors ul li").each(function () {
        // Count
        cico++;

        // Match
        if (cico == cvpf) {
          // Variables
          cid = $(this).attr("data-cid");
          col = $(this).attr("data-color");

          // Update
          $("#colorevt-quick .circ").css("background", col);
          $("#pop-evt-new #qck-color").val(cid);
          $(this).addClass("checked");

          // Break loop
          return false;
        }
      });

      // Update color on marker
      $("#newmarker").css("background", col);

      // Get contrast color
      let ccolr = calm.contrastinvert(col, true).toLowerCase();

      // Reset text color
      $("#newmarker").removeClass("co000000 coffffff");

      // Add text color class
      $("#newmarker").addClass("co" + ccolr);
    },
    colorevtqckshow: function (f) {
      // Get dimensions
      let rw = $("#colorevtdropqck .vtcolors ul li").width() + 5;
      let rc = $("#colorevtdropqck .vtcolors ul li").length;
      let nx = 0;

      // Fit box
      if (rc > 10) {
        nx = Math.ceil(rc / 2) * rw + 12;
      } else {
        nx = rc * rw + 12;
      }

      // Reset
      $("#colorevtdropqck").removeAttr("style");
      // Adjust
      $("#colorevtdropqck").css("width", nx + "px");
      // Unbind
      $(document).unbind(".colorevtdropqck");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.colorevtdropqck", function (event) {
          // Hide
          colorpickerxo.colorevtqckhide();
        });
      }, 200);

      // Show
      $("#colorevtdropqck").addClass("show");

      // Adjust if too far right
      let ww = $(window).width();
      let ce = $("#colorevtdropqck").offset().left;
      let cw = $("#colorevtdropqck").width();
      let bw = $(f).width();
      let nw = cw - bw - 7;

      // Expands window, adjust
      if (ce + cw > ww) {
        $("#colorevtdropqck").css("left", -nw + "px");
      }
    },
    colorevtqckhide: function () {
      // Hide
      $("#colorevtdropqck").removeClass("show");

      // Unbind
      $(document).unbind(".colorevtdropqck");
    },
    colorevtqckset: function (f) {
      // Reset
      $("#colorevtdropqck .vtcolors ul .checked").removeClass("checked");
      $(f).addClass("checked");
      // Get color id
      let cid = $(f).attr("data-cid");
      // Get color
      let col = $(f).attr("data-color");
      // Update color
      $("#colorevt-quick .circ").css("background", col);
      // Update color on marker
      $("#newmarker").css("background", col);
      // Get contrast color
      let ccolr = calm.contrastinvert(col, true).toLowerCase();
      // Reset text color
      $("#newmarker").removeClass("co000000 coffffff");

      // Add text color class
      $("#newmarker").addClass("co" + ccolr);

      // Update color id
      $("#pop-evt-new #qck-color").val(cid);

      // Hide
      colorpickerxo.colorevtqckhide();
    },
  };
})();

// Attach
$(document).ready(function () {
  colorpickerxo.initialize();
});

/* Embeddable calendar config
---------------------------------------------------------------- */

var embcfg = (function () {
  return {
    initialize: function () {
      // Attach
      $(".quickembedbtn").on("click", function () {
        embcfg.show(this);
        return false;
      });
      $("#embcfg .clo").on("click", function () {
        embcfg.hide(this);
        return false;
      });
      $("#embcfg #emb-tit").on("blur", function () {
        embcfg.ifrupd(this);
        return false;
      });
      $("#embcfg .check-t1").on("click", function () {
        embcfg.chkset(this);
        return false;
      });
      $("#embcfg .radio-t1").on("click", function () {
        embcfg.radset(this);
        return false;
      });
      $("#embcfg .switch input").on("click", function () {
        embcfg.switchch(this);
      });
      $("#embcfg .embedoptionsshow").on("click", function () {
        embcfg.embedoptionsshow(this);
      });
      $("#embcfg-embed-options .close").on("click", function () {
        embcfg.embedoptionshide(this);
      });
      $("#embcfg-embed-options-bg").on("click", function () {
        embcfg.embedoptionshide(this);
      });
      $("#embcfg-embed-options .rm").on("click", function () {
        embcfg.embedoptionsreadmore(this);return false;
      });
      $("#embcfg-embed-options").on("click", function (event) {
        event.stopPropagation();
      });

      // Bind key press
      $("#embcfg #emb-tit").on("keyup.configtitle", function (e) {
        // Escape
        if (e.keyCode == "13") {
          // Hide
          embcfg.ifrupd();
        }
      });
    },
    ifrupd: function (f) {

      // Variables
      let istr = "", ustr = "";

      // Get calendar
      let cal = $("#embcfg").attr("data-calendar");
      // Calendar title + set
      let a1 = $("#emb-tit").val();

      istr += 'data-title="' + a1 + '" ';
      ustr += '&title=' + encodeURIComponent(a1);

      // Get options
      $("#sec-options .check-t1").each(function () {
        // Get set class
        let clk = $(this).hasClass("selected");
        let dat = $(this).attr("data-val");
        let prm = $(this).attr("data-param");

        // Collect
        istr += "data-" + dat + '="' + clk.toString() + '" ';
        ustr += "&" + prm + "=" + encodeURIComponent(clk.toString());

      });

      // Default view
      $("#sec-defaultview .radio-t1").each(function () {
        // Get set class
        let clk = $(this).hasClass("selected");
        let dat = $(this).attr("data-val");
        let prm = $(this).attr("data-param");

        // Collect
        if (clk) {
          istr += 'data-defaultview="' + dat + '" ';
          ustr += "&" + prm + "=" + encodeURIComponent(dat);
        }
      });

      // First day
      $("#sec-firstday .radio-t1").each(function () {
        // Get set class
        let clk = $(this).hasClass("selected");
        let dat = $(this).attr("data-val");
        let prm = $(this).attr("data-param");

        // Collect
        if (clk) {
          istr += 'data-firstday="' + dat + '" ';
          ustr += "&" + prm + "=" + encodeURIComponent(dat);
        }
      });

      // Timeformat
      $("#sec-timeformat .radio-t1").each(function () {
        // Get set class
        let clk = $(this).hasClass("selected");
        let dat = $(this).attr("data-val");
        let prm = $(this).attr("data-param");

        // Collect
        if (clk) {
          istr += 'data-datetimeformat="' + dat + '" ';
          ustr += "&" + prm + "=" + encodeURIComponent(dat);
        }
      });

      // Data calendars + calendars selected
      let dtcals = "";
      let dtcalssel = "";

      // Get selected calendars
      $("#sec-calsdisplay .check-t1").each(function () {
        // Selected? Get value
        let clk = $(this).hasClass("selected");
        let dat = $(this).attr("data-val");

        // Add
        if (clk) {
          // Add to "calendars"
          dtcals = dtcals + dat.toString() + "-";

          // Show calendar by default?
          if ($(this).closest(".checkwrp").find(".clus").is(":checked")) {
            // Add to "calendars"
            dtcalssel = dtcalssel + dat.toString() + "-";
          }
        }
      });

      // Remove last "-" from string
      dtcals = dtcals.replace(/-\s*$/, "");
      dtcalssel = dtcalssel.replace(/-\s*$/, "");

      // Final HTML
      let ostr =
        '<div class="ae-emd-cal" data-calendar="' +
        cal +
        '" data-calendars="' +
        dtcals +
        '" data-calendars-selected="' +
        dtcalssel +
        '" data-configure="true" ' +
        istr +
        '></div><script type="text/javascript">(function(){var e = document.createElement("script");e.type = "text/javascript";e.async = true;e.src = "https://cdn.addevent.com/libs/cal/js/cal.embed.t1.init.js";e.className = "ae-emd-script";document.getElementsByTagName("body")[0].appendChild(e);})();</script>';

      // URL
      let ustrpro = 'www.addevent.com';
      let ustrtmp = '/calendar/' + cal + '/embed/?id=' + cal + '&calendars=' + dtcals + '&calendars_sel=' + dtcalssel + '&config=true' + ustr + '#' + cal;

      // URL base
      if(window.configAeBaseUrl){
        ustrpro = window.configAeBaseUrl;
      }

      // Set final path
      let ustrfin = 'https://' + ustrpro + ustrtmp;

      // Update
      $("#embtxtcode").val(ostr);
      $("#embtxtexm").html(ostr);
      $("#embcfg-code-common").val(ostr);
      $("#embcfg-code-url").val(ustrfin);
      $("#embcfg-code-urlwf").val('<iframe src="' + ustrfin + '" style="width:100%;height:100%;border:0;"></iframe>');

      // Notify
      notificator.show("Loading");

      // Break
      setTimeout(function () {
        notificator.break();
      }, 500);
    },
    chkset: function (f) {
      // Calendars to display option clicked?
      if ($(f).hasClass("calsdisplay")) {
        // Get fid
        let fid = $(f).attr("data-fid");
        // Find root list
        let bas = $(f).closest(".checkwrp");

        // Check or uncheck
        if ($(f).hasClass("selected")) {
          // Uncheck
          $(bas).addClass("selected");
        } else {
          // Check
          $(bas).removeClass("selected");
        }
      }

      // Summarize
      embcfg.ifrupd();
    },
    radset: function (f) {
      // Reset
      $(f).parent(".ls-t1").find(".radio-t1").removeClass("selected");
      $(f)
        .parent(".ls-t1")
        .find(".radio-t1 input[type=radio]")
        .prop("checked", false);

      $(f).addClass("selected");
      $(f).find(".radio-t1 input[type=radio]").prop("checked", true);

      // Summarize
      embcfg.ifrupd();
    },
    switchch: function (f) {
      // Checked?
      if ($(f).is(":checked")) {
        $(f).prop("checked", true);
      } else {
        $(f).prop("checked", false);
      }

      // Summarize
      embcfg.ifrupd();
    },
    show: function (f) {
      // Show
      $("#embcfg").addClass("show");

      // Add
      $("#doc").addClass("overflow");

      // Notify
      notificator.show("Loading");

      // Timed
      setTimeout(function () {
        embcfg.ifrupd();
      }, 400);
    },
    hide: function (f) {
      // Hide
      $("#embcfg").removeClass("show");

      // Remove
      $("#doc").removeClass("overflow");
    },
    embedoptionsshow:function(){

      // Show
      $("#embcfg-embed-options").show();
      $("#embcfg-embed-options-bg").show();

      // Timed show
      setTimeout(function(){
        $("#embcfg-embed-options").addClass('show');
      }, 200);

      // Unbind
      $(document).unbind(".embcfgembopts");

      // Timed
      setTimeout(function(){

        // Bind
        $(document).on("click.embcfgembopts", function(event){

          // Hide
          embcfg.embedoptionshide();

        });

      }, 200);

    },
    embedoptionshide:function(){

      // Unbind
      $(document).unbind(".embcfgembopts");

      // Remove
      $("#embcfg-embed-options").removeClass('show');

      // Timed hide
      setTimeout(function(){
        $("#embcfg-embed-options").hide();
        $("#embcfg-embed-options-bg").hide();
      }, 400);

    },
    embedoptionsreadmore:function(f){

      // Get base
      let bas = $(f).closest('.opt');

      // Show
      $(bas).find('.mor').show();

      // Hide
      $(f).hide();

    }
  };
})();

/* Attach */
$(document).ready(function () {
  embcfg.initialize();
});

/* Embeddable calendar events config
---------------------------------------------------------------- */
var embelp = (function () {
  return {
    initialize: function () {
      // Attach
      $(".quickembedeventsbtn").on("click", function () {
        embelp.show(this);
        return false;
      });
      $("#embelp .close").on("click", function () {
        embelp.hide(this);
        return false;
      });
      $("#embelp .elpinp").on("blur", function () {
        embelp.ifrupd(this);
        return false;
      });
      $("#embelp #elp-language").on("change", function () {
        embelp.ifrupd(this);
      });
      $("#embelp #elp-maxevents").on("change", function () {
        embelp.ifrupd(this);
      });
      $("#embelp #elp-evtdesclen").on("change", function () {
        embelp.ifrupd(this);
      });
      $("#embelp .check-t1").on("click", function () {
        embelp.chkset(this);
        return false;
      });
      $("#embelp .radio-t1").on("click", function () {
        embelp.radset(this);
        return false;
      });

      // Bind key press
      $("#embelp .elpinp").on("keyup.configtitle", function (e) {
        // Escape
        if (e.keyCode == "13") {
          // Hide
          embelp.ifrupd();
        }
      });
    },
    ifrupd: function (f) {
      // Variables
      let istr = "";
      // Get calendar
      let cal = $("#embelp").attr("data-calendar");

      // Labels
      let a1 = $("#elp-lbl-upcoming").val();
      let a2 = $("#elp-lbl-subscribe").val();
      let a3 = $("#elp-lbl-noevents").val();
      let a4 = $("#elp-lbl-readmore").val();
      let a5 = $("#elp-lbl-in").val();
      let a6 = $("#elp-lbl-days").val();
      let a7 = $("#elp-lbl-day").val();
      let a8 = $("#elp-lbl-hours").val();
      let a9 = $("#elp-lbl-hour").val();
      let a10 = $("#elp-lbl-minutes").val();
      let a11 = $("#elp-lbl-minute").val();
      let a12 = $("#elp-lbl-seconds").val();
      let a13 = $("#elp-lbl-second").val();
      let a14 = $("#elp-lbl-live").val();

      istr += 'data-lbl-upcoming="' + a1 + '" ';
      istr += 'data-lbl-subscribe="' + a2 + '" ';
      istr += 'data-no-events="' + a3 + '" ';
      istr += 'data-lbl-readmore="' + a4 + '" ';
      istr += 'data-lbl-in="' + a5 + '" ';
      istr += 'data-lbl-days="' + a6 + '" ';
      istr += 'data-lbl-day="' + a7 + '" ';
      istr += 'data-lbl-hours="' + a8 + '" ';
      istr += 'data-lbl-hour="' + a9 + '" ';
      istr += 'data-lbl-minutes="' + a10 + '" ';
      istr += 'data-lbl-minute="' + a11 + '" ';
      istr += 'data-lbl-seconds="' + a12 + '" ';
      istr += 'data-lbl-second="' + a13 + '" ';
      istr += 'data-lbl-live="' + a14 + '" ';

      // Get options
      $("#sec-elp-options .check-t1").each(function () {
        // Get set class
        let clk = $(this).hasClass("selected");
        let dat = $(this).attr("data-val");

        // Collect
        istr += "data-" + dat + '="' + clk.toString() + '" ';
      });

      // Default view
      $("#sec-elp-defaultview .radio-t1").each(function () {
        // Get set class
        let clk = $(this).hasClass("selected");
        let dat = $(this).attr("data-val");

        // Collect
        if (clk) {
          istr += 'data-default-view="' + dat + '" ';
        }
      });

      // Stay on page
      $("#sec-elp-stayonpage .radio-t1").each(function () {
        // Get set class
        let clk = $(this).hasClass("selected");
        let dat = $(this).attr("data-val");

        // Collect
        if (clk) {
          istr += 'data-stayonpage="' + dat + '" ';
        }
      });

      // Timeformat
      $("#sec-elp-timeformat .radio-t1").each(function () {
        // Get set class
        let clk = $(this).hasClass("selected");
        let dat = $(this).attr("data-val");

        // Collect
        if (clk) {
          istr += 'data-datetime-format="' + dat + '" ';
        }
      });

      // Get time language
      let timlng = $("#elp-language :selected").val();
      istr += 'data-datetime-language="' + timlng + '" ';

      // Get max events
      let timmxe = $("#elp-maxevents :selected").val();
      istr += 'data-events-max="' + timmxe + '" ';

      // Get description length
      let deslgt = $("#elp-evtdesclen :selected").val();
      istr += 'data-description-length="' + deslgt + '" ';

      // Final HTML
      let ostr =
        '<div style="width:100%;height:500px;" class="ae-emd-cal-events" data-calendar="' +
        cal +
        '" ' +
        istr +
        '></div><script type="text/javascript" src="https://cdn.addevent.com/libs/cal/js/cal.events.embed.t4.init.js"></script>';

      // Update
      $("#embelptxtcode").val(ostr);
      $("#embelptxtexm").html(ostr);

      // Notify
      notificator.show("Loading");

      // Break
      setTimeout(function () {
        notificator.break();
      }, 500);
    },
    chkset: function (f) {
      // Summarize
      embelp.ifrupd();
    },
    radset: function (f) {
      // Reset
      $(f).parent(".ls-t1").find(".radio-t1").removeClass("selected");
      $(f)
        .parent(".ls-t1")
        .find(".radio-t1 input[type=radio]")
        .prop("checked", false);

      $(f).addClass("selected");
      $(f).find(".radio-t1 input[type=radio]").prop("checked", true);

      // Summarize
      embelp.ifrupd();
    },
    show: function (f) {
      // Show
      $("#embelp").addClass("show");

      // Add
      $("#doc").addClass("overflow");

      // Notify
      notificator.show("Loading");

      // Timed
      setTimeout(function () {
        embelp.ifrupd();
      }, 400);
    },
    hide: function (f) {
      // Hide
      $("#embelp").removeClass("show");

      // Remove
      $("#doc").removeClass("overflow");
    },
  };
})();

/* Attach */
$(document).ready(function () {
  embelp.initialize();
});

/* Xaccount
---------------------------------------------------------------- */
var xaccount = (function () {
  return {
    initialize: function () {
      // Attach
      $(".xaccountactivate").on("click", function () {
        xaccount.activate(this);
        return false;
      });
      $(".calendarxlaunch").on("click", function () {
        xaccount.xlaunch(this);
        return false;
      });
    },
    xlaunch: function () {
      // Open new window
      window.open("https://www.calendarx.com/calendars");
    },
    activate: function (f) {
      // Notify
      notificator.show("Activating CalendarX..");

      // Disable submit
      $(".xaccountactivate").prop("disabled", true);

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/actions/user/xaccount.create.php",
        dataType: "json",
        cache: false,
        success: function (data) {
          // Clear loading
          notificator.show("Activated", true);
          // Parse
          let obj = data;

          // Handle
          if (obj.account.created == "true") {
            // Account created
            $("#xacc-activate").hide();
            $("#xacc-created").show();

            // Append values
            $("#xacc-created .username").text(obj.account.username);
            $("#xacc-created .password").text(obj.account.password);
          } else {
            // Something went wrong
            csrfpop.show();
          }
        },
      });
    },
  };
})();

// Attach
$(document).ready(function () {
  xaccount.initialize();
});

/* Custom fields
---------------------------------------------------------------- */
var cusfields = (function () {
  // Variables
  let optscount = 500;

  return {
    initialize: function () {
      // Exists?
      if ($d("cusfields")) {
        // Attach
        $("#cusfields .addfield").on("click", function () {
          cusfields.fieldsshow(this, { new: "true" });
          return false;
        });
        $("#cusfields .list .edit").on("click", function () {
          cusfields.fieldsshow(this, { new: "false" });
          return false;
        });
        $("#cusfields .list .delete").on("click", function () {
          cusfields.fielddelete(this);
          return false;
        });
        $("#cusfields .list .td1 .check").on("click", function () {
          cusfields.checktd1(this);
          return false;
        });
        $("#cusfields .enablecheck").on("click", function () {
          cusfields.enablecheck(this);
          return false;
        });

        // Forms
        $("#rsvpformlabels").on("submit", function () {
          return cusfields.rsvpformlabelsform(this);
        });
        $("#rsvpforminfo").on("submit", function () {
          return cusfields.rsvpforminfo(this);
        });
        $("#rsvpformsignup").on("submit", function () {
          return cusfields.rsvpformsignup(this);
        });
        $("#rsvpformreminder1").on("submit", function () {
          return cusfields.rsvpformreminder1(this);
        });
        $("#rsvpformreminder2").on("submit", function () {
          return cusfields.rsvpformreminder2(this);
        });
        $("#rsvpformfollowup1").on("submit", function () {
          return cusfields.rsvpformfollowup1(this);
        });
        $("#rsvpformeventupdate").on("submit", function () {
          return cusfields.rsvpformeventupdate(this);
        });

        // Read more
        $(".readmore").on("click", function () {
          cusfields.readmoreshow(this);
          return false;
        });

        // Restore
        $("#cusfields .restore").on("click", function () {
          cusfields.restore(this);
          return false;
        });

        // View
        $("#cusfields .view").on("click", function () {
          cusfields.viewform(this);
          return false;
        });

        // Email test popup
        $("#cusfields .emailtestpm").on("click", function () {
          cusfields.emailtestshow(this);
          return false;
        });
        $("#emailtestpm-pop .cancel").on("click", function () {
          cusfields.emailtesthide(this);
          return false;
        });
        $("#emailtestpm-pop").on("click", function (event) {
          event.stopPropagation();
        });
        $("#emailtestform").on("submit", function () {
          return cusfields.emailtestsend(this);
        });

        // Delete form
        $("#cusfields .deleteform").on("click", function () {
          cusfields.deleteform(this);
          return false;
        });

        // Readonly
        cusfields.readonly();
      }

      // Attach
      $(".onetimenewrsvpform").on("click", function () {
        cusfields.gotonewform(this);
        return false;
      });
      $(".conlist-t1 .deletersvpuser").on("click", function () {
        cusfields.deletersvpuser(this);
        return false;
      });
      $(".conlist-t1 .deletesubsuser").on("click", function () {
        cusfields.deletesubsuser(this);
        return false;
      });
      $(".lists-t2 .templatecopy").on("click", function () {
        cusfields.templatecopy(this);
        return false;
      });
    },
    readonly: function () {
      // Standard?
      let std = $("#cusfields").attr("data-readonly");

      // Readonly?
      if (std == "true") {
        // Disable
        $("#cusfields input").prop("readonly", true);
        $("#cusfields select").prop("disabled", true);
        $(
          "#customfields_list .td5, #customfields_list .td6, #customfields_list .td7"
        ).text("");
        $("#customfields_list .check, #cusfields form .check").off();
        $("#cusfields .addfield").off();
        $("#cusfields .sbt-t1").hide();
        $("#cusfields .addfield").on("click", function () {
          return false;
        });
      }
    },
    emailtestsend: function (f) {
      // Remove error classes
      $(".inperr-t2").removeClass("inperr-t2");

      // Execute
      let execute = true;

      // Validate
      if (!validate.email(f.email.value)) {
        // Execute
        execute = false;

        // Error
        $(f.email).addClass("inperr-t2");
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#emailtestform").serialize();

        $("#emailtestform .save").addClass("disabled");

        // Disable
        $("#emailtestform .save").prop("disabled", true);

        // Saving
        notificator.show("Sending");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-email-test.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              $("#emailtestform .save").removeClass("disabled");

              // Enable submit
              $("#emailtestform .save").prop("disabled", false);

              // Sent
              notificator.show("Sent", true);

              // Timed
              setTimeout(function () {
                cusfields.emailtesthide();
              }, 500);
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    emailtestshow: function (f) {
      // Get values
      let fid = $(f).attr("data-form-id");
      let tid = $(f).attr("data-template-id");

      // Update
      $("#emailtestform .formid").val(fid);
      $("#emailtestform .template").val(tid);

      // Transfer template code
      if (tid == "rsvpformsignup") {
        $("#emailtestform .templatecode").val(codemrr2.getValue());
        $("#emailtestform .templatesubject").val(
          $("#rsvpformsignup .title").val()
        );
      } else if (tid == "rsvpformreminder1") {
        $("#emailtestform .templatecode").val(codemrr3.getValue());
        $("#emailtestform .templatesubject").val(
          $("#rsvpformreminder1 .title").val()
        );
      } else if (tid == "rsvpformreminder2") {
        $("#emailtestform .templatecode").val(codemrr4.getValue());
        $("#emailtestform .templatesubject").val(
          $("#rsvpformreminder2 .title").val()
        );
      } else if (tid == "rsvpformfollowup1") {
        $("#emailtestform .templatecode").val(codemrr6.getValue());
        $("#emailtestform .templatesubject").val(
          $("#rsvpformfollowup1 .title").val()
        );
      } else if (tid == "rsvpformeventupdate") {
        $("#emailtestform .templatecode").val(codemrr5.getValue());
        $("#emailtestform .templatesubject").val(
          $("#rsvpformeventupdate .title").val()
        );
      }

      // Unbind
      $(document).unbind(".emailtestpmwin");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.emailtestpmwin", function (event) {
          // Hide
          cusfields.emailtesthide();
        });
      }, 200);

      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#emailtestpm-pop").height();

      // New top
      let nt = st + (wh / 2 - ph / 2 - 50);

      // Adjust to minimum
      if (nt < 30) {
        nt = 30;
      }

      // Update positions
      $("#emailtestpm-pop").css("top", nt + "px");
      // Timed show
      setTimeout(function () {
        // Show
        $("#emailtestpm-pop").addClass("show");
        $("#emailtestpm-pop-bg").addClass("show");

        // Focus
        $("#emailtestpm-pop .email").focus();
      }, 200);
    },
    emailtesthide: function () {
      // Hide
      $("#emailtestpm-pop").removeClass("show");
      $("#emailtestpm-pop-bg").removeClass("show");

      // Unbind
      $(document).unbind(".emailtestpmwin");
    },
    readmoreshow: function (f) {
      // Get link rel
      let rel = $(f).attr("data-rel");

      // Show
      $("#" + rel).show();

      // Hide read more link
      $(f).hide();
    },
    restore: function (f, s) {
      // Get closest form
      let frm = $(f).closest("form").attr("id");
      // Notify
      notificator.show("Restoring");
      // Data
      let dat = "template=" + frm;
      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax_events_oe_templates.php",
        data: dat,
        cache: false,
        dataType: "json",
        success: function (data) {
          // Notify
          notificator.show("Restored", true);
          // Parse
          const obj = data;
          // Update
          $("#" + frm + " .title").val(obj.data.title);

          // Update codemirror value
          if (frm == "rsvpformsignup") {
            codemrr2.setValue(obj.data.template);
          } else if (frm == "rsvpformreminder1") {
            codemrr3.setValue(obj.data.template);
          } else if (frm == "rsvpformreminder2") {
            codemrr4.setValue(obj.data.template);
          } else if (frm == "rsvpformfollowup1") {
            codemrr6.setValue(obj.data.template);
          } else if (frm == "rsvpformeventupdate") {
            codemrr5.setValue(obj.data.template);
          }
        },
      });
    },
    viewform: function (f) {
      // Open
      window.open($(f).attr("data-href"));
    },
    gotonewform: function (f) {
      // Get event id
      let fid = $(f).attr("data-event-id");
      // CSRF
      let csrf = $(f).attr("data-csrf");
      // Notify
      notificator.show("Creating form");
      // Data
      let dat = "event_id=" + fid + "&csrf=" + csrf;

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/actions/calendar/event-rsvp-form-new.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);

          // OK
          if (obj.meta.code == "200") {
            // Notify
            notificator.show("Form created", true);

            // Handle
            if (obj.data.ok == "true") {
              // Redirect
              location.href = "/templates/rsvpforms/" + obj.data.template_id;
            } else {
              // Alert
              alert("An error has occurred. Please try again..");

              // Reload
              location.reload();
            }
          } else {
            // Notify
            notificator.break();

            // Error
            csrfpop.show();
          }
        },
      });
    },
    getsetsorting: function () {
      // Sortorder values
      let svo = "";

      // Loop
      $("#customfield-sortable .td1 input[type=checkbox]").each(function () {
        // Get field reference
        let ref = $(this).attr("data-name");

        // Add to collection
        if ($(this).is(":checked")) {
          svo = svo + ref + ",";
        }
      });

      // Add
      $("#customfield-sortable .sortinp").val(svo);
    },
    checktd1: function (f) {
      // Get rel
      let rel = $(f).attr("data-rel");
      let fid = $(f).attr("data-id");
      let frr = $(f).attr("data-form-rel");
      let csrf = $(f).attr("data-csrf");

      // Allowed?
      if (!$(f).hasClass("disabled")) {
        let active = "true";

        // Toogle
        if ($(f).hasClass("checked")) {
          $("#" + rel + " .td1 input").prop("checked", false);

          // Remove
          $(f).removeClass("checked");

          active = "false";
        } else {
          $("#" + rel + " .td1 input").prop("checked", true);

          // Add
          $(f).addClass("checked");

          active = "true";
        }

        // Invoke sorting
        cusfields.getsetsorting();

        // Notify
        notificator.show("Updating");

        // Data
        let dat =
          "id=" +
          fid +
          "&formrelation=" +
          frr +
          "&active=" +
          active +
          "&csrf=" +
          csrf;

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-field-mandatory.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Notify
              notificator.show("Updated", true);
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }
    },
    enablecheck: function (f) {
      // Get rel
      let rel = $(f).attr("data-rel");

      // Allowed?
      if (!$(f).hasClass("disabled")) {
        // Toogle
        if ($(f).hasClass("checked")) {
          $("#" + rel).prop("checked", false);

          // Remove
          $(f).removeClass("checked");
        } else {
          $("#" + rel).prop("checked", true);

          // Add
          $(f).addClass("checked");
        }
      }
    },
    fieldsshow: function (f, opts) {
      // Get relation if any
      let rel = $(f).attr("data-rel");
      let frmrel = $(f).attr("data-form-rel");

      // New?
      if (opts.new == "true") {
        // Remove relation
        rel = "";

        // Notify
        notificator.show("Loading editor");
      } else {
        // Notify
        notificator.show("Loading editor");
      }

      // Set data
      let dat = "relation=" + rel + "&formrelation=" + frmrel;

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax_events_oe_customfields.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Notify
          notificator.break();

          // Append
          $("#cusfields-pop").html(data);

          // Get select default value
          let sdv = $("#cusfield-datatype").attr("data-default");

          $("#cusfield-datatype").val(sdv);

          // Attach
          $("#cusfields-pop .cancel").on("click", function () {
            cusfields.fieldshide(this);
            return false;
          });
          $("#cusfields-pop").on("click", function (event) {
            event.stopPropagation();
          });
          $("#cusfields-pop .datatype").on("change", function () {
            cusfields.fieldchange(this);
          });
          $("#cusfields-pop .addoption").on("click", function () {
            cusfields.optionadd(this);
          });
          $("#cusfields-form").on("submit", function () {
            return cusfields.validate(this);
          });

          // Keypress on add option
          $("#cusfield-option-text").keydown(function (event) {
            // If Tabulator or Enter
            if (event.which == "9" || event.which == "13") {
              // Trigger
              cusfields.optionadd();

              // Prevent
              event.preventDefault();
            }
          });

          // Attach events
          cusfields.optionsattach();

          // Sort
          sortables.initialize();

          // Unbind
          $(document).unbind(".cusfieldswin");

          // Timed
          setTimeout(function () {
            // Bind
            $(document).on("click.cusfieldswin", function (event) {
              // Hide
              cusfields.fieldshide();
            });
          }, 200);

          // Get dimensions
          let wh = $(window).height();
          let st = $(document).scrollTop();
          let ph = $("#cusfields-pop").height();
          let pw = $("#cusfields-pop").width();

          // New top
          let nt = st + (wh / 2 - ph / 2);

          // Adjust to minimum
          if (nt < 30) {
            nt = 30;
          }

          // Update positions
          $("#cusfields-pop").css("top", nt + "px");

          // Timed show
          setTimeout(function () {
            // Show
            $("#cusfields-pop").addClass("show");
            $("#cusfields-pop-bg").addClass("show");

            // New?
            if (opts.new == "true") {
              // Reset
              $("#cusfield-title").val("");
              $("#cusfield-datatype").val("text");
              $("#cusfields-pop-opts").hide();
              $("#cusfields-pop-opts .opttext").val("");

              // Focus
              $("#cusfield-title").focus();
            }
          }, 200);
        },
      });
    },
    adjustwin: function () {
      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#cusfields-pop").height();
      let pw = $("#cusfields-pop").width();

      // New top
      let nt = st + (wh / 2 - ph / 2);

      // Adjust to minimum
      if (nt < 30) {
        nt = 30;
      }

      // Update positions
      $("#cusfields-pop").css("top", nt + "px");
    },
    fieldshide: function () {
      // Hide
      $("#cusfields-pop").removeClass("show");
      $("#cusfields-pop-bg").removeClass("show");

      // Unbind
      $(document).unbind(".cusfieldswin");
    },
    fieldchange: function (f) {
      // Get value
      let val = $(f).val();

      // Toogle (multipe)
      if (val == "select" || val == "radios") {
        // Show
        $("#cusfields-pop-opts").show();
      } else {
        // Hide
        $("#cusfields-pop-opts").hide();
      }

      // Toogle (querystring parameters)
      if (val == "querystring_hidden" || val == "querystring_text") {
        // Show
        $("#cusfields-pop-querystring").show();
      } else {
        // Hide
        $("#cusfields-pop-querystring").hide();
      }

      // Marketing consent
      if (val == "consent") {
        // Show
        $("#cusfields-pop-consent").show();
      } else {
        // Hide
        $("#cusfields-pop-consent").hide();
      }

      // Marketing consent
      if (val == "customhtml") {
        // Show
        $("#cusfields-pop-customhtml").show();
      } else {
        // Hide
        $("#cusfields-pop-customhtml").hide();
      }

      // Adjust
      cusfields.adjustwin();
    },
    fielddelete: function (f) {
      // Allowed?
      if (!$(f).hasClass("disabled")) {
        // Confirm
        let con = confirm(
          "Are you sure you want to delete the field? \n\nAll associated data in your events will be removed.\nPlease note: This process cannot be undone."
        );

        // Delete
        if (con) {
          // Get id
          let fid = $(f).attr("data-id");
          let frr = $(f).attr("data-form-rel");

          // CSRF
          let csrf = $(f).attr("data-csrf");

          // Notify
          notificator.show("Deleting field");

          // Data
          let dat = "id=" + fid + "&formrelation=" + frr + "&csrf=" + csrf;

          // Post
          $.ajax({
            type: "POST",
            url: "/source/web/actions/calendar/event-rsvp-form-field-delete.php",
            data: dat,
            cache: false,
            success: function (data) {
              // Parse
              const obj = parseResponse(data);

              // OK
              if (obj.meta.code == "200") {
                // Notify
                notificator.show("Deleted", true);

                // Timed
                setTimeout(function () {
                  // Reload list
                  cusfields.fieldsload(frr);
                }, 500);
              } else {
                // Notify
                notificator.break();

                // Error
                csrfpop.show();
              }
            },
          });
        }
      }
    },
    fieldsload: function (rel) {
      // Data
      let dat = "formrelation=" + rel;

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax_events_oe_customfields_list.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Append data
          $("#customfields_list").html(data);

          // Attach
          $("#cusfields .list .edit").on("click", function () {
            cusfields.fieldsshow(this, { new: "false" });
            return false;
          });
          $("#cusfields .list .delete").on("click", function () {
            cusfields.fielddelete(this);
            return false;
          });
          $("#cusfields .list .td1 .check").on("click", function () {
            cusfields.checktd1(this);
            return false;
          });

          // Sortable
          sortables.initialize();
        },
      });
    },
    validate: function (f) {
      // Variables
      let execute = true,
        optionscount = 0;

      // Hide warnings
      $(".warnings").hide();

      // Get option value
      let opt = $("#cusfield-datatype").val();

      // Field text
      let tit = $("#cusfield-title").val();

      // Option type
      if (opt == "select" || opt == "radios") {
        // Count the number of options (max 15)
        $("#cusfield-options .fieldoptionval").each(function () {
          // Get text values
          let txvl = $(this).find(".opttext").val();

          // Valid?
          if (validate.empty(txvl)) {
            // Add
            optionscount++;
          }
        });

        // None found?
        if (optionscount < 2) {
          execute = false;
        }
      } else if (opt == "querystring_hidden" || opt == "querystring_text") {
        // Any querystring?
        if (!validate.empty(f.querystring.value)) {
          execute = false;
        }
      }

      // Any descriptive text?
      if (!validate.empty(tit)) {
        execute = false;
      }

      // Execute?
      if (execute) {
        // Get data
        let dat = $("#cusfields-form").serialize();

        // Get relation
        let rel = $("#cusfields-relation").val();
        let formrel = $("#cusfields-formrelation").val();

        // Disable submit
        $("#cusfields-form .save").prop("disabled", true);
        $("#cusfields-form .save").addClass("disabled");

        // Saving / Creating
        if (rel != "") {
          notificator.show("Saving field");
        } else {
          notificator.show("Creating field");
        }

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-field-save.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Clear
              $("#cusfields-form .save").prop("disabled", false);
              $("#cusfields-form .save").removeClass("disabled");

              // Handle
              if (obj.field.created == "true" || obj.field.updated == "true") {
                // Created
                if (obj.field.created == "true") {
                  // Saved
                  notificator.show("Field created", true);
                }

                // Saved
                if (obj.field.updated == "true") {
                  // Saved
                  notificator.show("Field saved", true);
                }

                // Hide
                cusfields.fieldshide();

                // Reload list
                cusfields.fieldsload(formrel);
              } else {
                // General error
                alert("General error. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      } else {
        // Option type
        if (
          (opt == "select" && optionscount < 2) ||
          (opt == "radios" && optionscount < 2)
        ) {
          // Show
          $("#warn-selsuff").show();
        } else if (opt == "querystring_hidden" || opt == "querystring_text") {
          // Show
          $("#warn-quesuff").show();
        } else {
          // Show
          $("#warn-insuff").show();
        }
      }

      return false;
    },
    optionadd: function () {
      // Get text
      let val = $("#cusfield-option-text").val();

      // Variables
      let htout = "",
        optionscount = 0;

      // Count the number of options (max 15)
      $("#cusfield-options .fieldoptionval").each(function () {
        // Add
        optionscount++;
      });

      // Empty?
      if (validate.empty(val) && optionscount < 15) {
        // Add
        optscount++;

        // Html
        htout +=
          '<div class="des-t2 sortoption fieldoptionval nocallback" id="field_opt_n' +
          optscount +
          '">';
        htout += '	<div class="lft">';
        htout += '		<div class="pd">';
        htout +=
          '			<input type="text" name="field_option[]" value="' +
          val +
          '" maxlength="50" placeholder="Option Text" class="opttext" />';
        htout += "		</div>";
        htout += "	</div>";
        htout += '	<div class="rgt">';
        htout += "		<ul>";
        htout +=
          '			<li><a href="#" title="" class="sort" data-rel="field_opt_n' +
          optscount +
          '" tabindex="-1"><i class="material-icons">reorder</i></a></li>';
        htout +=
          '			<li><a href="#" title="" class="delete" data-rel="field_opt_n' +
          optscount +
          '" tabindex="-1"><i class="material-icons">delete</i></a></li>';
        htout += "		</ul>";
        htout += "	</div>";
        htout += '	<div class="clr"></div>';
        htout += "</div>";

        // Append to list
        $("#cusfield-options").append(htout);

        // Reset
        $("#cusfield-option-text").val("");

        // Focus
        $("#cusfield-option-text").focus();

        // Attach events
        cusfields.optionsattach();

        // Toogle adder
        cusfields.optionscounttoogle();

        // Destroy
        //$(".sortable").sortable("destroy");

        // Sortable
        sortables.initialize();
      }
    },
    optionscounttoogle: function () {
      // Variables
      let optionscount = 0;

      // Count the number of options (max 15)
      $("#cusfield-options .fieldoptionval").each(function () {
        // Add
        optionscount++;
      });

      // Show / hide
      if (optionscount >= 15) {
        // Hide
        $("#cusfield-adder").hide();
      } else {
        // Show
        $("#cusfield-adder").show();
      }
    },
    optionremove: function (f) {
      // Get relation
      let rel = $(f).attr("data-rel");

      // Remove
      $("#" + rel).remove();

      // Toogle adder
      cusfields.optionscounttoogle();

      // Destroy
      //$(".sortable").sortable("destroy");

      // Sortable
      sortables.initialize();
    },
    optionsattach: function () {
      // Loop
      $("#cusfield-options .fieldoptionval").each(function () {
        // Get delete
        let delobj = $(this).find(".delete");

        // Already attached?
        let delcls = $(delobj).hasClass("isset");

        // Not set?
        if (!delcls) {
          // Attach
          $(delobj).on("click", function () {
            cusfields.optionremove(this);
            return false;
          });
        }
      });
    },
    rsvpformlabelsform: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");

      // Execute
      let execute = true;

      // Validate
      if (!validate.empty(f.title.value)) {
        // Execute
        execute = false;

        // Error
        $(f.title).addClass("inperr");
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#rsvpformlabels").serialize();

        $("#rsvpformlabels .save").addClass("disabled");

        // Disable submit
        $("#rsvpformlabels .save").prop("disabled", true);

        // Saving
        notificator.show("Saving");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-labels.php",
          data: dat,
          cache: false,
          success: function (data) {
            $("#rsvpformlabels .save").removeClass("disabled");

            // Disable submit
            $("#rsvpformlabels .save").prop("disabled", false);

            // Parse
            const obj = parseResponse(data);

            // Handle
            if (obj.data.ok == "true") {
              // Saved
              notificator.show("Saved", true);
            } else {
              // General error
              alert("General error. Please try again..");
            }
          },
        });
      }

      return false;
    },
    rsvpforminfo: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");

      // Execute
      let execute = true;

      // Validate
      if (!validate.empty(f.template_name.value)) {
        // Execute
        execute = false;

        // Error
        $(f.template_name).addClass("inperr");
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#rsvpforminfo").serialize();

        $("#rsvpforminfo .save").addClass("disabled");

        // Disable submit
        $("#rsvpforminfo .save").prop("disabled", true);

        // Saving
        notificator.show("Saving");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-info.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              $("#rsvpforminfo .save").removeClass("disabled");

              // Disable submit
              $("#rsvpforminfo .save").prop("disabled", false);

              // Handle
              if (obj.data.ok == "true") {
                // Saved
                notificator.show("Saved", true);
              } else {
                // General error
                alert("General error. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    rsvpformsignup: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");

      // Execute
      let execute = true;

      // Validate
      if (!validate.empty(f.subject.value)) {
        // Execute
        execute = false;

        // Error
        $(f.subject).addClass("inperr");
      }

      // Validate
      if (!validate.empty(f.template.value)) {
        // Execute
        execute = false;

        // Error
        $(f.template).addClass("inperr");
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#rsvpformsignup").serialize();

        $("#rsvpformsignup .save").addClass("disabled");

        // Disable submit
        $("#rsvpformsignup .save").prop("disabled", true);

        // Saving
        notificator.show("Saving");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-signup.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              $("#rsvpformsignup .save").removeClass("disabled");

              // Disable submit
              $("#rsvpformsignup .save").prop("disabled", false);

              // Handle
              if (obj.data.ok == "true") {
                // Saved
                notificator.show("Saved", true);
              } else {
                // General error
                alert("General error. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    rsvpformreminder1: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");

      // Execute
      let execute = true;

      // Validate
      if (!validate.empty(f.subject.value)) {
        // Execute
        execute = false;

        // Error
        $(f.subject).addClass("inperr");
      }

      // Validate
      if (!validate.empty(f.template.value)) {
        // Execute
        execute = false;

        // Error
        $(f.template).addClass("inperr");
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#rsvpformreminder1").serialize();

        $("#rsvpformreminder1 .save").addClass("disabled");

        // Disable submit
        $("#rsvpformreminder1 .save").prop("disabled", true);

        // Saving
        notificator.show("Saving");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-reminder1.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              $("#rsvpformreminder1 .save").removeClass("disabled");

              // Disable submit
              $("#rsvpformreminder1 .save").prop("disabled", false);

              // Handle
              if (obj.data.ok == "true") {
                // Saved
                notificator.show("Saved", true);
              } else {
                // General error
                alert("General error. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    rsvpformreminder2: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");

      // Execute
      let execute = true;

      // Validate
      if (!validate.empty(f.subject.value)) {
        // Execute
        execute = false;

        // Error
        $(f.subject).addClass("inperr");
      }

      // Validate
      if (!validate.empty(f.template.value)) {
        // Execute
        execute = false;

        // Error
        $(f.template).addClass("inperr");
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#rsvpformreminder2").serialize();

        $("#rsvpformreminder2 .save").addClass("disabled");

        // Disable submit
        $("#rsvpformreminder2 .save").prop("disabled", true);

        // Saving
        notificator.show("Saving");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-reminder2.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              $("#rsvpformreminder2 .save").removeClass("disabled");

              // Disable submit
              $("#rsvpformreminder2 .save").prop("disabled", false);

              // Handle
              if (obj.data.ok == "true") {
                // Saved
                notificator.show("Saved", true);
              } else {
                // General error
                alert("General error. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    rsvpformfollowup1: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");

      // Execute
      let execute = true;

      // Validate
      if (!validate.empty(f.subject.value)) {
        // Execute
        execute = false;

        // Error
        $(f.subject).addClass("inperr");
      }

      // Validate
      if (!validate.empty(f.template.value)) {
        // Execute
        execute = false;

        // Error
        $(f.template).addClass("inperr");
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#rsvpformfollowup1").serialize();

        $("#rsvpformfollowup1 .save").addClass("disabled");

        // Disable submit
        $("#rsvpformfollowup1 .save").prop("disabled", true);

        // Saving
        notificator.show("Saving");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-followup1.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              $("#rsvpformfollowup1 .save").removeClass("disabled");

              // Disable submit
              $("#rsvpformfollowup1 .save").prop("disabled", false);

              // Handle
              if (obj.data.ok == "true") {
                // Saved
                notificator.show("Saved", true);
              } else {
                // General error
                alert("General error. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    rsvpformeventupdate: function (f) {
      // Remove error classes
      $(".inperr").removeClass("inperr");

      // Execute
      let execute = true;

      // Validate
      if (!validate.empty(f.subject.value)) {
        // Execute
        execute = false;

        // Error
        $(f.subject).addClass("inperr");
      }

      // Validate
      if (!validate.empty(f.template.value)) {
        // Execute
        execute = false;

        // Error
        $(f.template).addClass("inperr");
      }

      // Valid?
      if (execute) {
        // Get data
        let dat = $("#rsvpformeventupdate").serialize();

        $("#rsvpformeventupdate .save").addClass("disabled");

        // Disable submit
        $("#rsvpformeventupdate .save").prop("disabled", true);

        // Saving
        notificator.show("Saving");

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-event-update.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              $("#rsvpformeventupdate .save").removeClass("disabled");

              // Disable submit
              $("#rsvpformeventupdate .save").prop("disabled", false);

              // Handle
              if (obj.data.ok == "true") {
                // Saved
                notificator.show("Saved", true);
              } else {
                // General error
                alert("General error. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    deleteform: function (f) {
      // Get id
      let fid = $(f).attr("data-id");
      let eid = $(f).attr("data-event-id");

      // CSRF
      let csrf = $(f).attr("data-csrf");

      // Confirm
      let con = confirm("Are you sure you want to delete the form?");

      // Delete
      if (con) {
        // Notify
        notificator.show("Deleting form");

        // Set data
        let dat = "id=" + fid + "&csrf=" + csrf;

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-delete.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Updated
              if (obj.data.ok == "true") {
                // Notify
                notificator.show("Form deleted", true);

                // Reload
                setTimeout(function () {
                  // Go back
                  location.href = "/templates/rsvpforms";
                }, 1000);
              } else {
                // Error
                alert("An error has occurred. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
          error: function () {
            // Fail
            alert("An error has occurred. Please try again..");
          },
        });
      }
    },
    deletersvpuser: function (f) {
      // Get id
      let fid = $(f).attr("data-userid");

      // CSRF
      let csrf = $(f).attr("data-csrf");

      // Confirm
      let con = confirm("Are you sure you want to delete the attendee?");

      // Delete
      if (con) {
        // Notify
        notificator.show("Deleting");

        // Set data
        let dat = "id=" + fid + "&csrf=" + csrf;

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/event-rsvp-form-user-delete.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Updated
              if (obj.data.ok == "true") {
                // Notify
                notificator.show("Deleted", true);

                // Reload
                setTimeout(function () {
                  // Go back
                  location.reload();
                }, 1000);
              } else {
                // Error
                alert("An error has occurred. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
          error: function () {
            // Fail
            alert("An error has occurred. Please try again..");
          },
        });
      }
    },
    deletesubsuser: function (f) {
      // Get id + cal
      let fid = $(f).attr("data-userid");
      let cal = $(f).attr("data-cal");

      // CSRF
      let csrf = $(f).attr("data-csrf");

      // Confirm
      let con = confirm("Are you sure you want to delete the subscriber?");

      // Delete
      if (con) {
        // Notify
        notificator.show("Deleting");

        // Set data
        let dat = "id=" + fid + "&cal=" + cal + "&csrf=" + csrf;

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/calendar/calendar-subscriber-user-delete.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Updated
              if (obj.data.ok == "true") {
                // Notify
                notificator.show("Deleted", true);

                // Reload
                setTimeout(function () {
                  // Go back
                  location.reload();
                }, 1000);
              } else {
                // Error
                alert("An error has occurred. Please try again..");
              }
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
          error: function () {
            // Fail
            alert("An error has occurred. Please try again..");
          },
        });
      }
    },
    templatecopy: function (f) {
      // Get template ID +  type
      let tid = $(f).attr("data-template-id");
      let typ = $(f).attr("data-type");

      // CSRF
      let csrf = $(f).attr("data-csrf");

      // Notify
      notificator.show("Duplicating..");

      // Set data
      let dat = "id=" + tid + "&type=" + typ + "&csrf=" + csrf;

      // Ajax
      $.ajax({
        type: "POST",
        url: "/source/web/actions/templates/template-copy.php",
        data: dat,
        cache: false,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);

          // OK
          if (obj.meta.code == "200") {
            // Updated
            if (obj.data.ok == "true") {
              // Notify
              notificator.show("Duplicated", true);

              // Reload
              setTimeout(function () {
                // Go back
                location.reload();
              }, 1000);
            } else {
              // Error
              alert("An error has occurred. Please try again..");
            }
          } else {
            // Notify
            notificator.break();

            // Error
            csrfpop.show();
          }
        },
        error: function () {
          // Fail
          alert("An error has occurred. Please try again..");
        },
      });
    },
  };
})();

// Attach
$(document).ready(function () {
  cusfields.initialize();
});

/* Sortables
---------------------------------------------------------------- */

var sortables = (function () {
  return {
    initialize: function () {
      // Attach
      $(".sortable .sortoption").on("click", function () {
        return false;
      });

      // Initialize sortables
      $(".sortable").sortable({
        items: ".sortoption",
        helper: fixHelper,
        //helper: "clone",
        handle: ".sort",
        update: function (event, ui) {
          // Get relation id
          if (typeof ui.item === "object" && ui.item.length > 0) {
            let fid = $(ui.item).attr("id");

            // Callback
            if (!$("#" + fid).hasClass("nocallback")) {
              sortables.getsortorder(fid);
            }
          }
        },
        sort: function (event, ui) {
          let $target = $(event.target);
          if (!/html|body/i.test($target.offsetParent()[0].tagName)) {
            let top =
              event.pageY -
              $target.offsetParent().offset().top -
              ui.helper.outerHeight(true) / 2;
            ui.helper.css({ top: top + "px" });
          }
        },
      });
    },
    getsortorder: function (id) {
      // Get pararent div
      let fid = $("#" + id)
        .closest(".sortable")
        .attr("id");
      let csrf = $("#" + id)
        .closest(".sortable")
        .attr("data-csrf");

      // Match
      if (fid == "customfield-sortable") {
        // Get form id
        let foi = $("#customfield-sortable").attr("data-form-id");
        // Set fields variable
        let flds = "";

        // Loop
        $("#customfield-sortable .sortoption").each(function () {
          // Collect
          let fld = $(this).attr("data-id");

          // Add
          flds += fld + ",";
        });

        // Notify
        notificator.show("Updating");

        // Data
        let dat = "id=" + foi + "&sortorder=" + flds + "&csrf=" + csrf;

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/events/rsvp-form-fields-sortorder.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Notify
              notificator.show("Updated", true);
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      // Match
      if (fid == "meettype-sortable") {
        // Call
        sortables.getsortorder();
      }
    },
  };
})();

/* Attach */
$(document).ready(function () {
  sortables.initialize();
});

// Table row width fixer
var fixHelper = function (e, ui) {
  // Get children
  ui.children().each(function () {
    // Set width
    $(this).width($(this).width());
  });

  // Return
  return ui;
};

/* Attendees list
---------------------------------------------------------------- */

var attendeeslist = (function () {
  return {
    initialize: function () {
      // Attach
      $("#attn-filter").on("click", function () {
        attendeeslist.filtershow(this);
        return false;
      });
      $("#attn-filter-drop .reset").on("click", function () {
        attendeeslist.filterreset(this);
        return false;
      });
      $("#attn-filter-drop .done").on("click", function () {
        attendeeslist.filterdone(this);
        return false;
      });
      $("#attn-filter-drop .opt-t1 .inl").on("click", function () {
        attendeeslist.filteropts(this);
        return false;
      });
      $("#attn-filter-drop").on("click", function (event) {
        event.stopPropagation();
      });
      $("#attn-download").on("click", function () {
        attendeeslist.download(this);
        return false;
      });
    },
    download: function (f) {
      // Get href
      let ref = $(f).attr("data-href");

      // Download
      location.href = ref;
    },
    filterreset: function (f) {
      // Reset
      $("#attn-filter-drop .opt-t1 .inl").removeClass("checked");
      $("#attn-filter-drop #attn-filter-sort").val("desc");

      $("#attn-filter-drop .opt-t1 .inl").first().addClass("checked");
    },
    filterdone: function (f) {
      // Get attending + sort value
      let atn = $("#attn-filter-drop .checked").attr("data-value");
      let srt = $("#attn-filter-drop #attn-filter-sort").val();
      // Query
      let que = "";
      // Toggle
      if (atn == "all" && srt == "desc") {
        // No que
      } else {
        if (atn != "all") {
          que += "?attending=" + atn;
        }
        if (srt != "desc") {
          if (que != "") {
            que += "&order=" + srt;
          } else {
            que += "?order=" + srt;
          }
        }
      }

      // Change
      location.href = location.href.split("?")[0] + que;
    },
    filteropts: function (f) {
      // Reset
      $("#attn-filter-drop .opt-t1 .inl").removeClass("checked");
      $("#attn-filter-drop .opt-t1 input").prop("checked", false);

      $(f).addClass("checked");
      $(f).find("input").prop("checked", true);
    },
    filtershow: function (f) {
      // Unbind
      $(document).unbind(".attendeeslistwin");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.attendeeslistwin", function (event) {
          // Hide
          attendeeslist.filterhide();
        });
      }, 200);

      // Show
      $("#attn-filter-drop").show();
    },
    filterhide: function (f) {
      // Hide
      $("#attn-filter-drop").hide();

      // Unbind
      $(document).unbind(".attendeeslistwin");
    },
  };
})();

/* Attach */
$(document).ready(function () {
  attendeeslist.initialize();
});

/* Subscribers list
---------------------------------------------------------------- */

var subscriberslist = (function () {
  return {
    initialize: function () {
      // Attach
      $("#subs-filter").on("click", function () {
        subscriberslist.filtershow(this);
        return false;
      });
      $("#subs-filter-drop .reset").on("click", function () {
        subscriberslist.filterreset(this);
        return false;
      });
      $("#subs-filter-drop .done").on("click", function () {
        subscriberslist.filterdone(this);
        return false;
      });
      $("#subs-filter-drop .opt-t1 .inl").on("click", function () {
        subscriberslist.filteropts(this);
        return false;
      });
      $("#subs-filter-drop").on("click", function (event) {
        event.stopPropagation();
      });
      $("#subs-download").on("click", function () {
        subscriberslist.download(this);
        return false;
      });
    },
    download: function (f) {
      // Get href
      let ref = $(f).attr("data-href");

      // Download
      location.href = ref;
    },
    filterreset: function (f) {
      // Reset
      $("#subs-filter-drop .opt-t1 .inl").removeClass("checked");
      $("#subs-filter-drop #subs-filter-sort").val("desc");

      $("#subs-filter-drop .opt-t1 .inl").first().addClass("checked");
    },
    filterdone: function (f) {
      // Get status + sort value
      let atn = $("#subs-filter-drop .checked").attr("data-value");
      let srt = $("#subs-filter-drop #subs-filter-sort").val();
      // Query
      let que = "";
      // Toggle
      if (atn == "active" && srt == "desc") {
        // No que
      } else {
        if (atn != "all") {
          que += "?status=" + atn;
        }
        if (srt != "desc") {
          if (que != "") {
            que += "&order=" + srt;
          } else {
            que += "?order=" + srt;
          }
        }
      }

      // Change
      location.href = location.href.split("?")[0] + que;
    },
    filteropts: function (f) {
      // Reset
      $("#subs-filter-drop .opt-t1 .inl").removeClass("checked");
      $("#subs-filter-drop .opt-t1 input").prop("checked", false);

      $(f).addClass("checked");
      $(f).find("input").prop("checked", true);
    },
    filtershow: function (f) {
      // Unbind
      $(document).unbind(".subscriberslistwin");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.subscriberslistwin", function (event) {
          // Hide
          subscriberslist.filterhide();
        });
      }, 200);

      // Show
      $("#subs-filter-drop").show();
    },
    filterhide: function (f) {
      // Hide
      $("#subs-filter-drop").hide();

      // Unbind
      $(document).unbind(".subscriberslistwin");
    },
  };
})();

/* Attach */
$(document).ready(function () {
  subscriberslist.initialize();
});

/* Search box for calendar subscribers or RSVP attendees
---------------------------------------------------------------- */
var searchboxsr = (function () {
  // Variables
  let working = false;

  return {
    initialize: function () {
      // Attach
      $("#searchboxsrlnk").on("click", function () {
        searchboxsr.show(this);
        return false;
      });
      $("#searchbox .clo").on("click", function () {
        searchboxsr.hide(this);
        return false;
      });
      $("#searchboxfrm").on("submit", function () {
        return searchboxsr.validate(this);
      });

      // Exists?
      if ($("#searchboxsrlnk").length) {
        // CTRL + F
        $(window).keydown(function (e) {
          // CTRL + F
          if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
            // Prevent
            e.preventDefault();

            // Show search bar
            searchboxsr.show(this);
          }

          // Escape
          if (e.keyCode == "27" && $("#searchbox").hasClass("show")) {
            // Hide
            searchboxsr.hide(this);
          }
        });
      }
    },
    show: function () {
      // Show
      $("#searchbox").show();
      $("#searchbox").addClass("show");

      // Disable "filter" + "download"
      $(".tab-t1 .bts .bt-t1").css("visibility", "hidden");
      $(".tab-t1 .bts .bt-t2").css("visibility", "hidden");

      // Focus
      setTimeout(function () {
        $("#searchboxval").focus();
      }, 300);
    },
    hide: function () {
      // Hide
      $("#searchbox").hide();
      $("#searchbox").removeClass("show");

      // Disable "filter" + "download"
      $(".tab-t1 .bts .bt-t1").css("visibility", "visible");
      $(".tab-t1 .bts .bt-t2").css("visibility", "visible");

      // Reset
      $("#searchboxval").val("");

      // If a search has been run, clicking on X will reload the page
      if ($("#searchbox").hasClass("searchrun")) {
        // Reload
        location.reload();
      }
    },
    validate: function (f) {
      let styurl = "";
      // Type of search
      let styp = $("#searchboxsrlnk").attr("data-type"),
        styw = "";

      if (styp == "event-attendees") {
        styw = "event";
        styurl = "ajax.event.attendees.search.php";
      } else if (styp == "calendar-subscribers") {
        styw = "calendar";
        styurl = "ajax.calendars.subscribers.search.php";
      }

      // Allowed?
      if (!working) {
        // Reset
        $("#searchbox").removeClass("err-t1");
        // Variables
        let execute = true;

        // Validate empty
        if (!validate.empty($("#searchboxval").val())) {
          execute = false;

          // Error
          $("#searchbox").addClass("err-t1");
        }

        // Execute
        if (execute) {
          // Remove warnings
          $("#searchbox").removeClass("err-t1");
          // Get data
          let dat = $("#searchboxfrm").serialize();

          working = true;

          // Loading
          $("#searchbox").addClass("loading");
          // Set, set
          $("#searchbox").addClass("searchrun");

          // Ajax
          $.ajax({
            type: "POST",
            url: "/source/web/templates/" + styurl,
            data: dat,
            cache: false,
            success: function (data) {
              // Calendar or event
              if (styw == "calendar") {
                // Append
                $("#tab-subscribers .conlist-t1").html(data);
                $("#tab-subscribers .hideonsearch").hide();

                // Attach
                $("#tab-subscribers .conlist-t1 .lnk").on("click", function () {
                  eventform2.subshow(this);
                  return false;
                });
                $("#tab-subscribers .conlist-t1 .deletesubsuser").on(
                  "click",
                  function () {
                    cusfields.deletesubsuser(this);
                    return false;
                  }
                );
              } else if (styw == "event") {
                // Append
                $("#tab-attendees .conlist-t1").html(data);
                $("#tab-attendees .hideonsearch").hide();

                // Attach
                $("#tab-rsvp-attendees .conlist-t1 .lnk").on(
                  "click",
                  function () {
                    eventform1.subshow(this);
                    return false;
                  }
                );
                $("#tab-attendees .conlist-t1 .lnk").on("click", function () {
                  eventform1.subshow(this);
                  return false;
                });
                $("#tab-attendees .conlist-t1 .deletersvpuser").on(
                  "click",
                  function () {
                    cusfields.deletersvpuser(this);
                    return false;
                  }
                );
              }

              // Clear loading
              $("#searchbox").removeClass("loading");

              working = false;
            },
            error: function () {
              console.log("Error");
            },
          });
        }
      }

      return false;
    },
  };
})();

// Attach
$(document).ready(function () {
  searchboxsr.initialize();
});

/* CSRF popup
---------------------------------------------------------------- */

var csrfpop = (function () {
  return {
    show: function (f) {
      // Build menu if it doesn't exists
      csrfpop.typehtml(f);
      // Show
      setTimeout(function () {
        $("#pop-csrf").addClass("show");
      }, 300);
    },
    refresh: function (f) {
      // Refresh
      location.reload();
    },
    typehtml: function (f) {
      // Get object
      let obj = $d("pop-csrf");

      // Set variable
      let html = "";

      // Add HTML
      if (!obj) {
        html += '<div class="pop-csrf" id="pop-csrf">';
        html += '	<div class="rle">';
        html += '		<div class="lf">';
        html += "			<p>";
        html += "				Page token has expired. <br />";
        html += "				<span>Please refresh page <em>(F5)</em> to save.</span>";
        html += "			</p>";
        html += "		</div>";
        html += '		<div class="rg">';
        html += '			<input type="button" value="Refresh" class="refresh" />';
        html += "		</div>";
        html += '		<div class="cl"></div>';
        html += "	</div>";
        html += "</div>";

        // Append
        $("body").append(html);

        // Append
        $("#pop-csrf .refresh").on("click", function () {
          csrfpop.refresh(this);
        });
      }
    },
  };
})();

/* Idle
---------------------------------------------------------------- */

var idletime = (function () {
  // Variables
  let timeout = 30 * 60000,
    timeout_warn = 25 * 60000,
    idletimer = null,
    logouttimer = null,
    countdown_timer = null;

  return {
    initialize: function (f) {
      // Get cookie values
      let acc_timeout = $("#doc").attr("data-timeout");

      // Keep session?
      if (acc_timeout == "true") {
        // Initialize
        idletime.timers();

        // Event listeners
        $(window).on(
          "mousemove mousedown keypress touchstart click scroll",
          function () {
            idletime.timers();
          }
        );
      }
    },
    timers: function () {
      // Clear
      clearTimeout(idletimer);
      clearTimeout(logouttimer);
      clearTimeout(countdown_timer);

      // Warn
      idletimer = setTimeout(function () {
        idletime.warn();
      }, timeout_warn);

      // Log out
      logouttimer = setTimeout(function () {
        idletime.logout();
      }, timeout);

      // Hide
      idletime.hide();
    },
    warn: function (f) {
      // Build menu if it doesn't exists
      idletime.typehtml(f);
      // Show
      setTimeout(function () {
        $("#pop-inat1").addClass("show");
      }, 300);
      // Set countdown
      idletime.countdown();
    },
    hide: function (f) {
      // Remove
      $("#pop-inat1").removeClass("show");
    },
    logout: function (f) {
      // Redirect
      location.href = "/source/web/actions/user/signout.php";
    },
    typehtml: function (f) {
      // Get object
      let obj = $d("pop-inat1");

      // Set variable
      let html = "";

      // Add HTML
      if (!obj) {
        html += '<div class="pop-inat1" id="pop-inat1">';
        html += '	<div class="rle">';
        html += '		<div class="lf">';
        html += "			<p>";
        html += "				Inactivity warning. <br />";
        html +=
          '				<span>Session expires in <span id="pop-inat1-time"></span>.</span>';
        html += "			</p>";
        html += "		</div>";
        html += '		<div class="rg">';
        html += '			<input type="button" value="OK" class="refresh" />';
        html += "		</div>";
        html += '		<div class="cl"></div>';
        html += "	</div>";
        html += "</div>";

        // Append
        $("body").append(html);

        // Append
        $("#pop-inat1 .refresh").on("click", function () {
          csrfpop.refresh(this);
        });
      }
    },
    countdown: function () {
      // Set the date we're counting down to
      let countdown_total = 60000 * 5,
        countdown_tmp = 0;

      // Update the count down every 1 second
      countdown_timer = setInterval(function () {
        // Add
        countdown_tmp = countdown_tmp + 1000;

        // Find the distance between now and the count down date
        let distance = countdown_total - countdown_tmp;

        // Time left
        //var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        //var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (minutes.length == "1") {
          minutes = "0" + minutes;
        }
        if (seconds.length == "1") {
          seconds = "0" + seconds;
        }

        // Display the result in the element with id="demo"
        $("#pop-inat1-time").text(minutes + "m " + seconds + "s");

        // Countdown done
        if (distance < 0) {
          clearInterval(countdown_timer);
        }
      }, 1000);
    },
  };
})();

/* Attach */
$(document).ready(function () {
  idletime.initialize();
});

/* Password indicator
---------------------------------------------------------------- */

var passwordindicator = (function () {
  return {
    initialize: function () {
      // Any instance?
      if ($(".passwordindicator").length > 0) {
        // Append
        $(".passwordindicator").on("keypress keyup keydown", function () {
          passwordindicator.checkpassstrength(this);
        });
      }

      // Any instance?
      if ($(".passwordtoogle").length > 0) {
        // Append
        $(".passwordtoogle").on("click touchstart", function () {
          passwordindicator.toogletype(this);
        });
      }
    },
    toogletype: function (f) {
      // Get type
      let typ = $(".passwordindicator").prop("type");

      // Toggle
      if (typ == "password") {
        $(".passwordindicator").prop("type", "text");
        $(".passwordtoogle").addClass("visible");
      } else {
        $(".passwordindicator").prop("type", "password");
        $(".passwordtoogle").removeClass("visible");
      }
    },
    checkpassstrength: function (f) {
      // Get score
      //let score = passwordindicator.score(f.value);

      // When to show the indicator
      if (!validate.passwordLength(f.value)) {
        // Clear
        $(".passwordvisindi").attr("class", "passwordvisindi");
        $(".passwordvisindi").text("");
      } else {
        // Update
        if (validate.containsUppercase(f.value) && validate.containsLowercase(f.value)) {
          $(".passwordvisindi").attr("class", "passwordvisindi strong");
          $(".passwordvisindi").text("Valid");
        } else {
          $(".passwordvisindi").attr("class", "passwordvisindi weak");
          $(".passwordvisindi").text("Invalid");
        }
      }
    },
    score: function (pass) {
      // Variables
      let score = 0;
      if (!pass || !validate.passwordLength(pass) || !validate.containsUppercase(pass) || !validate.containsLowercase(pass)) {
        return score;
      }

      // Award every unique letter until 5 repetitions
      let letters = new Object();
      for (let i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
      }

      // Bonus points for mixing it up
      let variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
      };

      let variationcount = 0;

      // Loop variations
      for (let check in variations) {
        variationcount += variations[check] == true ? 1 : 0;
      }

      // Collect, set score
      score += (variationcount - 1) * 10;

      return parseInt(score);
    },
  };
})();

/* Attach */
$(document).ready(function () {
  passwordindicator.initialize();
});

/* Newsletter unsubscribe page
---------------------------------------------------------------- */

var unsubpage = (function () {
  return {
    initialize: function () {
      // Any unsubscribe page?
      if ($(".unsub").length > 0) {
        // Append
        $("#frm-unsub .check").on("click", function () {
          unsubpage.toggle(this);
          return false;
        });
        $("#frm-unsub").on("submit", function () {
          return unsubpage.validate(this);
        });
      }
    },
    validate: function (f) {
      // Hide
      $("#frm-unsub-saved").hide();

      // Execute
      let execute = true;

      // Valid?
      if (execute) {
        // Notify
        notificator.show("Saving");

        // Get data
        let dat = $("#frm-unsub").serialize();

        // Set loading
        $("#frm-unsub .save").addClass("sbt-load-t1");

        // Disable submit
        $("#frm-unsub .save").prop("disabled", true);

        // Ajax
        $.ajax({
          type: "POST",
          url: "/source/web/actions/user/email-preferences.php",
          data: dat,
          cache: false,
          success: function (data) {
            // Parse
            const obj = parseResponse(data);

            // OK
            if (obj.meta.code == "200") {
              // Clear loading
              $("#frm-unsub .save").removeClass("sbt-load-t1");

              // Enable submit
              $("#frm-unsub .save").prop("disabled", false);

              // Notify
              notificator.show("Saved", true);

              // Show
              $("#frm-unsub-saved").show();

              // Scroll to top
              $("html, body").animate(
                { scrollTop: 0 },
                "slow",
                "easeInOutQuart"
              );
            } else {
              // Notify
              notificator.break();

              // Error
              csrfpop.show();
            }
          },
        });
      }

      return false;
    },
    toggle: function (f) {
      // Check / uncheck
      if ($(f).hasClass("checked")) {
        $(f).removeClass("checked");
        $(f).find("input").val("false");
      } else {
        $(f).addClass("checked");
        $(f).find("input").val("true");
      }
    },
  };
})();

/* Attach */
$(document).ready(function () {
  unsubpage.initialize();
});

/* Doc helper
---------------------------------------------------------------- */
let dochelper = (function () {
  // Variables
  let open = false,
    loaded = false;

  return {
    initialize: function () {
      // Attach
      $(".dochelper").on("click", function () {
        dochelper.toogle(this);
      });

      // Loaded?
      if (!loaded) {
        // Load
        dochelper.loadjs();
        loaded = true;
      }
    },
    loadjs: function () {
      // Load
      !(function (e, t, n) {
        function a() {
          let e = t.getElementsByTagName("script")[0],
            n = t.createElement("script");
          (n.type = "text/javascript"),
            (n.async = !0),
            (n.src = "https://beacon-v2.helpscout.net"),
            e.parentNode.insertBefore(n, e);
        }
        if (
          ((e.Beacon = n =
            function (t, n, a) {
              e.Beacon.readyQueue.push({ method: t, options: n, data: a });
            }),
          (n.readyQueue = []),
          "complete" === t.readyState)
        )
          return a();
        e.attachEvent
          ? e.attachEvent("onload", a)
          : e.addEventListener("load", a, !1);
      })(window, document, window.Beacon || function () {});

      // Init
      window.Beacon("init", "5d34a6c8-08c0-4675-935b-aa607f7ef8ec");
      // Attach
      Beacon("on", "open", dochelper.onopen);
    },
    onopen: function () {
      // Toggle classes
      $(".dochelper .search").removeClass("on");
      $(".dochelper .close").addClass("on");

      open = true;
    },
    toogle: function () {
      // Open / closed?
      if (!open) {
        // Close
        Beacon("open");

        // Toggle classes
        $(".dochelper .search").removeClass("on");
        $(".dochelper .close").addClass("on");

        open = true;
      } else {
        // Close
        Beacon("close");

        // Toggle classes
        $(".dochelper .search").addClass("on");
        $(".dochelper .close").removeClass("on");

        open = false;
      }
    },
  };
})();

/* Dynamic select box
---------------------------------------------------------------- */
var dynamicselect = (function () {
  return {
    initialize: function () {
      // Attach
      $(".autoresize").on("change", function () {
        dynamicselect.fit(this);
      });
      // Loop
      $(".autoresize").each(function () {
        // Set object
        let obj = $(this);

        // Invoke
        dynamicselect.fit(obj);
      });
    },
    fitall: function () {
      // Loop
      $(".autoresize").each(function () {
        // Set object
        let obj = $(this);
        // Invoke
        dynamicselect.fit(obj);
      });
    },
    fit: function (f) {
      // Element
      let elm = $(f);
      // Get styles
      let pz = $(elm).css("font-size");
      let p0 = $(elm).css("paddingLeft");
      let p1 = $(elm).css("paddingTop");
      let p2 = $(elm).css("paddingRight");
      let p3 = $(elm).css("paddingBottom");

      // Append temporary container
      $("body").append(
        '<div id="dynamicselecttmp" style="position:absolute;left:0px;top:0px;padding-left:' +
          p0 +
          ";padding-top:" +
          p1 +
          ";padding-right:" +
          p2 +
          ";padding-bottom:" +
          p3 +
          ";z-index:100000;font-size:" +
          pz +
          ';white-space:nowrap;"></div>'
      );

      // Get text/values based on element type, update text
      if (elm.is("select")) {
        $("#dynamicselecttmp").html($(elm).find("option:selected").text());
      } else {
        $("#dynamicselecttmp").html($(elm).val().trim());
      }

      // Get width
      let wid = $("#dynamicselecttmp").outerWidth();
      // Remove temporary container
      $("#dynamicselecttmp").remove();
      // Update width
      $(elm).width(wid);
    },
  };
})();

/* Attach */
$(document).ready(function () {
  dynamicselect.initialize();
});

/* Content tabs
---------------------------------------------------------------- */
var contenttabs = (function () {
  return {
    initialize: function () {
      // Exists?
      if ($(".tab-t1").length) {
        // Append
        $(".tab-t1 .ab a").on("click", function () {
          contenttabs.show(this);
          return false;
        });
      }
    },
    show: function (f) {
      // Reset
      $(".tab-t1 .ab a").removeClass("active");
      // Hide
      $(".contenttab").hide();
      $(".contenttab").removeClass("show");
      // Add class
      $(f).addClass("active");
      // Get relation
      let rel = $(f).attr("data-rel");
      // Show
      $("#" + rel).show();

      setTimeout(function () {
        $("#" + rel).addClass("show");
      }, 200);
    },
  };
})();

// Attach
$(document).ready(function () {
  contenttabs.initialize();
});

/* Frontpage + product pages
---------------------------------------------------------------- */

var fropro = (function () {
  // Global variables
  let fsval = 0;

  return {
    initialize: function () {
      // Attach
      $(".videodemo").on("click", function () {
        fropro.videodemoplay(this);
        return false;
      });
      $(".videodemoclose").on("click", function () {
        fropro.videodemohide(this);
        return false;
      });
      $("#vid-dem-t1 video").on("click", function (event) {
        event.stopPropagation();
      });
      $("#vid-dem-t1 video").on("ended", function () {
        fropro.videodemoended(this);
        return false;
      });
      $(".apismenu a").on("click", function () {
        fropro.apismenu(this);
        return false;
      });
      $(".fnt-sub-t1 .anclnk").on("click", function () {
        fropro.anchorlnk(this);
        return false;
      });

      // Get videos
      $(".videoauto").on("canplay canplaythrough", function () {
        // Activate
        fropro.activatevideo(this);
      });

      // Frontpage text switch
      setTimeout(function () {
        fropro.fswitchinit(true);
      }, 1000);
    },
    activatevideo: function (f) {
      // Play
      $(f).get(0).play();
    },
    videodemoplay: function (f) {
      // Show
      $("#vid-dem-t1").show();
      $("#vid-dem-t1-bg").show();

      $("#vid-dem-t1").css("visibility", "hidden");

      // Get dimensions
      let wh = $(window).height();
      let vh = $("#vid-dem-t1 video").height();
      let th = (wh - vh) / 2 - 30;
      if (th < 50) {
        th = 40;
      }

      $("#vid-dem-t1 .pd").css("paddingTop", th + "px");
      $("#vid-dem-t1").css("visibility", "visible");
      // Play
      $("#vid-dem-t1 video").get(0).play();
      // Unbind
      $(document).unbind(".videodemo");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.touchend.videodemo", function () {
          fropro.videodemohide();
        });
      }, 200);
    },
    videodemoended: function (f) {
      // Rewind
      $("#vid-dem-t1 video").get(0).currentTime = 1;
    },
    videodemohide: function (f) {
      // Show
      $("#vid-dem-t1").hide();
      $("#vid-dem-t1-bg").hide();
      // Play
      $("#vid-dem-t1 video").get(0).pause();
      // Unbind
      $(document).unbind(".videodemo");
    },
    apismenu: function (f) {
      // Reset
      $("#swi-exm1").hide();
      $("#swi-exm2").hide();
      $("#swi-exm3").hide();
      $("#swi-exm1-des").hide();
      $("#swi-exm2-des").hide();
      $("#swi-exm3-des").hide();

      // Reset menu
      $(".apismenu a").removeClass("active");
      // Set active
      $(f).addClass("active");
      // Get rel
      let rel = $(f).attr("data-rel");

      // Show
      $("#" + rel).show();
      $("#" + rel + "-des").show();
    },
    anchorlnk: function (f) {
      // Get "href"
      let ref = $(f).attr("href");
      // Remove #
      ref = ref.replace("#", "");
      // Get top height
      let th = $(".top").outerHeight();
      // Go to warning
      let t = $("#" + ref).offset().top - th - 90;
      // Scroll to top
      $("html, body").animate({ scrollTop: t }, "slow", "easeInOutQuart");
    },
    fswitchinit: function (t) {
      // Exists?
      if ($(".fnt-t1 .cc").length) {
        // Reset
        $(".cc .hide").removeClass("hide");
        // Start interval
        if (t) {
          setInterval(function () {
            fropro.fswitch();
          }, 3000);
        }
      }
    },
    fswitch: function () {
      // Add
      fsval++;
      // Reset
      if (fsval == "6") {
        fsval = 0;
      }

      // Toogle
      $(".cc .l1").css(
        "width",
        $(".cc .l1 .i" + (fsval + 1) + " span").outerWidth() + "px"
      );
      $(".cc .l1").attr("class", "ul l1 x" + fsval);

      // Timed
      //setTimeout(function(){
      $(".cc .l2").css(
        "width",
        $(".cc .l2 .i" + (fsval + 1) + " span").outerWidth() + "px"
      );
      $(".cc .l2").attr("class", "ul l2 x" + fsval);
      //}, 400);
    },
  };
})();

// Attach
$(document).ready(function () {
  fropro.initialize();
});

/* Plan limitation popup
---------------------------------------------------------------- */

var plnlmtpop = (function () {
  // Variables
  let accwrntimer = null,
    accwrncol = false,
    accwrncoltim = null;

  return {
    initialize: function () {
      // Exists?
      if ($(".plnlmtnot").length > 0) {
        // Append
        $(".plnlmtnot").on("click", function () {
          plnlmtpop.show(this);
          return false;
        });
      }

      // Warnings? (bottom right)
      if ($(".accwrn-t1").length > 0) {
        // Append
        $(".accwrn-t1").on("mouseover", function () {
          plnlmtpop.wrnboriover(this);
          return false;
        });
        $(".accwrn-t1").on("mouseout", function () {
          plnlmtpop.wrnboriout(this);
          return false;
        });
        $(".accwrn-t1").on("click", function () {
          plnlmtpop.wrnborishowwarning(this);
          return false;
        });

        // Show warning on init? Else show bottom right warning
        if ($("#accwrn").hasClass("showoninit")) {
          // Exceed type
          let exctype = "";

          // Exceed type
          if ($("#accwrn").hasClass("exceed3")) {
            exctype = "exceed3";
          } else if ($("#accwrn").hasClass("exceed4")) {
            exctype = "exceed4";
          } else if ($("#accwrn").hasClass("exceed5")) {
            exctype = "exceed5";
          } else if ($("#accwrn").hasClass("exceed6")) {
            exctype = "exceed6";
          }

          // Any exceeds we're looking for?
          if (exctype != "") {
            // Get pre-selected calendar view type
            let wrniniexc = cookies.read("warning_init_" + exctype);
            // Show?
            if (wrniniexc != "true") {
              // Show
              setTimeout(function () {
                plnlmtpop.wrnborishowwarningoninit($("#accwrn"), exctype);
              }, 5000);
            }
          }
        } else {
          // Run warning
          plnlmtpop.wrnboriinit();
        }
      }

      // Timed prevent
      setTimeout(function () {
        // Prevent features
        plnlmtpop.preventfeatures();
      }, 500);
    },
    preventfeatures: function () {
      // Prevents
      $(".preventselect")
        .unbind()
        .on("change", function () {
          plnlmtpop.preventselect(this);
        });

      // Remove copy, bind prevent
      $(".preventcopy").removeClass("copyclipbutton copyatm");
      $(".preventcopy")
        .unbind()
        .on("click", function () {
          plnlmtpop.preventcopy(this);
        });

      $(".preventembedpopup").unbind();
      $(".preventembedpopup").on("click", function () {
        plnlmtpop.preventembedpopup(this);
        return false;
      });

      $(".preventoptionsselect .itm").not(".default").unbind();
      $(".preventoptionsselect .itm")
        .not(".default")
        .on("click", function () {
          plnlmtpop.preventoptionsselect(
            $(this).closest(".preventoptionsselect")
          );
          return false;
        });

      // Template copy
      $(".preventactionsclick .item .templatecopy").unbind();
      $(".preventactionsclick .item .templatecopy").on("click", function () {
        plnlmtpop.preventactionclick($(this).closest(".preventactionsclick"));
        return false;
      });
      $(".preventactionsclick").find(".check, .edit, .delete, .move").unbind();
      $(".preventactionsclick")
        .find(".check, .edit, .delete, .move")
        .on("click", function () {
          plnlmtpop.preventactionclick($(this).closest(".preventactionsclick"));
          return false;
        });

      // Change from "submit" to "button"
      $(".preventbuttonsave").attr("type", "button");
      $(".preventbuttonsave")
        .unbind()
        .on("click", function () {
          plnlmtpop.preventformsave(this);
        });

      // Change from "submit" to "button"
      $(".preventbuttonclick").attr("type", "button");
      $(".preventbuttonclick")
        .unbind()
        .on("click", function () {
          plnlmtpop.preventbuttonproceed(this);
        });

      // Disable link
      $(".preventlinkclick")
        .unbind()
        .on("click", function () {
          plnlmtpop.preventlinkclick(this);
          return false;
        });

      // Disable list links
      $(".preventitemlinkclick a")
        .unbind()
        .on("click", function () {
          plnlmtpop.preventitemlinkclick(this);
          return false;
        });

      // Prevent form submit
      $(".preventformsubmit")
        .unbind()
        .on("submit", function () {
          return plnlmtpop.preventformsubmit(this);
        });
      $(".preventformsubmit .save").attr("type", "button");
      $(".preventformsubmit .save")
        .unbind()
        .on("click", function () {
          plnlmtpop.preventformsubmitclick($(this).closest("form"));
          return false;
        });

      // Exceed notices
      if ($(".exceednotice").length) {
        // Get first exceed notice
        let exlm = $(".exceednotice").first();

        // Invoke
        plnlmtpop.exceednotice(exlm);
      }
    },
    show: function (f) {
      // Does pop up exists?
      plnlmtpop.addpopup(f);

      // Timed show
      setTimeout(function () {
        $("#pop-plan-limit-bg").addClass("show");
        $("#pop-plan-limit").addClass("show");
      }, 200);

      // Get dimensions
      let st = $(window).scrollTop();

      // Unbind
      $(document).unbind(".plnlmtpopobj");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.plnlmtpopobj", function () {
          plnlmtpop.hide();
        });
      }, 200);

      // Dimmer
      $("#doc").addClass("dimmer overflow");
    },
    hide: function (f) {
      // Hide
      $("#pop-plan-limit-bg").removeClass("show");
      $("#pop-plan-limit").removeClass("show");
      $("#pop-plan-limit-close").remove();

      // Unbind
      $(document).unbind(".plnlmtpopobj");

      // Destroy
      setTimeout(function () {
        $("#pop-plan-limit-bg").remove();
        $("#pop-plan-limit").remove();
      }, 1000);

      // Dimmer
      $("#doc").removeClass("dimmer overflow");

      // Re-enable form save button and disable loading
      // TODO: Set this up for all forms where the popup is used
      // Events
      $(".eventform1 .save").prop("disabled", false);
      // Event series options
      $("#repeat-confirm-opts .load").css("visibility", "hidden");
      $("#repeat-confirm-opts .save").prop("disabled", false);
      $("#repeat-confirm-opts .save").removeClass("disabled");
      // Account users
      $('#users-man-form input[type="submit"]').removeClass("sbt-load-t1");
      $('#users-man-form input[type="submit"]').prop("disabled", false);
    },
    upgrade: function (f) {
      // Go to
      location.href = "/account/plan";
    },
    trial: function (f) {

      // Add loading
      $('#pop-plan-limit .frtr .trial').addClass('loading');

      // Append trial
      misc.trialaccount(f, 'trial_confirm');

    },
    addpopup: function (f) {
      // Get object
      let obj = $d("pop-plan-limit");

      // Set variable
      let html = "",
        feat = "",
        feathd = "",
        featds = "",
        hdcls = "",
        objcls = "",
        wrpcls = "",
        ampltyp = "",
        ampltxt = "";
      let num_current_events = "",
        num_extra_events = "",
        num_limit_events = "";

      // Exists?
      if ($("#account-plnlmt-info")) {
        // Get event details
        num_current_events = $("#account-plnlmt-info").attr(
          "data-current-events"
        );
        num_extra_events = $("#account-plnlmt-info").attr("data-extra-events");
        num_limit_events = $("#account-plnlmt-info").attr("data-limit-events");
      }

      // Include trial option
      let trialshow = false;
      let trialoffer= false;

      // Has the user already invoked a trial?
      let trialapp = $('#account-guid').attr('data-account-trial-expires');

      // If a trial already has been applied = ignore, else offer trial
      if(trialapp == '' || trialapp == '0'){
        trialoffer = true;
      }

      // Object exists?
      if (!obj) {
        // Features
        let feas = [];

        feas[0] = "Recurring events";
        feas[1] = "Embeddable calendars";
        feas[2] = "Customizable templates <br>and RSVP forms";
        feas[3] = "Automated add-to-calendar links";
        feas[4] = "Analytics";
        feas[5] = "API & Zapier integration";
        feas[6] = "Embeddable event";
        feas[7] = "Custom event landing page templates";
        feas[8] = "Custom RSVP forms";
        feas[9] = "Event series";
        feas[10] = "Custom calendar landing page templates";
        feas[11] = "Custom embeddable calendar page templates";
        feas[12] = "Multiple active calendars";
        feas[13] = "Import/sync events from external calendars";
        feas[14] = "Collect information from calendar subscribers";
        feas[15] = "Embeddable events list";
        feas[16] = "More events";
        feas[17] = "Add more domains (whitelist) to your account";
        feas[18] = "Multiple account users";
        feas[19] = 'Customize the "From" name and email address';
        feas[20] = "Detailed analytics";
        feas[21] = "Send update emails to your RSVP attendees";
        feas[22] =
          "Remove AddEvent branding from all buttons and links, event and calendar landing page, and embeddable widgets";
        feas[23] = "More Account users";
        feas[24] = "Higher usage limits";
        feas[25] = "Dedicated Account Manager";
        feas[26] = "Developer & priority support";

        let account_plan_type = parseInt(
          $("#account-guid").attr("data-account-plan-type")
        );

        // Customize templates
        if ($(f).hasClass("unlk1")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Custom event landing page templates</span> are only available on Small Business, Professional, and Enterprise plans.";

          // List
          feat += '<li class="selected">' + feas[7] + "</li>";
          feat += "<li>" + feas[2] + "</li>";
          feat += "<li>" + feas[1] + "</li>";
          feat += "<li>" + feas[0] + "</li>";
          feat += "<li>" + feas[3] + "</li>";
          feat += "<li>" + feas[4] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "custom-template-event-page";
          trialshow = true;

        } else if ($(f).hasClass("unlk2")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Embeddable events</span> are only available on Small Business, Professional, and Enterprise plans.";

          // List
          feat += '<li class="selected">' + feas[6] + "</li>";
          feat += "<li>" + feas[1] + "</li>";
          feat += "<li>" + feas[0] + "</li>";
          feat += "<li>" + feas[3] + "</li>";
          feat += "<li>" + feas[4] + "</li>";
          feat += "<li>" + feas[5] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "embeddable-events";
          trialshow = true;
        } else if ($(f).hasClass("unlk3")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Custom RSVP forms</span> are only available on Small Business, Professional, and Enterprise plans.";

          // List
          feat += '<li class="selected">' + feas[8] + "</li>";
          feat += "<li>" + feas[7] + "</li>";
          feat += "<li>" + feas[1] + "</li>";
          feat += "<li>" + feas[0] + "</li>";
          feat += "<li>" + feas[3] + "</li>";
          feat += "<li>" + feas[4] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "custom-rsvp-forms";
          trialshow = true;
        } else if ($(f).hasClass("unlk4")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Embeddable RSVP forms</span> are only available on Small Business, Professional, and Enterprise plans.";

          // List
          feat += '<li class="selected">' + feas[8] + "</li>";
          feat += "<li>" + feas[7] + "</li>";
          feat += "<li>" + feas[1] + "</li>";
          feat += "<li>" + feas[0] + "</li>";
          feat += "<li>" + feas[3] + "</li>";
          feat += "<li>" + feas[4] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "embeddable-rsvp-forms";
          trialshow = true;
        } else if ($(f).hasClass("unlk5")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Event series</span> is only available on Small Business, Professional, and Enterprise plans.";

          // List
          feat += '<li class="selected">' + feas[9] + "</li>";
          feat += "<li>" + feas[0] + "</li>";
          feat += "<li>" + feas[2] + "</li>";
          feat += "<li>" + feas[7] + "</li>";
          feat += "<li>" + feas[8] + "</li>";
          feat += "<li>" + feas[4] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "event-series";
          trialshow = true;
        } else if ($(f).hasClass("unlk6")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Recurring events</span> are only available on Small Business, Professional, and Enterprise plans.";

          // List
          feat += '<li class="selected">' + feas[0] + "</li>";
          feat += "<li>" + feas[9] + "</li>";
          feat += "<li>" + feas[2] + "</li>";
          feat += "<li>" + feas[7] + "</li>";
          feat += "<li>" + feas[8] + "</li>";
          feat += "<li>" + feas[4] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "recurring-events";
          trialshow = true;
        } else if ($(f).hasClass("unlk7")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Custom calendar landing page templates</span> are only available on Small Business, Professional, and Enterprise plans.";

          // List
          feat += '<li class="selected">' + feas[10] + "</li>";
          feat += "<li>" + feas[11] + "</li>";
          feat += "<li>" + feas[2] + "</li>";
          feat += "<li>" + feas[1] + "</li>";
          feat += "<li>" + feas[8] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "custom-template-calendar-page";
          trialshow = true;
        } else if ($(f).hasClass("unlk8")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Custom embeddable calendar page templates</span> are only available on Small Business, Professional, and Enterprise plans.";

          // List
          feat += '<li class="selected">' + feas[11] + "</li>";
          feat += "<li>" + feas[10] + "</li>";
          feat += "<li>" + feas[8] + "</li>";
          feat += "<li>" + feas[2] + "</li>";
          feat += "<li>" + feas[1] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "custom-template-embeddable-calendar-page";
          trialshow = true;
        } else if ($(f).hasClass("unlk9")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Importing/syncing events from external calendars</span> is only available on Small Business, Professional, and Enterprise plans.";

          // List
          feat += "<li>" + feas[13] + "</li>";
          feat += "<li>" + feas[14] + "</li>";
          feat += "<li>" + feas[1] + "</li>";
          feat += "<li>" + feas[0] + "</li>";
          feat += "<li>" + feas[4] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "import-external-calendar";
          trialshow = true;
        } else if ($(f).hasClass("unlk10")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Embeddable calendars</span> are only available on Small Business, Professional, and Enterprise plans.";

          // List
          feat += "<li>" + feas[1] + "</li>";
          feat += "<li>" + feas[11] + "</li>";
          feat += "<li>" + feas[6] + "</li>";
          feat += "<li>" + feas[8] + "</li>";
          feat += "<li>" + feas[12] + "</li>";
          feat += "<li>" + feas[4] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "embeddable-calendar";
          trialshow = true;
        } else if ($(f).hasClass("unlk11")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Embeddable events lists</span> are only available on Small Business, Professional, and Enterprise plans.";

          // List
          feat += "<li>" + feas[15] + "</li>";
          feat += "<li>" + feas[1] + "</li>";
          feat += "<li>" + feas[11] + "</li>";
          feat += "<li>" + feas[6] + "</li>";
          feat += "<li>" + feas[8] + "</li>";
          feat += "<li>" + feas[12] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "embeddable-events-list";
          trialshow = true;
        } else if ($(f).hasClass("unlk12")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            'Customizing the <span>"From" name and email address</span> for RSVP emails is only available on Small Business, Professional, and Enterprise plans.';

          // List
          feat += "<li>" + feas[19] + "</li>";
          feat += "<li>" + feas[8] + "</li>";
          feat += "<li>" + feas[7] + "</li>";
          feat += "<li>" + feas[6] + "</li>";
          feat += "<li>" + feas[15] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "customize-from-name-email";
          trialshow = true;
        } else if ($(f).hasClass("unlk13")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Detailed Analytics</span> is only available on Small Business annual, Professional, or Enterprise plans.";

          // List
          feat += "<li>" + feas[20] + "</li>";
          feat += "<li>" + feas[1] + "</li>";
          feat += "<li>" + feas[2] + "</li>";
          feat += "<li>" + feas[8] + "</li>";
          feat += "<li>" + feas[10] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "detailed-analytics";
          trialshow = true;
        } else if ($(f).hasClass("unlk14")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "<span>Sending update emails</span> to your event attendees is only available on Small Business annual, Professional, or Enterprise plans.";

          // List
          feat += "<li>" + feas[21] + "</li>";
          feat += "<li>" + feas[8] + "</li>";
          feat += "<li>" + feas[7] + "</li>";
          feat += "<li>" + feas[19] + "</li>";
          feat += "<li>" + feas[4] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "send-update-emails";
          trialshow = true;
        } else if ($(f).hasClass("unlk15")) {
          // Headline + description
          feathd = "Upgrade to unlock";
          featds =
            "Remove <span>AddEvent branding</span> is only available on Small Business, Professional, or Enterprise plans.";

          // List
          feat += "<li>" + feas[22] + "</li>";
          feat += "<li>" + feas[7] + "</li>";
          feat += "<li>" + feas[8] + "</li>";
          feat += "<li>" + feas[1] + "</li>";

          // Amplitude
          ampltyp = "upgrade-icon-popup";
          ampltxt = "remove-addevent-branding";
          trialshow = true;
        }

        // Exceeds
        if ($(f).hasClass("exceed1")) {
          // Headline + description
          feathd = "You've reached your limit!";
          featds =
            "You have reached the <span>maximum number of calendars</span> you can create with your current plan.";

          // List
          if (account_plan_type == 2) {
            feat += "<li>" + feas[23] + "</li>";
            feat += "<li>" + feas[24] + "</li>";
            feat += "<li>" + feas[25] + "</li>";
            feat += "<li>" + feas[26] + "</li>";
          } else {
            feat += '<li class="selected">' + feas[12] + "</li>";
            feat += "<li>" + feas[0] + "</li>";
            feat += "<li>" + feas[1] + "</li>";
            feat += "<li>" + feas[2] + "</li>";
            feat += "<li>" + feas[3] + "</li>";
            feat += "<li>" + feas[4] + "</li>";
          }

          // Special classes
          hdcls = "l1";

          // Amplitude
          ampltyp = "enduser-limit-popup";
          ampltxt = "reached-number-of-calendars";
        } else if ($(f).hasClass("exceed2")) {
          // Headline + description
          feathd = "You've reached your limit!";
          featds =
            "You have reached the <span>maximum number of events</span> you can create with your current plan.";

          // List
          if (account_plan_type == 2) {
            feat += "<li>" + feas[23] + "</li>";
            feat += "<li>" + feas[24] + "</li>";
            feat += "<li>" + feas[25] + "</li>";
            feat += "<li>" + feas[26] + "</li>";
          } else {
            feat += '<li class="selected">' + feas[16] + "</li>";
            feat += "<li>" + feas[0] + "</li>";
            feat += "<li>" + feas[9] + "</li>";
            feat += "<li>" + feas[8] + "</li>";
            feat += "<li>" + feas[2] + "</li>";
            feat += "<li>" + feas[4] + "</li>";
          }

          // Special classes
          hdcls = "l1";

          // Amplitude
          ampltyp = "enduser-limit-popup";
          ampltxt = "reached-number-of-events";
        } else if ($(f).hasClass("exceed3")) {
          // Headline + description
          feathd = $("#accwrn .txtonl").text();
          featds = $("#accwrn .message").text();

          // List
          if (account_plan_type == 2) {
            feat += "<li>" + feas[23] + "</li>";
            feat += "<li>" + feas[24] + "</li>";
            feat += "<li>" + feas[25] + "</li>";
            feat += "<li>" + feas[26] + "</li>";
          } else {
            feat += "<li>" + feas[0] + "</li>";
            feat += "<li>" + feas[1] + "</li>";
            feat += "<li>" + feas[2] + "</li>";
            feat += "<li>" + feas[3] + "</li>";
            feat += "<li>" + feas[13] + "</li>";
            feat += "<li>" + feas[4] + "</li>";
            feat += "<li>" + feas[5] + "</li>";
          }

          // Special classes
          objcls = "mutx";

          // Amplitude
          ampltyp = "enduser-limit-popup";
          ampltxt = "usage-limits-nearing";
        } else if ($(f).hasClass("exceed4")) {
          // Headline + description
          feathd = $("#accwrn .txtonl").text();
          featds = $("#accwrn .message").text();

          // List
          if (account_plan_type == 2) {
            feat += "<li>" + feas[23] + "</li>";
            feat += "<li>" + feas[24] + "</li>";
            feat += "<li>" + feas[25] + "</li>";
            feat += "<li>" + feas[26] + "</li>";
          } else {
            feat += "<li>" + feas[0] + "</li>";
            feat += "<li>" + feas[1] + "</li>";
            feat += "<li>" + feas[2] + "</li>";
            feat += "<li>" + feas[3] + "</li>";
            feat += "<li>" + feas[12] + "</li>";
            feat += "<li>" + feas[13] + "</li>";
            feat += "<li>" + feas[4] + "</li>";
            feat += "<li>" + feas[5] + "</li>";
          }

          // Special classes
          objcls = "mutx slm2 tl";

          // Amplitude
          ampltyp = "enduser-limit-popup";
          ampltxt = "usage-limits-exceeded";
        } else if ($(f).hasClass("exceed5")) {
          // Headline + description
          feathd = $("#accwrn .txtonl").text();
          featds = $("#accwrn .message").text();

          // List
          if (account_plan_type == 2) {
            feat += "<li>" + feas[23] + "</li>";
            feat += "<li>" + feas[24] + "</li>";
            feat += "<li>" + feas[25] + "</li>";
            feat += "<li>" + feas[26] + "</li>";
          } else {
            feat += "<li>" + feas[0] + "</li>";
            feat += "<li>" + feas[1] + "</li>";
            feat += "<li>" + feas[2] + "</li>";
            feat += "<li>" + feas[3] + "</li>";
            feat += "<li>" + feas[4] + "</li>";
            feat += "<li>" + feas[5] + "</li>";
          }

          // Special classes
          objcls = "mutx slm1";

          // Amplitude
          ampltyp = "enduser-limit-popup";
          ampltxt = "usage-limits-paused";
        } else if ($(f).hasClass("exceed6")) {
          // Headline + description
          feathd = "Upgrade for more incredible tools 🚀";
          featds =
            "Upgrade now to unlock more powerful functionality such as embeddable calendars, automated add-to-calendar links, higher usage limits, and more!";

          // List
          if (account_plan_type == 2) {
            feat += "<li>" + feas[23] + "</li>";
            feat += "<li>" + feas[24] + "</li>";
            feat += "<li>" + feas[25] + "</li>";
            feat += "<li>" + feas[26] + "</li>";
          } else {
            feat += "<li>" + feas[0] + "</li>";
            feat += "<li>" + feas[1] + "</li>";
            feat += "<li>" + feas[2] + "</li>";
            feat += "<li>" + feas[3] + "</li>";
            feat += "<li>" + feas[4] + "</li>";
            feat += "<li>" + feas[5] + "</li>";
          }

          // Special classes
          objcls = "mutx slm3";

          // Amplitude
          ampltyp = "enduser-limit-popup";
          ampltxt = "unlock-more";
        } else if ($(f).hasClass("exceed7")) {
          // Headline + description
          feathd = "You've reached your limit!";
          featds =
            "You have reached the <span>maximum number of licensed domains</span> that you can add to your account with your current plan. <br /><br />Please upgrade to add more domains.";

          // List
          if (account_plan_type == 2) {
            feat += "<li>" + feas[23] + "</li>";
            feat += "<li>" + feas[24] + "</li>";
            feat += "<li>" + feas[25] + "</li>";
            feat += "<li>" + feas[26] + "</li>";
          } else {
            feat += '<li class="selected">' + feas[17] + "</li>";
            feat += "<li>" + feas[0] + "</li>";
            feat += "<li>" + feas[1] + "</li>";
            feat += "<li>" + feas[2] + "</li>";
            feat += "<li>" + feas[3] + "</li>";
            feat += "<li>" + feas[4] + "</li>";
          }

          // Special classes
          objcls = "mutx slm3";

          // Amplitude
          ampltyp = "enduser-limit-popup";
          ampltxt = "reached-domains";
        } else if ($(f).hasClass("exceed8")) {
          // Headline + description
          feathd = "You've reached your limit!";
          featds =
            "You have reached your plan's <span>maximum number of Account User</span>. Please upgrade to add more Account Users.";

          // List
          if (account_plan_type == 2) {
            feat += "<li>" + feas[23] + "</li>";
            feat += "<li>" + feas[24] + "</li>";
            feat += "<li>" + feas[25] + "</li>";
            feat += "<li>" + feas[26] + "</li>";
          } else {
            feat += '<li class="selected">' + feas[18] + "</li>";
            feat += "<li>" + feas[0] + "</li>";
            feat += "<li>" + feas[1] + "</li>";
            feat += "<li>" + feas[2] + "</li>";
            feat += "<li>" + feas[3] + "</li>";
            feat += "<li>" + feas[4] + "</li>";
          }

          // Special classes
          hdcls = "l1";

          // Amplitude
          ampltyp = "enduser-limit-popup";
          ampltxt = "reached-account-users";
        } else if ($(f).hasClass("exceed9")) {
          // Headline + description
          feathd = "You've reached your limit!";
          featds =
            "This calendar contains " +
            num_extra_events +
            " events. Duplicating this calendar would exceed the <span>maximum number of events</span> you can create with your current plan (you have used " +
            num_current_events +
            " / " +
            num_limit_events +
            " events so far).";

          // List
          if (account_plan_type == 2) {
            feat += "<li>" + feas[23] + "</li>";
            feat += "<li>" + feas[24] + "</li>";
            feat += "<li>" + feas[25] + "</li>";
            feat += "<li>" + feas[26] + "</li>";
          } else {
            feat += '<li class="selected">' + feas[18] + "</li>";
            feat += "<li>" + feas[0] + "</li>";
            feat += "<li>" + feas[1] + "</li>";
            feat += "<li>" + feas[2] + "</li>";
            feat += "<li>" + feas[3] + "</li>";
            feat += "<li>" + feas[4] + "</li>";
          }

          // Special classes
          hdcls = "l1";
          objcls = "mutx slm2";

          // Amplitude
          ampltyp = "enduser-limit-popup";
          ampltxt = "exceed-number-of-events";
        } else if ($(f).hasClass("exceed10")) {
          // Headline + description
          feathd = "You've reached your limit!";
          featds =
            "This event series contains " +
            num_extra_events +
            " events. You have currently used " +
            num_current_events +
            " / " +
            num_limit_events +
            " available on your current plan. Creating this event series would exceed the <span>maximum number of events</span> you can create with your current plan. <br /><br />Please update the event series or upgrade your plan to continue.";

          // List
          if (account_plan_type == 2) {
            feat += "<li>" + feas[23] + "</li>";
            feat += "<li>" + feas[24] + "</li>";
            feat += "<li>" + feas[25] + "</li>";
            feat += "<li>" + feas[26] + "</li>";
          } else {
            feat += '<li class="selected">' + feas[18] + "</li>";
            feat += "<li>" + feas[0] + "</li>";
            feat += "<li>" + feas[1] + "</li>";
            feat += "<li>" + feas[2] + "</li>";
            feat += "<li>" + feas[3] + "</li>";
            feat += "<li>" + feas[4] + "</li>";
          }

          // Special classes
          hdcls = "l1";
          objcls = "mutx slm2";

          // Amplitude
          ampltyp = "enduser-limit-popup";
          ampltxt = "exceed-number-of-events";
        } else if ($(f).hasClass("exceed11")) {
          // Headline + description
          feathd = "You've reached your limit!";
          featds =
            "Updating this event series would create " +
            num_extra_events +
            " new events. You have currently used " +
            num_current_events +
            " / " +
            num_limit_events +
            " available on your current plan. Creating this event series would exceed the <span>maximum number of events</span> you can create with your current plan. <br /><br />Please update the event series or upgrade your plan to continue.";

          // List
          if (account_plan_type == 2) {
            feat += "<li>" + feas[23] + "</li>";
            feat += "<li>" + feas[24] + "</li>";
            feat += "<li>" + feas[25] + "</li>";
            feat += "<li>" + feas[26] + "</li>";
          } else {
            feat += '<li class="selected">' + feas[18] + "</li>";
            feat += "<li>" + feas[0] + "</li>";
            feat += "<li>" + feas[1] + "</li>";
            feat += "<li>" + feas[2] + "</li>";
            feat += "<li>" + feas[3] + "</li>";
            feat += "<li>" + feas[4] + "</li>";
          }

          // Special classes
          hdcls = "l1";
          objcls = "mutx slm2";

          // Amplitude
          ampltyp = "enduser-limit-popup";
          ampltxt = "exceed-number-of-events";
        }

        // Set to "false"
        trialshow = false;

        // Show trial
        if(trialshow && trialoffer){
          wrpcls = "withtrial";
        }

        // Amplitude
        try {
          aeTracker.trackUpgradePopupViewed(ampltxt, ampltyp);
        } catch (e) {
          console.log(e);
        }

        // Hide checkmarks for plans above "Small Business"
        if (account_plan_type > 2) {
          objcls += " ppt1";
        }

        html += '<div class="upg-t1-clo" id="pop-plan-limit-close">';
        html += '	<i class="material-icons">close</i>';
        html += "</div>";

        html += '<div class="upg-t1-wrap ' + wrpcls + '" id="pop-plan-limit-bg">';
        html += '    <div class="px">';
        html += '        <div class="upg-t1-inn" id="pop-plan-limit">';

        html += '            <div class="upg-t1 ' + objcls + '">';
        html += '                <div class="bg">';
        html += '                    <div class="sha">';
        html += '                        <div class="rl">';
        html += '                            <div class="txt">';
        html += '                                <div class="bin">';
        html += '                                	<div class="hx">';
        html += '                                    	<p class="hd ' + hdcls + '">' + feathd + "</p>";
        html += '                                    	<p class="tx">' + featds + "</p>";
        html += "                                	</div>";
        html += '                                	<div class="bt">';
        html += '                                    	<button type="button" class="upgrade">';
        html += '                                       	 Upgrade Plan <span class="material-icons ico">bolt</span>';
        html += "                                    	</button>";
        html += "                                	</div>";

        html += '                                 <!-- Trial offer -->';
        html += '                                 <div class="frtr">';
        html += '                                     <div class="bdr"></div>';
        html += '                                     <div class="or">';
        html += '                                         <div class="io">OR</div>';
        html += '                                     </div>';
        html += '                                     <div class="lbl">';
        html += '                                         <p>Try our paid features for FREE</p>';
        html += '                                     </div>';
        html += '                                     <div class="act">';
        html += '                                         <button type="button" class="trial">';
        html += '                                             Start your 7-day trial';
        html += '                                         </button>';
        html += '                                         <div class="done"><div class="zoom-in"><span class="material-icons-outlined iu">done</span></div></div>';
        html += '                                     </div>';
        html += '                                     <div class="exp">';
        html += '                                         <p>';
        html += '                                             Your free trial starts instantly with no credit card required. ';
        html += '                                             When the trial period ends, your account will automatically revert to your previous plan.';
        html += '                                         </p>';
        html += '                                     </div>';
        html += '                                 </div>';

        html += "                                </div>";
        html += "                            </div>";
        html += '                            <div class="fea">';
        html += '                                <div class="pd">';
        html += "                                    <ul>" + feat + "</ul>";
        html += "                                </div>";
        html += '                                <div class="lbic"></div>';
        html += "                            </div>";
        html += "                        </div>";
        html += "                    </div>";
        html += "                </div>";
        html += "            </div>";

        html += "        </div>";
        html += "    </div>";
        html += "</div>";

        // Append
        $("body").append(html);

        // Attach
        $("#pop-plan-limit-close").on("click", function () {
          plnlmtpop.hide(this);
          return false;
        });
        $("#pop-plan-limit .upgrade").on("click", function () {
          // Amplitude
          try {
            aeTracker.trackUpgradeButtonClicked(
              "upgrade-button-clicked",
              ampltxt
            );
          } catch (e) {
            console.log(e);
          }
          // Upgrade
          plnlmtpop.upgrade(this);
          return false;
        });

        $("#pop-plan-limit .trial").on("click", function () {
          // Amplitude
          try {
            aeTracker.trackTrialActivated("trial-button-clicked", ampltxt);
          } catch (e) {
            console.log(e);
          }
          // Trial
          plnlmtpop.trial(this);
          return false;
        });

        $("#pop-plan-limit").on("click", function (event) {
          event.stopPropagation();
        });
      }
    },
    preventselect: function (f) {
      // Select default
      $(f).val("");
      // Hide save option (if available), show templates option
      $("#selevttmp-btn").hide();
      $("#selevttmp-btx").show();

      // Show popup
      plnlmtpop.show(f);
    },
    preventcopy: function (f) {
      // Show popup
      plnlmtpop.show(f);
    },
    preventembedpopup: function (f) {
      // Show popup
      plnlmtpop.show(f);
    },
    preventoptionsselect: function (f) {
      // Show popup
      plnlmtpop.show(f);
    },
    preventactionclick: function (f) {
      // Show popup
      plnlmtpop.show(f);
    },
    preventformsave: function (f) {
      // Show popup
      plnlmtpop.show(f);
    },
    preventbuttonproceed: function (f) {
      // Show popup
      plnlmtpop.show(f);
    },
    preventlinkclick: function (f) {
      // Show popup
      plnlmtpop.show(f);
    },
    preventitemlinkclick: function (f) {
      // Show popup
      plnlmtpop.show($(f).closest(".preventitemlinkclick"));
    },
    preventformsubmit: function (f) {
      // Show popup
      plnlmtpop.show(f);
      return false;
    },
    preventformsubmitclick: function (f) {
      // Show popup
      plnlmtpop.show(f);
    },
    exceednotice: function (f) {
      // Show popup
      plnlmtpop.show(f);
    },
    wrnboriinit: function () {
      // Show warning
      accwrntimer = setTimeout(function () {
        // Show warning
        $("#accwrn").removeClass("collapsed");
      }, 2000);
      accwrntimer = setTimeout(function () {
        // Hide warning
        $("#accwrn").addClass("collapsed");
      }, 7000);
    },
    wrnboriover: function () {
      accwrncol = true;
      // Show
      plnlmtpop.wrnborishow();
      // Clear timeout
      clearTimeout(accwrncoltim);
    },
    wrnboriout: function () {
      accwrncol = false;
      // Timed hide
      accwrncoltim = setTimeout(function () {
        // Hide
        plnlmtpop.wrnborihide();
      }, 1000);
    },
    wrnborishow: function () {
      // Show warning
      $("#accwrn").removeClass("collapsed");
    },
    wrnborihide: function () {
      // Show warning
      $("#accwrn").addClass("collapsed");
    },
    wrnborishowwarning: function (f) {
      // Show warning
      plnlmtpop.show(f);
    },
    wrnborishowwarningoninit: function (f, typ) {
      // Stop using cookies to prevent pop-ups from showing too often and instead use
      //  the NotificationLog db table through the NotificationHandler in the backend
      // Show warning
      plnlmtpop.show(f);
    },
  };
})();

// Ready
$(document).ready(function () {
  plnlmtpop.initialize();
});

/* FAQ
---------------------------------------------------------------- */
let faq = (function () {
  return {
    initialize: function () {
      // Attach
      $(".faq .acc .item .item-top").on("click", (e) => {
        // Set object
        let item = $(e.currentTarget).parents(".item");
        // If not active
        if (!$(item).hasClass("active")) {
          // Reset
          $(".faq .acc .item").removeClass("active");
          $(".faq .acc .item .txt").slideUp({
            done: () => {
              $(".faq .acc .item:not(.active)").removeClass("active");
            },
          });

          $(item)
            .find(".txt")
            .slideDown({
              start: () => {
                $(item).addClass("active");
              },
            });
        } else {
          // Set active
          $(".faq .acc .item .txt").slideUp({
            done: () => {
              $(".faq .acc .item").removeClass("active");
            },
          });
        }
      });
    },
  };
})();

// Attach
$(document).ready(function () {
  faq.initialize();
});

/* RRule generator
---------------------------------------------------------------- */

let rrule = (function () {
  return {
    initialize: function () {
      // Attach
      $("#rrule-pop #interval").on("change", function () {
        rrule.interval(this);
        return false;
      });
      $("#rrule-pop #end").on("change", function () {
        rrule.end(this);
        return false;
      });
      $("#rrule-pop #weekdays ul li").on("click", function () {
        rrule.weekdaysday(this);
        return false;
      });
      $("#rrule-pop #interval-num").on("change blur keyup", function () {
        rrule.getrule(this);
        return false;
      });

      $("#rrule-pop #end-num-val").on("keydown change blur", function (e) {

        // Value has to be larger than "0"
        if(e.target.value <= '0'){
          $("#rrule-pop #end-num-val").val('1');
        }

        if (e.which == "8" || e.which == "46") {
          // Prevent default
          e.preventDefault();
          // Set to null
          e.target.value = "";
        } else {
          // Get rule timed
          setTimeout(function () {
            rrule.getrule(this);
          }, 200);
        }
      });

      $("#rrule-pop #interval-num").on("change blur", function (e) {
        if (e.target.value == "" || e.target.value == "0") {
          e.target.value = 1;
        }
      });

      $("#rrule-pop .rad1_month_inp_dy").on("change", function () {
        rrule.getrule(this);
        return false;
      });
      $("#rrule-pop .rad2_month_inp_po").on("change", function () {
        rrule.getrule(this);
        return false;
      });
      $("#rrule-pop .rad2_month_inp_dy").on("change", function () {
        rrule.getrule(this);
        return false;
      });
      $("#rrule-pop .rad1_year_inp_mo").on("change", function () {
        rrule.getrule(this);
        return false;
      });
      $("#rrule-pop .rad1_year_inp_dy").on("change", function () {
        rrule.getrule(this);
        return false;
      });
      $("#rrule-pop .rad2_year_inp_po").on("change", function () {
        rrule.getrule(this);
        return false;
      });
      $("#rrule-pop .rad2_year_inp_dy").on("change", function () {
        rrule.getrule(this);
        return false;
      });
      $("#rrule-pop .rad2_year_inp_mo").on("change", function () {
        rrule.getrule(this);
        return false;
      });
      $("#rrule-pop #check-year-rad1, #rrule-pop #check-year-rad2").on(
        "click",
        function () {
          rrule.yearrad(this);
          return false;
        }
      );
      $("#rrule-pop #check-month-rad1, #rrule-pop #check-month-rad2").on(
        "click",
        function () {
          rrule.monthrad(this);
          return false;
        }
      );
      $("#rrule-pop .readmore").on("click", function () {
        rrule.readmore(this);
        return false;
      });
      $("#rrule-pop .foot .save").on("click", function () {
        rrule.formsave(this);
      });
      $("#rrule-pop .foot .cancel, #rrule-pop .close").on("click", function () {
        rrule.formcancel(this);
      });

      // Date picker
      $("#end-date-val")
        .datepicker({
          showWeek: true,
          firstDay: "1",
          dateFormat: "M d, yy",
          autoclose: true,
          minDate: 0,
        })
        .on("change", function (e) {
          // Timed, get date
          setTimeout(function () {
            // Get start date
            let sda = $("#end-date-val").datepicker("getDate");
            // Get dates
            let sdaf =
              sda.getFullYear() +
              ("0" + (sda.getMonth() + 1)).slice(-2) +
              ("0" + sda.getDate()).slice(-2) +
              "T235959";

            // Update
            $("#end-date-val").attr("data-date", sdaf);

            // Update
            rrule.getrule();
          }, 200);
        });

      // Convert date
      let d2 = new Date();

      // Add one month
      d2.setMonth(d2.getMonth() + 1);

      // Set new "to" date + set min date
      $("#end-date-val").datepicker("setDate", d2);

      // Date string attr
      let sdaf =
        d2.getFullYear() +
        ("0" + (d2.getMonth() + 1)).slice(-2) +
        ("0" + d2.getDate()).slice(-2) +
        "T235959";

      // Update
      $("#end-date-val").attr("data-date", sdaf);
    },
    initsetup: function () {
      // Variables
      let freq = "";
      // Rule
      let str = $("#rrule_fin").val().toLowerCase();
      // Split
      let strarr = str.split(";");

      // Loop
      for (let i = 0; i < strarr.length; i++) {
        // Split values
        let itmarr = strarr[i].split("=");

        // Get values
        let rnam = itmarr[0];
        let rval = itmarr[1];

        // FREQ
        if (rnam == "freq") {
          freq = rval;
        }
      }

      // Todays date variables
      let d = new Date();
      let now_month = d.getMonth() + 1;
      let now_date = d.getDate();
      let now_day = d.getDay(); // Sunday is 0, Monday is 1
      let now_day_lbl = "MO";

      // Toggle today labels
      if (now_day == "0") {
        now_day_lbl = "SU";
      } else if (now_day == "1") {
        now_day_lbl = "MO";
      } else if (now_day == "2") {
        now_day_lbl = "TU";
      } else if (now_day == "3") {
        now_day_lbl = "WE";
      } else if (now_day == "4") {
        now_day_lbl = "TH";
      } else if (now_day == "5") {
        now_day_lbl = "FR";
      } else if (now_day == "6") {
        now_day_lbl = "SA";
      }

      // Set all defaults
      if (freq == "") {
        // Set defaults
        $("#weekdays .wkdys" + now_day_lbl.toLowerCase()).addClass("active");
        $("#check-month-rad1").addClass("selected");
        $(".rad1_month_inp_dy").val(now_date);
        $(".rad2_month_inp_po").val("1");
        $(".rad2_month_inp_dy").val(now_day_lbl);
        $("#check-year-rad1").addClass("selected");
        $(".rad1_year_inp_mo").val(now_month);
        $(".rad1_year_inp_dy").val(now_date);
        $(".rad2_year_inp_dy").val(now_day_lbl);
        $(".rad2_year_inp_mo").val(now_month);
      } else if (freq == "daily") {
        // Set defaults
        $("#weekdays .wkdys" + now_day_lbl.toLowerCase()).addClass("active");
        $("#check-month-rad1").addClass("selected");
        $(".rad1_month_inp_dy").val(now_date);
        $(".rad2_month_inp_po").val("1");
        $(".rad2_month_inp_dy").val(now_day_lbl);
        $("#check-year-rad1").addClass("selected");
        $(".rad1_year_inp_mo").val(now_month);
        $(".rad1_year_inp_dy").val(now_date);
        $(".rad2_year_inp_dy").val(now_day_lbl);
        $(".rad2_year_inp_mo").val(now_month);
      } else if (freq == "weekly") {
        // Set defaults
        $("#check-month-rad1").addClass("selected");
        $(".rad1_month_inp_dy").val(now_date);
        $(".rad2_month_inp_po").val("1");
        $(".rad2_month_inp_dy").val(now_day_lbl);
        $("#check-year-rad1").addClass("selected");
        $(".rad1_year_inp_mo").val(now_month);
        $(".rad1_year_inp_dy").val(now_date);
        $(".rad2_year_inp_dy").val(now_day_lbl);
        $(".rad2_year_inp_mo").val(now_month);
      } else if (freq == "monthly") {
        // Set defaults
        $("#weekdays .wkdys" + now_day_lbl.toLowerCase()).addClass("active");
        $("#check-year-rad1").addClass("selected");
        $(".rad1_year_inp_mo").val(now_month);
        $(".rad1_year_inp_dy").val(now_date);
        $(".rad2_year_inp_dy").val(now_day_lbl);
        $(".rad2_year_inp_mo").val(now_month);
      } else if (freq == "yearly") {
        // Set defaults
        $("#weekdays .wkdys" + now_day_lbl.toLowerCase()).addClass("active");
        $("#check-month-rad1").addClass("selected");
        $(".rad1_month_inp_dy").val(now_date);
        $(".rad2_month_inp_po").val("1");
        $(".rad2_month_inp_dy").val(now_day_lbl);
      }
    },
    setup: function () {
      // Variables
      let freq = "",
        interval = "",
        byday = "",
        count = "",
        until = "",
        bymonthday = "",
        bysetpos = "",
        bymonth = "";
      // Get rule (if any)
      let rrl = $("#rrule_fin").val();
      let bydarr = [];

      // Hide all options
      $("#weekdays").hide();
      $("#year-inps").hide();
      $("#month-inps").hide();
      $("#end-date").hide();
      $("#end-num").hide();

      // Any rule
      if (rrl != "") {
        // Rule
        let str = $("#rrule_fin").val().toLowerCase();

        // Split
        let strarr = str.split(";");
        let itmarr = [];

        // Loop
        for (let i = 0; i < strarr.length; i++) {
          // Split values
          itmarr = strarr[i].split("=");

          // Get values
          let rnam = itmarr[0];
          let rval = itmarr[1];

          // FREQ
          if (rnam == "freq") {
            freq = rval;
          }

          // Interval
          if (rnam == "interval") {
            interval = rval;
          }

          // Count
          if (rnam == "count") {
            count = rval;
          }

          // Until
          if (rnam == "until") {
            until = rval;
          }

          // Byday
          if (rnam == "byday") {
            byday = rval;
          }

          // Bymonthday
          if (rnam == "bymonthday") {
            bymonthday = rval;
          }

          // Bymonth
          if (rnam == "bymonth") {
            bymonth = rval;
          }
        }

        // Empty values
        if (interval == "" || interval == "0") {
          interval = "1";
        }

        // Set "freq"
        if (freq == "daily") {
          $("#interval").val("day");
          $("#interval-num").val(interval);
        } else if (freq == "weekly") {
          $("#interval").val("week");
          $("#weekdays").show();

          // Add ","
          byday = byday + ",";

          // Split values
          bydarr = byday.split(",");

          // Loop days
          for (let io = 0; io < bydarr.length; io++) {
            // Contains anything?
            if (bydarr[io] != "") {
              // Add active
              $("#weekdays .wkdys" + bydarr[io]).addClass("active");
            }
          }
        } else if (freq == "monthly") {
          $("#interval").val("month");
          $("#month-inps").show();

          // Toggle
          if (bymonthday != "") {
            $("#check-month-rad1").addClass("selected");
            $(".rad1_month_inp_dy").val(bymonthday);
          } else {
            // Isolate number
            let iso_num = byday;
            iso_num = iso_num.replace("mo", "");
            iso_num = iso_num.replace("tu", "");
            iso_num = iso_num.replace("we", "");
            iso_num = iso_num.replace("th", "");
            iso_num = iso_num.replace("fr", "");
            iso_num = iso_num.replace("sa", "");
            iso_num = iso_num.replace("su", "");

            // Isolate day
            let iso_day = byday;
            iso_day = iso_day.replace("1", "");
            iso_day = iso_day.replace("2", "");
            iso_day = iso_day.replace("3", "");
            iso_day = iso_day.replace("4", "");
            iso_day = iso_day.replace("-1", "");
            iso_day = iso_day.replace("-", "");

            $("#check-month-rad2").addClass("selected");
            $(".rad2_month_inp_po").val(iso_num);
            $(".rad2_month_inp_dy").val(iso_day.toUpperCase());
          }
        } else if (freq == "yearly") {
          $("#interval").val("year");
          $("#year-inps").show();

          if (bymonthday != "") {
            $("#check-year-rad1").addClass("selected");
            $(".rad1_year_inp_mo").val(bymonth);
            $(".rad1_year_inp_dy").val(bymonthday);
          } else {
            // Isolate number
            let iso_num = byday;
            iso_num = iso_num.replace("mo", "");
            iso_num = iso_num.replace("tu", "");
            iso_num = iso_num.replace("we", "");
            iso_num = iso_num.replace("th", "");
            iso_num = iso_num.replace("fr", "");
            iso_num = iso_num.replace("sa", "");
            iso_num = iso_num.replace("su", "");
            iso_num = iso_num.replace(/,/gi, "");

            // Isolate day
            let iso_day = byday;
            iso_day = iso_day.replace("1", "");
            iso_day = iso_day.replace("2", "");
            iso_day = iso_day.replace("3", "");
            iso_day = iso_day.replace("4", "");
            iso_day = iso_day.replace("-1", "");
            iso_day = iso_day.replace("-", "");

            $("#check-year-rad2").addClass("selected");
            $(".rad2_year_inp_po").val(iso_num);
            $(".rad2_year_inp_dy").val(iso_day.toUpperCase());
            $(".rad2_year_inp_mo").val(bymonth);
          }
        }

        // Set "until"
        if (until != "") {
          // Get date info
          let yr = until.substr(0, 4);
          let mo = until.substr(4, 2);
          let dy = until.substr(6, 2);
          let mo_lbl = "";

          // Toggle month
          if (mo == "01") {
            mo_lbl = "Jan";
          } else if (mo == "02") {
            mo_lbl = "Feb";
          } else if (mo == "03") {
            mo_lbl = "Mar";
          } else if (mo == "04") {
            mo_lbl = "Apr";
          } else if (mo == "05") {
            mo_lbl = "May";
          } else if (mo == "06") {
            mo_lbl = "Jun";
          } else if (mo == "07") {
            mo_lbl = "Jul";
          } else if (mo == "08") {
            mo_lbl = "Aug";
          } else if (mo == "09") {
            mo_lbl = "Sep";
          } else if (mo == "10") {
            mo_lbl = "Oct";
          } else if (mo == "11") {
            mo_lbl = "Nov";
          } else if (mo == "12") {
            mo_lbl = "Dec";
          }

          // Final date
          let da_fin = mo_lbl + " " + dy + ", " + yr;

          $("#end").val("ondate");
          $("#end-date-val").val(da_fin);
          $("#end-date").show();

          // Get start date
          let sda = $("#end-date-val").datepicker("getDate");
          // Get dates
          let sdaf =
            sda.getFullYear() +
            ("0" + (sda.getMonth() + 1)).slice(-2) +
            ("0" + sda.getDate()).slice(-2) +
            "T235959";

          // Update
          $("#end-date-val").attr("data-date", sdaf);
        } else if (count != "") {
          $("#end-num-val").val(count);
          $("#end-num").show();
          $("#end").val("after");
        } else {
          $("#end").val("never");
        }
      }

      if (freq == "yearly") {
        $("#interval-num-rw").hide();
      } else {
        $("#interval-num-rw").show();
      }

      // Window width
      $("#rrule-pop").removeClass("w1 w2");

      if (freq == "daily" || freq == "weekly") {
        console.log("unused");
      } else if (freq == "monthly") {
        $("#rrule-pop").addClass("w2");
      } else if (freq == "yearly") {
        $("#rrule-pop").addClass("w1");
      }

      // Label
      rrule.rruleexp(rrl);
    },
    rruleexp: function (rule) {
      // Get date/times values
      let sda = $("#eventform1 #date_start").datepicker("getDate");
      let sdt = $("#eventform1 #date_start_time").val();
      // Start
      let sdaf =
        ("0" + (sda.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + sda.getDate()).slice(-2) +
        "/" +
        sda.getFullYear();
      // Date + time to validate
      let date_time_start = sdaf + " " + sdt;
      // Set data
      let dat = "rule=" + rule + "&date=" + date_time_start;
      // Post
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax_rrule_human.php",
        data: dat,
        success: function (data) {
          // Parse
          const obj = parseResponse(data);
          // Get variables
          let exp = obj.rule.exp;
          let eat = obj.rule.first_day_valid;
          let ead = obj.rule.first_day_date;
          let ead_yy = obj.rule.first_day_date_yy;
          let ead_mm = parseInt(obj.rule.first_day_date_mm) - 1;
          let ead_dd = obj.rule.first_day_date_dd;
          let eah = obj.rule.first_day_date_hm;

          // Incorrect start date
          if (eat == "false") {
            // Show
            $("#recurring-rule-error").show();
          } else {
            // Hide
            $("#recurring-rule-error").hide();
          }

          // Update
          $("#rule-exp").html(exp);
          $("#recurring-exp-rule").html(exp);

          return exp;
        },
      });
    },
    rruleexpX: function (rule) {
      // Variables
      let freq = "",
        interval = "",
        byday = "",
        count = "",
        until = "",
        bymonthday = "",
        bysetpos = "",
        bymonth = "";
      let exp = "";
      let strarr = [];
      let bydarr = [];
      let iso_day = "";

      // Any rule?
      if (rule) {
        // Get rule (if any)
        let rrl = rule.toLowerCase();

        // Any rule
        if (rrl != "") {
          // Split
          strarr = rrl.split(";");
          // Loop
          for (let i = 0; i < strarr.length; i++) {
            // Split values
            let itmarr = strarr[i].split("=");

            // Get values
            let rnam = itmarr[0];
            let rval = itmarr[1];

            // Get/set variables
            if (rnam == "freq") {
              freq = rval;
            }
            if (rnam == "interval") {
              interval = rval;
            }
            if (rnam == "count") {
              count = rval;
            }
            if (rnam == "until") {
              until = rval;
            }
            if (rnam == "byday") {
              byday = rval;
            }
            if (rnam == "bymonthday") {
              bymonthday = rval;
            }
            if (rnam == "bymonth") {
              bymonth = rval;
            }
            if (rnam == "bysetpos") {
              bysetpos = rval;
            }
          }

          // Set "freq"
          if (freq == "daily") {
            if (interval == "1") {
              exp += "Every day, ";
            } else {
              exp += "Every " + interval + " days, ";
            }
          } else if (freq == "weekly") {
            //Every week on Mon, until Feb 24, 2020, 7 occurrence(s)
            //Every 2 weeks on Mon, Wed, until Apr 6, 2020, 13 occurrence(s)

            // Label
            if (interval == "1") {
              exp += "Every week on ";
            } else {
              exp += "Every " + interval + " week on ";
            }

            // Add ","
            byday = byday + ",";
            // Split values
            bydarr = byday.split(",");

            // Loop days
            for (let io = 0; io < bydarr.length; io++) {
              // Contains anything?
              if (bydarr[io] != "") {
                // Label
                if (bydarr[io] == "mo") {
                  exp += "Mon, ";
                }
                if (bydarr[io] == "tu") {
                  exp += "Tue, ";
                }
                if (bydarr[io] == "we") {
                  exp += "Wed, ";
                }
                if (bydarr[io] == "th") {
                  exp += "Thu, ";
                }
                if (bydarr[io] == "fr") {
                  exp += "Fri, ";
                }
                if (bydarr[io] == "sa") {
                  exp += "Sat, ";
                }
                if (bydarr[io] == "su") {
                  exp += "Sun, ";
                }
              }
            }
          } else if (freq == "monthly") {
            //Every month on the 13 of the month, until Jul 13, 2020, 7 occurrence(s)
            //Every month on the First Sun, until Jul 13, 2020, 6 occurrence(s)

            // Label
            if (interval == "1" || interval == "") {
              exp += "Every month on the ";
            } else {
              exp += "Every " + interval + " months on the ";
            }

            // Toggle
            if (byday != "") {
              // Isolate number
              let iso_num = byday;
              iso_num = iso_num.replace("mo", "");
              iso_num = iso_num.replace("tu", "");
              iso_num = iso_num.replace("we", "");
              iso_num = iso_num.replace("th", "");
              iso_num = iso_num.replace("fr", "");
              iso_num = iso_num.replace("sa", "");
              iso_num = iso_num.replace("su", "");

              // Isolate day
              iso_day = byday;
              iso_day = iso_day.replace("1", "");
              iso_day = iso_day.replace("2", "");
              iso_day = iso_day.replace("3", "");
              iso_day = iso_day.replace("4", "");
              iso_day = iso_day.replace("-1", "");
              iso_day = iso_day.replace("-", "");

              // First, second etc
              if (iso_num == "1") {
                exp += "first ";
              } else if (iso_num == "2") {
                exp += "second ";
              } else if (iso_num == "3") {
                exp += "third ";
              } else if (iso_num == "4") {
                exp += "fourth ";
              } else if (iso_num == "-1") {
                exp += "last ";
              }

              // Monday, Tuesday etc
              if (iso_day == "mo") {
                exp += "Monday, ";
              } else if (iso_day == "tu") {
                exp += "Tuesday, ";
              } else if (iso_day == "we") {
                exp += "Wednesday, ";
              } else if (iso_day == "th") {
                exp += "Thursday, ";
              } else if (iso_day == "fr") {
                exp += "Friday, ";
              } else if (iso_day == "sa") {
                exp += "Saturday, ";
              } else if (iso_day == "su") {
                exp += "Sunday, ";
              }
            } else if (bymonthday != "") {
              // st, nd, rd
              if (bymonthday == "1") {
                bymonthday += "st";
              } else if (bymonthday == "2") {
                bymonthday += "nd";
              } else if (bymonthday == "3") {
                bymonthday += "rd";
              } else if (bymonthday == "21") {
                bymonthday += "st";
              } else if (bymonthday == "22") {
                bymonthday += "nd";
              } else if (bymonthday == "23") {
                bymonthday += "rd";
              } else if (bymonthday == "31") {
                bymonthday += "st";
              } else {
                bymonthday += "th";
              }

              // 14th of the month
              exp += bymonthday + " of the month, ";
            }
          } else if (freq == "yearly") {
            // Label
            exp += "Every year on ";

            if (byday != "") {
              // FREQ=YEARLY;BYMONTH=4;BYDAY=4SU,MO,TU,WE,TH,FR,SA
              // FREQ=YEARLY;BYMONTH=4;BYDAY=4MO,TU,WE,TH,FR
              // FREQ=YEARLY;BYMONTH=4;BYDAY=4SU,MO,TU,WE,TH,FR,SA

              // Isolate number
              let iso_num = byday;
              iso_num = iso_num.replace("mo", "");
              iso_num = iso_num.replace("tu", "");
              iso_num = iso_num.replace("we", "");
              iso_num = iso_num.replace("th", "");
              iso_num = iso_num.replace("fr", "");
              iso_num = iso_num.replace("sa", "");
              iso_num = iso_num.replace("su", "");
              iso_num = iso_num.replace(/,/gi, "");

              // Isolate day
              iso_day = byday;
              iso_day = iso_day.replace("1", "");
              iso_day = iso_day.replace("2", "");
              iso_day = iso_day.replace("3", "");
              iso_day = iso_day.replace("4", "");
              iso_day = iso_day.replace("-1", "");
              iso_day = iso_day.replace("-", "");

              // The
              exp += "the ";

              // First, second etc
              if (iso_num == "1") {
                exp += "first ";
              } else if (iso_num == "2") {
                exp += "second ";
              } else if (iso_num == "3") {
                exp += "third ";
              } else if (iso_num == "4") {
                exp += "fourth ";
              } else if (iso_num == "-1") {
                exp += "last ";
              }

              // Monday, Tuesday etc
              if (iso_day == "mo") {
                exp += "Monday ";
              } else if (iso_day == "tu") {
                exp += "Tuesday ";
              } else if (iso_day == "we") {
                exp += "Wednesday ";
              } else if (iso_day == "th") {
                exp += "Thursday ";
              } else if (iso_day == "fr") {
                exp += "Friday ";
              } else if (iso_day == "sa") {
                exp += "Saturday ";
              } else if (iso_day == "su") {
                exp += "Sunday ";
              } else if (iso_day == "su,mo,tu,we,th,fr,sa") {
                exp += "Day (all days) ";
              } else if (iso_day == "mo,tu,we,th,fr") {
                exp += "Weekday ";
              } else if (iso_day == "su,sa") {
                exp += "Weekend day ";
              }

              // Jan, Feb
              if (bymonth == "1") {
                exp += " of January, ";
              } else if (bymonth == "2") {
                exp += " of February, ";
              } else if (bymonth == "3") {
                exp += " of March, ";
              } else if (bymonth == "4") {
                exp += " of April, ";
              } else if (bymonth == "5") {
                exp += " of May, ";
              } else if (bymonth == "6") {
                exp += " of June, ";
              } else if (bymonth == "7") {
                exp += " of July, ";
              } else if (bymonth == "8") {
                exp += " of August, ";
              } else if (bymonth == "9") {
                exp += " of September, ";
              } else if (bymonth == "10") {
                exp += " of October, ";
              } else if (bymonth == "11") {
                exp += " of November, ";
              } else if (bymonth == "12") {
                exp += " of December, ";
              }
            } else {
              // Jan, Feb
              if (bymonth == "1") {
                exp += " January ";
              } else if (bymonth == "2") {
                exp += " February ";
              } else if (bymonth == "3") {
                exp += " March ";
              } else if (bymonth == "4") {
                exp += " April ";
              } else if (bymonth == "5") {
                exp += " May ";
              } else if (bymonth == "6") {
                exp += " June ";
              } else if (bymonth == "7") {
                exp += " July ";
              } else if (bymonth == "8") {
                exp += " August ";
              } else if (bymonth == "9") {
                exp += " September ";
              } else if (bymonth == "10") {
                exp += " October ";
              } else if (bymonth == "11") {
                exp += " November ";
              } else if (bymonth == "12") {
                exp += " December ";
              }

              // st, nd, rd
              if (bymonthday == "1") {
                bymonthday += "st";
              } else if (bymonthday == "2") {
                bymonthday += "nd";
              } else if (bymonthday == "3") {
                bymonthday += "rd";
              } else if (bymonthday == "21") {
                bymonthday += "st";
              } else if (bymonthday == "22") {
                bymonthday += "nd";
              } else if (bymonthday == "23") {
                bymonthday += "rd";
              } else if (bymonthday == "31") {
                bymonthday += "st";
              } else {
                bymonthday += "th";
              }

              // 14th of the month
              exp += bymonthday + ", ";
            }
          }

          /* END
                    -------------------------------------------------- */

          // Set "until"
          if (until != "") {
            // Get date info
            let yr = until.substr(0, 4);
            let mo = until.substr(4, 2);
            let dy = until.substr(6, 2);
            let mo_lbl = "";

            // Toggle month
            if (mo == "01") {
              mo_lbl = "Jan";
            } else if (mo == "02") {
              mo_lbl = "Feb";
            } else if (mo == "03") {
              mo_lbl = "Mar";
            } else if (mo == "04") {
              mo_lbl = "Apr";
            } else if (mo == "05") {
              mo_lbl = "May";
            } else if (mo == "06") {
              mo_lbl = "Jun";
            } else if (mo == "07") {
              mo_lbl = "Jul";
            } else if (mo == "08") {
              mo_lbl = "Aug";
            } else if (mo == "09") {
              mo_lbl = "Sep";
            } else if (mo == "10") {
              mo_lbl = "Oct";
            } else if (mo == "11") {
              mo_lbl = "Nov";
            } else if (mo == "12") {
              mo_lbl = "Dec";
            }

            // Final date
            let da_fin = mo_lbl + " " + dy + ", " + yr;

            // Label
            exp += "until " + da_fin;
          } else if (count != "") {
            // Label
            if (count == "1") {
              exp += "after " + count + " occurrence";
            } else {
              exp += "after " + count + " occurrences";
            }
          } else {
            // Label
            exp += " forever";
          }
        }
      }

      // Update
      $("#rule-exp").html(exp);

      return exp;
    },
    interval: function () {
      // Update RRULE
      rrule.getrule();

      // Get setup
      rrule.setup();
    },
    weekdaysday: function (f) {
      // Active?
      if ($(f).hasClass("active")) {
        // Remove
        $(f).removeClass("active");
      } else {
        $(f).addClass("active");
      }

      // Active count
      let count = 0;

      // Loop, count active days
      $("#weekdays .active").each(function () {
        count = count + 1;
      });

      // No days, set monday to active
      if (count == "0") {
        $(f).addClass("active");
      }

      // Update RRULE
      rrule.getrule();
    },
    end: function () {
      // Get value
      let val = $("#end").val();

      // Hide all
      $("#end-num").hide();
      $("#end-date").hide();

      // Toggle
      if (val == "never") {
        // Hide
        $("#end-num").hide();
      } else if (val == "after") {
        // Show
        $("#end-num").show();

        // Set to one, if nothing
        if ($("#end-num-val").val() == "") {
          $("#end-num-val").val("1");
        }
      } else if (val == "ondate") {
        // Show
        $("#end-date").show();
      }

      // Update RRULE
      rrule.getrule();
    },
    yearrad: function (f) {
      // Reset
      $("#year-inps .radio-t1").removeClass("selected");

      // Set to active
      $(f).addClass("selected");

      // Update RRULE
      rrule.getrule();
    },
    monthrad: function (f) {
      // Reset
      $("#month-inps .radio-t1").removeClass("selected");

      // Set to active
      $(f).addClass("selected");

      // Update RRULE
      rrule.getrule();
    },
    readmore: function (f) {
      // Get rel
      let rel = $(f).attr("data-rel");

      // Hide
      $(f).hide();
      // Show
      $("#" + rel).show();
    },
    getrule: function () {
      // Final string
      let str = "";

      // Get value
      let val = $("#interval").val();
      let end = $("#end").val();

      //RRULE:FREQ=MONTHLY;INTERVAL=18;COUNT=10;BYMONTHDAY=10,11,12,13,14,15

      // Frequence type
      if (val == "year") {
        // Collect
        str += "FREQ=YEARLY;";
      } else if (val == "month") {
        // Collect
        str += "FREQ=MONTHLY;";

        if ($("#interval-num").val() != "1") {
          str += "INTERVAL=" + $("#interval-num").val() + ";";
        }
      } else if (val == "week") {
        // Collect
        str += "FREQ=WEEKLY;";

        if ($("#interval-num").val() != "1") {
          str += "INTERVAL=" + $("#interval-num").val() + ";";
        }
      } else if (val == "day") {
        // Collect
        str += "FREQ=DAILY;";

        if ($("#interval-num").val() != "1") {
          str += "INTERVAL=" + $("#interval-num").val() + ";";
        }
      }

      // INTERVAL

      // End
      if (end == "never") {
        // Return nothing
        // FREQ=DAILY;INTERVAL=2
      } else if (end == "after") {

        // Collect
        if($("#end-num-val").val() <= '0'){
          str += "COUNT=1;";
        }else{
          str += "COUNT=" + $("#end-num-val").val() + ";";
        }

        // FREQ=DAILY;INTERVAL=2;COUNT=3
      } else if (end == "ondate") {
        // Collect
        str += "UNTIL=" + $("#end-date-val").attr("data-date") + ";";

        // FREQ=DAILY;INTERVAL=2;UNTIL=20191130T080000Z
      }

      // Toggle
      if (val == "year") {
        // Option 1
        if ($("#check-year-rad1").hasClass("selected")) {
          // Collect
          str += "BYMONTH=" + $(".rad1_year_inp_mo").val() + ";";
          str += "BYMONTHDAY=" + $(".rad1_year_inp_dy").val() + ";";

          // FREQ=YEARLY;BYMONTH=3;BYMONTHDAY=12;UNTIL=20191130T080000Z
        }

        // Option 2
        if ($("#check-year-rad2").hasClass("selected")) {
          // Collect
          str += "BYMONTH=" + $(".rad2_year_inp_mo").val() + ";";
          str +=
            "BYDAY=" +
            $(".rad2_year_inp_po").val() +
            $(".rad2_year_inp_dy").val() +
            ";";

          //str += 'BYMONTH=7;BYDAY=3FR';
          // FREQ=YEARLY;BYDAY=TU;BYSETPOS=1;BYMONTH=4;UNTIL=20191130T080000Z
        }
      } else if (val == "month") {
        // Option 1
        if ($("#check-month-rad1").hasClass("selected")) {
          // Collect
          str += "BYMONTHDAY=" + $(".rad1_month_inp_dy").val() + ";";
          // FREQ=MONTHLY;BYMONTHDAY=2;INTERVAL=1;UNTIL=20191130T080000Z
        }

        // Option 2
        if ($("#check-month-rad2").hasClass("selected")) {
          // Collect
          str +=
            "BYDAY=" +
            $(".rad2_month_inp_po").val() +
            $(".rad2_month_inp_dy").val() +
            ";";
          // FREQ=MONTHLY;BYSETPOS=2;BYDAY=TU;INTERVAL=1;UNTIL=20191130T080000Z
        }
      } else if (val == "week") {
        // Day vals
        let dvls = "";

        // Loop, add day
        $("#weekdays ul .active").each(function () {
          dvls += $(this).attr("data-val") + ",";
        });

        // Remove last comma
        dvls = dvls.replace(/,\s*$/, "");

        // Collect
        str += "BYDAY=" + dvls + ";";

        // FREQ=WEEKLY;BYDAY=SU,MO,TU,WE;INTERVAL=2;UNTIL=20191130T080000Z
      } else if (val == "day") {
        // Collect
        // FREQ=DAILY;INTERVAL=2;UNTIL=20191130T080000Z
      }

      // Remove last semi colon
      str = str.replace(/;\s*$/, "");

      // Update
      $("#rrule_fin").val(str);

      // Setup
      rrule.setup();
    },
    formsave: function () {
      // Get rule
      let rrl = $("#rrule_fin").val();
      // Get rule explaination
      let exp = rrule.rruleexp(rrl);

      // Any rule?
      if (rrl != "") {
        // Show + update rule
        $("#recurring-form-exp").show();
        $("#recurring-exp-rule").text(exp);

        $("#check-recurring").addClass("selected");
        $("#recurring").val("true");
        $("#recurring_rule").val(rrl);
      }

      // Hide
      rrule.hide();
    },
    formcancel: function () {
      // Hide
      rrule.hide();
    },
    show: function () {
      // Show
      $("#rrule-pop").show();
      $("#rrule-pop-bg").show();

      // Get dimensions
      let wh = $(window).height();
      let st = $(document).scrollTop();
      let ph = $("#rrule-pop").outerHeight();
      let nt = st + (wh / 2 - ph / 2) - 30;

      // No negative
      if (nt < 50) {
        nt = 50;
      }

      // Set popup top
      $("#rrule-pop").css("top", nt + "px");

      // Timed show
      setTimeout(function () {
        $("#rrule-pop").addClass("show");
        $("#rrule-pop-bg").addClass("show");
      }, 200);

      // Reset
      $("#check-month-rad1, #check-month-rad2").removeClass("selected");
      $("#check-year-rad1, #check-year-rad2").removeClass("selected");

      // Get rule from form
      let rrl = $("#recurring_rule").val();

      // No rule?
      if (rrl == "") {
        // Todays date variables
        let d = new Date();
        //var now_day = d.getDay();
        let now_day = d.getUTCDay();
        let now_day_lbl = "MO";

        // Toggle today labels
        if (now_day == "0") {
          now_day_lbl = "SU";
        } else if (now_day == "1") {
          now_day_lbl = "MO";
        } else if (now_day == "2") {
          now_day_lbl = "TU";
        } else if (now_day == "3") {
          now_day_lbl = "WE";
        } else if (now_day == "4") {
          now_day_lbl = "TH";
        } else if (now_day == "5") {
          now_day_lbl = "FR";
        } else if (now_day == "6") {
          now_day_lbl = "SA";
        }

        // Standard rule
        $("#rrule_fin").val("FREQ=WEEKLY;COUNT=1;BYDAY=" + now_day_lbl);
      } else {
        // Update
        $("#rrule_fin").val(rrl);
      }

      // Setup
      rrule.initsetup();
      // Setup
      rrule.setup();
    },
    hide: function () {
      // Hide
      $("#rrule-pop").removeClass("show");
      $("#rrule-pop-bg").removeClass("show");

      // Timed hide
      setTimeout(function () {
        // Hide
        $("#rrule-pop").hide();
        $("#rrule-pop-bg").hide();
      }, 200);

      // Get rule
      let rrl = $("#recurring_rule").val();

      // Any rule?
      if (rrl == "") {
        // Remove
        $("#recurring").val("false");
        $("#check-recurring").removeClass("selected");
        $("#recurring-rule-error").hide();
      }
    },
    generatewindow: function (f) {
      // Does window exists?
      if (!$d("rrule-pop")) {
        // Post
        $.ajax({
          type: "POST",
          url: "/source/web/templates/ajax_rrule_generator.php",
          success: function (data) {
            // Append
            $("body").append(data);
            // Initialize form fields
            rrule.initialize();
            // Bind
            $(".selectabletext").on("click", function () {
              codemarkup.selectableinputclick(this);
            });
            // Show
            rrule.show(f);
          },
        });
      } else {
        // Show
        rrule.show(f);
      }
    },
    initializedocswindow: function () {
      // General window link (used on documenation pages)
      $(".rrulegeneratewindow").on("click", function () {
        rrule.generatewindow(this);
        return false;
      });
    },
  };
})();

// Attach
$(document).ready(function () {
  rrule.initialize();
  rrule.initializedocswindow();
});

/* Copy code (MNI, 2022)
---------------------------------------------------------------- */

let copycodex = (function () {
  return {
    initialize: function () {
      // Append
      $(".copyclipbutton").on("click", function () {
        copycodex.elem(this);
      });
    },
    elem: function (f) {
      // Get target
      let rel = $(f).attr("data-clipboard-target");

      // Remove #
      rel = rel.replace("#", "");

      // Textarea or DIV?
      if ($("#" + rel).prop("type") == "textarea") {
        copycodex.copy(f);
      } else {
        copycodex.copydiv(f);
      }
    },
    copy: function (f) {
      // If the copy container doesn't exists, then add it
      if (!$("#tmpcopywrp").length) {
        // Append
        $("body").append(
          '<div id="tmpcopywrp" style="position:fixed;left:-1000px;top:0px;z-index:9999999;"><textarea id="tmpcopytext"></textarea></div>'
        );
      }

      // Get relation
      let rel = $(f).attr("data-clipboard-target");

      // Remove #
      rel = rel.replace("#", "");

      // Get content
      let ctx = $("#" + rel).val();

      // Transfer to textarea
      $("#tmpcopytext").val(ctx);

      // Select content
      $("#tmpcopytext").select();

      // Copy
      document.execCommand("copy");

      // Notify
      notificator.show("Copied", true);
    },
    copydiv: function (f) {
      // Get relation
      let rel = $(f).attr("data-clipboard-target");

      // Remove #
      rel = rel.replace("#", "");

      // Delete if exists
      if ($("#copycat").length) {
        $("#copycat").remove();
      }

      // Get content
      let con = $("#" + rel).html();

      // Add
      $("body").append(
        '<div id="copycat" style="position:fixed;left:-10000px;top:0px;z-index:9999999;">' +
          con +
          "</div>"
      );

      // Timed
      setTimeout(function () {
        // Copy
        let doc = document,
          text = doc.getElementById("copycat"),
          range,
          selection;
        if (doc.body.createTextRange) {
          range = doc.body.createTextRange();
          range.moveToElementText(text);
          range.select();
          document.execCommand("copy");
        } else if (window.getSelection) {
          selection = window.getSelection();
          range = doc.createRange();
          range.selectNodeContents(text);
          selection.removeAllRanges();
          selection.addRange(range);
          document.execCommand("copy");
        }
      }, 400);

      // Notify
      notificator.show("Copied", true);
    },
  };
})();

/* Attach */
$(document).ready(function () {
  copycodex.initialize();
});

/* Window alert
---------------------------------------------------------------- */

var windowalert = function(){

  // Stored function
  let myf = null;

  // Default labels
  let def_tit = 'We\'re sorry..', def_desc = 'Something went wrong while trying to perform an action. Please try again.', def_btnok = 'OK', def_cls = '';

  // Use labels
  let tit, desc, btnok, wincls;

  return {
    properties:function(opts){

      // Title
      if(opts && (typeof(opts.tit) !== "undefined")){
        tit = opts.tit;
      }else{
        tit = def_tit;
      }

      // Description
      if(opts && (typeof(opts.desc) !== "undefined")){
        desc = opts.desc;
      }else{
        desc = def_desc;
      }

      // Button OK
      if(opts && (typeof(opts.btnok) !== "undefined")){
        btnok = opts.btnok;
      }else{
        btnok = def_btnok;
      }

      // Window class
      if(opts && (typeof(opts.class) !== "undefined")){
        wincls = opts.class;
      }

    },
    run:function(func,opts){

      // Any properties?
      windowalert.properties(opts);

      // Notify (remove if running)
      notificator.break();

      // Store function to call if "yes"
      myf = func;

      // Kill if exists
      if($('#pop-awa').length){

        // Remove
        $('#pop-awa').remove();
        $('#pop-awa-bg').remove();

      }

      // HTML
      let ht = '';

      // Create
      ht += '<div class="popays-t1 ' + wincls + '" id="pop-awa">';
      ht += '    <div class="rel">';
      ht += '        <div class="txw">';
      ht += '            <p class="hd-t1">' + tit + '</p>';
      ht += '            <p class="tx-t1">' + desc + '</p>';
      ht += '        </div>';
      ht += '        <div class="btsw">';
      ht += '            <div class="bts-t1">';
      ht += '                <div class="flt">';
      ht += '                    <input type="button" value="' + btnok + '" class="submit full" />';
      ht += '                </div>';
      ht += '                <div class="clr"></div>';
      ht += '            </div>';
      ht += '        </div>';
      ht += '    </div>';
      ht += '</div>';
      ht += '<div class="popays-t1-bg" id="pop-awa-bg"></div>';

      // Append
      $("body").append(ht);

      // Attach
      $('#pop-awa .submit').bind('click', function(){windowalert.ok(this);return false;});

      // Show
      setTimeout(function(){

        // Add
        $('#pop-awa').addClass('show');
        $('#pop-awa-bg').addClass('show');

      }, 100);

    },
    ok:function(f){

      // Execute function
      myf();

      // Delayed hide
      setTimeout(function(){

        // Hide
        windowalert.hide();

      }, 500);

    },
    hide:function(){

      // Hide
      $('#pop-awa').removeClass('show');
      $('#pop-awa-bg').removeClass('show');

      // Delayed hide
      setTimeout(function(){

        // Hide
        $('#pop-awa').hide();
        $('#pop-awa-bg').hide();

      }, 500);

    }
  };
}();

/* AT beta popup
---------------------------------------------------------------- */
let betapopup = (function () {
  return {
    initialize: function () {
      // Attach
      $("#beta-popup-btn").on("click", function () {
        betapopup.click(this);
      });
      $("#beta-popup .close a").on("click", function () {
        betapopup.hide();
        return false;
      });
      $("#beta-popup .dontshowagain").on("click", function () {
        betapopup.dontshowagain();
        return false;
      });
      $("#beta-popup").click(function (event) {
        event.stopPropagation();
      });

      // First time trigger, have we displayed the popup?
      let promo_at_dsa_exp = cookies.read("promo_at_dsa_exp");

      // No exposure, let's popup
      if (promo_at_dsa_exp != "true") {
        // Timed show
        setTimeout(function () {
          // Click
          betapopup.click(this);
          // Set cookie
          cookies.create("promo_at_dsa_exp", "true", 365);
        }, 7000);
      }
    },
    click: function () {
      // Set click
      $("#beta-popup-btn").addClass("click");
      // Show
      betapopup.show();
    },
    show: function () {
      $("#beta-popup-bg").show();
      $("#beta-popup").show();
      // Timed show
      setTimeout(function () {
        $("#beta-popup").addClass("show");
      }, 200);
    },
    hide: function () {
      // Hide
      $("#beta-popup").removeClass("show");
      // Remove click
      $("#beta-popup-btn").removeClass("click");
      // Timed show
      setTimeout(function () {
        // Hide
        $("#beta-popup-bg").hide();
        $("#beta-popup").hide();
      }, 500);
    },
    dontshowagain: function () {
      // Hide
      $("#beta-popup").hide();
      $("#beta-popup-bg").hide();
      $("#beta-popup-btn").hide();
      // Set cookie
      cookies.create("promo_at_dsa", "true", 365);

      // Post
      $.ajax({
        type: "POST",
        url: "/source/web/actions/user/_promo.at.dontshowagain.php",
        cache: false,
      });
    },
  };
})();

/* Attach */
$(document).ready(function () {
  betapopup.initialize();
});

/* Expanding Textareas (https://github.com/bgrins/ExpandingTextareas)
---------------------------------------------------------------- */
(function (factory) {
  if (typeof define === "function" && define.amd) {
    // AMD. Register as an anonymous module.
    define(["jquery"], factory);
  } else {
    // Browser globals
    factory(jQuery);
  }
})(function ($) {
  // Class Definition
  // ================
  let Expanding = function ($textarea, options) {
    this.$textarea = $textarea;
    this.$textCopy = $("<span />");
    this.$clone = $('<pre class="expanding-clone"><br /></pre>').prepend(
      this.$textCopy
    );

    $textarea
      .wrap($('<div class="expanding-wrapper" style="position:relative" />'))
      .after(this.$clone);

    this.attach();
    this.setStyles();
    this.update();

    if (typeof options.update === "function") {
      $textarea.on("update.expanding", options.update);
    }
  };

  Expanding.DEFAULTS = {
    autoInitialize: true,
    initialSelector: "textarea.expanding",
  };

  $.expanding = $.extend({}, Expanding.DEFAULTS, $.expanding || {});

  // Returns the version of Internet Explorer or -1
  // (indicating the use of another browser).
  // From: http://msdn.microsoft.com/en-us/library/ms537509(v=vs.85).aspx#ParsingUA
  let ieVersion = (function () {
    let v = -1;
    if (navigator.appName === "Microsoft Internet Explorer") {
      let ua = navigator.userAgent;
      let re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
      if (re.exec(ua) !== null) v = parseFloat(RegExp.$1);
    }
    return v;
  })();

  // Check for oninput support
  // IE9 supports oninput, but not when deleting text, so keyup is used.
  // onpropertychange _is_ supported by IE8/9, but may not be fired unless
  // attached with `attachEvent`
  // (see: http://stackoverflow.com/questions/18436424/ie-onpropertychange-event-doesnt-fire),
  // and so is avoided altogether.
  let inputSupported =
    "oninput" in document.createElement("input") && ieVersion !== 9;

  Expanding.prototype = {
    // Attaches input events
    // Only attaches `keyup` events if `input` is not fully suported
    attach: function () {
      let events = "input.expanding change.expanding",
        _this = this;
      if (!inputSupported) events += " keyup.expanding";
      this.$textarea.on(events, function () {
        _this.update();
      });
    },

    // Updates the clone with the textarea value
    update: function () {
      this.$textCopy.text(this.$textarea.val().replace(/\r\n/g, "\n"));
      // Use `triggerHandler` to prevent conflicts with `update` in Prototype.js
      this.$textarea.triggerHandler("update.expanding");
    },

    // Tears down the plugin: removes generated elements, applies styles
    // that were prevously present, removes instance from data, unbinds events
    destroy: function () {
      this.$clone.remove();
      this.$textarea
        .unwrap()
        .attr("style", this._oldTextareaStyles || "")
        .removeData("expanding")
        .unbind(
          "input.expanding change.expanding keyup.expanding update.expanding"
        );

      delete this._oldTextareaStyles;
    },

    setStyles: function () {
      this._resetStyles();
      this._setCloneStyles();
      this._setTextareaStyles();
    },

    // Applies reset styles to the textarea and clone
    // Stores the original textarea styles in case of destroying
    _resetStyles: function () {
      this._oldTextareaStyles = this.$textarea.attr("style");

      this.$textarea.add(this.$clone).css({
        margin: 0,
        webkitBoxSizing: "border-box",
        mozBoxSizing: "border-box",
        boxSizing: "border-box",
        width: "100%",
      });
    },

    // Sets the basic clone styles and copies styles over from the textarea
    _setCloneStyles: function () {
      let css = {
        display: "block",
        border: "0 solid",
        visibility: "hidden",
        minHeight: this.$textarea.outerHeight(),
      };

      if (this.$textarea.attr("wrap") === "off") {
        css.overflowX = "scroll";
      } else {
        css.whiteSpace = "pre-wrap";
      }

      this.$clone.css(css);
      this._copyTextareaStylesToClone();
    },

    _copyTextareaStylesToClone: function () {
      let _this = this,
        properties = [
          "lineHeight",
          "textDecoration",
          "letterSpacing",
          "fontSize",
          "fontFamily",
          "fontStyle",
          "fontWeight",
          "textTransform",
          "textAlign",
          "direction",
          "wordSpacing",
          "fontSizeAdjust",
          "wordWrap",
          "word-break",
          "borderLeftWidth",
          "borderRightWidth",
          "borderTopWidth",
          "borderBottomWidth",
          "paddingLeft",
          "paddingRight",
          "paddingTop",
          "paddingBottom",
          "maxHeight",
        ];

      $.each(properties, function (i, property) {
        let val = _this.$textarea.css(property);

        // Prevent overriding percentage css values.
        if (_this.$clone.css(property) !== val) {
          _this.$clone.css(property, val);
          if (property === "maxHeight" && val !== "none") {
            _this.$clone.css("overflow", "hidden");
          }
        }
      });
    },

    _setTextareaStyles: function () {
      this.$textarea.css({
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        resize: "none",
        overflow: "auto",
      });
    },
  };

  // Plugin Definition
  // =================
  function Plugin(option) {
    if (option === "active") return !!this.data("expanding");
    this.filter("textarea").each(function () {
      let $this = $(this);
      let instance = $this.data("expanding");

      if (instance && option === "destroy") return instance.destroy();
      if (instance && option === "refresh") return instance.setStyles();

      let visible = this.offsetWidth > 0 || this.offsetHeight > 0;
      if (!visible)
        _warn(
          "ExpandingTextareas: attempt to initialize an invisible textarea. " +
            "Call expanding() again once it has been inserted into the page and/or is visible."
        );

      if (!instance && visible) {
        const options = $.extend(
          {},
          $.expanding,
          typeof option === "object" && option
        );
        $this.data("expanding", new Expanding($this, options));
      }
    });

    return this;
  }

  $.fn.expanding = Plugin;
  $.fn.expanding.Constructor = Expanding;

  function _warn(text) {
    if (window.console && console.warn) {
      console.warn(text);
    }
  }

  $(function () {
    if ($.expanding.autoInitialize) {
      $($.expanding.initialSelector).expanding();
    }
  });
});

//TODO: [AE-2480] Is it really necessary to add libraries via JS code
/* AddEvent scripts
---------------------------------------------------------------- */
(function () {
  let ae1 = document.createElement("script");
  ae1.type = "text/javascript";
  ae1.async = true;
  ae1.src =
    "https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js?cache=20180812043000";
  let s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(ae1, s);
  let ae2 = document.createElement("script");
  ae2.type = "text/javascript";
  ae2.async = true;
  ae2.src =
    "https://cdn.addevent.com/libs/stc/1.0.2/stc.min.js?cache=20180812043000";
  s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(ae2, s);
})();

/* Google tag manager
---------------------------------------------------------------- */

// Load script + gtag
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MD4C6KL3');

window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'GTM-MD4C6KL3');

/* Google tag manager custom events
---------------------------------------------------------------- */

var googtagman = function(){
  return {
    init:function(){

      // Exists?
      if($('.googtagman').length){

        // Run
        googtagman.run();

      }

    },
    run:function(){

      // Each item
      $('.googtagman').each(function(index,elem){

        // Has the event already been run?
        if(!$(this).hasClass('googisset')){

          // Get event
          let elm = $(this);
          let evt = $(elm).attr('data-event');

          // Set
          $(this).addClass('googisset');

          // Call sub functions
          googtagman.fire(evt,elm);

        }

      });

    },
    fire:function(evt,elm){

      // Variables
      let dva, dii, din, dti;

      // Login
      if(evt == 'login'){

        // Run
        gtag('event', 'login');

      }

      // Sign up
      if(evt == 'sign_up'){

        // Run
        gtag('event', 'sign_up');

      }

      // Begin checkout
      if(evt == 'begin_checkout'){

        // Get variables
        dva = $(elm).attr('data-value');
        dii = $(elm).attr('data-item-id');
        din = $(elm).attr('data-item-name');

        // Run
        gtag("event", "begin_checkout", {
          currency: "USD",
          value: dva,
          items: [{
            item_id: dii,
            item_name: din
          }]
        });

      }

      // Purchase
      if(evt == 'purchase'){

        // Get variables
        dva = $(elm).attr('data-value');
        dii = $(elm).attr('data-item-id');
        din = $(elm).attr('data-item-name');
        dti = $(elm).attr('data-transaction-id');

        // Run
        gtag("event", "purchase", {
          transaction_id: dti,
          currency: "USD",
          value: dva,
          items: [{
            item_id: dii,
            item_name: din
          }]
        });

      }

    },
    getaccountsignupcheckout:function(){

      // Get selected item
      let pln = $('#your-order .planitem:visible').first();

      // Get plan and price items
      let pri = $(pln).find('.gtmprice').val();
      let ppt = $(pln).find('.gtmplantype').val();
      let ppd = $(pln).find('.gtmplanid').val();
      let pys = $(pln).find('.gtmplanschedule').val();

      // The plan
      let pla = ppt + ' - ' + pys;

      // Create checkout
      googtagman.createcheckout('begin_checkout', pri, ppt, ppd, pla);

    },
    getaccountcheckout:function(){

      // Get active plan
      let pln = $('#pypo-plans .cycleopt.active');

      // Get plan and price items
      let pri = $(pln).attr('data-price');
      let ppt = $(pln).attr('data-plan-type');
      let ppd = $(pln).attr('data-plan-id');
      let pys = $(pln).attr('data-payment-schedule');

      // The plan
      let pla = ppt + ' - ' + pys;

      // Create checkout
      googtagman.createcheckout('begin_checkout', pri, ppt, ppd, pla);

    },
    createcheckout:function(typ,pri,ppt,ppd,pla){

      // Append
      $('body').append('<div class="googtagman hide" data-event="' + typ + '" data-value="' + pri + '" data-item-id="' + ppd + '" data-item-name="' + pla + '"></div>');

      // Run
      googtagman.run();

    }
  };
}();

// Attach
$(document).ready(function () {
  googtagman.init();
});

initializeTrackerEvents(aeTracker);
