/* Top menu and general stuff
---------------------------------------------------------------- */
export const topmenu = {
  // Variables
  proexptim: null,
  proexphid: null,

  initialize: function () {
    // Attach
    $("#sidebarleft .newtypeobj").on("click", function () {
      topmenu.newtypeobjshow(this);
      return false;
    });
    $(".top .tooltypeobj").on("click", function () {
      topmenu.tooltypeobjshow(this);
      return false;
    });
    $(".top .accountshtobj").on("click", function () {
      topmenu.accountshtobjshow(this);
      return false;
    });
    $(".top .accounthlpobj").on("click", function () {
      topmenu.accounthlpobjshow(this);
      return false;
    });
    $(".top .proexp").on("click", function () {
      topmenu.proexpshow(this);
      return false;
    });
    $(".top .proexp, .top #proexp-drop .ret").on("mouseover", function () {
      topmenu.proexpshow(this);
    });
    $(".top #proexp-drop").on("mouseover", function () {
      topmenu.proexpclr(this);
    });
    $(".top .proexp").on("mouseout", function () {
      topmenu.proexpouttim(this);
    });
    $(".top #proexp-drop").on("mouseout", function () {
      topmenu.proexpouttim(this);
    });
    $(".top #proexp-drop").on("mouseout", function () {
      topmenu.proexpouttim(this);
    });
    $(".top #mnu-ipm-drp .did").on("click", function () {
      topmenu.ipmmnushow(this);
      return false;
    });
    $(".top #mnu-ipm-drp .close").on("click", function () {
      topmenu.ipmmnuhide(this);
      return false;
    });
    $(".top #mnu-ipm-drp").on("click", function (event) {
      event.stopPropagation();
    });
    $(".footer-t1 .dropbtn").on("click", function () {
      return false;
    });

    // Mobile left menu
    $("#mlmu").on("click", function () {
      topmenu.mlmushow(this);
      return false;
    });
    $("#mlmu").on("click", function (event) {
      event.stopPropagation();
    });

    // Back to's
    $(
      ".opera .navback, .opera .navbacktotemplates, .opera .navbacktocalendarview, .opera .navbacktoeventview"
    ).on("click", function () {
      topmenu.backto(this);
      return false;
    });
    $(".opera .navbacktodashboard").on("click", function () {
      topmenu.backto(this, "dashboardfi");
      return false;
    });
    $(".eventform1 .sbt-t1 .cancel").on("click", function () {
      topmenu.backto(this, "dashboard");
      return false;
    });
    $(".eventform2 .sbt-t1 .cancel").on("click", function () {
      topmenu.backto(this, "dashboard");
      return false;
    });
    // Prevent
    $("#tooltypeobjdrop").on("click", function (event) {
      event.stopPropagation();
    });
    $("#accountshtobjdrop").on("click", function (event) {
      event.stopPropagation();
    });
  },
  backto: function (f, d) {
    // Get variables
    let trf = $("#doc").attr("data-ref");
    let ddr = $("#doc").attr("data-dashboard-ref");
    let der = $("#doc").attr("data-event-ref");
    let brf = $("#doc").attr("data-base-ref");
    let ref = trf.substring(1);
    let bef = brf.substring(1);
    let dty = $(f).attr("data-type");
    let dtyOrg = $(f).attr("data-type");
    let dra = $(f).attr("data-area");
    let cal = $(f).attr("data-calendar");
    let evt = $(f).attr("data-event");
    let dts = $(f).attr("data-temp-section");

    // Set if "d" exists
    if (d != null) {
      dty = d;
    }

    // Split return path
    let res = ref.split("/");
    let a0 = "",
      a1 = "",
      a2 = "",
      a3 = "",
      a4 = "",
      a5 = "";
    if (res[0] != null) {
      a0 = res[0];
    }
    if (res[1] != null) {
      a1 = res[1];
    }
    if (res[2] != null) {
      a2 = res[2];
    }
    if (res[3] != null) {
      a3 = res[3];
    }
    if (res[4] != null) {
      a4 = res[4];
    }
    if (res[5] != null) {
      a5 = res[5];
    }

    // Split base path
    let bes = bef.split("/");
    let b0 = "",
      b1 = "",
      b2 = "",
      b3 = "",
      b4 = "",
      b5 = "";
    if (bes[0] != null) {
      b0 = bes[0];
    }
    if (bes[1] != null) {
      b1 = bes[1];
    }
    if (bes[2] != null) {
      b2 = bes[2];
    }
    if (bes[3] != null) {
      b3 = bes[3];
    }
    if (bes[4] != null) {
      b4 = bes[4];
    }
    if (bes[5] != null) {
      b5 = bes[5];
    }

    // Toggle back's
    if (d == "dashboardfi") {
      if (dtyOrg == "dashboard" && cal == "new") {
        location.href = "/calendars";
      } else {
        if (cal != "" && typeof cal !== "undefined") {
          location.href = "/calendars/" + cal;
        } else {
          location.href = "/calendars";
        }
      }
    } else if (dty == "dashboard") {
      if (
        (a0 == "calendars" && a1 != "" && a2 == "") ||
        (a0 == "calendars" &&
          a1 != "" &&
          (a2 == "schedule" || a2 == "month" || a2 == "all-events"))
      ) {
        history.back();
      } else if (cal != "" && ddr != "") {
        location.href = ddr;
      } else if (cal != "") {
        location.href = "/calendars/" + cal;
      } else {
        location.href = "/calendars";
      }
    } else if (dty == "general") {
      if (a0 == "calendars") {
        history.back();
      } else {
        if (trf != "") {
          location.href = trf;
        } else {
          location.href = "/calendars";
        }
      }
    } else if (dty == "templates") {
      if (dra != "" && typeof dra !== "undefined") {
        // When we're in "Back to templates"

        if (dra == "events") {
          location.href = "/templates/events";
        } else if (dra == "calendar") {
          location.href = "/templates/calendar";
        } else if (dra == "rsvpforms") {
          location.href = "/templates/rsvpforms";
        } else if (dra == "calendar-embed") {
          location.href = "/templates/calendar-embed";
        } else {
          location.href = "/calendars";
        }
      } else {
        // When we're in templates -> "Back"
        // User originates from an event, a calendar, or has opened the
        // page as a new window (no reference)

        if ((dts == "events" || dts == "rsvpforms") && der != "") {
          location.href = der;
        } else if (
          (dts == "calendar" || dts == "calendar-embed") &&
          ddr != ""
        ) {
          location.href = ddr + "/view";
        } else {
          location.href = "/calendars";
        }
      }
    } else if (dty == "calendarview") {
      if (a0 == "calendars" && a1 != "" && a2 == "view") {
        history.back();
      } else if (a0 == "calendars" && a1 != "" && a2 == "subscribers") {
        location.href = "/calendars/" + a1 + "/view";
      } else {
        location.href = "/calendars";
      }
    } else if (dty == "eventview") {
      if (
        a0 == "calendars" &&
        a1 != "" &&
        a2 == "event" &&
        a3 != "" &&
        a4 == "attendees"
      ) {
        location.href = "/calendars/" + cal + "/event/" + evt + "/view";
      } else if (a0 == "calendars" && a1 != "" && a2 == "event" && a3 != "") {
        history.back();
      } else {
        location.href = "/calendars";
      }
    }
  },
  proexpshow: function () {
    // Clear
    clearTimeout(this.proexptim);

    // Timed show
    this.proexphid = setTimeout(function () {
      // Show
      $("#proexp-drop").show();

      // Get position of "proexp" and screen
      let ol = $(".proexp").offset().left;
      let sw = $(window).width();

      // Set position
      let tx = parseInt((ol * 100) / sw);

      // Remove aligns
      $("#proexp-drop").removeClass("setlft");

      // If less than, align left
      if (tx < 30) {
        $("#proexp-drop").addClass("setlft");
      }

      // Show
      setTimeout(function () {
        $("#proexp-drop").addClass("active");
      }, 10);
    }, 300);
  },
  proexpclr: function () {
    // Clear timer
    clearTimeout(this.proexptim);
    clearTimeout(this.proexphid);
  },
  proexpouttim: function () {
    // Timed hide
    clearTimeout(this.proexptim);
    clearTimeout(this.proexphid);
    this.proexptim = setTimeout(function () {
      topmenu.proexphide();
    }, 300);
  },
  proexphide: function () {
    // Hide
    $("#proexp-drop").removeClass("active");
    // Hide
    this.proexphid = setTimeout(function () {
      $("#proexp-drop").hide();
    }, 300);
  },
  newtypeobjshow: function () {
    // Clear
    topmenu.clearall("newtypeobj");
    // Show / Hide
    if ($("#newtypeobjdrop").hasClass("show")) {
      // Hide
      topmenu.newtypeobjhide();
    } else {
      // Show + set active
      $("#sidebarleft .newtypeobj").addClass("active");

      // Show
      $("#newtypeobjdrop").show();

      // Timed show
      setTimeout(function () {
        $("#newtypeobjdrop").addClass("show");
      }, 200);

      // Unbind + Timed
      $(document).off(".newtypeobjdrop");
      setTimeout(function () {
        $(document).on("click.newtypeobjdrop", function () {
          topmenu.newtypeobjhide();
        });
      }, 200);
    }
  },
  newtypeobjhide: function () {
    // Hide + remove active
    $("#sidebarleft .newtypeobj").removeClass("active");
    $("#newtypeobjdrop").removeClass("show");

    // Timed hide
    setTimeout(function () {
      $("#newtypeobjdrop").hide();
    }, 200);

    // Unbind
    $(document).unbind(".newtypeobjdrop");
    $(document).unbind(".newtypeobjdrop");
  },
  tooltypeobjshow: function () {
    // Clear
    topmenu.clearall("tooltypeobj");

    // Show / Hide
    if ($("#tooltypeobjdrop").hasClass("show")) {
      // Hide
      topmenu.tooltypeobjhide();
    } else {
      // Show + set active
      $(".top .tooltypeobj").addClass("active");
      $("#tooltypeobjdrop").show();

      // Timed show
      setTimeout(function () {
        $("#tooltypeobjdrop").addClass("show");
      }, 200);

      // Unbind + Timed
      $(document).unbind(".tooltypeobjdrop");
      setTimeout(function () {
        $(document).on("click.tooltypeobjdrop", function () {
          topmenu.tooltypeobjhide();
        });
      }, 200);
    }
  },
  tooltypeobjhide: function () {
    // Hide + remove active
    $(".top .tooltypeobj").removeClass("active");
    $("#tooltypeobjdrop").removeClass("show");

    // Timed hide
    setTimeout(function () {
      $("#tooltypeobjdrop").hide();
    }, 200);

    // Unbind
    $(document).unbind(".tooltypeobjdrop");
    $(document).unbind(".tooltypeobjdrop");
  },
  accountshtobjshow: function () {
    // Clear
    topmenu.clearall("accountshtobj");
    // Show / Hide
    if ($("#accountshtobjdrop").hasClass("show")) {
      // Hide
      topmenu.accountshtobjhide();
    } else {
      // Show + set active
      $(".top .accountshtobj").addClass("active");
      $("#accountshtobjdrop").show();

      // Timed show
      setTimeout(function () {
        $("#accountshtobjdrop").addClass("show");
      }, 200);

      // Unbind + Timed
      $(document).unbind(".accountshtobjdrop");
      setTimeout(function () {
        $(document).on("click.accountshtobjdrop", function () {
          topmenu.accountshtobjhide();
        });
      }, 200);
    }
  },
  accountshtobjhide: function () {
    // Hide + remove active
    $(".top .accountshtobj").removeClass("active");
    $("#accountshtobjdrop").removeClass("show");

    // Timed hide
    setTimeout(function () {
      $("#accountshtobjdrop").hide();
    }, 200);

    // Unbind
    $(document).unbind(".accountshtobjdrop");
  },
  accounthlpobjshow: function () {
    // Clear
    topmenu.clearall("accounthlpobj");

    // Show / Hide
    if ($("#accountshthlpdrop").hasClass("show")) {
      // Hide
      topmenu.accounthlpobjhide();
    } else {
      // Show + set active
      $(".top .accounthlpobj").addClass("active");
      $("#accountshthlpdrop").show();

      // Timed show
      setTimeout(function () {
        $("#accountshthlpdrop").addClass("show");
      }, 200);

      // Unbind + Timed
      $(document).unbind(".accounthlpobdrop");
      setTimeout(function () {
        $(document).on("click.accounthlpobdrop", function () {
          topmenu.accounthlpobjhide();
        });
      }, 200);
    }
  },
  accounthlpobjhide: function () {
    // Hide + remove active
    $(".top .accounthlpobj").removeClass("active");
    $("#accountshthlpdrop").removeClass("show");

    // Timed hide
    setTimeout(function () {
      $("#accountshthlpdrop").hide();
    }, 200);

    // Unbind
    $(document).off(".accounthlpobdrop");
    $(document).off(".accounthlpobdrop");
  },
  clearall: function (o) {
    // Clear all
    if (o != "newtypeobj") {
      topmenu.newtypeobjhide();
    }
    if (o != "tooltypeobj") {
      topmenu.tooltypeobjhide();
    }
    if (o != "accountshtobj") {
      topmenu.accountshtobjhide();
    }
    if (o != "accounthlpobj") {
      topmenu.accounthlpobjhide();
    }
  },
  mlmushow: function (f) {
    // Show
    if ($(".cont-t1 .left").hasClass("active")) {
      // Hide
      topmenu.mlmuhide(f);
    } else {
      // Show
      $(".cont-t1 .left").addClass("active");

      // Update labels
      $("#mlmu-lbl").text($("#mlmu-lbl").attr("data-close"));

      // Unbind + Timed
      $(document).off(".mlmuobj");
      setTimeout(function () {
        $(document).on("click.touchup.mlmuobj", function () {
          topmenu.mlmuhide();
        });
      }, 200);
    }
  },
  mlmuhide: function () {
    // Hide
    $(".cont-t1 .left").removeClass("active");

    // Update labels
    $("#mlmu-lbl").text($("#mlmu-lbl").attr("data-menu"));

    // Unbind
    $(document).off(".mlmuobj");
  },
  ipmmnushow: function (f) {
    // Show
    if ($("#mnu-ipm-drp .dr").is(":visible")) {
      // Hide
      topmenu.ipmmnuhide(f);
    } else {
      // Show
      $("#mnu-ipm-drp .dr").show();
      $(".top #mnu-ipm-drp .did").addClass("active");

      // Unbind + Timed
      $(document).off(".ipmmnuobj");
      setTimeout(function () {
        $(document).on("click.touchup.ipmmnuobj", function () {
          topmenu.ipmmnuhide();
        });
      }, 200);
    }
  },
  ipmmnuhide: function () {
    // Hide
    $("#mnu-ipm-drp .dr").hide();
    $(".top #mnu-ipm-drp .did").removeClass("active");

    // Unbind
    $(document).off(".ipmmnuobj");
  },
};

// Attach
$(document).ready(function () {
  topmenu.initialize();
});
