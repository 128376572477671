/* Validation
---------------------------------------------------------------- */
export const validate = {
  email: function (str) {
    let filter = /^[^\s@]+@[^\s@]+\.[a-z]{2,20}$/i;
    if (filter.test(str)) {
      return true;
    } else {
      return false;
    }
  },
  empty: function (str) {
    let filter = /^\s+$/;
    let expression;
    if (filter.test(str) || str == "") {
      expression = false;
    } else {
      expression = true;
    }
    return expression;
  },
  onlynumbers: function (evt) {
    let charCode = evt.which ? evt.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  },
  passwordLength: function (str) {
    return str.length >= 14;
  },
  containsLowercase: function (str) {
    return /[a-z]/.test(str);
  },
  containsUppercase: function (str) {
    return /[A-Z]/.test(str);
  },
  containsNumbers: function (str) {
    return /[0-9]/.test(str);
  },
  containsSpecial: function (str) {
    return /[`!@#$%^&*()_\-=+;:,.\/?]/.test(str);
  },
};
