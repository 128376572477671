"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PlanGroupData {
    constructor(item) {
        this.id = 0;
        this.name = "";
        if (typeof item.id !== "undefined") {
            this.id = item.id;
        }
        if (typeof item.name !== "undefined") {
            this.name = item.name;
        }
    }
}
exports.default = PlanGroupData;
