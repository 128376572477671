/* global grecaptcha */
/* global captchaApiKey */

import { fileload } from "./fileloader";
/* reCAPTCHA v3
---------------------------------------------------------------- */
export const grecapform = {
  grecapready: false,
  grecapformobj: null,
  grecapformname: null,
  grecaptim: null,

  /**
   *
   * @param {JQuery} formObj
   */
  run: function (formObj) {
    // Run until ready
    this.grecaptim = setInterval(function () {
      // Test if ready
      grecapform.execute(formObj);
    }, 500);
  },
  /**
   * @param {JQuery} formObj
   */
  execute: function (formObj) {
    if (typeof formObj !== "object") {
      console.log(formObj);
    }

    let formName = formObj.attr("data-grecapform");

    // Is the object ready to execute?
    if (typeof grecaptcha === "object") {
      // Clear interval
      let tokenObj = formObj.find("input[name=grecaptcha_token]");

      if (tokenObj.length === 0) {
        tokenObj = $('<input type="hidden" name="grecaptcha_token" value=""/>');
        formObj.prepend(tokenObj);
      }

      let actionObj = formObj.find("input[name=grecaptcha_action]");

      if (actionObj.length === 0) {
        actionObj = $(
          '<input type="hidden" name="grecaptcha_action" value=""/>'
        );
        formObj.prepend(actionObj);
      }

      // Google reCAPTCHA
      grecaptcha
        .execute(captchaApiKey, { action: formName })
        .then(function (token) {
          tokenObj.val(token);
          actionObj.val(formName);
        });

      clearInterval(this.grecaptim);
    }
  },
  setready: function () {
    // Set ready
    this.grecapready = true;
  },
  coveredForms: function () {
    return $(
      "form[id=form-signup], form[id=form-signin], form[id=form-invited], form[id=form-forgot], form[id=users-man-form]"
    );
  },
};

// Callback function when Google reCAPTCHA has loaded
const grecaptchaloaded = function () {
  // Set ready
  grecapform.setready();
};

$(document).ready(function () {
  let items = grecapform.coveredForms();

  if (items.length > 0) {
    console.log("loading captcha lib");
    fileload.load(
      "https://www.google.com/recaptcha/api.js?render=" + captchaApiKey,
      "js",
      true,
      grecaptchaloaded
    );
  }
});
