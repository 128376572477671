/* Onboarding
---------------------------------------------------------------- */
export const introt1 = {
  initialize: function () {
    // Exists?
    if ($('#int-t1').length) {
      // Attach
      $('#introt1 .addfield').on('click', function () { introt1.fieldsshow(this); return false; });
      $('#int-t1 .next').on('click', function () { introt1.s2(this); return false; });
      $('#int-t1 .skip').on('click', function () { introt1.skip(this); return false; });
      $('#int-t1 .restart').on('click', function () { introt1.restart(this); return false; });
      $('#int-t1 .done').on('click', function () { introt1.done(this); return false; });
      $('#int-st2 .next').on('click', function () { introt1.s3(this); return false; });
      $('#int-st3 .next').on('click', function () { introt1.s4(this); return false; });
      $('#int-st4 .next').on('click', function () { introt1.s5(this); return false; });

      // Disable links
      $('#int-st4 .m11').on('click', function () { return false; });
      // Show
      this.s1();

      // Remove "myevent" onclick
      setTimeout(function () { $('#int-t1 .myevent').unbind('click'); $('#int-t1 .myevent').click(function () { return false; }); }, 500);
      setTimeout(function () { $('#int-t1 .myevent').unbind('click'); $('#int-t1 .myevent').click(function () { return false; }); }, 1000);
      setTimeout(function () { $('#int-t1 .myevent').unbind('click'); $('#int-t1 .myevent').click(function () { return false; }); }, 1500);
      setTimeout(function () { $('#int-t1 .myevent').unbind('click'); $('#int-t1 .myevent').click(function () { return false; }); }, 2000);
      setTimeout(function () { $('#int-t1 .myevent').unbind('click'); $('#int-t1 .myevent').click(function () { return false; }); }, 4500);
    }
  },
  s1: function () {
    // Show
    $('#int-t1-bg').show();
    // Timed
    setTimeout(function () {
      $('#int-t1').addClass('show');
    }, 200);
  },
  s2: function () {
    // Hide
    //$('#int-t1').hide();
    $('#int-t1').removeClass('show');
    // Show
    $('#int-st2').addClass('show');
    // Show
    $('#int-st2-noti').addClass('show');
  },
  s3: function () {
    // Get positions
    let elm = $('#maincalendar').find('tr').eq(2).find('td').first();
    let xo = $('.left-t1').width();

    // Adjust
    $('#int-st3').css('left', (xo + 3) + 'px');
    $('#int-st3').css('top', (parseInt($(elm).offset().top) - 45 + 4) + 'px');
    // Hide
    $('#int-st2').hide();
    // Show
    $('#int-st3').show();

    setTimeout(function () { $('#int-st3-a').addClass('show'); }, 100);
    setTimeout(function () { $('#int-st3-b').addClass('show'); }, 300);
    setTimeout(function () { $('#int-st3-c').addClass('show'); }, 1200);
  },
  s4: function () {
    // Hide
    $('#int-st3').hide();
    // Show
    $('#int-st4').show();

    setTimeout(function () { $('#int-st4-a').addClass('show'); }, 500);
    setTimeout(function () { $('#int-st4-b').addClass('show'); }, 1000);
  },
  s5: function () {
    // Hide
    $('#int-st4').hide();
    // Show / hide
    $('#int-t1').addClass('show');
    $('#int-t1-a').hide();
    $('#int-t1-b').show();
  },
  skip: function () {
    // Hide
    $('#int-t1-bg').remove();
    $('#int-t1').remove();

    // Reload
    location.reload();
  },
  restart: function () {
    // Remove style attribute
    $("#int-st2, #int-st3, #int-st4").removeAttr("style")
    // Show
    $('#int-st2, #int-st2-noti, #int-st3, #int-st3-a, #int-st3-b, #int-st3-c, #int-st4, #int-st4-a, #int-st4-b').removeClass('show');
    // Timed next
    setTimeout(function () {
      introt1.s2(this);
    }, 300);
  },
  done: function () {
    // Hide
    $('#int-t1-bg').remove();
    $('#int-t1').remove();
    // Reload
    location.reload();
  }
};
