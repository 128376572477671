/* Address lookup
---------------------------------------------------------------- */

import { tooltip } from "./tooltip";
import { cookies } from "./cookies";

export const addresslookup = (function () {
  // Variables
  let adrfits = 0;

  return {
    initialize: function () {
      // Attach
      $(".addresshelp, .addresshelpnotzid, .timezonechg").on(
        "click",
        function () {
          addresslookup.show(this);
          return false;
        }
      );
      $(window).on("resize", function () {
        addresslookup.hide();
      });
    },
    show: function (f, cls) {
      // Hide tooltip
      tooltip.hide();
      // Timezone value
      let tzidval = "";

      // Get timezone
      if ($(f).hasClass("timezonechg")) {
        tzidval = $(f).text();
      } else if (
        ($(f).hasClass("addresshelp") || $(f).hasClass("addresshelpnotzid")) &&
        $(f).attr("data-zone") !== undefined
      ) {
        tzidval = $(f).attr("data-zone");
      }

      // Remove container
      $("body").find(".addressctn").remove();

      // Variables
      let html = "",
        ofw = $(f).width(),
        ofl = $(f).offset().left,
        oft = $(f).offset().top;

      /* Popup */
      html += '<div class="addresslookup tziddrop" id="adrlook">';
      html += '	<div class="he" id="addresslookup-form">';
      html +=
        '		<input type="text" name="add" value="" placeholder="Enter country, capital or address.." autocomplete="off" class="address" />';
      html += '		<div class="sic submit">';
      html += '			<div class="rl">';
      html += '				<i class="material-icons">search</i>';
      html += "			</div>";
      html += "		</div>";
      html += '		<div class="clc">';
      html += '			<div class="rl">';
      html += '				<i class="material-icons">close</i>';
      html += "			</div>";
      html += "		</div>";
      html += '		<div class="ldc load"></div>';
      html += "	</div>";
      html += '	<div class="list">';
      html += '		<div class="sugg">';
      html += "			<p>Suggestions</p>";
      html += '			<a href="#" title="" class="clear">Clear</a>';
      html += "		</div>";
      html +=
        '		<div id="adrlook-nores" class="hide"><p>Your search did not return any results.</p></div>';
      html += '		<ul class="result" id="adrlook-items"></ul>';
      html += '		<ul id="known-items" class="loading"></ul>';
      html += "	</div>";
      html += "</div>";

      // Add container
      $("body").append(
        '<div class="addressctn" style="width:' +
          ofw +
          "px;left:" +
          ofl +
          "px;top:" +
          oft +
          'px;"><div class="adrctninx">' +
          html +
          "</div></div>"
      );

      // Any class to apply?
      if (cls == "exttzid") {
        $(".addressctn").addClass("exttzid");
      }

      // Get know time zones
      $.ajax({
        type: "POST",
        url: "/source/web/templates/ajax_get_timezones_list.php",
        cache: true,
        success: function (data) {
          // Remove loading
          $(".addresslookup #known-items").removeClass("loading");
          // Append
          $(".addresslookup #known-items").html(data);
          // Pre-selected element?
          let pelm = null;
          // Find tooltip
          $(".addresslookup #known-items li").each(function () {
            // Get zone
            let zo = $(this).attr("data-zone");

            // Match
            if (zo == tzidval) {
              // Set selected
              $(this).addClass("selected");
              // Set
              pelm = $(this);
            }
          });

          // Scroll to position
          if (pelm) {
            // Timed
            setTimeout(function () {
              // Get position
              let x2 = $(pelm).position().top;
              // Scroll to
              $("#adrlook .list").scrollTop(x2);
            }, 400);
          }

          // Attach
          $("#known-items li").on("click", function () {
            addresslookup.apply(this);
            return false;
          });
        },
      });

      // Get height
      let ww = $(window).width();
      let eh = $(".addresslookup").outerHeight();
      let ew = $(".addresslookup").outerWidth();
      let ol = $(".addresslookup").offset().left;

      // Set left
      if (ol + ew > ww) {
        $(".addresslookup").css("left", ww - ew - ol - 50 + "px");
      }

      // Set top
      $(".addresslookup").css("top", -(eh / 4) + "px");

      // Show
      setTimeout(function () {
        $("#adrlook").addClass("show");
      }, 200);

      // Attach
      $("#addresslookup-form .address").keypress(function (e) {
        // Enter
        if (e.which == "13") {
          // Lookup
          addresslookup.getaddress();
          // Prevent
          e.preventDefault();
        }
      });

      // Append
      $("#addresslookup-form .submit").on("click", function () {
        addresslookup.getaddress();
        return false;
      });
      $("#adrlook .apply").on("click", function () {
        addresslookup.apply(this);
        return false;
      });
      $(".addresslookup .clear, .addresslookup .clc").on("click", function () {
        addresslookup.clear(this);
        return false;
      });
      $("#adrlook").on("click", function (event) {
        event.stopPropagation();
      });

      // Unbind
      $(document).unbind(".addresslookuppopup");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).on("click.addresslookuppopup", function () {
          addresslookup.hide();
        });
        // Focus
        $("#addresslookup-form .address").focus();
      }, 200);
    },
    hide: function () {
      // Show
      $("#adrlook").removeClass("show");
      // Remove extra classes
      $(".addressctn").removeClass("exttzid");
    },
    clear: function () {
      // Clear search value
      $(".addresslookup .address").val("");
      $(".addresslookup .address").focus();

      // Hide search suggestions
      $(".addresslookup .submit").show();
      $(".addresslookup .clc").hide();
      $(".addresslookup .load").hide();
      $(".addresslookup .sugg").hide();
      $(".addresslookup #adrlook-nores").hide();
      $(".addresslookup .result").hide();

      // Known items
      $(".addresslookup #known-items").show();
    },
    apply: function (f) {
      // Result value
      let val = $(f).attr("data-zone");
      let off = $(f).attr("data-offset");

      // Set
      $(".zonecodeupd, .timezonechg, #external-timezone .zon-t1 .lbl").html(
        val
      );
      $(".timezone, #external-timezone #ext-cal-zone").val(val);

      // Update address help title
      $(".addresshelp, .timezonechg").attr(
        "data-title",
        "(" + off + ") " + val
      );
      $(".addresshelp, .timezonechg").attr("data-zone", val);
      $(".addresshelp p").text(off);
      $(".addresshelp .bt").html(
        '<span class="lbl">(' + off + ")</span> " + val
      );

      // Set cookie
      cookies.create("timezone", val, 365);
      cookies.create("timezoneoffset", off, 365);

      // Show / hide (eventform #1)
      $(".eventform1 #tzid-on").show();
      $(".eventform1 #tzid-off").hide();

      // Hide
      addresslookup.hide();

      // Unbind
      $(document).unbind(".addresslookuppopup");
    },
    getaddress: function () {
      // Get address
      let address = $(".addresslookup .address").val();

      // Any address?
      if (address != "") {
        // Show loader
        $("#adrlook .load").show();
        // Reset list
        $("#adrlook-items").html("");
        // Reset counter
        adrfits = 0;

        // Call
        $.ajax({
          dataType: "json",
          url:
            "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            encodeURIComponent(address) +
            "&key=AIzaSyD20yeadofoNcyi7FjOTAl7ne5lpDVHR00",
          success: function (data) {
            // Get status
            if (data.status == "OK") {
              // Get results
              $.each(data.results, function (key, val) {
                // Get zoneid
                addresslookup.getzone(
                  val.geometry.location.lat,
                  val.geometry.location.lng
                );
              });

              // Timed
              setTimeout(function () {
                // Hide loader
                $("#adrlook .load").hide();
                // Show clear
                $("#adrlook .clc").show();

                // Hide no results
                $("#adrlook-nores").hide();

                // Show
                $("#adrlook-items").show();
                $(".addresslookup .sugg").show();
                $(".addresslookup #adrlook-items").show();

                // Hide
                $(".addresslookup #known-items").hide();

                // Show result
                $(".addresslookup .result").show();
              }, 1000);
            } else {
              // Timed
              setTimeout(function () {
                // Hide loader
                $("#adrlook .load").hide();
                // Hide results
                $("#adrlook-items").hide();
                // Show result
                $("#adrlook-nores").show();
                // Show
                $(".addresslookup .result").show();
              }, 1000);
            }
          },
          error: function () {
            // Timed
            setTimeout(function () {
              // Hide loader
              $("#adrlook .load").hide();
              // Hide results
              $("#adrlook-items").hide();
              // Show result
              $("#adrlook-nores").show();
            }, 1000);
          },
        });
      }
    },
    getzone: function (lat, lng) {
      // Zone
      let zone = "",
        zoneFin = "";
      // Timestamp
      let stamp = Math.round(new Date().getTime() / 1000).toString();
      // Call
      $.ajax({
        dataType: "json",
        url: "/geolocation-to-tzid/?location=" + lat + "," + lng,
        success: function (data) {
          // Get zone id
          zone = data.timeZoneId;
          // Set
          zoneFin = zone;

          // Get offset
          let tmp =
              (parseInt(data.rawOffset) + parseInt(data.dstOffset)) / 60 / 60,
            det = "+",
            pt1 = "",
            pt2 = "";
          // Determine if + -  none
          if (tmp < 0) {
            det = "-";
          } else if (tmp == 0) {
            det = "";
          }
          // Convert to string
          tmp = tmp.toString();

          // Remove + -
          tmp = tmp.replace("+", "");
          tmp = tmp.replace("-", "");
          tmp = tmp.replace(",", ":");
          tmp = tmp.replace(".", ":");

          // Split
          let res = tmp.split(":");
          if (res[0] == undefined) {
            pt1 = "0";
          } else {
            pt1 = res[0];
          }
          if (res[1] == undefined) {
            pt2 = "0";
          } else {
            pt2 = res[1];
          }

          // Set
          if (pt1.length == "1") {
            pt1 = "0" + pt1;
          }
          if (pt2 == "5") {
            pt2 = "30";
          } else {
            pt2 = "00";
          }

          // Final
          let timz = det + pt1 + ":" + pt2;
          // Valid?
          if (typeof zone !== "undefined") {
            // Show max 10 results
            if (adrfits < 10) {
              let newListItem = $("<li></li>");
              newListItem.attr("data-zone", zoneFin);
              newListItem.attr("data-offset", "GMT" + timz);
              newListItem.on("click", function () {
                addresslookup.apply(this);
              });

              let offsetSpan = $("<span>(GMT" + timz + ")</span>");
              offsetSpan.addClass("offst");

              let innerSpan = $("<span>" + zoneFin + "</span>");
              innerSpan.addClass("nam");

              newListItem.append(offsetSpan);
              newListItem.append(innerSpan);
              $("#adrlook-items").append(newListItem);
              // Count
              adrfits++;
            }
          }

          // Return
          return zone;
        },
      });
    },
    notimezone: function () {
      // only comments
    },
  };
})();

// Attach
$(document).ready(function () {
  addresslookup.initialize();
});
