/* Fileloader
---------------------------------------------------------------- */
export const fileload = {
  load: function (filename, filetype, async, callback) {
    // Variable
    let s = null;
    let loadAsync = false;

    if (typeof async === "boolean") {
      loadAsync = async;
    }

    // File type
    if (filetype == "js") {
      // Append
      s = document.createElement("script");
      s.type = "text/javascript";
      s.src = filename;
      s.async = loadAsync;
      if (typeof callback === "function") {
        s.onload = callback();
      }
    } else if (filetype == "css") {
      // Append
      s = document.createElement("link");
      s.rel = "stylesheet";
      s.type = "text/css";
      s.href = filename;
    }

    if (s !== null) {
      $("head").append(s);
    }
  },
};
