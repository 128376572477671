"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PlanData {
    constructor(item) {
        this.pid = 0;
        this.plan_id = 0;
        this.payment_schedule = '';
        this.plan_type_id = 0;
        this.plan_type_name = '';
        if (typeof item.pid !== 'undefined') {
            this.pid = item.pid;
        }
        if (typeof item.plan_id !== "undefined") {
            this.plan_id = item.plan_id;
        }
        if (typeof item.payment_schedule !== "undefined") {
            this.payment_schedule = item.payment_schedule;
        }
        if (typeof item.plan_type_id !== "undefined") {
            this.plan_type_id = item.plan_type_id;
        }
        if (typeof item.plan_type_name !== "undefined") {
            this.plan_type_name = item.plan_type_name;
        }
    }
    isAnnual() {
        return this.payment_schedule === "annual";
    }
    getPlanTypeName() {
        return this.plan_type_name;
    }
    getPlanTypeId() {
        return this.plan_type_id;
    }
    getPlanId() {
        return this.plan_id;
    }
}
exports.default = PlanData;
